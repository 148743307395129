import {AbstractCommand} from './abstract-command';

export class ContrattoCommand extends AbstractCommand {
    servizio: string;
    autoApprovazione: boolean;
    prezzo1: number;
    prezzo2: number;
    prezzo3: number;
    livelloServizioAttivazione: number;
    livelloServizioIntervento: number;
    dataStipula: Date;
    durata: number;
    dataScadenza: Date;
    rti: boolean;
    prescrittoreXml: boolean;
    cig: string;
    aziendaId: number;
    appaltoId: number;
    note: string;
    percentualeEvaporazioneContenitori: number;
    percentualeEvaporazioneStroller: number;
    regione: string;
    tipoErogazione: string;
    tipoCanale: string;
    tipoContatto: string;
    codiceEsenzione: string;
    tipoEsenzione: string;
    subjectCreazione: string;
    msgCreazione: string;
    subjectAttivazione: string;
    msgAttivazione: string;
    subjectSospensione: string;
    msgSospensione: string;
    subjectModifica: string;
    msgModifica: string;
    subjectRinnovo: string;
    msgRinnovo: string;
    subjectRiattivazione: string;
    msgRiattivazione: string;
    subjectCessazione: string;
    msgCessazione: string;
    recipient: string;

    constructor(
        servizio: string,
        autoApprovazione: boolean,
        prezzo1: number,
        prezzo2: number,
        prezzo3: number,
        livelloServizioAttivazione: number,
        livelloServizioIntervento: number,
        dataStipula: Date,
        durata: number,
        dataScadenza: Date,
        rti: boolean,
        prescrittoreXml: boolean,
        cig: string,
        aziendaId: number,
        appaltoId: number,
        note: string,
        percentualeEvaporazioneContenitori: number,
        percentualeEvaporazioneStroller: number,
        regione: string,
        tipoErogazione: string,
        tipoCanale: string,
        tipoContatto: string,
        codiceEsenzione: string,
        tipoEsenzione: string,
        subjectCreazione: string,
        msgCreazione: string,
        subjectAttivazione: string,
        msgAttivazione: string,
        subjectSospensione: string,
        msgSospensione: string,
        subjectModifica: string,
        msgModifica: string,
        subjectRinnovo: string,
        msgRinnovo: string,
        subjectRiattivazione: string,
        msgRiattivazione: string,
        subjectCessazione: string,
        msgCessazione: string,
        recipient: string
    ) {
        super();
        this.servizio = servizio;
        this.autoApprovazione = autoApprovazione;
        this.prezzo1 = prezzo1;
        this.prezzo2 = prezzo2;
        this.prezzo3 = prezzo3;
        this.livelloServizioAttivazione = livelloServizioAttivazione;
        this.livelloServizioIntervento = livelloServizioIntervento;
        this.dataStipula = dataStipula;
        this.durata = durata;
        this.dataScadenza = dataScadenza;
        this.rti = rti;
        this.prescrittoreXml = prescrittoreXml;
        this.cig = cig;
        this.aziendaId = aziendaId;
        this.appaltoId = appaltoId;
        this.note = note;
        this.percentualeEvaporazioneContenitori = percentualeEvaporazioneContenitori;
        this.percentualeEvaporazioneStroller = percentualeEvaporazioneStroller;
        this.regione = regione;
        this.tipoErogazione = tipoErogazione;
        this.tipoCanale = tipoCanale;
        this.tipoContatto = tipoContatto;
        this.codiceEsenzione = codiceEsenzione;
        this.tipoEsenzione = tipoEsenzione;
        this.subjectCreazione = subjectCreazione;
        this.msgCreazione = msgCreazione;
        this.subjectAttivazione = subjectAttivazione;
        this.msgAttivazione = msgAttivazione;
        this.subjectSospensione = subjectSospensione;
        this.msgSospensione = msgSospensione;
        this.subjectModifica = subjectModifica;
        this.msgModifica = msgModifica;
        this.subjectRinnovo = subjectRinnovo;
        this.msgRinnovo = msgRinnovo;
        this.subjectRiattivazione = subjectRiattivazione;
        this.msgRiattivazione = msgRiattivazione;
        this.subjectCessazione = subjectCessazione;
        this.msgCessazione = msgCessazione;
        this.recipient = recipient;
    }
}
