import {Component} from '@angular/core';
import {Action} from '../../model/action';
import {DatagridComponent} from '../common/datagrid.component';
import {Concentratore} from '../../model/query/concentratore';
import {Router} from '@angular/router';
import {ActionService} from '../../core/action.service';
import {ConcentratoriService} from '../../core/concentratori.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-concentratori',
    templateUrl: './concentratori.component.html',
    styleUrls: ['./concentratori.component.css']
})
export class ConcentratoriComponent extends DatagridComponent<Concentratore> {

    constructor(
        private concentratoriService: ConcentratoriService,
        public actionService: ActionService,
        dialog: DialogService,
        router: Router
    ) {
        super(concentratoriService, router, dialog);
    }

    onAction(entity: Concentratore, action: Action) {
        console.log(action);
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }

    protected getEditUrl(): string {
        return '/concentratori/';
    }


}
