import {Component} from '@angular/core';
import {DatagridComponent} from '../common/datagrid.component';
import {OperatoreCallCenter} from '../../model/query/operatore-callcenter';
import {Router} from '@angular/router';
import {OperatoriCallCenterService} from '../../core/operatori-callcenter.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-operatori-callcenter',
    templateUrl: './operatori-callcenter.component.html',
    styleUrls: ['./operatori-callcenter.component.css']
})
export class OperatoriCallCenterComponent extends DatagridComponent<OperatoreCallCenter> {

    constructor(
        private operatoriCallCenterService: OperatoriCallCenterService,
        dialog: DialogService,
        router: Router
    ) {
        super(operatoriCallCenterService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/operatori-callcenter/';
    }

}
