// *************************************************
// * domolife_system Project - © 2020
// *
// * Author: Armando Taglieri (jadbit)
// * Created on: 3/31/20 11:31 PM
// *************************************************

import {ReportWithPrescrittoreAndDistrettoCommand} from './report-with-prescrittore-and-distretto-command';

export class ReportWithPrescrittoreAndDistrettoDownloadCommand extends ReportWithPrescrittoreAndDistrettoCommand {

    reportType: string;

    constructor(
        aziendaId: number,
        page: number,
        size: number,
        from: string,
        to: string,
        prescrittoreId: number,
        distrettoId: number,
        partnerId: number,
        tipo: string,
        reportType: string
    ) {
        super(aziendaId, page, size, from, to, prescrittoreId, distrettoId, partnerId, tipo);
        this.reportType = reportType;
    }
}
