// *************************************************
// * domolife_system Project - © 2020
// *
// * Author: Armando Taglieri (jadbit)
// * Created on: 3/30/20 4:09 PM
// *************************************************

export class ReportCommand {
    aziendaId: number;
    page: number;
    size: number;
    from: string;
    to: string;

    constructor(
        aziendaId: number,
        page: number,
        size: number,
        from: string,
        to: string,
    ) {
        this.aziendaId = aziendaId;
        this.page = page;
        this.size = size;
        this.from = from;
        this.to = to;
    }
}
