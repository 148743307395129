import {AccountCommand} from './account-command';

export class PartnerCreateCommand extends AccountCommand {

    partner: any;

    constructor(nome: string, cognome: string, username: string, email: string, ragioneSociale: string) {
        super(username, email);
        this.partner = {
            'nome': nome,
            'cognome': cognome,
            'ragioneSociale': ragioneSociale,
            'attivo': true
        };
    }
}
