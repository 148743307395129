import {Component} from '@angular/core';
import {DatagridComponent} from '../common/datagrid.component';
import {Infermiere} from '../../model/query/infermiere';
import {Router} from '@angular/router';
import {InfermieriService} from '../../core/infermieri.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-infermieri',
    templateUrl: './infermieri.component.html',
    styleUrls: ['./infermieri.component.css']
})
export class InfermieriComponent extends DatagridComponent<Infermiere> {

    constructor(
        private infermieriService: InfermieriService,
        dialog: DialogService,
        router: Router
    ) {
        super(infermieriService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/infermieri/';
    }

}
