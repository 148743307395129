import {Injectable} from '@angular/core';
import {Contratto} from '../model/query/contratto';

@Injectable()
export class PrescrizioneStatusService {

    status: any = {
        contratto: null,
        codiceFiscale: null
    };

    public getStatus(): any {
        const status = Object.assign({}, this.status);
        this.init();
        return status;
    }

    public setStatus(status: any) {
        this.status = status;
    }

    private init() {
        this.status = {
            contratto: null,
            codiceFiscale: null
        };
    }

    constructor() {
        this.init();
    }
}
