// *************************************************
// * domolife_system Project - © 2020
// *
// * Author: Armando Taglieri (jadbit)
// * Created on: 3/30/20 4:09 PM
// *************************************************

import {ReportComplianceCommand} from './report-compliance-command';

export class ReportComplianceDownloadCommand extends ReportComplianceCommand {

    reportType: string;

    constructor(
        aziendaId: number,
        page: number,
        size: number,
        from: string,
        to: string,
        distrettoId: number,
        partnerId: number,
        pazienteId: number,
        reportType: string
    ) {
        super(aziendaId, page, size, from, to, distrettoId, partnerId, pazienteId);
        this.reportType = reportType;
    }
}
