import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Azienda} from '../model/query/azienda';
import {Distretto} from '../model/query/distretto';
import {Prescrittore} from '../model/query/prescrittore';
import {Approvatore} from '../model/query/approvatore';
import {Operatore} from '../model/query/operatore';
import {Appaltante} from '../model/query/appaltante';
import {Profilo} from '../model/query/profilo';
import {Ventilatore} from '../model/query/ventilatore';
import {Comune} from '../model/query/comune';

@Injectable()
export class AziendeService extends BaseService<Azienda> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('aziende');
    }

    getPrescrittori(id?: string): Observable<Array<Prescrittore>> {
        return this.httpClient.get<Array<Prescrittore>>(this.apiUrl + '/' + id + '/prescrittori').pipe(
            catchError(this.handleError(this.apiUrl + '- : get prescrittori', null))
        );
    }

    getApprovatori(id?: string): Observable<Array<Approvatore>> {
        return this.httpClient.get<Array<Approvatore>>(this.apiUrl + '/' + id + '/approvatori').pipe(
            catchError(this.handleError(this.apiUrl + '- : get approvatori', null))
        );
    }

    getOperatori(id?: string): Observable<Array<Operatore>> {
        return this.httpClient.get<Array<Operatore>>(this.apiUrl + '/' + id + '/operatori').pipe(
            catchError(this.handleError(this.apiUrl + '- : get operatori', null))
        );
    }

    getAppaltanti(id?: string): Observable<Array<Appaltante>> {
        return this.httpClient.get<Array<Appaltante>>(this.apiUrl + '/' + id + '/appaltanti').pipe(
            catchError(this.handleError(this.apiUrl + '- : get appaltanti', null))
        );
    }

    getProfili(id?: string): Observable<Array<Profilo>> {
        return this.httpClient.get<Array<Profilo>>(this.apiUrl + '/' + id + '/profili').pipe(
            catchError(this.handleError(this.apiUrl + '- : get profili', null))
        );
    }

    getVentilatori(id?: string): Observable<Array<Ventilatore>> {
        return this.httpClient.get<Array<Ventilatore>>(this.apiUrl + '/' + id + '/ventilatori').pipe(
            catchError(this.handleError(this.apiUrl + '- : get ventilatori', null))
        );
    }

    getDistretti(id?: string): Observable<Array<Distretto>> {
        return this.httpClient.get<Array<Distretto>>(this.apiUrl + '/' + id + '/lista-distretti').pipe(
            catchError(this.handleError(this.apiUrl + '- : get distretti', null))
        );
    }

    getComuni(id: string, distrettoId: string): Observable<Array<Comune>> {
        return this.httpClient.get<Array<Comune>>(this.apiUrl + '/' + id + '/distretti/' + distrettoId + '/lista-comuni').pipe(
            catchError(this.handleError(this.apiUrl + '- : get comuni', null))
        );
    }

    getNumeroPrescrizioniDaProcessare(id: string, tipo: string) {
        return this.httpClient.get(this.apiUrl + '/' + id + '/da-processare/' + tipo).pipe(
            catchError(this.handleError(this.apiUrl + ' - da processare', null))
        );
    }

    getNumeroPrescrizioniDaAutorizzare(id: string) {
        return this.httpClient.get(this.apiUrl + '/' + id + '/da-autorizzare').pipe(
            catchError(this.handleError(this.apiUrl + ' - da attivare', null))
        );
    }

}
