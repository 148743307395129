import {Injectable} from '@angular/core';

@Injectable()
export class Globals {
    readonly DEFAULT_LANGUAGE_ID: number = 2;
    readonly DECIMAL_QUANTITY_PATTERN: string = '^[0-9]+(\.[0-9]{1,3})?$';
    readonly INTEGER_PATTERN: string = '^[0-9]+$';
    readonly INTEGER_QUANTITY_PATTERN: string = '^[0-9]+$';
    readonly MOBILE_CODE_PATTERN: string = '^[0-9\-]+$';
    readonly NO_SPACES_ONLY: string = '(?!^ +$)^.+$';
    readonly FISCAL_CODE: string = '^[A-Z0-9]{16}$';
    readonly DATE_LITTLE_ENDIAN: string = '^(((0[1-9]|[12][0-9]|3[01])[- /.](0[13578]|1[02])|(0[1-9]|[12][0-9]|30)[- /.](0[469]|11)|(0[1-9]|1\\d|2[0-8])[- /.]02)[- /.]\\d{4}|29[- /.]02[- /.](\\d{2}(0[48]|[2468][048]|[13579][26])|([02468][048]|[1359][26])00))$';
    readonly TIPO_RIPARAZIONE_BOMBOLA_SOST_VALVOLA: number = 6;
    readonly TIPO_RIPARAZIONE_DEWAR_SOST_EROGATORE: number = 14;
    readonly TIPO_RIPARAZIONE_STROLLER_SOST_EROGATORE: number = 42;
    readonly TIPO_RIPARAZIONE_DEWAR_RIPARAZ_PERDITE: number = 25;
    readonly TIPO_RIPARAZIONE_STROLLER_RIPARAZ_PERDITE: number = 53;
    readonly TIPO_RIPARAZIONE_DEWAR_NER_TEST: number = 34;
    readonly TIPO_RIPARAZIONE_STROLLER_NER_TEST: number = 62;
    readonly TIPO_RIPARAZIONE_BOMBOLA_ALTRO: number = 7;
    readonly TIPO_RIPARAZIONE_DEWAR_ALTRO: number = 35;
    readonly TIPO_RIPARAZIONE_STROLLER_ALTRO: number = 63;
    readonly TIPO_RIPARAZIONE_DEWAR_SOST_VALVOLE: number = 68;
    readonly TIPO_RIPARAZIONE_STROLLER_SOST_VALVOLE: number = 69;
    readonly TIPO_RIPARAZIONE_DEWAR_SOST_BASE: number = 26;
    readonly TIPO_RIPARAZIONE_STROLLER_SOST_BASE: number = 54;
    readonly TIPO_TASK_CONSEGNA_REPARTO: string = 'cre';
    readonly TIPO_TASK_CONSEGNA_PAZIENTE: string = 'cpa';
    readonly TIPO_TASK_SOSPENSIONE: string = 'sos';
    readonly TIPO_TASK_ACCESSO_ORDINARIO: string = 'aor';
    readonly TIPO_TASK_ACCESSO_STRAORDINARIO: string = 'ast';
    readonly TIPO_TASK_GENERICO: string = 'gen';
    readonly TIPO_TASK_COLLAUDO: string = 'col';
    readonly TIPO_TASK_CONSEGNA_MATERIALI: string = 'cma';
    readonly TIPO_TASK_VERIFICA_ELETTRICA: string = 'vel';
    readonly TIPO_TASK_SOSTITUZIONE: string = "stz";
    readonly TIPO_TASK_RITIRO_DISPOSITIVO: string = "rit";
    readonly TIPO_TASK_FOLLOWUP: string = "fup";
    readonly TIPO_TASK_COMPLIANCE: string = "cpl";
    readonly TIPO_TASK_REPORTISTICA: string = "rep";
}
