import {Component} from '@angular/core';
import {AuthService} from '../../../core/auth.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styles: []
})
export class MainComponent {
    constructor(public authService: AuthService) {
    }
}
