import {Ventilatore} from './ventilatore';
import {Profilo} from './profilo';
import {ModalitaVentilatoria} from './modalita-ventilatoria';
import {MaterialeConsumabile} from './materiale-consumabile';
import {ParametroVentilatoreDto} from './parametro-ventilatore-dto';
import {ParametroAllarmeDto} from './parametro-allarme-dto';
import {StatoTaskPrescrizione} from '../stato-task-prescrizione';

export class PrescrizioneVentilatoreDto {
    id: number;
    ventilatore: Ventilatore;
    profilo: Profilo;
    quantita: number;
    modalitaVentilatoria: ModalitaVentilatoria;
    parametriVentilatore: Array<ParametroVentilatoreDto>;
    materiali: Array<MaterialeConsumabile>;
    parametriAllarme: Array<ParametroAllarmeDto>;
    statoTask: StatoTaskPrescrizione;
    matricola1: string;
    matricola2: string;
    modelloMaschera: string;
    marcaMaschera: string;
    tipoMaschera: string;
    tagliaMaschera: string;
}
