import {ContrattoCommand} from './contratto-command';
import {ContrattoPartnerDto} from './contratto-partner-dto';

export class ContrattoDto {
    contratto: ContrattoCommand;
    approvatoriIds: Array<number>;
    operatoriIds: Array<number>;
    appaltantiIds: Array<number>;
    prescrittoriIds: Array<number>;
    partners: Array<ContrattoPartnerDto>;
    materialiIds: Array<number>;
    quantitaMin: Array<number>;
    quantitaMax: Array<number>;
    profiliIds: Array<number>;
    prezzi: Array<number>;
    giornalieri: Array<boolean>;
    maschereIds: Array<number>;

    constructor(
        contratto: ContrattoCommand,
        approvatoriIds: Array<number>,
        operatoriIds: Array<number>,
        appaltantiIds: Array<number>,
        prescrittoriIds: Array<number>,
        partners: Array<ContrattoPartnerDto>,
        materialiIds: Array<number>,
        quantitaMin: Array<number>,
        quantitaMax: Array<number>,
        profiliIds: Array<number>,
        prezzi: Array<number>,
        giornalieri: Array<boolean>,
        maschereIds: Array<number>
    ) {
        this.contratto = contratto;
        this.approvatoriIds = approvatoriIds;
        this.operatoriIds = operatoriIds;
        this.appaltantiIds = appaltantiIds;
        this.prescrittoriIds = prescrittoriIds;
        this.partners = partners;
        this.materialiIds = materialiIds;
        this.quantitaMin = quantitaMin;
        this.quantitaMax = quantitaMax;
        this.profiliIds = profiliIds;
        this.prezzi = prezzi;
        this.giornalieri = giornalieri;
        this.maschereIds = maschereIds;
    }
}
