import {Component} from '@angular/core';
import {VentilatoriService} from '../../core/ventilatori.service';
import {Router} from '@angular/router';
import {DatagridComponent} from '../common/datagrid.component';
import {Ventilatore} from '../../model/query/ventilatore';
import {Action} from '../../model/action';
import {ActionService} from '../../core/action.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-ventilatori',
    templateUrl: './ventilatori.component.html',
    styleUrls: ['./ventilatori.component.css']
})
export class VentilatoriComponent extends DatagridComponent<Ventilatore> {

    constructor(
        private ventilatoriService: VentilatoriService,
        dialog: DialogService,
        public actionService: ActionService,
        router: Router
    ) {
        super(ventilatoriService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/ventilatori/';
    }

    onAction(entity: Ventilatore, action: Action) {
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }



}
