import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {MaterialeConsumabile} from '../model/query/materiale-consumabile';
import {MaterialeKit} from '../model/query/materiale-kit';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class MaterialiService extends BaseService<MaterialeConsumabile> {

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('materiali-consumabili');
    }

    getKits(idMateriale: string): Observable<MaterialeKit[]> {
        return this.httpClient.get<MaterialeKit[]>(this.apiUrl + '/' + idMateriale + '/kits').pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

}
