import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {QueryResult} from '../model/query/query-result';
import {ModalitaVentilatoria} from '../model/query/modalita-ventilatoria';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ModalitaVentilatorieService extends BaseService<ModalitaVentilatoria> {

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('modalita-ventilatorie');
    }

    getModalitaVentilatorie(): Observable<QueryResult<ModalitaVentilatoria>> {
        return this.httpClient.get<QueryResult<ModalitaVentilatoria>>(this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + '- : get modalita ventilatorie', null))
        );
    }

}
