import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Appalto} from '../model/query/appalto';

@Injectable()
export class AppaltiService extends BaseService<Appalto> {

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('appalti');
    }
}
