import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Action} from '../../../model/action';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {ActionService} from '../../../core/action.service';
import {ConcentratoriService} from '../../../core/concentratori.service';
import {Concentratore} from '../../../model/query/concentratore';
import {ConcentratoreCommand} from '../../../model/command/concentratore-command';

@Component({
    selector: 'app-concentratore-detail',
    templateUrl: './concentratore-detail.component.html',
    styleUrls: ['./concentratore-detail.component.css']
})
export class ConcentratoreDetailComponent implements OnInit {

    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    concentratore: Concentratore;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;

    constructor(
        private route: ActivatedRoute,
        public concentratoriService: ConcentratoriService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        public actionService: ActionService,
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        if (this.id !== 'new') {
            this.concentratoriService.get(this.id).subscribe((concentratore) => {
                this.concentratore = concentratore;
                this.initializeFormFields(concentratore);
            });
        } else {
            this.initializeFormFields(null);
        }
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command = new ConcentratoreCommand(
            formValue.nome,
            formValue.tipo,
        );
        if (!this.id || this.id === 'new') {
            this.concentratoriService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'Concentratore salvato!';
                    this.alertClosed = false;
                }
            });
        } else {
            this.concentratoriService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Concentratore salvato!';
                    this.alertClosed = false;
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            tipo: ['', [Validators.required]],
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(concentratore: Concentratore): void {
        let formValues;

        if (concentratore) {
            formValues = {
                nome: concentratore.nome,
                tipo: concentratore.tipo,
            };
        } else {
            formValues = {
                nome: '',
                tipo: '',
            };
        }
        this.form.setValue(formValues);
    }

}
