import {AccountCommand} from './account-command';

export class OperatoreCallCenterCreateCommand extends AccountCommand {

    operatoreCallCenter: any;

    constructor(nome: string, cognome: string, username: string, email: string) {
        super(username, email);
        this.operatoreCallCenter = {
            'nome': nome,
            'cognome': cognome,
        };
    }
}
