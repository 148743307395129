import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {KitMaterialiService} from '../../../core/kit-materiali.service';
import {KitMateriale} from '../../../model/query/kit-materiale';
import {ProfiliService} from '../../../core/profili.service';
import {Profilo} from '../../../model/query/profilo';
import {ProfiloCommand} from '../../../model/command/profilo-command';
import {ProfiloCommandDto} from '../../../model/command/profilo-command-dto';
import {Ventilatore} from '../../../model/query/ventilatore';
import {ActionService} from '../../../core/action.service';
import {UtilsService} from '../../../core/utils.service';
import {VentilatoriService} from '../../../core/ventilatori.service';
import {Action} from '../../../model/action';
import {DialogService} from '../ui/../../dialog';

@Component({
    selector: 'app-profilo-detail',
    templateUrl: './profilo-detail.component.html'
})
export class ProfiloDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    alertMessage: string;
    alertClosed = true;
    action: Action;
    readOnly = false;
    codiceCorrente: string;
    kits: Array<KitMateriale> = new Array <KitMateriale>();
    selectedLeftVentilatori: Ventilatore[] = new Array<Ventilatore>();
    selectedRightVentilatori: Ventilatore[] = new Array<Ventilatore>();
    rightVentilatori: Ventilatore[] = new Array<Ventilatore>();
    leftVentilatori: Ventilatore[] = new Array<Ventilatore>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private kitMaterialiService: KitMaterialiService,
        private profiliService: ProfiliService,
        private ventilatoriService: VentilatoriService,
        private utilsService: UtilsService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        public actionService: ActionService,
        private dialog: DialogService
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        this.kitMaterialiService.getAll(0, 10000000, [], [], [], null, null).subscribe(kits => {
            this.kits = kits.objects;
            if (this.id !== 'new') {
                this.profiliService.get(this.id).subscribe((profilo: Profilo) => {
                    this.codiceCorrente = profilo.codice;
                    this.initializeFormFields(profilo);
                    if (this.action != null && this.action == this.actionService.ACTION.VIEW) {
                        this.readOnly = true;
                    }
                    this.ventilatoriService.getVentilatori().subscribe((result) => {
                        this.leftVentilatori= result.objects;
                        this.profiliService.getVentilatori(Number(this.id)).subscribe(result => {
                            for (const obj of result) {
                                this.rightVentilatori.push(obj.ventilatore);
                                this.removeVentilatoreFromArray(obj.ventilatore, this.leftVentilatori);
                            }
                        });
                        this.sortVentilatori();
                    });
                });
            } else {
                this.initializeFormFields(null);
                this.ventilatoriService.getVentilatori().subscribe((result) => {
                    this.leftVentilatori = result.objects;
                    this.sortVentilatori();
                });
            }
        });
    }

    createForm(): void {
        const formGroup = {
            codice: ['', [Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            codicePrestazione: ['', [Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            selectedKitId: [''],
            selectedKitSelezionabiliId: [''],
            selectedKitMedicazioneId: [''],
            selectedKitMedicazioneSelezionabiliId: [''],
            bloccato: [false]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(profilo: Profilo): void {
        let formValues;

        if (profilo) {
            formValues = {
                codice: profilo.codice || '',
                codicePrestazione: profilo.codicePrestazione || '',
                nome: profilo.nome,
                selectedKitId: profilo.kitMaterialeForeignKey ? profilo.kitMaterialeForeignKey : '',
                selectedKitSelezionabiliId: profilo.kitMaterialeSelezionabiliForeignKey ? profilo.kitMaterialeSelezionabiliForeignKey : '',
                selectedKitMedicazioneId: profilo.kitMaterialeMedicazioneForeignKey ? profilo.kitMaterialeMedicazioneForeignKey : '',
                selectedKitMedicazioneSelezionabiliId: profilo.kitMaterialeMedicazioneSelezionabiliForeignKey ? profilo.kitMaterialeMedicazioneSelezionabiliForeignKey : '',
                bloccato: profilo.bloccato ? profilo.bloccato : false
            };
        } else {
            formValues = {
                codice: '',
                codicePrestazione: '',
                nome: '',
                selectedKitId: '',
                selectedKitSelezionabiliId: '',
                selectedKitMedicazioneId: '',
                selectedKitMedicazioneSelezionabiliId: '',
                bloccato: false
            };
        }
        this.form.setValue(formValues);
    }


    onSubmit() {
        const dto: ProfiloCommandDto = new ProfiloCommandDto();
        const formValue = this.form.getRawValue();
        
        this.submitButton = ClrLoadingState.LOADING;
        dto.profilo = new ProfiloCommand(
            formValue.codice != '' ? formValue.codice : null,
            formValue.codicePrestazione != '' ? formValue.codicePrestazione : null,
            formValue.nome, 
            formValue.selectedKitId != '' ? Number(formValue.selectedKitId) : 0,
            formValue.selectedKitSelezionabiliId != '' ? Number(formValue.selectedKitSelezionabiliId) : 0,
            formValue.selectedKitMedicazioneId != '' ? Number(formValue.selectedKitMedicazioneId) : 0,
            formValue.selectedKitMedicazioneSelezionabiliId != '' ? Number(formValue.selectedKitMedicazioneSelezionabiliId) : 0,
            formValue.bloccato
        );
        dto.ventilatoriIds = this.rightVentilatori.map(obj => obj.id);
        if (this.id === 'new') {
            this.profiliService.create(dto).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'Il lotto/profilo è stato creato';
                    this.alertClosed = false;
                    this.redirectToProfili();
                }
            });
        } else {
            this.profiliService.update(this.id, dto).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Il lotto/profilo è stato aggiornato';
                    this.alertClosed = false;
                    this.redirectToProfili();
                }
            });
        }

    }

    removeVentilatoreFromArray(obj: Ventilatore, array: Array<Ventilatore>) {
        const index = array.findIndex(x => x.id === obj.id);

        if (index != -1) {
            array.splice(index, 1);
        }
    }

    swapVentilatori() {
        const model = this.swapVentilatoreAssignment({
            leftArray: this.leftVentilatori,
            leftSelectedArray: this.selectedLeftVentilatori,
            rightArray: this.rightVentilatori,
            rightSelectedArray: this.selectedRightVentilatori
        });
        this.leftVentilatori = model.leftArray;
        this.selectedLeftVentilatori = model.leftSelectedArray;
        this.rightVentilatori = model.rightArray;
        this.selectedRightVentilatori = model.rightSelectedArray;
        this.sortVentilatori();
    }

    swapVentilatoreAssignment(model: {
        leftArray: Array<Ventilatore>,
        leftSelectedArray: Array<Ventilatore>,
        rightArray: Array<Ventilatore>,
        rightSelectedArray: Array<Ventilatore>
    }) {
        for (const obj of model.leftSelectedArray) {
            model.rightArray.push(obj);
            this.removeVentilatoreFromArray(obj, model.leftArray);
        }
        for (const obj of model.rightSelectedArray) {
            model.leftArray.push(obj);
            this.removeVentilatoreFromArray(obj, model.rightArray);
        }
        model.leftSelectedArray = new Array<Ventilatore>();
        model.rightSelectedArray = new Array<Ventilatore>();

        return model;
    }

    duplicaProfilo(): void {
        const formValue = this.form.getRawValue();
        
        if (formValue.codice === this.codiceCorrente) {
            this.dialog.info({
                title: 'Info',
                content: 'Il profilo duplicato deve avere un codice diverso',
            }).subscribe();
        } else {
            this.submitButton = ClrLoadingState.LOADING;
            this.profiliService.duplica(Number(this.id), formValue.codice, formValue.nome).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Il lotto/profilo è stato duplicato';
                    this.alertClosed = false;
                    this.redirectToProfili();
                }
            });
        }            
    }
    
    sortVentilatori(): void {
        this.leftVentilatori.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
        this.rightVentilatori.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
    }

    private redirectToProfili() {
        this.router.navigate(['/profili']);
    }
}
