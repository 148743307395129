import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import * as moment from 'moment';
import {FormBuilder, Validators} from '@angular/forms';
import {ReportTaskBaseComponent} from "../report-task-base.component";
import {ReportsService} from '../../../core/reports.service';
import {UtilsService} from '../../../core/utils.service';
import {AuthService} from "../../../core/auth.service";
import {ReportTaskCommand} from '../../../model/command/report-task-command';
import {PazientiService} from "../../../core/pazienti.service";
import {IncaricatiService} from "../../../core/incaricati.service";
import {Globals} from '../../../core/globals.service';
import {TaskService} from "../../../core/task.service";
import {Paziente} from "../../../model/query/paziente";
import {AziendeCorrentiService} from '../../../core/aziende-correnti.service';
import {Azienda} from '../../../model/query/azienda';
import {Task} from '../../../model/query/task';
import {TaskDocumento} from '../../../model/query/task-documento';
import {DialogService} from '../../dialog';

@Component({
    selector: 'app-report-task',
    templateUrl: './report-task.component.html',
    styleUrls: ['./report-task.component.css']
})
export class ReportTaskComponent extends ReportTaskBaseComponent {
    year: any;
    dateFrom: string;
    dateTo: string;
    pazienti: Paziente[];
    pazienteId: number;
    tipoTask: string;
    months = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dec'];
    
    constructor(
        protected formBuilder: FormBuilder,
        protected reportsService: ReportsService,
        protected pazientiService: PazientiService,
        protected incaricatiService: IncaricatiService,
        protected aziendeCorrentiService: AziendeCorrentiService,
        public utilsService: UtilsService,
        public globals: Globals,
        public taskService: TaskService,
        public authService: AuthService,
        protected dialog: DialogService
    ) {
        super(formBuilder, aziendeCorrentiService, pazientiService, incaricatiService, globals, utilsService, taskService, authService, dialog);
    }

    initializeDateVariables(): void {
        this.year = moment.utc().get('y');
        this.dateFrom = moment.utc({y: this.year, M: 0}).startOf('month').format('YYYY-MM-DD');
        this.dateTo = moment.utc({y: this.year, M: 11}).endOf('month').format('YYYY-MM-DD');
    }

    initializeData(): Observable<any> {
        this.pazienteId = null;
        return this.pazientiService.getAll(0, 1000000, ["comune.distretto.aziendaForeignKey"], ["eq"], [this.azienda.id.toString()], "cognome", "asc").pipe(
            tap(result => this.pazienti = result.objects)
        );
    }

    fetchReport(): Observable<any> {
        this.dateFrom = moment.utc({y: this.year, M: 0}).startOf('month').format('YYYY-MM-DD');
        this.dateTo = moment.utc({y: this.year, M: 11}).endOf('month').format('YYYY-MM-DD');
        
        const command = new ReportTaskCommand(
            this.azienda.id,
            this.currentPage,
            this.sizePage,
            this.dateFrom,
            this.dateTo,
            this.pazienteId,
            this.tipoTask
        );
        
        return this.reportsService.fetchReportTask(command);
    }
    
    createForm(): void {
        const formGroup = {
            anno: [{value: ''}, [Validators.required]],
            paziente: [{ value: "" }],
            tipoTask: [{ value: "" }]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initForm() {
        const formValues = {
            anno: this.year,
            paziente: '',
            tipoTask: ''
        };
        this.form.setValue(formValues);
    }

    readFormValues(formValue: any): void {
        this.year = formValue.anno;
        this.pazienteId = formValue.paziente ? formValue.paziente : null;
        this.tipoTask = formValue.tipoTask ? formValue.tipoTask : null;
    }
}
