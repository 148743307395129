import {Component} from '@angular/core';
import {Subscription} from 'rxjs';
import {forkJoin} from 'rxjs';
import {ClrDatagridStateInterface} from '@clr/angular';
import * as moment from 'moment';
import {FormBuilder, Validators} from '@angular/forms';
import {ReportsService} from '../../../core/reports.service';
import {UtilsService} from '../../../core/utils.service';
import {AuthService} from '../../../core/auth.service';
import {ReportScadenzeCommand} from '../../../model/command/report-scadenze-command';
import {ReportScadenzeDownloadCommand} from '../../../model/command/report-scadenze-download-command';
import {AziendeCorrentiService} from '../../../core/aziende-correnti.service';
import {Azienda} from '../../../model/query/azienda';
import { PartnersService } from "../../../core/partners.service";
import { Partner } from "../../../model/query/partner";
import {DialogService} from '../../dialog';

@Component({
    selector: 'app-report-scadenze',
    templateUrl: './report-scadenze.component.html',
    styleUrls: ['./report-scadenze.component.css']
})
export class ReportScadenzeComponent {
    subscription: Subscription;
    tipiPrescrizioniDescription: any;
    total: number;
    loading = false;
    form: any;

    objectList: any[];
    object: any;
    currentState: ClrDatagridStateInterface;

    azienda: Azienda;
    year: any;
    month: any;

    dateFrom: string;
    dateTo: string;
    distrettoId: number;
    distretti: { id: number, nome: string, aziendaForeignKey: number }[];
    partners: Partner[];
    partnerId: number;

    constructor(
        private formBuilder: FormBuilder,
        private reportsService: ReportsService,
        public utilsService: UtilsService,
        private partnersService: PartnersService,
        private aziendeCorrentiService: AziendeCorrentiService,
        public authService: AuthService,
        private dialog: DialogService
    ) {
        this.createForm();
        this.tipiPrescrizioniDescription = this.utilsService.getServiziDescription();
        this.subscription = this.aziendeCorrentiService.getAzienda().subscribe(azienda => {
            if (azienda != null) {
                this.year = moment.utc().get('y');
                this.month = moment.utc().get('M');
                this.dateFrom = moment.utc({y: this.year, M: this.month}).startOf('month').format('YYYY-MM-DD');
                this.dateTo = moment.utc({y: this.year, M: this.month}).endOf('month').format('YYYY-MM-DD');
                this.partnerId = null;
                this.distrettoId = null;
                this.aziendeCorrentiService.getDistretti().subscribe(results => this.distretti = results);
                this.partnersService.getAll(0, 1000000, null, null, null, null, null).subscribe((results) => {
                    this.partners = results.objects;
                    this.initForm();
                    this.azienda = azienda;
                });
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    refresh(state: ClrDatagridStateInterface) {
        if (this.azienda) {
            this.loading = true;
            const command = new ReportScadenzeCommand(
                this.azienda.id,
                state.page.current ? state.page.current - 1 : 0,
                state.page.size ? state.page.size : 50,
                this.dateFrom,
                this.dateTo,
                this.distrettoId ? +this.distrettoId : null,
                this.partnerId ? +this.partnerId : null
            );
            this.reportsService.fetchReportScadenze(command).subscribe((result) => {
                if (result) {
                    this.total = result.count;
                    this.objectList = result.objects;
                }
                this.loading = false;
            });
        }
        this.currentState = state;
    }

    createForm(): void {
        const formGroup = {
            mese: [{value: ''}, [Validators.required]],
            anno: [{value: ''}, [Validators.required]],
            partner: [{ value: "" }],
            distretto: [{value: ''}]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initForm() {
        const formValues = {
            mese: this.month,
            anno: this.year,
            partner: "",
            distretto: ''
        };
        this.form.setValue(formValues);
    }

    openDownloadAlert() {
        this.onSubmit("xls");
    }

    onSubmit(type: any) {
        const formValue = this.form.getRawValue();
        
        this.currentState.page.current = 0;
        this.currentState.page.size = 50;
        this.year = formValue.anno;
        this.month = formValue.mese;
        let mFrom : moment.Moment = moment.utc({ y: this.year, M: this.month }).startOf('month');

        if (!mFrom.isAfter(moment.utc())) {
            this.distrettoId = formValue.distretto ? formValue.distretto : null;
            this.partnerId = formValue.partner ? formValue.partner : null;
            this.dateFrom = mFrom.format('YYYY-MM-DD');
            this.dateTo = moment.utc({y: this.year, M: this.month}).endOf('month').format('YYYY-MM-DD');
            if (type) {
                const command = new ReportScadenzeDownloadCommand(
                    this.azienda.id,
                    null,
                    null,
                    this.dateFrom,
                    this.dateTo,
                    this.distrettoId,
                    this.partnerId,
                    type
                );
                this.reportsService.getReportScadenzeDownloadLink(command).subscribe((url) => {
                    if (url) {
                        // window.location.href = url;
                        window.open(url);
                    }
                });
            } else {
                this.refresh(this.currentState);
            }
        } else {
            this.total = 0;
            this.objectList = [];
        }
    }

}
