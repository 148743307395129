// *************************************************
// * domolife_system Project - © 2020
// *
// * Author: Armando Taglieri (jadbit)
// * Created on: 3/30/20 11:31 PM
// *************************************************

import {ReportCommand} from './report-command';

export class ReportWithPrescrittoreAndDistrettoCommand extends ReportCommand {

    prescrittoreId: number;
    distrettoId: number;
    partnerId: number;
    tipo: string;
    
    constructor(
        aziendaId: number,
        page: number,
        size: number,
        from: string,
        to: string,
        prescrittoreId: number,
        distrettoId: number,
        partnerId: number,
        tipo: string
    ) {
        super(aziendaId, page, size, from, to);
        this.prescrittoreId = prescrittoreId;
        this.distrettoId = distrettoId;
        this.partnerId = partnerId;
        this.tipo = tipo;
    }
}
