// *************************************************
// * domolife_system Project - © 2020
// *
// * Author: Armando Taglieri (jadbit)
// * Created on: 3/30/20 11:31 PM
// *************************************************

import {ReportCommand} from '../command/report-command';
import {StatoPrescrizione} from '../stato-prescrizione';

export class ReportPazientiVentCommand extends ReportCommand {
    prescrittoreId: number;
    strutturaId: number;
    profiloId: number;
    ventilatoreId: number;
    stato: StatoPrescrizione;

    constructor(
        aziendaId: number,
        page: number,
        size: number,
        from: string,
        to: string,
        prescrittoreId: number,
        strutturaId: number,
        profiloId: number,
        ventilatoreId: number,
        stato: StatoPrescrizione
    ) {
        super(aziendaId, page, size, from, to);
        this.prescrittoreId = prescrittoreId;
        this.strutturaId = strutturaId;
        this.profiloId = profiloId;
        this.ventilatoreId = ventilatoreId;
        this.stato = stato;
    }
}
