// *************************************************
// * domolife_system Project - © 2020
// *
// * Author: Armando Taglieri (jadbit)
// * Created on: 3/30/20 4:09 PM
// *************************************************

import {ReportCommand} from './report-command';

export class ReportCostiCommand extends ReportCommand {
    gruppoServizio: string;
    tipoServizio: string;
    distrettoId: number;
    partnerId: number;
    pazienteId: number;
    extraAsl: boolean;

    constructor(
        aziendaId: number,
        page: number,
        size: number,
        from: string,
        to: string,
        gruppoServizio: string,
        tipoServizio: string,
        distrettoId: number,
        partnerId: number,
        pazienteId: number,
        extraAsl: boolean
    ) {
        super(aziendaId, page, size, from, to);
        this.gruppoServizio = gruppoServizio;
        this.tipoServizio = tipoServizio;
        this.distrettoId = distrettoId;
        this.partnerId = partnerId;
        this.pazienteId = pazienteId;
        this.extraAsl = extraAsl;
    }
}
