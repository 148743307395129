import {Component, Directive} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {Observable} from 'rxjs';
import {ClrDatagridStateInterface} from '@clr/angular';
import {Globals} from '../../core/globals.service';
import {PazientiService} from "../../core/pazienti.service";
import {IncaricatiService} from "../../core/incaricati.service";
import {UtilsService} from '../../core/utils.service';
import {AuthService} from "../../core/auth.service";
import {TaskService} from "../../core/task.service";
import {AziendeCorrentiService} from '../../core/aziende-correnti.service';
import {Azienda} from '../../model/query/azienda';
import {Task} from '../../model/query/task';
import {TaskDocumento} from '../../model/query/task-documento';
import {DialogService} from '../dialog';

@Directive()
export abstract class ReportTaskBaseComponent {
    subscription: Subscription;

    tipiTask1: Array<string>;
    tipiTask2: Array<string>;
    colorTipoTask: Object;
    tipiTaskDescription: any;
    tipiTaskDescriptionForReport: any;
    
    total: number;
    loading = false;
    form: any;

    objectList = new Array();
    object: any;
    currentState: ClrDatagridStateInterface;

    azienda: Azienda;

    sizePage: number = 20;
    currentPage: number = 0;
    pages: number = 0;

    showTaskModal: boolean = false;
    task: Task;
    taskModalTitle: string;
    documentiTask = new Array<TaskDocumento>();
    ddt: Array<TaskDocumento>;
    
    constructor(
        protected formBuilder: FormBuilder,
        protected aziendeCorrentiService: AziendeCorrentiService,
        protected pazientiService: PazientiService,
        protected incaricatiService: IncaricatiService,
        public globals: Globals,
        public utilsService: UtilsService,
        public taskService: TaskService,
        public authService: AuthService,
        protected dialog: DialogService
    ) {
        this.tipiTask1 = this.utilsService.getTipiTask1();
        this.tipiTask2 = this.utilsService.getTipiTask2();
        this.tipiTaskDescription = this.utilsService.getTipiTaskDescription();
        this.tipiTaskDescriptionForReport = this.utilsService.getTipiTaskDescriptionForReport();
        this.colorTipoTask = {
            'cre': 'red',
            'cpa': 'green',
            'sos': 'darkgrey',
            'aor': 'blue',
            'ast': 'goldenrod',
            'gen': 'black',
            'col': 'darkmagenta',
            'cma': 'brown'
        };
        this.initializeDateVariables();
        this.createForm();
        this.subscription = this.aziendeCorrentiService.getAzienda().subscribe(azienda => {
           if (azienda != null) {
               this.azienda = azienda;
               this.initializeData().subscribe(() => {
                   this.initForm();
                   this.refreshNormalTable();
               });
            }
        });
            
    }

    protected abstract initializeDateVariables(): void;
    protected abstract initializeData(): Observable<any>;
    protected abstract createForm(): void;
    protected abstract initForm(): void;
    
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    next(){
        if(this.currentPage+1 == this.pages) return;
        this.currentPage = this.currentPage +1;
        this.refreshNormalTable();
    }

    prev(){
        if(this.currentPage == 0) return;
        this.currentPage = this.currentPage -1;
        this.refreshNormalTable();
    }


    onChangeSizePage(){
        this.currentPage = 0
        this.refreshNormalTable();
    }

    refreshNormalTable(){
        this.loading = true;
        this.objectList = [];
        this.pages = 0;
        this.fetchReport().subscribe((result) => {
            if (result) {
                this.total = result.count;
                if (result.objects && result.objects.length > 0) {
                    this.pages =  Math.ceil(this.total / this.sizePage)
                    this.objectList = result.objects;
                }
            }
            this.loading = false;
        });
    }

    protected abstract fetchReport(): Observable<any>;
    
    onSubmit(type: any) {
        this.readFormValues(this.form.getRawValue());
        this.refreshNormalTable();
    }

    protected abstract readFormValues(formValue: any): void;
    
    onDownloadTaskDocument(tipo: string, documentId: number) {
        this.taskService.getDocumentDownloadUrl(tipo, documentId.toString()).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    onShowTask(id: string, tipo: string): void {
        this.taskService.getTask(id, tipo).subscribe((t: Task) => {
            this.task = t;
            this.task.dataPrevista = new Date(t.dataPrevista);
            this.task.dataCompletamento = new Date(t.dataCompletamento);
            this.taskModalTitle = tipo == this.globals.TIPO_TASK_GENERICO ? this.task.descrizione : this.utilsService.getTipiTaskDescription()[tipo];
            this.taskService.getDocumenti('documenti', id).subscribe(documenti => {
                this.documentiTask = documenti;
                this.taskService.getDocumenti('ddt', id).subscribe(ddt => {
                    this.ddt = ddt;
                    this.showTaskModal = true;
                })
            })
        });
    }
}
