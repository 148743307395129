import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BadgesService {

    private daAutorizzareAnnouncedSource = new Subject();
    private daProcessareAnnouncedSource = new Subject();

    daAutorizzareAnnounced$ = this.daAutorizzareAnnouncedSource.asObservable();
    daProcessareAnnounced$ = this.daProcessareAnnouncedSource.asObservable();

    announceDaAutorizzareUpdate() {
        this.daAutorizzareAnnouncedSource.next('');
    }

    announceDaProcessareUpdate() {
        this.daProcessareAnnouncedSource.next('');
    }

}
