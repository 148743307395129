import {Injectable} from '@angular/core';
import {MeService} from './me.service';
import {AuthService} from './auth.service';

@Injectable()
export class CheckPathAuthorizationService {
    enabledServices: Array<string>;
    
    constructor(
        private meService: MeService,
        private authService: AuthService
    ) {
        this.meService.getEnabledServices().subscribe((enabledServices: Array<string>) => {
            this.enabledServices = enabledServices;
        });
    }

    public isAuthorized(path: string, queryParams?: any): boolean {
        if (path.startsWith('/aziende')
            || path.startsWith('/elenco-utenti')
            || path.startsWith('/approvatori')
            || path.startsWith('/appaltanti')
            || path.startsWith('/infermieri')
            || path.startsWith('/operatori-callcenter')
            || path.startsWith('/amministratori')
            || path.startsWith('/operatori')
            || path.startsWith('/partners')
            || path.startsWith('/contratti')
            || path.startsWith('/appalti')
            || path.startsWith('/concentratori')
            || path.startsWith('/saturimetri')
            || path.startsWith('/materiali')
            || path.startsWith('/kit-materiali')
            || path.startsWith('/parametri-ventilatore')
            || path.startsWith('/parametri-allarme')
            || path.startsWith('/profili')
            || path.startsWith('/modalita-ventilatorie')
            || path.startsWith('/ventilatori')
            || path.startsWith('/incaricati')
            || path.startsWith('/infermieri')
            || path.startsWith('/fornitori')
            || path.startsWith('/import_jobs')
            || path.startsWith('/strutture')
            || path.startsWith('/reparti')
            || path.startsWith('/maschere')
            || path.startsWith('/login-events')
            || path.startsWith('/export_ministero')) {
            return this.authService.isAmministratoreUser();
        } else if (path.startsWith('/pazienti-call-center')) {
            return this.authService.isOperatoreCallCenterUser();
        } else if (path.startsWith('/pazienti')) {
            return this.authService.isAmministratoreUser() || this.authService.isOperatoreUser() || this.authService.isPrescrittoreUser();
        } else if (path.startsWith('/prescrittori-esterni')) {
            return this.authService.isAmministratoreUser() || this.authService.isOperatoreUser();
        } else if (path.startsWith('/prescrittori')) {
            return this.authService.isAmministratoreUser();
        } else if (path.startsWith('/attivita-titolazione')) {
            return this.authService.isAmministratoreUser() || this.authService.isCommercialeUser() || this.authService.isTecnicoVentilazioneUser() || this.authService.isInfermiereUser() || this.authService.isFornitoreUser();
        } else if (path.startsWith('/prescrizioni/')) {
            return this.authService.isAmministratoreUser() || this.authService.isOperatoreUser() || this.authService.isTecnicoVentilazioneUser() || this.authService.isCommercialeUser() || 
                this.authService.isPrescrittoreUser() || this.authService.isApprovatoreUser() || this.authService.isAppaltanteUser() || this.authService.isPartnerUser() || this.authService.isFornitoreUser();
        } else if (path.startsWith('/prescrizioni')) {
            if (!this.authService.isAmministratoreUser() && !this.authService.isOperatoreUser() &&
                !this.authService.isPrescrittoreUser() && !this.authService.isApprovatoreUser() && !this.authService.isAppaltanteUser() && !this.authService.isPartnerUser() && !this.authService.isFornitoreUser()) {
                    return false;
            } else {
                if (queryParams && queryParams.pfilter && queryParams.pfilter == 'da_autorizzare') {
                    return this.authService.isAmministratoreUser() || this.authService.isApprovatoreUser();
                } else if (path.includes('-o2l')) {
                    return this.isServiceEnabled('o2l');
                } else if (path.includes('-o2c')) {
                    return this.isServiceEnabled('o2c');
                } else if (path.includes('-sat')) {
                    return this.isServiceEnabled('sat');
                } else if (path.includes('-vent')) {
                    return this.isServiceEnabled('vent');
                } else if (path.includes('-ega')) {
                    return this.isServiceEnabled('ega');
                } else if (path.includes('-ai')) {
                    return this.isServiceEnabled('ai');
                }
            }
        } else if (path.startsWith('/task-calendar')) {
            return this.authService.isAmministratoreUser() || (this.authService.isOperatoreUser() || this.authService.isApprovatoreUser() || this.authService.isAppaltanteUser()) 
                && this.isServiceEnabled('vent');
        } else if (path.startsWith('/task')) {
            return this.authService.isAmministratoreUser() || this.authService.isCommercialeUser() || this.authService.isTecnicoVentilazioneUser() 
                || (this.authService.isOperatoreUser() || this.authService.isFornitoreUser()) && this.isServiceEnabled('vent');
        } else if (path.startsWith('/ricerca-prescrizioni')) {
            return this.isServiceEnabled('vent');
        } else if (path == '/reports') {
            return this.authService.isAmministratoreUser() || this.authService.isOperatoreUser() || this.authService.isApprovatoreUser() || this.authService.isAppaltanteUser();
        } else if (path.startsWith('/reports')) {
            if (!this.authService.isAmministratoreUser() && !this.authService.isOperatoreUser() && !this.authService.isApprovatoreUser() && 
                !this.authService.isAppaltanteUser() && !this.authService.isTecnicoVentilazioneUser() && !this.authService.isFornitoreUser()) {
                return false;
            } else {
                if (path.includes('/costi')) {
                    if (queryParams.type == 'o2') {
                        return this.isServiceEnabled('o2l') || this.isServiceEnabled('o2c') || this.isServiceEnabled('sat');
                    } else if (queryParams.type == 'vent') {
                        return this.isServiceEnabled('vent');
                    } else if (queryParams.type == 'serv') {
                        return this.isServiceEnabled('ega') || this.isServiceEnabled('ai');
                    }
                } else if (path.includes('/consegne') || path.includes('/compliance') || path.includes('/scadenze')) {
                    return this.isServiceEnabled('o2l') || this.isServiceEnabled('o2c') || this.isServiceEnabled('sat');
                } else if (path.includes('/task')) {
                    return this.isServiceEnabled('vent');
                } else if (path.includes('/planning')) {
                    return this.isServiceEnabled('vent') && !this.authService.isAppaltanteUser() && !this.authService.isApprovatoreUser();
                } else if (path.includes('/pazienti')) {
                    if (path.includes('/pazienti-vent')) {
                        return this.isServiceEnabled('vent');
                    } else {
                        return true;
                    }
                } 
            }
        } else {
            return true;
        }
    }

    public isServiceEnabled(service: string): boolean {
        return this.enabledServices && this.enabledServices.indexOf(service) >= 0;
    }
}
