import {AccountCommand} from './account-command';
import {Cittadinanza} from '../cittadinanza';

export class PazienteCreateCommand extends AccountCommand {

    paziente: any;

    constructor(
        username: string,
        email: string,
        nome: string,
        cognome: string,
        // codicePartner: string,
        dataNascita: string,
        cittaNascita: string,
        // provinciaNascita: string,
        sesso: string,
        codiceFiscale: string,
        cittadinanza: Cittadinanza,
        nazioneId: string,
        telefono: string,
        telefonoFisso: string,
        comuneId: string,
        extraAsl: boolean,
        residenzaExtraAsl: string,
        indirizzo: string,
        numeroCivico: string,
        cap: string,
        comuneDiConsegnaId: string,
        comuneConsegna: string,
        indirizzoConsegna: string,
        numeroCivicoConsegna: string,
        capConsegna: string,
        nomeDelegato: string,
        cognomeDelegato: string,
        qualificaDelegato: string,
        telefonoDelegato: string,
        mailDelegato: string,
        giaInTerapia: boolean,
        zonaDiConsegna: number,
        codiceDistributivo: string
    ) {
        super(username, email);
        this.paziente = {
            'nome': nome,
            'cognome': cognome,
            // 'codicePartner': codicePartner,
            'dataNascita': dataNascita,
            'cittaNascita': cittaNascita,
            // 'provinciaNascita': provinciaNascita,
            'sesso': sesso,
            'codiceFiscale': codiceFiscale,
            'cittadinanza': cittadinanza,
            'nazioneId': nazioneId,
            'telefono': telefono,
            'telefonoFisso': telefonoFisso,
            'comuneId': comuneId,
            'extraAsl': extraAsl,
            'residenzaExtraAsl': residenzaExtraAsl,
            'indirizzo': indirizzo,
            'numeroCivico': numeroCivico,
            'cap': cap,
            'comuneDiConsegnaId': comuneDiConsegnaId,
            'comuneConsegna': comuneConsegna,
            'indirizzoConsegna': indirizzoConsegna,
            'numeroCivicoConsegna': numeroCivicoConsegna,
            'capConsegna': capConsegna,
            'nomeDelegato': nomeDelegato,
            'cognomeDelegato': cognomeDelegato,
            'qualificaDelegato': qualificaDelegato,
            'telefonoDelegato': telefonoDelegato,
            'mailDelegato': mailDelegato,
            'giaInTerapia': giaInTerapia,
            'zonaDiConsegna': zonaDiConsegna,
            'codiceDistributivo': codiceDistributivo
        };
    }
}
