export class ChiusuraTaskDto {
    dataCompletamento: Date;
    completato: boolean;
    note: string;

    constructor(
        dataCompletamento: Date,
        completato: boolean,
        note: string
    ) {
        this.dataCompletamento = dataCompletamento;
        this.completato = completato;
        this.note = note;
    }
}
