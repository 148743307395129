import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, of as observableOf} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {FormBuilder, Validators} from '@angular/forms';
import {ClrDatagridStateInterface} from '@clr/angular';
import {DatagridComponent} from '../common/datagrid.component';
import {DatagridRequest} from '../common/datagrid.component';
import {QueryResult} from '../../model/query/query-result';
import {PrescrizioniService} from '../../core/prescrizioni.service';
import {Prescrizione} from '../../model/query/prescrizione';
import {PrescrizioneVentilazione} from '../../model/query/prescrizione-ventilazione';
import {PrescrizioneAction} from '../../model/prescrizione-action';
import {PrescrizioneActionsService} from '../../core/prescrizione-actions.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-ricerca-prescrizioni',
    templateUrl: './ricerca-prescrizioni.component.html',
    styles: []
})
export class RicercaPrescrizioniComponent extends DatagridComponent<Prescrizione> {
    form: any;
    
    constructor(
        protected formBuilder: FormBuilder,
        public prescrizioniService: PrescrizioniService, 
        public prescrizioneActionsService: PrescrizioneActionsService,
        dialog: DialogService, 
        router: Router) {
        super(prescrizioniService, router, dialog);
        this.createForm();
        this.initForm();
    }

    createForm(): void {
        const formGroup = {
            codice: [{value: ''}, []],
            cognome: [{value: ''}, []],
            nome: [{value: ''}, []]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initForm() {
        const formValues = {
            codice: '',
            cognome: '',
            nome: ''
        };
        this.form.setValue(formValues);
    }

    onSubmit(type: any) {
        this.refresh(null);
    }
    
    protected manageRequest(request: DatagridRequest<this>) {
        let formValue = request.component.form.getRawValue();

        if (formValue.codice || formValue.cognome || formValue.nome) {
            request.state = {page: {current: 0, from: 0, size: -1, to: -1}};
            super.manageRequest(request);
        }
    }
    
    protected fetchData(
        state: ClrDatagridStateInterface,
        fields: Array<string>,
        operators: Array<string>,
        values: Array<any>
    ): Observable<QueryResult<PrescrizioneVentilazione>> {
        let formValue = this.form.getRawValue();
        
        if (formValue.codice) {
            fields.push('codice');
            operators.push('eq');
            values.push(formValue.codice);
        }
        if (formValue.cognome) {
            fields.push('paziente.cognome');
            operators.push('like');
            values.push('%' + formValue.cognome + '%');
        }
        if (formValue.nome) {
            fields.push('paziente.nome');
            operators.push('like');
            values.push('%' + formValue.nome + '%');
        }
        return this.prescrizioniService.getAllPrescrizioni(
            'vent',
            0,
            10,
            fields,
            operators,
            values,
            null,
            null
        ).pipe(
            switchMap((result: QueryResult<PrescrizioneVentilazione>) => {
                result.objects = result.objects.map(p => {
                    p['profili'] = p.prescrizioniVentilatore.map(pr => (pr.profilo.codice || '') + ' - ' + pr.profilo.nome).join('\n');
                    return p;
                });
                return observableOf(result);
            })
        );
    }

    protected getEditUrl(): string {
        return '/prescrizioni/';
    }

    onAction(entity: Prescrizione, action: PrescrizioneAction) {
        const url = this.getEditUrl() + entity.id;
        const queryParams = this.getEditQueryParams(entity);
        queryParams['action'] = action;
        this.router.navigate([url], {queryParams: queryParams});
    }

    protected getEditQueryParams(entity: Prescrizione): any {
        return {type: 'vent'};
    }
}
