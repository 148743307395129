import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from "@angular/common";
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { QueryResult } from '../../../model/query/query-result';
import { Observable, Subscription } from 'rxjs';
import { PrescrizioniService } from '../../../core/prescrizioni.service';
import { AziendeCorrentiService } from '../../../core/aziende-correnti.service';
import { Contenitore } from '../../../model/query/contenitore';
import { Paziente } from '../../../model/query/paziente';
import { PrescrizioneOssigenoLiquido } from '../../../model/query/prescrizione-ossigeno-liquido';
import { Globals } from '../../../core/globals.service';
import { FiscalCodeValidator, UtilsService } from '../../../core/utils.service';
import { ClrLoadingState } from '@clr/angular';
import { Contratto } from '../../../model/query/contratto';
import { PrescrittoriService } from '../../../core/prescrittori.service';
import { PrescrittoriEsterniService } from '../../../core/prescrittori-esterni.service';
import { AuthService } from '../../../core/auth.service';
import { PazientiService } from '../../../core/pazienti.service';
import { MeService } from '../../../core/me.service';
import { PrescrizioneStatusService } from '../../../core/prescrizione-status.service';
import { Cittadinanza } from '../../../model/cittadinanza';
import { StatoPrescrizione } from '../../../model/stato-prescrizione';
import { StatoPaziente } from '../../../model/stato-paziente';
import { ContenitoriService } from '../../../core/contenitori.service';
import { ReportsService } from '../../../core/reports.service';
import { PrescrizioneOssigenoLiquidoCommand } from '../../../model/command/prescrizione-ossigeno-liquido-command';
import { User } from '../../../model/query/user';
import { Azienda } from '../../../model/query/azienda';
import { forkJoin, of } from 'rxjs';
import { Prescrizione } from '../../../model/query/prescrizione';
import { PrescrizioneConScadenza } from '../../../model/query/prescrizione-con-scadenza';
import { PrescrizioneDocumento } from '../../../model/query/prescrizione-documento';
import { PrescrizioneDocumentoCommand } from '../../../model/command/prescrizione-documento-command';
import { PrescrizioneNotaCommand } from '../../../model/command/prescrizione-nota-command';
import { PrescrizioneNota } from '../../../model/query/prescrizione-nota';
import { environment } from '../../../../environments/environment';
import { ContrattiService } from '../../../core/contratti.service';
import { Partner } from '../../../model/query/partner';
import { PrescrizioneNotaPrescrittore } from '../../../model/query/prescrizione-nota-prescrittore';
import { PrescrizioneNotaPrescrittoreCommand } from '../../../model/command/prescrizione-nota-prescrittore-command';
import { PrescrizioneActionsService } from '../../../core/prescrizione-actions.service';
import { PrescrizioneAction } from '../../../model/prescrizione-action';
import { PartnersService } from '../../../core/partners.service';
import { PrescrizioneO2lAttivaCommand } from '../../../model/command/prescrizione-o2l-attiva-command';
import { BadgesService } from '../../../core/badges.service';
import * as moment from 'moment';
import { PrescrizioneConcentratore } from '../../../model/query/prescrizione-concentratore';
import { PrescrizioneO2cAttivaCommand } from '../../../model/command/prescrizione-o2c-attiva-command';
import { PrescrizioneConcentratoreCommand } from '../../../model/command/prescrizione-concentratore-command';
import { PrescrizioneSaturimetro } from '../../../model/query/prescrizione-saturimetro';
import { PrescrizioneSatAttivaCommand } from '../../../model/command/prescrizione-sat-attiva-command';
import { PrescrizioneSaturimetroCommand } from '../../../model/command/prescrizione-saturimetro-command';
import { PrescrizioneVentilazioneCommand } from '../../../model/command/prescrizione-ventilazione-command';
import { PrescrizioneVentilatoreCommandDto } from '../../../model/command/prescrizione-ventilatore-command-dto';
import { PrescrizioneVentilazioneCommandDto } from '../../../model/command/prescrizione-ventilazione-command-dto';
import { PrescrizioneVentAttivaCommand } from '../../../model/command/prescrizione-vent-attiva-command';
import { PrescrizioneEga } from '../../../model/query/prescrizione-ega';
import { PrescrizioneEgaCommand } from '../../../model/command/prescrizione-ega-command';
import { PrescrizioneAssistenzaInfermieristica } from '../../../model/query/prescrizione-assistenza-infermieristica';
import { PrescrizioneAssistenzaInfermieristicaCommand } from '../../../model/command/prescrizione-assistenza-infermieristica-command';
import { Concentratore } from '../../../model/query/concentratore';
import { Maschera } from '../../../model/query/maschera';
import { ConcentratoriService } from '../../../core/concentratori.service';
import { Saturimetro } from '../../../model/query/saturimetro';
import { SaturimetriService } from '../../../core/saturimetri.service';
import { PrescrizioneVentilazione } from '../../../model/query/prescrizione-ventilazione';
import { PrescrizioneVentilazioneDto } from '../../../model/query/prescrizione-ventilazione-dto';
import { ProfiliService } from '../../../core/profili.service';
import { ModalitaVentilatorieService } from '../../../core/modalita-ventilatorie.service';
import { ParametriVentilatoreService } from '../../../core/parametri-ventilatore.service';
import { ParametriAllarmeService } from '../../../core/parametri-allarme.service';
import { Profilo } from '../../../model/query/profilo';
import { Ventilatore } from '../../../model/query/ventilatore';
import { ModalitaVentilatoria } from '../../../model/query/modalita-ventilatoria';
import { MaterialeConsumabile } from '../../../model/query/materiale-consumabile';
import { ParametroVentilatore } from '../../../model/query/parametro-ventilatore';
import { ParametroAllarme } from '../../../model/query/parametro-allarme';
import { ParametroAllarmeDto } from '../../../model/query/parametro-allarme-dto';
import { PrescrizioneVentilatoreDto } from '../../../model/query/prescrizione-ventilatore-dto';
import { ParametroVentilatoreDto } from '../../../model/query/parametro-ventilatore-dto';
import { TaskService } from '../../../core/task.service';
import { IncaricatiService } from '../../../core/incaricati.service';
import { StatoTaskPrescrizione } from '../../../model/stato-task-prescrizione';
import { Task } from '../../../model/query/task';
import { Prescrittore } from '../../../model/query/prescrittore';
import { PrescrittoreEsterno } from '../../../model/query/prescrittore-esterno';
import { TaskCommand } from '../../../model/command/task-command';
import { TaskConsegnaReparto } from '../../../model/query/task-consegna-reparto';
import { TaskConsegnaRepartoCommand } from '../../../model/command/task-consegna-reparto-command';
import { TaskConsegnaPaziente } from '../../../model/query/task-consegna-paziente';
import { TaskConsegnaPazienteCommand } from '../../../model/command/task-consegna-paziente-command';
import { TaskSospensione } from '../../../model/query/task-sospensione';
import { TaskSospensioneCommand } from '../../../model/command/task-sospensione-command';
import { TaskAccessoOrdinario } from '../../../model/query/task-accesso-ordinario';
import { TaskAccessoStraordinario } from '../../../model/query/task-accesso-straordinario';
import { TaskAccessoOrdinarioCommand } from '../../../model/command/task-accesso-ordinario-command';
import { TaskAccessoStraordinarioCommand } from '../../../model/command/task-accesso-straordinario-command';
import { TaskGenerico } from '../../../model/query/task-generico';
import { TaskGenericoCommand } from '../../../model/command/task-generico-command';
import { TaskConsegnaMateriali } from '../../../model/query/task-consegna-materiali';
import { TaskConsegnaMaterialiCommand } from '../../../model/command/task-consegna-materiali-command';
import { TaskVerificaElettrica } from '../../../model/query/task-verifica-elettrica';
import { TaskVerificaElettricaCommand } from '../../../model/command/task-verifica-elettrica-command';
import { TaskCollaudo } from '../../../model/query/task-collaudo';
import { TaskCollaudoCommand } from '../../../model/command/task-collaudo-command';
import { TaskRitiroDispositivo } from '../../../model/query/task-ritiro-dispositivo';
import { TaskSostituzione } from '../../../model/query/task-sostituzione';
import { TaskCompliance } from '../../../model/query/task-compliance';
import { TaskReportistica } from '../../../model/query/task-reportistica';
import { TaskFollowup } from '../../../model/query/task-followup';
import { TaskRitiroDispositivoCommand } from '../../../model/command/task-ritiro-dispositivo-command';
import { TaskSostituzioneCommand } from '../../../model/command/task-sostituzione-command';
import { TaskComplianceCommand } from '../../../model/command/task-compliance-command';
import { TaskReportisticaCommand } from '../../../model/command/task-reportistica-command';
import { TaskFollowupCommand } from '../../../model/command/task-followup-command';
import { Incaricato } from '../../../model/query/incaricato';
import { TaskDocumento } from '../../../model/query/task-documento';
import { TaskDdt } from '../../../model/query/task-ddt';
import { TaskDocumentoCommand } from '../../../model/command/task-documento-command';
import { CodicePazienteResponse } from '../../../model/codice-paziente-response';
import { dateFields, datiCliniciFields, diagnosiFields, terapiaFields } from '../fields';
import { KitMaterialiService } from '../../../core/kit-materiali.service';
import { DialogService } from '../../dialog/dialog.service';
import { TipoIncaricato } from '../../../model/tipo-incaricato';

@Component({
    selector: 'app-prescrizione-detail',
    templateUrl: './prescrizione-detail.component.html',
    styleUrls: ['./prescrizione-detail.component.css']
})
export class PrescrizioneDetailComponent implements OnInit {
    @ViewChild('fileInputDocumentoTask', { static: false }) fileInputDocumentoTask: ElementRef;
    @ViewChild('fileInputDdtTask', { static: false }) fileInputDdtTask: ElementRef;
    @ViewChild('fileInputDocumento', { static: false }) fileInputDocumento: ElementRef;
    @ViewChild('fileInputDdt', { static: false }) fileInputDdt: ElementRef;
    @ViewChild('fileInputDocPaziente', { static: false }) fileInputDocPaziente: ElementRef;

    NUOVA: string = 'nuova';
    tipiTaskDescription: any;
    statiTaskDescription: any;
    statiTask: Array<Number>;
    queryParams: any;
    id: string;
    form: FormGroup;
    servizioForm: FormGroup;
    submitButton: ClrLoadingState = ClrLoadingState.SUCCESS;
    prescrizioneType: string;
    prescriptionFilter: string;
    prescrizioneO2l: PrescrizioneOssigenoLiquido;
    prescrizioneO2c: PrescrizioneConcentratore;
    prescrizioneSat: PrescrizioneSaturimetro;
    prescrizioneVent: PrescrizioneVentilazione;
    prescrizioneEga: PrescrizioneEga;
    prescrizioneAi: PrescrizioneAssistenzaInfermieristica;
    prescrizioneConScadenza: PrescrizioneConScadenza;
    prescrizione: Prescrizione;
    paziente: Paziente;
    modalServizioOpened: boolean;
    modalSceltaTaskOpen: boolean;
    modalOpened: boolean;
    modalPazienteDecedutoOpened: boolean = false;
    closed = true;
    isServizioSelected = false;
    showPazienteFormInputGroup = false;
    currentUser: User;
    contratto: Contratto;
    userContratti: Array<Contratto> = new Array<Contratto>();
    serviziDescription: any;
    contenitori: Array<Contenitore> = new Array<Contenitore>();
    maschere: Array<Maschera> = new Array<Maschera>();
    statiDescription: Array<string>;
    documento: File;
    docPaziente: File;
    documenti = new Array<PrescrizioneDocumento>();
    docPazienti = new Array<PrescrizioneDocumento>();
    documentiTask = new Array<TaskDocumento>();
    ddtTask = new Array<TaskDdt>();
    ddt: File;
    ddts = new Array<PrescrizioneDocumento>();
    alertMessage: string;
    noteFornitoreList: PrescrizioneNota[];
    notePrescrittoreList: PrescrizioneNotaPrescrittore[];
    risultato: File;

    partnerList: Partner[] = [];
    storageBasePath: string;

    isAssignedToPartner = false;
    showPartnerModal = false;
    partnerModalAssign = true;
    assignedPartner: Partner;
    assignedPartnerId: number;
    dataAssegnazione: Date;
    codicePartner: string;

    action: PrescrizioneAction;

    // sections disable flags
    allSectionsDisabled = false;
    pazienteSectionDisabled = false;
    notePrescrittoreSectionDisabled = false;
    datiCliniciSectionDisabled = false;
    attivazioneSectionDisabled = false;
    diagnosiSectionDisabled = false;
    documentiSectionDisabled = false;
    terapiaSectionDisabled = false;
    ddtSectionDisabled = false;
    submitSectionDisabled = false;
    prescrizioneSectionDisabled = false;

    // sections hide flags
    pazienteSectionHidden = false;
    attivazioneSectionHidden = false;
    datiCliniciSectionHidden = false;
    notePrescrittoreSectionHidden = false;
    diagnosiSectionHidden = false;
    noteFornitoreSectionHidden = false;
    terapiaSectionHidden = false;
    documentiSectionHidden = false;
    ddtSectionHidden = false;
    docPazientiSectionHidden = false;
    storicoSectionHidden = false;
    submitSectionHidden = false;
    updatedFields = [];
    authorizedActions: PrescrizioneAction[];
    flussoNotturnoOreError = false;
    flussoRiposoOreError = false;
    flussoSottoSforzoOreError = false;

    diagnosiList: string[] = [
        'bpco55',
        'bpco60Ipossiemia',
        'bpco60Policitemia',
        'bpco60Edemi',
        'bpco60Ipertensione',
        'fc55',
        'fc60Ipossiemia',
        'fc60Policitemia',
        'fc60Edemi',
        'fc60Ipertensione',
        'malattieInterstiziali',
        'ipertensionePolmonare',
        'neoplasieMaligne',
        'patologieNeuro',
        'apneaOstruttiva',
        'scompensoCardiacoIpossiemia',
        'desaturazioneNotturna',
        'desaturazioneEsercizio',
        'osas',
        'osasAltra',
        'poliomielite',
        'miopatieCongenite',
        'miopatieMitocondriali',
        'distrofiaMuscolare',
        'guillainBarre',
        'sla',
        'atrofiaMuscolareSpinale',
        'paralisiDiaframma',
        'lesioniNervoFrenico',
        'cifoscoliosi',
        'deformitaToracica',
        'ipoventilazioneCongenita',
        'ipoventilazioneCentrale',
        'leigh',
        'ipoventilazionePostChirurgica',
        'ipoventilazionePostRadioterapia',
        'ipoventilazioneNeoplasie',
        'ipoventilazionePostTraumatica',
        'bpco',
        'bronchiectasie',
        'fibrosiCistica',
        'fibrosiPolmonare',
        'malattieCardiacheCongenite',
        'malattieCardiacheAcquisite',
        'diagnosiAltra'
    ];

    bpco60Diagnosi: string[] = [
        'bpco60Ipossiemia',
        'bpco60Policitemia',
        'bpco60Edemi',
        'bpco60Ipertensione'
    ];

    fc60Diagnosi: string[] = [
        'fc60Ipossiemia',
        'fc60Policitemia',
        'fc60Edemi',
        'fc60Ipertensione'
    ];

    previousDate: Date;
    showConfirmationModal = false;
    confirmation = false;
    showDateAlertModal = false;
    dateAlertModalText: String;
    dataAttivazioneAlert: Date;
    dataAttivazioneMassima: Date;
    showLoadPrescriptionModal = false;
    loadPrescriptionModalBlock = false;
    prescriptionIdToImport: number;
    prescriptionIdToImportType: string;

    concentratori: Array<Concentratore>;
    saturimetri: Array<Saturimetro>;

    revisions: Array<any> = new Array<any>();
    selectedRevision: any;

    incaricati: Array<Incaricato>;
    prescrittori: Array<Prescrittore>;
    prescrittoriEsterni: Array<PrescrittoreEsterno>;
    profili: Array<Profilo>;
    ventilatoriModal: Array<Ventilatore> = new Array<Ventilatore>();
    modalitaVentilatorie: Array<ModalitaVentilatoria> = new Array<ModalitaVentilatoria>();
    parametriVentilatore: Array<ParametroVentilatore> = new Array<ParametroVentilatore>();
    parametriAllarme: Array<ParametroAllarme> = new Array<ParametroAllarme>();
    prescrizioniVentilatore: Array<PrescrizioneVentilatoreDto> = new Array<PrescrizioneVentilatoreDto>();
    tasks: Array<Task>;
    selectedPrescrizioneVentilatore: PrescrizioneVentilatoreDto;
    previousPrescrizioneVentilatore: PrescrizioneVentilatoreDto;
    selectedParametro: ParametroVentilatoreDto;
    selectedParametroAllarme: ParametroAllarmeDto;
    selectedTask: Task;
    taskFetched: boolean = false;
    selectedTaskConsegnaReparto: TaskConsegnaReparto;
    selectedTaskConsegnaPaziente: TaskConsegnaPaziente;
    selectedTaskSospensione: TaskSospensione;
    selectedTaskAccessoOrdinario: TaskAccessoOrdinario;
    selectedTaskAccessoStraordinario: TaskAccessoStraordinario;
    selectedTaskGenerico: TaskGenerico;
    selectedTaskConsegnaMateriali: TaskConsegnaMateriali;
    selectedTaskVerificaElettrica: TaskVerificaElettrica;
    selectedTaskCollaudo: TaskCollaudo;
    selectedTaskFollowup: TaskFollowup;
    selectedTaskCompliance: TaskCompliance;
    selectedTaskReportistica: TaskReportistica;
    selectedTaskSostituzione: TaskSostituzione;
    selectedTaskRitiroDispositivo: TaskRitiroDispositivo;
    subscription: Subscription;
    azienda: Azienda;

    showPrescrizioneVentilatoreModal: boolean = false;
    prescrizioneVentilatoreModal = {
        id: undefined,
        ventilatore: undefined,
        profilo: undefined,
        backup: false,
        modalita: undefined,
        add: true
    };
    showParametroModal: boolean = false;
    parametroModal = {
        parametro: undefined,
        valore: 0,
        unitaDiMisura: undefined,
        add: true
    };
    availableParametri: Array<ParametroVentilatore>;
    showParametroAllarmeModal: boolean = false;
    parametroAllarmeModal = {
        parametro: undefined,
        min: 0,
        max: 0,
        unitaDiMisura: undefined,
        add: true
    };
    availableParametriAllarme: Array<ParametroAllarme>;
    taskModal = {
        id: undefined,
        tipo: '',
        title: '',
        ventilatore: undefined,
        incaricato: undefined,
        dataPrevista: undefined,
        dataCompletamento: undefined,
        descrizione: undefined,
        noteUfficio: undefined,
        note: undefined,
        materialePronto: false,
        stato: undefined,
        documenti: undefined,
        followupPositivo: false,
        chiarimenti: false,
        ulterioreFollowup: false,
        motivoRitiro: '0',
        add: true,
        readonly: false
    };
    showTaskModal: boolean = false;
    showRevisionModal: boolean = false;
    selectedMaschere: Array<Maschera> = new Array<Maschera>;
    showMaschereModal: boolean = false;

    mapVentilatoriMateriali: Map<number, any> = new Map<number, any>();
    mapSelectedVentilatoriMateriali: Map<number, any> = new Map<number, any>();
    mapVentilatoriMaterialiMedicazione: Map<number, any> = new Map<number, any>();
    mapSelectedVentilatoriMaterialiMedicazione: Map<number, any> = new Map<number, any>();
    materiali: Array<any>;
    selectedMateriali: Array<any>;
    materialiMedicazione: Array<any>;
    selectedMaterialiMedicazione: Array<any>;
    maxDataPrescrizione: string;

    motiviRitiroDescription: any;
    
    showBlockAssistito: boolean = false;
    showBlockDelegato: boolean = false;
    showBlockDatiClinici: boolean = false;
    showBlockDiagnosi: boolean = false;
    showBlockNotePrescrittore: boolean = false;
    showBlockTerapia: boolean = false;
    showBlockDatiAttivazione: boolean = false;
    showBlockNoteInterne: boolean = false;
    showBlockDocumenti: boolean = false;
    showBlockDdt: boolean = false;
    showBlockDocumentiInterni: boolean = false;
    showBlockStorico: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        public prescrizioniService: PrescrizioniService,
        private prescrittoriService: PrescrittoriService,
        private prescrittoriEsterniService: PrescrittoriEsterniService,
        public utilsService: UtilsService,
        private reportsService: ReportsService,
        private formBuilder: FormBuilder,
        public globals: Globals,
        public authService: AuthService,
        private pazientiService: PazientiService,
        private meService: MeService,
        private aziendeCorrentiService: AziendeCorrentiService,
        private prescrizioneStatusService: PrescrizioneStatusService,
        public prescrizioneActionsService: PrescrizioneActionsService,
        private contenitoriService: ContenitoriService,
        private concentratoriService: ConcentratoriService,
        private saturimetriService: SaturimetriService,
        private profiliService: ProfiliService,
        private modalitaVentilatorieService: ModalitaVentilatorieService,
        private parametriVentilatoreService: ParametriVentilatoreService,
        private parametriAllarmeService: ParametriAllarmeService,
        private contrattiService: ContrattiService,
        public taskService: TaskService,
        private incaricatiService: IncaricatiService,
        private partnersService: PartnersService,
        private kitMaterialiService: KitMaterialiService,
        private badgesService: BadgesService,
        private dialog: DialogService
    ) {
        this.createServizioForm();
        this.form = this.formBuilder.group({});
        this.storageBasePath = environment.storageUrl;
        this.tipiTaskDescription = this.utilsService.getTipiTaskDescription();
        this.motiviRitiroDescription = this.utilsService.getMotiviRitiroDescription();
        this.statiTask = this.utilsService.getStatiTask();
        this.statiTaskDescription = this.utilsService.getStatiTaskDescription();
        this.maxDataPrescrizione = this.utilsService.formatDate(new Date(), 'YYYY-MM-DD');
    }

    ngOnInit() {
        this.statiDescription = this.getStatiDescription();
        this.serviziDescription = this.utilsService.getServiziDescription();
        this.id = this.route.snapshot.paramMap.get('id');

        const getMeObservable = this.meService.getMe();
        const getContenitoriObservable = this.contenitoriService.getContenitori();

        this.subscription = this.aziendeCorrentiService.getAzienda().subscribe(azienda => {
            this.azienda = azienda;
            if (azienda != null) {
                forkJoin([getMeObservable, getContenitoriObservable]).subscribe((results: [User, QueryResult<Contenitore>]) => {
                    this.currentUser = results[0];
                    this.contenitori = results[1].objects;
                    this.init();
                });
            }
        });
    }

    init(): void {
        this.route.queryParams.subscribe(params => {
            this.queryParams = params;
            if (params && params.type) {
                this.prescrizioneType = params.type;
            }
            if (this.id !== 'new') {
                this.isServizioSelected = true;
                if (params) {
                    if (params.action) {
                        this.action = params.action;
                    }
                    if (params.prescriptionFilter) {
                        this.prescriptionFilter = params.prescriptionFilter;
                    }
                    this.createForm();
                    if (this.prescrizioneType) {
                        switch (this.prescrizioneType) {
                            case 'o2l':
                                this.prescrizioniService.getPrescrizioneOssigenoLiquido(this.id).subscribe((prescrizione: PrescrizioneOssigenoLiquido) => {
                                    this.initializePrescrizione(prescrizione);
                                });
                                break;
                            case 'o2c':
                                this.prescrizioniService.getPrescrizioneConcentratore(this.id).subscribe((prescrizione: PrescrizioneConcentratore) => {
                                    this.initializePrescrizione(prescrizione);
                                });
                                break;
                            case 'sat':
                                this.prescrizioniService.getPrescrizioneSaturimetro(this.id).subscribe((prescrizione: PrescrizioneSaturimetro) => {
                                    this.initializePrescrizione(prescrizione);
                                });
                                break;
                            case 'vent':
                                this.prescrizioniService.getPrescrizioneVentilazione(this.id).subscribe((prescrizioneDto: PrescrizioneVentilazioneDto) => {
                                    this.initializePrescrizione(prescrizioneDto.prescrizione);
                                    this.prescrizioniVentilatore = prescrizioneDto.prescrizioniVentilatori;
                                });
                                break;
                            case 'ega':
                                this.prescrizioniService.getPrescrizioneEga(this.id).subscribe((prescrizione: PrescrizioneEga) => {
                                    this.initializePrescrizione(prescrizione);
                                });
                                break;
                            case 'ai':
                                this.prescrizioniService.getPrescrizioneAssistenzaInfermieristica(this.id).subscribe((prescrizione: PrescrizioneAssistenzaInfermieristica) => {
                                    this.initializePrescrizione(prescrizione);
                                });
                                break;
                            default:
                                break;
                        }
                    } else {
                        // TODO: show error
                    }
                }
            } else {
                if (this.authService.isPrescrittoreUser() || this.authService.isAmministratoreUser() || this.authService.isOperatoreUser()) {
                    let call = this.authService.isPrescrittoreUser() ?
                        this.prescrittoriService.getContratti(this.currentUser.id.toString()) :
                        this.contrattiService.getContrattiAzienda(this.azienda.id);

                    call.subscribe((contratti: Array<Contratto>) => {
                        this.userContratti = contratti;
                        const status = this.prescrizioneStatusService.getStatus();
                        if (status.contratto && status.codiceFiscale) {
                            const calls = {};

                            this.isServizioSelected = true;
                            this.contratto = status.contratto;
                            this.prescrizioneType = this.contratto.servizio;
                            this.createForm();
                            if (this.prescrizioneType == 'vent') {
                                calls['profili'] = this.contrattiService.getProfili(this.contratto.id.toString());
                                calls['modalitaVentilatorie'] = this.modalitaVentilatorieService.getAll(0, -1, null, null, null, 'nome', 'asc');
                                calls['parametriVentilatore'] = this.parametriVentilatoreService.getAll(0, -1, null, null, null, 'nome', 'asc');
                                calls['parametriAllarme'] = this.parametriAllarmeService.getAll(0, -1, null, null, null, 'nome', 'asc');
                                calls['incaricati'] = this.incaricatiService.getList();
                                calls['maschere'] = this.contrattiService.getMaschere(this.contratto.id.toString());
                                calls['prescrittori'] = this.contrattiService.getAssignedPrescrittori(this.contratto.id.toString(), 0, 10000);
                                calls['prescrittoriEsterni'] = this.prescrittoriEsterniService.getList();
                            }
                            if (Object.keys(calls).length > 0) {
                                forkJoin(calls).subscribe((values: any) => {
                                    if (values.hasOwnProperty('profili')) {
                                        this.profili = this.authService.isAmministratoreUser() ? values['profili'].map(ctv => ctv.profilo) : values['profili'].map(ctv => ctv.profilo);
                                    }
                                    if (values.hasOwnProperty('modalitaVentilatorie')) {
                                        this.modalitaVentilatorie = values['modalitaVentilatorie'].objects;
                                    }
                                    if (values.hasOwnProperty('parametriVentilatore')) {
                                        this.parametriVentilatore = values['parametriVentilatore'].objects;
                                    }
                                    if (values.hasOwnProperty('parametriAllarme')) {
                                        this.parametriAllarme = values['parametriAllarme'].objects;
                                    }
                                    if (values.hasOwnProperty('incaricati')) {
                                        this.incaricati = values['incaricati'].objects;
                                    }
                                    if (values.hasOwnProperty('maschere')) {
                                        this.maschere = values['maschere'].map(cm => cm.maschera);
                                    }
                                    if (values.hasOwnProperty('prescrittori')) {
                                        this.prescrittori = values['prescrittori'].map(o => o.prescrittore);
                                    }
                                    if (values.hasOwnProperty('prescrittoriEsterni')) {
                                        this.prescrittoriEsterni = values['prescrittoriEsterni'].objects;
                                    }
                                    this.initializeFormFields();
                                });
                            } else {
                                this.prescrittori = [this.currentUser as Prescrittore];
                                this.prescrittoriEsterniService.getList().subscribe(p => this.prescrittoriEsterni = p.objects);
                                this.initializeFormFields();
                            }
                            this.form.patchValue({
                                contrattoId: this.contratto.id,
                                codiceFiscale: status.codiceFiscale
                            });
                        } else {
                            if (this.prescrizioneType) {
                                this.userContratti = this.userContratti.filter(c => c.servizio === this.prescrizioneType);
                            }

                            if (this.userContratti.length == 1) {
                                this.contratto = this.userContratti[0];
                                this.initializeNewPrescription();
                            } else {
                                this.modalServizioOpened = true;
                            }
                        }
                    });
                } else {
                    // TODO: show error
                }
            }
        });
    }

    initializePrescrizione(prescrizione: Prescrizione): void {
        const calls = {};

        this.prescrittori = [prescrizione.prescrittore];
        if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RINNOVO) {
            if (this.authService.isPrescrittoreUser()) {
                this.previousDate = prescrizione.dataPrescrizione;
                prescrizione.dataPrescrizione = null;
            }
        }
        this.authorizedActions = this.prescrizioneActionsService.getAuthorizedActions(prescrizione);
        switch (this.prescrizioneType) {
            case 'o2l':
                this.prescrizioneO2l = prescrizione as PrescrizioneOssigenoLiquido;
                this.prescrizioneConScadenza = this.prescrizioneO2l;
                break;
            case 'o2c':
                this.prescrizioneO2c = prescrizione as PrescrizioneConcentratore;
                this.prescrizioneConScadenza = this.prescrizioneO2c;
                break;
            case 'sat':
                this.prescrizioneSat = prescrizione as PrescrizioneSaturimetro;
                this.prescrizioneConScadenza = this.prescrizioneSat;
                break;
            case 'vent':
                this.prescrizioneVent = prescrizione as PrescrizioneVentilazione;
                this.prescrizioneConScadenza = this.prescrizioneVent;
                break;
            case 'ega':
                this.prescrizioneEga = prescrizione as PrescrizioneEga;
                this.prescrizioneConScadenza = undefined;
                break;
            case 'ai':
                this.prescrizioneAi = prescrizione as PrescrizioneAssistenzaInfermieristica;
                this.prescrizioneConScadenza = undefined;
                break;
            default:
                break;
        }
        this.prescrizione = prescrizione;
        this.userContratti = [prescrizione.contratto];
        this.contratto = prescrizione.contratto;
        this.paziente = prescrizione ? prescrizione.paziente : null;
        if (prescrizione.partnerForeignKey) {
            this.assignedPartnerId = prescrizione.partnerForeignKey;
            this.isAssignedToPartner = true;
            this.dataAssegnazione = prescrizione.dataAssegnazione;
            this.codicePartner = prescrizione.codicePartner;
            if (!this.authService.isPrescrittoreUser() && !this.authService.isApprovatoreUser() && !this.authService.isAppaltanteUser()) {
                calls['partner'] = this.partnersService.get(prescrizione.partnerForeignKey.toString());
            }
        }

        this.prescrizioniService.getDocumenti('documenti', this.id).subscribe(documenti => {
            this.documenti = documenti;
        });
        this.prescrittoriEsterniService.getList().subscribe(p => this.prescrittoriEsterni = p.objects);
        if (this.authService.isAmministratoreUser() || this.authService.isOperatoreUser() || this.authService.isApprovatoreUser() || this.authService.isAppaltanteUser()) {
            if (this.authService.isAmministratoreUser() || this.authService.isOperatoreUser()) {
                calls['contrattoPartners'] = this.contrattiService.getAssignedPartners(this.contratto.id.toString(), 0, 1000);
                this.prescrizioniService.getDocumenti('docpazienti', this.id).subscribe((docs) => {
                    this.docPazienti = docs;
                });
            }
            this.prescrizioniService.getDocumenti('ddt', this.id).subscribe((ddts) => {
                this.ddts = ddts;
            });
        }
        switch (this.prescrizioneType) {
            case 'o2l':
                calls['revisions'] = this.prescrizioniService.getRevisioniPrescrizioneOssigenoLiquido(this.prescrizione.id.toString());
                break;
            case 'o2c':
                calls['revisions'] = this.prescrizioniService.getRevisioniPrescrizioneConcentratore(this.prescrizione.id.toString());
                if ((prescrizione as PrescrizioneConcentratore).tipoConcentratore == this.prescrizioniService.TIPO_CONCENTRATORE.FISSO) {
                    calls['concentratori'] = this.concentratoriService.getAllFissi();
                } else if ((prescrizione as PrescrizioneConcentratore).tipoConcentratore == this.prescrizioniService.TIPO_CONCENTRATORE.PORTATILE) {
                    calls['concentratori'] = this.concentratoriService.getAllPortatili();
                }
                break;
            case 'sat':
                calls['revisions'] = this.prescrizioniService.getRevisioniPrescrizioneSaturimetro(this.prescrizione.id.toString());
                calls['saturimetri'] = this.saturimetriService.getAll(0, 10000000, null, null, null, null, null);
                break;
            case 'vent':
                calls['revisions'] = this.prescrizioniService.getRevisioniPrescrizioneVentilazione(this.prescrizione.id.toString());
                calls['profili'] = this.contrattiService.getProfili(this.contratto.id.toString());
                calls['modalitaVentilatorie'] = this.modalitaVentilatorieService.getAll(0, -1, null, null, null, 'nome', 'asc');
                calls['parametriVentilatore'] = this.parametriVentilatoreService.getAll(0, -1, null, null, null, 'nome', 'asc');
                calls['parametriAllarme'] = this.parametriAllarmeService.getAll(0, -1, null, null, null, 'nome', 'asc');
                calls['incaricati'] = this.incaricatiService.getList();
                calls['maschere'] = this.contrattiService.getMaschere(this.contratto.id.toString());
                calls['prescrittori'] = this.contrattiService.getAssignedPrescrittori(this.contratto.id.toString(), 0, 10000);
                break;
            default:
                break;
        }
        if (Object.keys(calls).length > 0) {
            forkJoin(calls).subscribe((values: any) => {
                if (values.hasOwnProperty('contrattoPartners')) {
                    for (const contrattoPartner of values['contrattoPartners']) {
                        this.partnerList.push(contrattoPartner.partner);
                    }
                }
                if (values.hasOwnProperty('partner')) {
                    this.assignedPartner = values['partner'];
                }
                if (values.hasOwnProperty('revisions')) {
                    this.mapRevisionsData(values['revisions']);
                }
                switch (this.prescrizioneType) {
                    case 'o2l':
                        break;
                    case 'o2c':
                        if (values.hasOwnProperty('concentratori')) {
                            this.concentratori = values['concentratori'];
                        }
                        break;
                    case 'sat':
                        if (values.hasOwnProperty('saturimetri')) {
                            this.saturimetri = values['saturimetri'].objects;
                        }
                        break;
                    case 'vent':
                        if (values.hasOwnProperty('profili')) {
                            this.profili = this.authService.isAmministratoreUser() ? values['profili'].map(ctv => ctv.profilo) : values['profili'].map(ctv => ctv.profilo);
                        }
                        if (values.hasOwnProperty('modalitaVentilatorie')) {
                            this.modalitaVentilatorie = values['modalitaVentilatorie'].objects;
                        }
                        if (values.hasOwnProperty('parametriVentilatore')) {
                            this.parametriVentilatore = values['parametriVentilatore'].objects;
                        }
                        if (values.hasOwnProperty('parametriAllarme')) {
                            this.parametriAllarme = values['parametriAllarme'].objects;
                        }
                        if (values.hasOwnProperty('incaricati')) {
                            this.incaricati = values['incaricati'].objects;
                        }
                        if (values.hasOwnProperty('maschere')) {
                            this.maschere = values['maschere'].map(cm => cm.maschera);
                        }
                        if (values.hasOwnProperty('prescrittori')) {
                            this.prescrittori = values['prescrittori'].map(o => o.prescrittore);
                        }
                        break;
                    default:
                        break;
                }
                this.initializeFormFields();
            });
        } else {
            this.initializeFormFields();
        }
        if (this.prescrizioneO2l && this.prescrizioneO2l.modificata ||
            this.prescrizioneO2c && this.prescrizioneO2c.modificata ||
            this.prescrizioneVent && this.prescrizioneVent.modificata) {
            this.updatedFields = this.getUpdatedFields(prescrizione);
        }
        this.getNotePrescrittore(prescrizione);
        this.getNoteFornitore(prescrizione);
    }

    private mapRevisionsData(revisions: Array<any>): void {
        this.revisions = new Array<any>();

        for (let i = 0; i < revisions.length; ++i) {
            let revision = revisions[i];
            let r = {};
            let insert: boolean = false;

            r['revisionDate'] = revision.revision.revisionDate;
            r['update'] = false;
            if (revision.type == 'ADD' || revision.modifiedFields.some(f => !f.endsWith('Mod') && f !== 'modificata')) {
                if (revision.type == 'ADD') {
                    r['description'] = 'RICHIESTA ATTIVAZIONE';
                    r['revisionDate'] = revision.entity.dataRicezione || revision.entity.dataPrescrizione;
                    insert = true;
                } else {
                    if (revision.modifiedFields[0] == 'autorizzata') {
                        r['description'] = 'AUTORIZZATA';
                        r['revisionDate'] = revision.entity.dataAutorizzazione;
                        insert = true;
                    } else if (revision.modifiedFields.some(f => f === 'stato')) {
                        r['description'] = this.prescrizioniService.STATO_PRESCRIZIONE[revision.entity.stato].replace('_', ' ');
                        if (revision.entity.stato == StatoPrescrizione.ATTIVATA) {
                            r['dataStato'] = revision.entity.dataRiattivazione || revision.entity.dataAttivazione;
                        } else if (revision.entity.stato == StatoPrescrizione.SOSPESA) {
                            r['dataStato'] = revision.entity.dataSospensione;
                        } else if (revision.entity.stato == StatoPrescrizione.CESSATA) {
                            r['dataStato'] = revision.entity.dataCessazione;
                        } else if (revision.entity.stato == StatoPrescrizione.ANNULLATA) {
                            r['dataStato'] = revision.entity.dataAnnullamento;
                        }
                        insert = true;
                    } else if (revision.modifiedFields.some(f => f === 'dataAssegnazione')) {
                        if (revision.entity.codicePartner) {
                            r['description'] = 'ASSEGNATA A PARTNER';
                        } else {
                            r['description'] = 'DEASSEGNATA DA PARTNER';
                        }
                        r['revisionDate'] = revision.entity.dataAssegnazione;
                        insert = true;
                    } else {
                        r['description'] = '';
                        if (revision.modifiedFields.some(f => f === 'inRinnovo') && revision.entity.inRinnovo) {
                            r['description'] += 'RINNOVATA';
                            r['revisionDate'] = revision.entity.dataPrescrizione;
                            insert = true;
                        }
                        if (revision.modifiedFields.some(f => f === 'modificata') && revision.modifiedFields.some(f => dateFields[f] || diagnosiFields[f] || datiCliniciFields[f] || terapiaFields[f])) {
                            if (r['description']) {
                                r['description'] += '/';
                            }
                            r['description'] += 'MODIFICA ' + [...new Set(revision.modifiedFields.filter(f => f !== 'modificata' && f !== 'autorizzata' && (!f.startsWith('data') || f == 'dataPrescrizione') && !f.endsWith('Mod'))
                                .map(f => datiCliniciFields[f] ? 'DATI CLINICI' : (diagnosiFields[f] ? 'DIAGNOSI' : (terapiaFields[f] ? 'TERAPIA' : (dateFields[f] ? dateFields[f] : '')))).filter(s => s.length > 0))].toString();
                            r['updates'] = revision.modifiedFields.filter(f => f !== 'modificata' && f !== 'autorizzata' && !f.endsWith('Mod'))
                                .map(f => {
                                    return {
                                        "field": diagnosiFields[f] ? 'DIAGNOSI' : datiCliniciFields[f] || terapiaFields[f] || dateFields[f],
                                        "newValue": diagnosiFields[f] ? (revision.entity[f] ? diagnosiFields[f] : '') : revision.entity[f],
                                        "oldValue": diagnosiFields[f] ? (revisions[i - 1].entity[f] ? diagnosiFields[f] : '') : revisions[i - 1].entity[f],
                                        "date": f.startsWith('data')
                                    };
                                }).filter(o => o.field).sort((o1, o2) => {
                                    return o1.field < o2.field ? -1 : (o1.field === o2.field ? 0 : 1);
                                });
                            r['update'] = true;
                            insert = true;
                        }
                    }
                }
                if (insert) {
                    r['entity'] = revision.entity;
                    this.revisions.push(r);
                }
            }
        }
    }

    onShowRevision(): void {
        this.showRevisionModal = true;
    }

    servizioModalOnConfirm(): void {
        const formValue = this.servizioForm.getRawValue();
        if (formValue.contrattoId !== '') {
            for (const contratto of this.userContratti) {
                if (contratto.id === +formValue.contrattoId) {
                    this.contratto = contratto;
                    break;
                }
            }
            this.initializeNewPrescription();
        }
    }

    initializeNewPrescription(): void {
        const calls = {};

        this.prescrizioneType = this.contratto.servizio;
        this.createForm();
        this.prescrittoriEsterniService.getList().subscribe(p => this.prescrittoriEsterni = p.objects);
        if (this.prescrizioneType == 'vent') {
            calls['profili'] = this.contrattiService.getProfili(this.contratto.id.toString());
            calls['modalitaVentilatorie'] = this.modalitaVentilatorieService.getAll(0, -1, null, null, null, 'nome', 'asc');
            calls['parametriVentilatore'] = this.parametriVentilatoreService.getAll(0, -1, null, null, null, 'nome', 'asc');
            calls['parametriAllarme'] = this.parametriAllarmeService.getAll(0, -1, null, null, null, 'nome', 'asc');
            calls['incaricati'] = this.incaricatiService.getList();
            calls['maschere'] = this.contrattiService.getMaschere(this.contratto.id.toString());
            calls['prescrittori'] = this.contrattiService.getAssignedPrescrittori(this.contratto.id.toString(), 0, 10000);
        }
        if (Object.keys(calls).length > 0) {
            forkJoin(calls).subscribe((values: any) => {
                if (values.hasOwnProperty('profili')) {
                    this.profili = this.authService.isAmministratoreUser() ? values['profili'].map(ctv => ctv.profilo) : values['profili'].map(ctv => ctv.profilo);
                }
                if (values.hasOwnProperty('modalitaVentilatorie')) {
                    this.modalitaVentilatorie = values['modalitaVentilatorie'].objects;
                }
                if (values.hasOwnProperty('parametriVentilatore')) {
                    this.parametriVentilatore = values['parametriVentilatore'].objects;
                }
                if (values.hasOwnProperty('parametriAllarme')) {
                    this.parametriAllarme = values['parametriAllarme'].objects;
                }
                if (values.hasOwnProperty('incaricati')) {
                    this.incaricati = values['incaricati'].objects;
                }
                if (values.hasOwnProperty('maschere')) {
                    this.maschere = values['maschere'].map(cm => cm.maschera);
                }
                if (values.hasOwnProperty('prescrittori')) {
                    this.prescrittori = values['prescrittori'].map(o => o.prescrittore);
                }
                this.initializeFormFields();
            });
        } else {
            this.prescrittori = [this.currentUser as Prescrittore];
            this.initializeFormFields();
        }
        this.form.controls['contrattoId'].disable();
        this.isServizioSelected = true;
        this.modalServizioOpened = false;
        this.route.queryParams.subscribe(params => {
            if (params && params.pazienteId) {
                this.pazientiService.get(params.pazienteId).subscribe((paziente: Paziente) => {
                    if (paziente) {
                        this.paziente = paziente;
                        this.setPazienteFormInputGroup();
                    }
                });
            }
        });
    }

    servizioModalOnCancel(): void {
        if (this.prescrizioneType) {
            let queryParams = { tipo: this.prescrizioneType };

            this.router.navigate(['/prescrizioni'], { queryParams: queryParams });
        } else {
            this.router.navigate(['/pazienti']);
        }
    }

    createServizioForm(): void {
        const formGroup = {
            contrattoId: ['', [Validators.required]],
        };
        this.servizioForm = this.formBuilder.group(formGroup);
    }

    createForm(): void {
        this.allSectionsDisabled = false;
        this.pazienteSectionDisabled = false;
        this.notePrescrittoreSectionDisabled = false;
        this.datiCliniciSectionDisabled = false;
        this.attivazioneSectionDisabled = false;
        this.diagnosiSectionDisabled = false;
        this.documentiSectionDisabled = false;
        this.terapiaSectionDisabled = false;
        this.ddtSectionDisabled = false;
        this.submitSectionDisabled = false;
        this.pazienteSectionHidden = false;
        this.attivazioneSectionHidden = false;
        this.datiCliniciSectionHidden = false;
        this.notePrescrittoreSectionHidden = false;
        this.diagnosiSectionHidden = false;
        this.noteFornitoreSectionHidden = false;
        this.terapiaSectionHidden = false;
        this.documentiSectionHidden = false;
        this.ddtSectionHidden = false;
        this.docPazientiSectionHidden = false;
        this.storicoSectionHidden = false;
        this.submitSectionHidden = false;

        let formGroup: any;
        formGroup = {
            contrattoId: [{ value: '' }],
            codiceFiscale: ['', [Validators.required, FiscalCodeValidator()]],
            prescrittore: [{ value: '', disabled: this.authService.isPrescrittoreUser() }, [Validators.required]],
            prescrittoreEsterno: [{ value: '', disabled: !this.authService.isAmministratoreUser() && !this.authService.isOperatoreUser()}, []],
            struttura: [{ value: '', disabled: true }],
            reparto: [{ value: '', disabled: true }],
            pazienteNome: [{ value: '', disabled: true }],
            pazienteCognome: [{ value: '', disabled: true }],
            pazienteCodice: [{ value: null, disabled: true }],
            codicePartner: [{ value: null, disabled: true }],
            codiceAutorizzazione: [{ value: '' }],
            pazienteEmail: [{ value: '', disabled: true }],
            pazienteDataNascita: [{ value: '', disabled: true }],
            pazienteCittaNascita: [{ value: '', disabled: true }],
            pazienteSesso: [{ value: '', disabled: true }],
            pazienteCittadinanza: [{ value: '', disabled: true }],
            // pazienteNazione: [{value: '', disabled: true}],
            pazienteTelefono: [{ value: '', disabled: true }],
            pazienteTelefonoFisso: [{ value: '', disabled: true }],
            pazienteDistretto: [{ value: '', disabled: true }],
            pazienteExtraAsl: [{ value: false, disabled: true }],
            pazienteGiaInTerapia: [{ value: false, disabled: true }],
            pazienteResidenzaExtraAsl: [{ value: '', disabled: true }],
            pazienteComune: [{ value: '', disabled: true }],
            pazienteIndirizzo: [{ value: '', disabled: true }],
            pazienteNumeroCivico: [{ value: '', disabled: true }],
            pazienteCap: [{ value: '', disabled: true }],
            pazienteComuneConsegna: [{ value: '', disabled: true }],
            pazienteIndirizzoConsegna: [{ value: '', disabled: true }],
            pazienteNumeroCivicoConsegna: [{ value: '', disabled: true }],
            pazienteCapConsegna: [{ value: '', disabled: true }],
            pazienteNomeDelegato: [{ value: '', disabled: true }],
            pazienteCognomeDelegato: [{ value: '', disabled: true }],
            pazienteEmailDelegato: [{ value: '', disabled: true }],
            pazienteQualificaDelegato: [{ value: '', disabled: true }],
            pazienteTelefonoDelegato: [{ value: '', disabled: true }],
            pazienteStato: [{ value: '', disabled: true }],
            dataRicezione: [{ value: '' }],
            dataPrescrizione: [{ value: '' }, [Validators.required]],
            dataEGAInAria: [{ value: '' }],
            phEGAInAria: [{ value: '' }, [Validators.pattern(this.globals.DECIMAL_QUANTITY_PATTERN)]],
            o2EGAInAria: [{ value: '' }, [Validators.pattern(this.globals.DECIMAL_QUANTITY_PATTERN)]],
            co2EGAInAria: [{ value: '' }, [Validators.pattern(this.globals.DECIMAL_QUANTITY_PATTERN)]],
            co3EGAInAria: [{ value: '' }, [Validators.pattern(this.globals.DECIMAL_QUANTITY_PATTERN)]],
            dataEGASuppl: [{ value: '' }],
            phEGASuppl: [{ value: '' }, [Validators.pattern(this.globals.DECIMAL_QUANTITY_PATTERN)]],
            o2EGASuppl: [{ value: '' }, [Validators.pattern(this.globals.DECIMAL_QUANTITY_PATTERN)]],
            co2EGASuppl: [{ value: '' }, [Validators.pattern(this.globals.DECIMAL_QUANTITY_PATTERN)]],
            co3EGASuppl: [{ value: '' }, [Validators.pattern(this.globals.DECIMAL_QUANTITY_PATTERN)]],
            dataWalkingTest: [{ value: '' }],
            dataSaturimetria: [{ value: '' }],
            risultatoWalkingTest: [{ value: '' }, [Validators.min(0), Validators.pattern(this.globals.DECIMAL_QUANTITY_PATTERN)]],
            risultatoSaturimetria: [{ value: '' }, [Validators.min(0), Validators.pattern(this.globals.DECIMAL_QUANTITY_PATTERN)]],
            bpco55: [{ value: false }],
            bpco60: [{ value: false }],
            bpco60Ipossiemia: [{ value: false }],
            bpco60Policitemia: [{ value: false }],
            bpco60Edemi: [{ value: false }],
            bpco60Ipertensione: [{ value: false }],
            fc55: [{ value: false }],
            fc60: [{ value: false }],
            fc60Ipossiemia: [{ value: false }, []],
            fc60Policitemia: [{ value: false }],
            fc60Edemi: [{ value: false }],
            fc60Ipertensione: [{ value: false }],
            malattieInterstiziali: [{ value: false }],
            ipertensionePolmonare: [{ value: false }],
            neoplasieMaligne: [{ value: false }],
            patologieNeuro: [{ value: false }],
            apneaOstruttiva: [{ value: false }],
            scompensoCardiaco: [{ value: false }],
            scompensoCardiacoIpossiemia: [{ value: false }],
            desaturazioneNotturna: [{ value: false }],
            desaturazioneEsercizio: [{ value: false }],
            osas: [{ value: false }],
            osasAltra: [{ value: false }],
            poliomielite: [{ value: false }],
            miopatieCongenite: [{ value: false }],
            miopatieMitocondriali: [{ value: false }],
            distrofiaMuscolare: [{ value: false }],
            guillainBarre: [{ value: false }],
            sla: [{ value: false }],
            atrofiaMuscolareSpinale: [{ value: false }],
            paralisiDiaframma: [{ value: false }],
            lesioniNervoFrenico: [{ value: false }],
            cifoscoliosi: [{ value: false }],
            deformitaToracica: [{ value: false }],
            ipoventilazioneCongenita: [{ value: false }],
            ipoventilazioneCentrale: [{ value: false }],
            leigh: [{ value: false }],
            ipoventilazionePostChirurgica: [{ value: false }],
            ipoventilazionePostRadioterapia: [{ value: false }],
            ipoventilazioneNeoplasie: [{ value: false }],
            ipoventilazionePostTraumatica: [{ value: false }],
            bpco: [{ value: false }],
            bronchiectasie: [{ value: false }],
            fibrosiCistica: [{ value: false }],
            fibrosiPolmonare: [{ value: false }],
            malattieCardiacheCongenite: [{ value: false }],
            malattieCardiacheAcquisite: [{ value: false }],
            diagnosiAltra: [{ value: false }],
            descrizioneDiagnosi: [{ value: '', disabled: true }],
            stato: [{ value: '', disabled: true }],
            totaleLitriMese: [{ value: '' }],
            noteFornitore: [{ value: '' }],
            notePrescrittore: [{ value: '' }],
            diagnosiSelected: [{ value: false }]
        };
        if (this.prescrizioneType === 'o2l' || this.prescrizioneType === 'o2c' || this.prescrizioneType === 'sat' || this.prescrizioneType === 'vent') {
            formGroup.validita = [{ value: '' }, [Validators.required, Validators.min(1), Validators.max(24), Validators.pattern(this.globals.INTEGER_QUANTITY_PATTERN)]];
            formGroup.dataScadenza = [{ value: '', disabled: true }, [Validators.required]];
            formGroup.dataAttivazione = [{ value: null, disabled: true }];
            formGroup.dataRiattivazione = [{ value: '', disabled: true }];
            formGroup.dataSospensione = [{ value: '', disabled: true }];
            formGroup.dataAnnullamento = [{ value: '', disabled: true }];
            formGroup.dataCessazione = [{ value: '', disabled: true }];
        }
        if (this.prescrizioneType === 'o2l') {
            formGroup.o2Liquido = [{ value: false }];
            formGroup.portatile = [{ value: false }];
            formGroup.secondoContenitore = [{ value: false }];
            formGroup.flussoRiposo = [{ value: false }];
            formGroup.flussoRiposoLitriMin = [{ value: '' }, [Validators.min(0.25)]];
            formGroup.flussoRiposoOre = [{ value: '' }, [Validators.min(1)]];
            formGroup.flussoNotturno = [{ value: false }];
            formGroup.flussoNotturnoLitriMin = [{ value: '' }, [Validators.min(0.25)]];
            formGroup.flussoNotturnoOre = [{ value: '' }, [Validators.min(1)]];
            formGroup.flussoSottoSforzo = [{ value: false }];
            formGroup.flussoSottoSforzoLitriMin = [{ value: '' }, [Validators.min(0.25)]];
            formGroup.flussoSottoSforzoOre = [{ value: '' }, [Validators.min(1)]];
            formGroup.totaleLitriMese = [{ value: '' }, [Validators.min(450)]];
            formGroup.accessorioConsumabile = [{ value: false }];
            formGroup.carrelloContenitore = [{ value: false }];
            formGroup.stroller = [{ value: false }];
            formGroup.bombola = [{ value: '' }];
            formGroup.trasportoContoTerzi = [{ value: false }];
            formGroup.bollaConsegna = [{ value: '' }];
            formGroup.dataAttivazioneSecondoContenitore = [{ value: '' }];
            formGroup.bollaConsegnaSecondoContenitore = [{ value: '' }];
            formGroup.contenitoreId = [{ value: '', disabled: true }, [Validators.required]];
            formGroup.maxHours = [{ value: 0 }, [Validators.min(1), Validators.max(24)]];
        } else if (this.prescrizioneType === 'o2c') {
            formGroup.tipoConcentratore = [{ value: '' }, [Validators.required]];
            formGroup.litriMinuto = [{ value: '' }, [Validators.required, Validators.min(0.25)]];
            formGroup.oreTerapia = [{ value: '' }, [Validators.required, Validators.min(1)]];
            formGroup.bombolaBackup = [{ value: false }];
            formGroup.concentratore = [{ value: '', disabled: true }];
            formGroup.bollaConsegna = [{ value: '' }];
            formGroup.noteConcentratore = [{ value: '' }];
        } else if (this.prescrizioneType === 'sat') {
            formGroup.saturimetro = [{ value: '', disabled: true }];
            formGroup.bollaConsegna = [{ value: '' }];
        } else if (this.prescrizioneType === 'vent') {
            formGroup.consegnaPressoStruttura = [{ value: false }];
            formGroup.ccf = [{ value: '' }];
            formGroup.commerciale = [{ value: '' }, [Validators.required]];
            formGroup.tipoMaschera = [{ value: '', disabled: true }];
            formGroup.modelloMaschera = [{ value: '', disabled: true }];
            formGroup.marcaMaschera = [{ value: '', disabled: true }];
            formGroup.tagliaMaschera = [{ value: '', disabled: true }];
            this.datiCliniciSectionHidden = true;
        } else if (this.prescrizioneType === 'ega') {
            formGroup.tipoEga = [{ value: '' }, [Validators.required]];
            formGroup.dataEffettuazione = [{ value: '', disabled: true }, []];
            formGroup.risultato = [{ value: '', disabled: true }];
        } else if (this.prescrizioneType === 'ai') {
            formGroup.dataEffettuazione = [{ value: '', disabled: true }, []];
            formGroup.risultato = [{ value: '', disabled: true }];
            formGroup.paramSpo2 = [{ value: false }];
            formGroup.paramEtCo2 = [{ value: false }];
            formGroup.paramFR = [{ value: false }];
            formGroup.paramFC = [{ value: false }];
        }
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(): void {
        let formValues = {};

        if (this.prescrizione) {
            formValues = {
                contrattoId: this.prescrizione.contratto.id,
                codiceFiscale: this.paziente.codiceFiscale,
                prescrittore: this.prescrizione.prescrittore.id,
                prescrittoreEsterno: this.prescrizione.prescrittoreEsternoForeignKey ? this.prescrizione.prescrittoreEsternoForeignKey : '',
                struttura: this.prescrizione.prescrittore.struttura && this.prescrizione.prescrittore.struttura.nome ? this.prescrizione.prescrittore.struttura.nome : '',
                reparto: this.prescrizione.prescrittore.reparto && this.prescrizione.prescrittore.reparto.nome ? this.prescrizione.prescrittore.reparto.nome : '',
                pazienteNome: this.paziente.nome,
                pazienteCognome: this.paziente.cognome,
                pazienteCodice: this.prescrizione.codice ? this.prescrizione.codice : null,
                codicePartner: this.prescrizione.codicePartner ? this.prescrizione.codicePartner : null,
                codiceAutorizzazione: this.prescrizione.codiceAutorizzazione ? this.prescrizione.codiceAutorizzazione : '',
                pazienteEmail: this.paziente.account.email,
                pazienteDataNascita: this.utilsService.dateToField(this.paziente.dataNascita),
                pazienteCittaNascita: this.paziente.cittaNascita,
                pazienteSesso: this.paziente.sesso.toUpperCase(),
                pazienteCittadinanza: Cittadinanza[this.paziente.cittadinanza],
                // pazienteNazione: this.paziente.nazione ? this.paziente.nazione.nome : '',
                pazienteTelefono: this.paziente.telefono ? this.paziente.telefono : '',
                pazienteTelefonoFisso: this.paziente.telefonoFisso ? this.paziente.telefonoFisso : '',
                pazienteDistretto: this.paziente.comune.distretto.nome,
                pazienteExtraAsl: this.paziente.extraAsl,
                pazienteGiaInTerapia: this.paziente.giaInTerapia ? true : false,
                pazienteResidenzaExtraAsl: this.paziente.residenzaExtraAsl ? this.paziente.residenzaExtraAsl : '',
                pazienteComune: this.paziente.comune.nome,
                pazienteIndirizzo: this.paziente.indirizzo,
                pazienteNumeroCivico: this.paziente.numeroCivico,
                pazienteCap: this.paziente.cap,
                pazienteComuneConsegna: this.paziente.comuneConsegna ? this.paziente.comuneConsegna : '',
                pazienteIndirizzoConsegna: this.paziente.indirizzoConsegna,
                pazienteNumeroCivicoConsegna: this.paziente.numeroCivicoConsegna ? this.paziente.numeroCivicoConsegna : '',
                pazienteCapConsegna: this.paziente.capConsegna ? this.paziente.capConsegna : '',
                pazienteNomeDelegato: this.paziente.nomeDelegato ? this.paziente.nomeDelegato : '',
                pazienteCognomeDelegato: this.paziente.cognomeDelegato ? this.paziente.cognomeDelegato : '',
                pazienteEmailDelegato: this.paziente.mailDelegato ? this.paziente.mailDelegato : '',
                pazienteQualificaDelegato: this.paziente.qualificaDelegato ? this.paziente.qualificaDelegato : '',
                pazienteTelefonoDelegato: this.paziente.telefonoDelegato ? this.paziente.telefonoDelegato : '',
                pazienteStato: StatoPaziente[this.paziente.stato],
                dataRicezione: this.utilsService.dateToField(this.prescrizione.dataRicezione),
                dataPrescrizione: this.utilsService.dateToField(this.prescrizione.dataPrescrizione),
                dataEGAInAria: this.prescrizione.dataEGAInAria ? this.utilsService.dateToField(this.prescrizione.dataEGAInAria) : '',
                dataWalkingTest: this.utilsService.dateToField(this.prescrizione.dataWalkingTest) ? this.utilsService.dateToField(this.prescrizione.dataWalkingTest) : '',
                risultatoWalkingTest: this.prescrizione.risultatoWalkingTest ? this.prescrizione.risultatoWalkingTest : '',
                dataSaturimetria: this.utilsService.dateToField(this.prescrizione.dataSaturimetria) ? this.utilsService.dateToField(this.prescrizione.dataSaturimetria) : '',
                risultatoSaturimetria: this.prescrizione.risultatoSaturimetria ? this.prescrizione.risultatoSaturimetria : '',
                phEGAInAria: this.prescrizione.phEGAInAria ? this.prescrizione.phEGAInAria : '',
                o2EGAInAria: this.prescrizione.o2EGAInAria ? this.prescrizione.o2EGAInAria : '',
                co2EGAInAria: this.prescrizione.co2EGAInAria ? this.prescrizione.co2EGAInAria : '',
                co3EGAInAria: this.prescrizione.co3EGAInAria ? this.prescrizione.co3EGAInAria : '',
                dataEGASuppl: this.prescrizione.dataEGASuppl ? this.utilsService.dateToField(this.prescrizione.dataEGASuppl) : '',
                phEGASuppl: this.prescrizione.phEGASuppl ? this.prescrizione.phEGASuppl : '',
                o2EGASuppl: this.prescrizione.o2EGASuppl ? this.prescrizione.o2EGASuppl : '',
                co2EGASuppl: this.prescrizione.co2EGASuppl ? this.prescrizione.co2EGASuppl : '',
                co3EGASuppl: this.prescrizione.co3EGASuppl ? this.prescrizione.co3EGASuppl : '',
                bpco55: this.prescrizione.bpco55 ? this.prescrizione.bpco55 : false,
                bpco60: this.prescrizione.bpco60 ? this.prescrizione.bpco60 : false,
                bpco60Ipossiemia: this.prescrizione.bpco60Ipossiemia ? this.prescrizione.bpco60Ipossiemia : false,
                bpco60Policitemia: this.prescrizione.bpco60Policitemia ? this.prescrizione.bpco60Policitemia : false,
                bpco60Edemi: this.prescrizione.bpco60Edemi ? this.prescrizione.bpco60Edemi : false,
                bpco60Ipertensione: this.prescrizione.bpco60Ipertensione ? this.prescrizione.bpco60Ipertensione : false,
                fc55: this.prescrizione.fc55 ? this.prescrizione.fc55 : false,
                fc60: this.prescrizione.fc60 ? this.prescrizione.fc60 : false,
                fc60Ipossiemia: this.prescrizione.fc60Ipossiemia ? this.prescrizione.fc60Ipossiemia : false,
                fc60Policitemia: this.prescrizione.fc60Policitemia ? this.prescrizione.fc60Policitemia : false,
                fc60Edemi: this.prescrizione.fc60Edemi ? this.prescrizione.fc60Edemi : false,
                fc60Ipertensione: this.prescrizione.fc60Ipertensione ? this.prescrizione.fc60Ipertensione : false,
                malattieInterstiziali: this.prescrizione.malattieInterstiziali ? this.prescrizione.malattieInterstiziali : false,
                ipertensionePolmonare: this.prescrizione.ipertensionePolmonare ? this.prescrizione.ipertensionePolmonare : false,
                neoplasieMaligne: this.prescrizione.neoplasieMaligne ? this.prescrizione.neoplasieMaligne : false,
                patologieNeuro: this.prescrizione.patologieNeuro ? this.prescrizione.patologieNeuro : false,
                apneaOstruttiva: this.prescrizione.apneaOstruttiva ? this.prescrizione.apneaOstruttiva : false,
                scompensoCardiaco: this.prescrizione.scompensoCardiaco ? this.prescrizione.scompensoCardiaco : false,
                scompensoCardiacoIpossiemia: this.prescrizione.scompensoCardiacoIpossiemia ? this.prescrizione.scompensoCardiacoIpossiemia : false,
                desaturazioneNotturna: this.prescrizione.desaturazioneNotturna ? this.prescrizione.desaturazioneNotturna : false,
                desaturazioneEsercizio: this.prescrizione.desaturazioneEsercizio ? this.prescrizione.desaturazioneEsercizio : false,
                osas: this.prescrizione.osas ? this.prescrizione.osas : false,
                osasAltra: this.prescrizione.osasAltra ? this.prescrizione.osasAltra : false,
                poliomielite: this.prescrizione.poliomielite ? this.prescrizione.poliomielite : false,
                miopatieCongenite: this.prescrizione.miopatieCongenite ? this.prescrizione.miopatieCongenite : false,
                miopatieMitocondriali: this.prescrizione.miopatieMitocondriali ? this.prescrizione.miopatieMitocondriali : false,
                distrofiaMuscolare: this.prescrizione.distrofiaMuscolare ? this.prescrizione.distrofiaMuscolare : false,
                guillainBarre: this.prescrizione.guillainBarre ? this.prescrizione.guillainBarre : false,
                sla: this.prescrizione.sla ? this.prescrizione.sla : false,
                atrofiaMuscolareSpinale: this.prescrizione.atrofiaMuscolareSpinale ? this.prescrizione.atrofiaMuscolareSpinale : false,
                paralisiDiaframma: this.prescrizione.paralisiDiaframma ? this.prescrizione.paralisiDiaframma : false,
                lesioniNervoFrenico: this.prescrizione.lesioniNervoFrenico ? this.prescrizione.lesioniNervoFrenico : false,
                cifoscoliosi: this.prescrizione.cifoscoliosi ? this.prescrizione.cifoscoliosi : false,
                deformitaToracica: this.prescrizione.deformitaToracica ? this.prescrizione.deformitaToracica : false,
                ipoventilazioneCongenita: this.prescrizione.ipoventilazioneCongenita ? this.prescrizione.ipoventilazioneCongenita : false,
                ipoventilazioneCentrale: this.prescrizione.ipoventilazioneCentrale ? this.prescrizione.ipoventilazioneCentrale : false,
                leigh: this.prescrizione.leigh ? this.prescrizione.leigh : false,
                ipoventilazionePostChirurgica: this.prescrizione.ipoventilazionePostChirurgica ? this.prescrizione.ipoventilazionePostChirurgica : false,
                ipoventilazionePostRadioterapia: this.prescrizione.ipoventilazionePostRadioterapia ? this.prescrizione.ipoventilazionePostRadioterapia : false,
                ipoventilazioneNeoplasie: this.prescrizione.ipoventilazioneNeoplasie ? this.prescrizione.ipoventilazioneNeoplasie : false,
                ipoventilazionePostTraumatica: this.prescrizione.ipoventilazionePostTraumatica ? this.prescrizione.ipoventilazionePostTraumatica : false,
                bpco: this.prescrizione.bpco ? this.prescrizione.bpco : false,
                bronchiectasie: this.prescrizione.bronchiectasie ? this.prescrizione.bronchiectasie : false,
                fibrosiCistica: this.prescrizione.fibrosiCistica ? this.prescrizione.fibrosiCistica : false,
                fibrosiPolmonare: this.prescrizione.fibrosiPolmonare ? this.prescrizione.fibrosiPolmonare : false,
                malattieCardiacheCongenite: this.prescrizione.malattieCardiacheCongenite ? this.prescrizione.malattieCardiacheCongenite : false,
                malattieCardiacheAcquisite: this.prescrizione.malattieCardiacheAcquisite ? this.prescrizione.malattieCardiacheAcquisite : false,
                diagnosiAltra: this.prescrizione.diagnosiAltra ? this.prescrizione.diagnosiAltra : false,
                descrizioneDiagnosi: this.prescrizione.descrizioneDiagnosi ? this.prescrizione.descrizioneDiagnosi : '',
                stato: this.prescrizione.stato,
                totaleLitriMese: '',
                noteFornitore: '',
                notePrescrittore: '',
                diagnosiSelected: this.atLeastOneCheckboxIsSelected(this.diagnosiList)
            };

            if (this.prescrizioneConScadenza) {
                formValues['dataAttivazione'] = this.utilsService.dateToField(this.prescrizioneConScadenza.dataAttivazione);
                formValues['dataRiattivazione'] = this.utilsService.dateToField(this.prescrizioneConScadenza.dataRiattivazione);
                formValues['dataSospensione'] = this.utilsService.dateToField(this.prescrizioneConScadenza.dataSospensione);
                formValues['dataAnnullamento'] = this.utilsService.dateToField(this.prescrizioneConScadenza.dataAnnullamento);
                formValues['dataCessazione'] = this.utilsService.dateToField(this.prescrizioneConScadenza.dataCessazione);
                formValues['validita'] = this.prescrizioneConScadenza.validita;
                formValues['dataScadenza'] = this.utilsService.dateToField(this.prescrizioneConScadenza.dataScadenza) ? this.utilsService.dateToField(this.prescrizioneConScadenza.dataScadenza) : '';
            }

            if (this.prescrizioneType === 'o2l') {
                formValues['o2Liquido'] = this.prescrizioneO2l.o2Liquido ? this.prescrizioneO2l.o2Liquido : false;
                formValues['portatile'] = this.prescrizioneO2l.portatile ? this.prescrizioneO2l.portatile : false;
                formValues['secondoContenitore'] = this.prescrizioneO2l.secondoContenitore ? this.prescrizioneO2l.secondoContenitore : false;
                formValues['flussoRiposo'] = this.prescrizioneO2l.flussoRiposo ? this.prescrizioneO2l.flussoRiposo : false;
                formValues['flussoRiposoLitriMin'] = this.prescrizioneO2l.flussoRiposoLitriMin ? this.prescrizioneO2l.flussoRiposoLitriMin : '0';
                formValues['flussoRiposoOre'] = this.prescrizioneO2l.flussoRiposoOre ? this.prescrizioneO2l.flussoRiposoOre : '0';
                formValues['flussoNotturno'] = this.prescrizioneO2l.flussoNotturno ? this.prescrizioneO2l.flussoNotturno : false;
                formValues['flussoNotturnoLitriMin'] = this.prescrizioneO2l.flussoNotturnoLitriMin ? this.prescrizioneO2l.flussoNotturnoLitriMin : '0';
                formValues['flussoNotturnoOre'] = this.prescrizioneO2l.flussoNotturnoOre ? this.prescrizioneO2l.flussoNotturnoOre : '0';
                formValues['flussoSottoSforzo'] = this.prescrizioneO2l.flussoSottoSforzo ? this.prescrizioneO2l.flussoSottoSforzo : false;
                formValues['flussoSottoSforzoLitriMin'] = this.prescrizioneO2l.flussoSottoSforzoLitriMin ? this.prescrizioneO2l.flussoSottoSforzoLitriMin : '0';
                formValues['flussoSottoSforzoOre'] = this.prescrizioneO2l.flussoSottoSforzoOre ? this.prescrizioneO2l.flussoSottoSforzoOre : '0';
                formValues['totaleLitriMese'] = this.prescrizioneO2l.totaleLitriMese ? this.prescrizioneO2l.totaleLitriMese.toString() : '0';
                formValues['accessorioConsumabile'] = this.prescrizioneO2l.accessorioConsumabile ? this.prescrizioneO2l.accessorioConsumabile : false;
                formValues['carrelloContenitore'] = this.prescrizioneO2l.carrelloContenitore ? this.prescrizioneO2l.carrelloContenitore : false;
                formValues['stroller'] = this.prescrizioneO2l.stroller ? this.prescrizioneO2l.stroller : false;
                formValues['bombola'] = this.prescrizioneO2l.bombola ? this.prescrizioneO2l.bombola : false;
                formValues['trasportoContoTerzi'] = this.prescrizioneO2l.trasportoContoTerzi ? this.prescrizioneO2l.trasportoContoTerzi : false;
                formValues['bollaConsegna'] = this.prescrizioneO2l.bollaConsegna ? this.prescrizioneO2l.bollaConsegna : '';
                formValues['dataAttivazioneSecondoContenitore'] = this.prescrizioneO2l.dataAttivazioneSecondoContenitore ? this.utilsService.dateToField(this.prescrizioneO2l.dataAttivazioneSecondoContenitore) : '';
                formValues['bollaConsegnaSecondoContenitore'] = this.prescrizioneO2l.bollaConsegnaSecondoContenitore ? this.prescrizioneO2l.bollaConsegnaSecondoContenitore : '';
                formValues['contenitoreId'] = this.prescrizioneO2l.contenitore.id ? this.prescrizioneO2l.contenitore.id.toString() : '';
                formValues['maxHours'] = this.prescrizioneO2l.flussoRiposoOre + this.prescrizioneO2l.flussoNotturnoOre + this.prescrizioneO2l.flussoSottoSforzoOre;
            } else if (this.prescrizioneType === 'o2c') {
                formValues['tipoConcentratore'] = this.prescrizioneO2c.tipoConcentratore != null ? this.prescrizioneO2c.tipoConcentratore.toString() : '';
                formValues['litriMinuto'] = this.prescrizioneO2c.litriMinuto ? this.prescrizioneO2c.litriMinuto : '';
                formValues['oreTerapia'] = this.prescrizioneO2c.oreTerapia ? this.prescrizioneO2c.oreTerapia : '';
                formValues['bombolaBackup'] = this.prescrizioneO2c.bombolaBackup || false;
                formValues['concentratore'] = this.prescrizioneO2c.concentratore.hasOwnProperty('id') ? this.prescrizioneO2c.concentratore.id : '';
                formValues['bollaConsegna'] = this.prescrizioneO2c.bollaConsegna ? this.prescrizioneO2c.bollaConsegna : '';
                formValues['noteConcentratore'] = this.prescrizioneO2c.noteConcentratore ? this.prescrizioneO2c.noteConcentratore : '';
            } else if (this.prescrizioneType === 'sat') {
                formValues['saturimetro'] = this.prescrizioneSat.saturimetro.hasOwnProperty('id') ? this.prescrizioneSat.saturimetro.id : '';
                formValues['bollaConsegna'] = this.prescrizioneSat.bollaConsegna ? this.prescrizioneSat.bollaConsegna : '';
            } else if (this.prescrizioneType === 'vent') {
                formValues['consegnaPressoStruttura'] = this.prescrizioneVent.consegnaPressoStruttura ? this.prescrizioneVent.consegnaPressoStruttura : false;
                formValues['ccf'] = this.prescrizioneVent.ccf ? this.prescrizioneVent.ccf : '';
                formValues['commerciale'] = this.prescrizioneVent.incaricato && this.prescrizioneVent.incaricato.id ? this.prescrizioneVent.incaricato.id : '';
                formValues['tipoMaschera'] = '';
                formValues['modelloMaschera'] = '';
                formValues['marcaMaschera'] = '';
                formValues['tagliaMaschera'] = '';
            } else if (this.prescrizioneType === 'ega') {
                formValues['tipoEga'] = this.prescrizioneEga.tipoEga != null ? this.prescrizioneEga.tipoEga.toString() : '';
                formValues['dataEffettuazione'] = this.prescrizioneEga.dataEffettuazione ? this.utilsService.dateToField(this.prescrizioneEga.dataEffettuazione) : '';
                formValues['risultato'] = this.prescrizioneEga.fileRisultatoName ? this.prescrizioneEga.fileRisultatoName : '';
            } else if (this.prescrizioneType === 'ai') {
                formValues['dataEffettuazione'] = this.prescrizioneAi.dataEffettuazione ? this.utilsService.dateToField(this.prescrizioneAi.dataEffettuazione) : '';
                formValues['risultato'] = this.prescrizioneAi.fileRisultatoName ? this.prescrizioneAi.fileRisultatoName : '';
                formValues['paramSpo2'] = this.prescrizioneAi.spo2 ? true : false;
                formValues['paramEtCo2'] = this.prescrizioneAi.etco2 ? true : false;
                formValues['paramFR'] = this.prescrizioneAi.fr ? true : false;
                formValues['paramFC'] = this.prescrizioneAi.fc ? true : false;
            }
            this.showPazienteFormInputGroup = true;
            this.docPazientiSectionHidden = !this.authService.isAmministratoreUser() && !this.authService.isOperatoreUser();
        } else {
            formValues = {
                contrattoId: this.contratto ? this.contratto.id : '',
                codiceFiscale: '',
                prescrittore: this.prescrittori && this.prescrittori.length > 0 ? this.prescrittori[0].id : '',
                prescrittoreEsterno: 0,
                struttura: this.prescrittori && this.prescrittori.length > 0 && this.prescrittori[0].struttura && this.prescrittori[0].struttura.nome ? this.prescrittori[0].struttura.nome : '',
                reparto: this.prescrittori && this.prescrittori.length > 0 && this.prescrittori[0].reparto && this.prescrittori[0].reparto.nome ? this.prescrittori[0].reparto.nome : '',
                pazienteNome: '',
                pazienteCognome: '',
                pazienteCodice: null,
                codicePartner: null,
                codiceAutorizzazione: '',
                pazienteEmail: '',
                pazienteDataNascita: '',
                pazienteCittaNascita: '',
                pazienteSesso: '',
                pazienteCittadinanza: '',
                // pazienteNazione: '',
                pazienteTelefono: '',
                pazienteTelefonoFisso: '',
                pazienteDistretto: '',
                pazienteExtraAsl: false,
                pazienteGiaInTerapia: false,
                pazienteResidenzaExtraAsl: '',
                pazienteComune: '',
                pazienteIndirizzo: '',
                pazienteNumeroCivico: '',
                pazienteCap: '',
                pazienteComuneConsegna: '',
                pazienteIndirizzoConsegna: '',
                pazienteNumeroCivicoConsegna: '',
                pazienteCapConsegna: '',
                pazienteNomeDelegato: '',
                pazienteCognomeDelegato: '',
                pazienteEmailDelegato: '',
                pazienteQualificaDelegato: '',
                pazienteTelefonoDelegato: '',
                pazienteStato: '',
                dataRicezione: this.utilsService.dateToField(new Date()),
                dataPrescrizione: this.prescrizioneType == 'ega' || this.prescrizioneType === 'ai' ? this.utilsService.dateToField(new Date()) : '',
                dataWalkingTest: '',
                dataSaturimetria: '',
                dataEGAInAria: '',
                phEGAInAria: '',
                o2EGAInAria: '',
                co2EGAInAria: '',
                co3EGAInAria: '',
                dataEGASuppl: '',
                phEGASuppl: '',
                o2EGASuppl: '',
                co2EGASuppl: '',
                co3EGASuppl: '',
                risultatoWalkingTest: '',
                risultatoSaturimetria: '',
                bpco55: false,
                bpco60: false,
                bpco60Ipossiemia: false,
                bpco60Policitemia: false,
                bpco60Edemi: false,
                bpco60Ipertensione: false,
                fc55: false,
                fc60: false,
                fc60Ipossiemia: false,
                fc60Policitemia: false,
                fc60Edemi: false,
                fc60Ipertensione: false,
                malattieInterstiziali: false,
                ipertensionePolmonare: false,
                neoplasieMaligne: false,
                patologieNeuro: false,
                apneaOstruttiva: false,
                scompensoCardiaco: false,
                scompensoCardiacoIpossiemia: false,
                desaturazioneNotturna: false,
                desaturazioneEsercizio: false,
                osas: false,
                osasAltra: false,
                poliomielite: false,
                miopatieCongenite: false,
                miopatieMitocondriali: false,
                distrofiaMuscolare: false,
                guillainBarre: false,
                sla: false,
                atrofiaMuscolareSpinale: false,
                paralisiDiaframma: false,
                lesioniNervoFrenico: false,
                cifoscoliosi: false,
                deformitaToracica: false,
                ipoventilazioneCongenita: false,
                ipoventilazioneCentrale: false,
                leigh: false,
                ipoventilazionePostChirurgica: false,
                ipoventilazionePostRadioterapia: false,
                ipoventilazioneNeoplasie: false,
                ipoventilazionePostTraumatica: false,
                bpco: false,
                bronchiectasie: false,
                fibrosiCistica: false,
                fibrosiPolmonare: false,
                malattieCardiacheCongenite: false,
                malattieCardiacheAcquisite: false,
                diagnosiAltra: false,
                descrizioneDiagnosi: '',
                stato: this.prescrizioneType === 'o2l' || this.prescrizioneType === 'o2c' || this.prescrizioneType === 'vent' ?
                    StatoPrescrizione.RICHIESTA_ATTIVAZIONE.toString() : StatoPrescrizione.ATTIVATA.toString(),
                totaleLitriMese: '',
                noteFornitore: '',
                notePrescrittore: '',
                diagnosiSelected: false
            };
            if (this.prescrizioneType === 'o2l' || this.prescrizioneType === 'o2c' || this.prescrizioneType === 'sat' || this.prescrizioneType === 'vent') {
                formValues['dataAttivazione'] = null;
                formValues['dataRiattivazione'] = '';
                formValues['dataSospensione'] = '';
                formValues['dataAnnullamento'] = '';
                formValues['dataCessazione'] = '';
                formValues['validita'] = this.prescrizioneType == 'vent' ? '24' : '';
                formValues['dataScadenza'] = '';
            }
            if (this.prescrizioneType === 'o2l') {
                formValues['o2Liquido'] = false;
                formValues['portatile'] = false;
                formValues['secondoContenitore'] = false;
                formValues['flussoRiposo'] = false;
                formValues['flussoRiposoLitriMin'] = '0';
                formValues['flussoRiposoOre'] = '0';
                formValues['flussoNotturno'] = false;
                formValues['flussoNotturnoLitriMin'] = '0';
                formValues['flussoNotturnoOre'] = '0';
                formValues['flussoSottoSforzo'] = false;
                formValues['flussoSottoSforzoLitriMin'] = '0';
                formValues['flussoSottoSforzoOre'] = '0';
                formValues['totaleLitriMese'] = '0';
                formValues['accessorioConsumabile'] = false;
                formValues['carrelloContenitore'] = false;
                formValues['stroller'] = false;
                formValues['bombola'] = false;
                formValues['trasportoContoTerzi'] = false;
                formValues['bollaConsegna'] = '';
                formValues['dataAttivazioneSecondoContenitore'] = '';
                formValues['bollaConsegnaSecondoContenitore'] = '';
                formValues['maxHours'] = 0;
                formValues['contenitoreId'] = '';

                this.form.controls['contrattoId'].disable();
                this.form.controls['flussoRiposoLitriMin'].disable();
                this.form.controls['flussoRiposoOre'].disable();
                this.form.controls['flussoNotturnoLitriMin'].disable();
                this.form.controls['flussoNotturnoOre'].disable();
                this.form.controls['flussoSottoSforzoLitriMin'].disable();
                this.form.controls['flussoSottoSforzoOre'].disable();

                const requiredFields = [
                    'o2Liquido',
                    'portatile',
                    'flussoRiposo',
                    'flussoNotturno',
                    'flussoSottoSforzo',
                ];
                this.setRequiredCheckboxFormFields(requiredFields);
            } else if (this.prescrizioneType === 'o2c') {
                formValues['tipoConcentratore'] = '';
                formValues['litriMinuto'] = '';
                formValues['oreTerapia'] = '';
                formValues['concentratore'] = '';
                formValues['bollaConsegna'] = '';
                formValues['noteConcentratore'] = '';
                formValues['bombolaBackup'] = false;
            } else if (this.prescrizioneType === 'sat') {
                formValues['saturimetro'] = '';
                formValues['bollaConsegna'] = '';
            } else if (this.prescrizioneType === 'vent') {
                formValues['consegnaPressoStruttura'] = false;
                formValues['ccf'] = '';
                formValues['commerciale'] = '';
                formValues['tipoMaschera'] = '';
                formValues['modelloMaschera'] = '';
                formValues['marcaMaschera'] = '';
                formValues['tagliaMaschera'] = '';
            } else if (this.prescrizioneType === 'ega') {
                formValues['tipoEga'] = '';
                formValues['dataEffettuazione'] = '';
                formValues['risultato'] = '';
            } else if (this.prescrizioneType === 'ai') {
                formValues['dataEffettuazione'] = '';
                formValues['risultato'] = '';
                formValues['paramSpo2'] = false;
                formValues['paramEtCo2'] = false;
                formValues['paramFR'] = false;
                formValues['paramFC'] = false;
            }
            this.documentiSectionHidden = true;
            this.ddtSectionHidden = true;
            this.docPazientiSectionHidden = true;
        }
        this.form.setValue(formValues);
        if (this.prescrizioneType === 'o2l') {
            this.initFlussoOssigenoFields();
        }
        this.setOnFieldsChange();
        this.manageFields();
        if (this.queryParams && this.queryParams.prescrizioneVentilatore) {
            this.selectedPrescrizioneVentilatore = this.prescrizioniVentilatore.find(p => p.id.toString() == this.queryParams.prescrizioneVentilatore);
            this.selectedPrescrizioneVentilatoreChanged(null);
        } else if (this.prescrizione && this.prescrizioneType == 'vent') {
            this.selectedPrescrizioneVentilatore = this.prescrizioniVentilatore[0];
            this.selectedPrescrizioneVentilatoreChanged(null);
        }
    }

    initFlussoOssigenoFields() {
        const flussoList = ['flussoRiposo', 'flussoNotturno', 'flussoSottoSforzo'];
        const linkedFields = {
            'flussoRiposo': ['flussoRiposoLitriMin', 'flussoRiposoOre'],
            'flussoNotturno': ['flussoNotturnoLitriMin', 'flussoNotturnoOre'],
            'flussoSottoSforzo': ['flussoSottoSforzoLitriMin', 'flussoSottoSforzoOre'],
        };

        for (const f of flussoList) {
            if (!this.form.value[f]) {
                for (const f2 of linkedFields[f]) {
                    this.form.get(f2).clearValidators();
                    this.form.get(f2).updateValueAndValidity();
                }
            }
        }
    }

    manageFields() {
        if (this.prescrizioneType === 'ega') {
            this.attivazioneSectionHidden = true;
            this.storicoSectionHidden = true;
        } else if (this.prescrizioneType === 'ai') {
            this.attivazioneSectionHidden = true;
            this.storicoSectionHidden = true;
        }

        this.noteFornitoreSectionHidden = !this.authService.isAmministratoreUser() && !this.authService.isOperatoreUser();
        this.datiCliniciSectionDisabled = !this.authService.isAmministratoreUser() && !this.authService.isPrescrittoreUser() && (!this.authService.isOperatoreUser() || this.prescrizioneType !== 'vent');
        this.diagnosiSectionDisabled = !this.authService.isAmministratoreUser() && !this.authService.isPrescrittoreUser() && (!this.authService.isOperatoreUser() || this.prescrizioneType !== 'vent');
        this.terapiaSectionDisabled = !this.authService.isAmministratoreUser() && !this.authService.isPrescrittoreUser() && (!this.authService.isOperatoreUser() || this.prescrizioneType !== 'vent');
        this.notePrescrittoreSectionDisabled = !this.authService.isAmministratoreUser() && !this.authService.isPrescrittoreUser() && (!this.authService.isOperatoreUser() || this.prescrizioneType !== 'vent');
        
        if (this.id === 'new') {
            return;
        }

        let prescription;

        if (this.prescrizioneType === 'o2l') {
            prescription = this.prescrizioneO2l;
        } else if (this.prescrizioneType === 'o2c') {
            prescription = this.prescrizioneO2c;
        } else if (this.prescrizioneType === 'sat') {
            prescription = this.prescrizioneSat;
        } else if (this.prescrizioneType === 'vent') {
            prescription = this.prescrizioneVent;
        } else if (this.prescrizioneType === 'ega') {
            prescription = this.prescrizioneEga;
        } else if (this.prescrizioneType === 'ai') {
            prescription = this.prescrizioneAi;
        } else {
            this.allSectionsDisabled = true;
            this.submitSectionHidden = true;
            return;
        }
        if (!prescription) {
            this.allSectionsDisabled = true;
            this.submitSectionHidden = true;
            return;
        }

        const confirmOnlyActions = [
            this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.CONFERMA_MODIFICA,
            this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.CONFERMA_RINNOVO,
            this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.CONFERMA_PROROGA,
            this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.ANNULLAMENTO_RICHIESTA
        ];

        if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.VISUALIZZA) {
            this.allSectionsDisabled = true;
            this.form.get('codiceAutorizzazione').disable();
        }

        for (const action of confirmOnlyActions) {
            if (action == this.action) {
                this.allSectionsDisabled = true;
                break;
            }
        }

        if (this.atLeastOneCheckboxIsSelected(this.diagnosiList)) {
            for (const f of this.diagnosiList) {
                this.form.get(f).clearValidators();
                this.form.get(f).updateValueAndValidity();
            }
        }

        this.form.get('contrattoId').disable();
        this.form.get('codiceFiscale').disable();
        this.form.get('dataPrescrizione').disable();
        if (this.form.get('dataAttivazione')) {
            this.form.get('dataAttivazione').disable();
            this.form.get('validita').disable();
        }

        if (this.authService.isPrescrittoreUser()) {
            this.ddtSectionHidden = true;
            this.attivazioneSectionHidden = true;
        }
        if (this.authService.isPartnerUser()) {
            this.attivazioneSectionHidden = false;
        }

        switch (+prescription.stato) {
            case this.prescrizioniService.STATO_PRESCRIZIONE.RICHIESTA_ATTIVAZIONE: {
                const defaultFields = [
                    'pazienteTelefono',
                    'pazienteTelefonoFisso',
                    'pazienteIndirizzoConsegna',
                    'pazienteNumeroCivicoConsegna',
                    'pazienteCapConsegna',
                    'pazienteNomeDelegato',
                    'pazienteCognomeDelegato',
                    'pazienteQualificaDelegato',
                    'pazienteTelefonoDelegato',
                    'dataAttivazione',
                ];
                const partnerFields = [
                    'codicePartner',
                ];
                const operatoreFields = [
                    'pazienteCodice',
                ];
                const requiredFields = this.prescrizioneType !== 'vent' ? [
                    'pazienteIndirizzoConsegna',
                    'dataAttivazione'
                ] : [
                    'pazienteIndirizzoConsegna'
                ];
                if (this.prescrizioneType === 'o2l') {
                    defaultFields.push('contenitoreId');
                    requiredFields.push('contenitoreId');
                } else if (this.prescrizioneType === 'o2c') {
                    defaultFields.push('concentratore');
                    requiredFields.push('concentratore');
                } else if (this.prescrizioneType === 'sat') {
                    defaultFields.push('saturimetro');
                    requiredFields.push('saturimetro');
                }
                if (this.authService.isAmministratoreUser()) {
                    if (prescription.autorizzata) {
                        this.enableFormFields(defaultFields.concat(operatoreFields).concat(partnerFields));
                        this.setRequiredFormFields(requiredFields.concat(operatoreFields));
                    }
                }
                if (this.authService.isOperatoreUser()) {
                    if (prescription.autorizzata) {
                        if (this.isAssignedToPartner) {
                            this.allSectionsDisabled = true;
                            this.submitSectionHidden = true;
                        } else {
                            this.enableFormFields(defaultFields.concat(operatoreFields));
                            this.setRequiredFormFields(requiredFields.concat(operatoreFields));
                        }
                    } else {
                        this.allSectionsDisabled = true;
                        this.submitSectionHidden = true;
                    }
                } else if (this.authService.isApprovatoreUser() || this.authService.isAppaltanteUser()) {
                    this.allSectionsDisabled = true;
                    this.submitSectionDisabled = prescription.autorizzata;
                    this.submitSectionHidden = prescription.autorizzata;
                } else if (this.authService.isPartnerUser()) {
                    this.enableFormFields(defaultFields.concat(partnerFields));
                    this.setRequiredFormFields(requiredFields.concat(partnerFields));
                    if (prescription.autorizzata) {
                        if (this.isAssignedToPartner) {
                            this.submitSectionHidden = false;
                            this.submitSectionDisabled = false;
                        } else {
                            this.attivazioneSectionHidden = true;
                            this.submitSectionHidden = true;
                        }
                    } else {
                        this.allSectionsDisabled = true;
                        this.submitSectionHidden = true;
                    }
                } else if (this.authService.isPrescrittoreUser()) {
                    this.form.get('dataPrescrizione').enable();
                }
                break;
            }
            case this.prescrizioniService.STATO_PRESCRIZIONE.RICHIESTA_SOSPENSIONE: {
                if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.SOSPENSIONE) {
                    if (this.authService.isAmministratoreUser() || this.authService.isOperatoreUser() || this.authService.isPartnerUser()) {
                        this.form.get('dataSospensione').enable();
                        this.form.get('dataSospensione').setValue('');
                        this.form.get('dataSospensione').setValidators([Validators.required]);
                        this.form.get('dataSospensione').updateValueAndValidity();
                    }
                    this.attivazioneSectionDisabled = true;
                    this.documentiSectionDisabled = true;
                    this.ddtSectionDisabled = true;
                }
                break;
            }
            case this.prescrizioniService.STATO_PRESCRIZIONE.RICHIESTA_RIATTIVAZIONE: {
                if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RIATTIVAZIONE) {
                    if (this.authService.isAmministratoreUser() || this.authService.isOperatoreUser() || this.authService.isPartnerUser()) {
                        this.form.get('dataRiattivazione').enable();
                        this.form.get('dataRiattivazione').setValue('');
                        this.form.get('dataRiattivazione').setValidators([Validators.required]);
                        this.form.get('dataRiattivazione').updateValueAndValidity();
                    }
                    this.attivazioneSectionDisabled = true;
                    this.documentiSectionDisabled = true;
                    this.ddtSectionDisabled = true;
                }
                break;
            }
            case this.prescrizioniService.STATO_PRESCRIZIONE.RICHIESTA_CESSAZIONE: {
                if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.CESSAZIONE) {
                    if (this.authService.isAmministratoreUser() || this.authService.isOperatoreUser() || this.authService.isPartnerUser()) {
                        this.form.get('dataCessazione').enable();
                        this.form.get('dataCessazione').setValue('');
                        this.form.get('dataCessazione').setValidators([Validators.required]);
                        this.form.get('dataCessazione').updateValueAndValidity();
                    }
                    this.attivazioneSectionDisabled = true;
                    this.documentiSectionDisabled = true;
                    this.ddtSectionDisabled = true;
                }
                break;
            }
            case this.prescrizioniService.STATO_PRESCRIZIONE.ANNULLATA: {
                if (!this.authService.isAmministratoreUser()) {
                    this.allSectionsDisabled = true;
                    this.submitSectionHidden = true;
                }
                break;
            }
            case this.prescrizioniService.STATO_PRESCRIZIONE.CESSATA: {
                if (!this.authService.isAmministratoreUser()) {
                    this.allSectionsDisabled = true;
                    this.submitSectionHidden = true;
                }
                break;
            }
            case this.prescrizioniService.STATO_PRESCRIZIONE.SOSPESA: {
                if (this.authService.isAmministratoreUser()) {
                    if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RINNOVO) {
                        this.form.get('dataPrescrizione').enable();
                        this.form.get('validita').enable();
                        this.form.get('validita').setValue('');
                        this.disablePazienteFields();
                    }
                } else if (this.authService.isOperatoreUser()) {
                    if (this.isAssignedToPartner) {
                        this.allSectionsDisabled = true;
                        this.submitSectionHidden = true;
                    } else {
                        if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.MODIFICA) {
                            this.attivazioneSectionDisabled = false;
                            if (this.prescrizioneType === 'o2l') {
                                this.form.get('contenitoreId').enable();
                                this.form.get('contenitoreId').setValidators([Validators.required]);
                            } else if (this.prescrizioneType === 'sat') {
                                this.form.get('saturimetro').enable();
                                this.form.get('saturimetro').setValidators([Validators.required]);
                                this.form.get('bollaConsegna').enable();
                            }
                        } else {
                            this.attivazioneSectionDisabled = true;
                        }
                    }
                } else if (this.authService.isApprovatoreUser() || this.authService.isAppaltanteUser()) {
                    this.allSectionsDisabled = true;
                    this.submitSectionDisabled = prescription.autorizzata && this.action != this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.PROROGA;
                    this.submitSectionHidden = prescription.autorizzata && this.action != this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.PROROGA;
                } else if (this.authService.isPartnerUser()) {
                    if (!this.isAssignedToPartner) {
                        this.allSectionsDisabled = true;
                        this.submitSectionHidden = true;
                    }
                } else if (this.authService.isPrescrittoreUser()) {
                    if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.MODIFICA) {
                        this.disablePazienteFields();
                    } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RINNOVO) {
                        this.form.get('dataPrescrizione').enable();
                        this.form.get('validita').enable();
                        this.form.get('validita').setValue('');
                        this.disablePazienteFields();
                    } else if (this.action != this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RICHIESTA_SOSPENSIONE &&
                        this.action != this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RICHIESTA_CESSAZIONE &&
                        this.action != this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RICHIESTA_RIATTIVAZIONE) {
                        this.allSectionsDisabled = true;
                    }
                }
                break;
            }
            case this.prescrizioniService.STATO_PRESCRIZIONE.ATTIVATA: {
                if (this.authService.isAmministratoreUser()) {
                    if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RINNOVO) {
                        this.form.get('dataPrescrizione').enable();
                        this.form.get('validita').enable();
                        this.form.get('validita').setValue('');
                        this.disablePazienteFields();
                    } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.EFFETTUAZIONE) {
                        this.form.get('dataEffettuazione').enable();
                        this.form.get('dataEffettuazione').setValue('');
                        this.form.get('dataEffettuazione').updateValueAndValidity();
                    } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.MODIFICA) {
                        if (this.prescrizioneType === 'o2l') {
                            this.form.get('contenitoreId').enable();
                            this.form.get('contenitoreId').setValidators([Validators.required]);
                        } else if (this.prescrizioneType === 'sat') {
                            this.form.get('saturimetro').enable();
                            this.form.get('saturimetro').setValidators([Validators.required]);
                            this.form.get('bollaConsegna').enable();
                        }
                        this.form.get('pazienteCodice').enable();
                    } else {
                        this.attivazioneSectionDisabled = false;
                        if (this.prescrizioneType === 'o2l') {
                            this.form.get('contenitoreId').enable();
                            this.form.get('contenitoreId').setValidators([Validators.required]);
                        }
                    }
                } else if (this.authService.isOperatoreUser()) {
                    if (this.isAssignedToPartner) {
                        this.allSectionsDisabled = true;
                        this.submitSectionHidden = true;
                    } else {
                        if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.EFFETTUAZIONE) {
                            this.form.get('dataEffettuazione').enable();
                            this.form.get('dataEffettuazione').setValue('');
                            this.form.get('dataEffettuazione').updateValueAndValidity();
                        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.MODIFICA) {
                            this.attivazioneSectionDisabled = false;
                            if (this.prescrizioneType === 'o2l') {
                                this.form.get('contenitoreId').enable();
                                this.form.get('contenitoreId').setValidators([Validators.required]);
                            } else if (this.prescrizioneType === 'sat') {
                                this.form.get('saturimetro').enable();
                                this.form.get('saturimetro').setValidators([Validators.required]);
                                this.form.get('bollaConsegna').enable();
                            }
                            this.form.get('pazienteCodice').enable();
                        } else {
                            this.attivazioneSectionDisabled = false;
                            if (this.prescrizioneType === 'o2l') {
                                this.form.get('contenitoreId').enable();
                                this.form.get('contenitoreId').setValidators([Validators.required]);
                            }
                        }
                    }
                } else if (this.authService.isApprovatoreUser() || this.authService.isAppaltanteUser()) {
                    this.allSectionsDisabled = true;
                    this.submitSectionDisabled = prescription.autorizzata && this.action != this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.PROROGA;
                    this.submitSectionHidden = prescription.autorizzata && this.action != this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.PROROGA;
                } else if (this.authService.isPartnerUser()) {
                    if (!this.isAssignedToPartner) {
                        this.allSectionsDisabled = true;
                        this.submitSectionHidden = true;
                    } else {
                        if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.MODIFICA) {
                            this.form.get('codicePartner').enable();
                        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.EFFETTUAZIONE) {
                            this.form.get('dataEffettuazione').enable();
                            this.form.get('dataEffettuazione').setValue('');
                            this.form.get('dataEffettuazione').updateValueAndValidity();
                        } else {
                            this.attivazioneSectionDisabled = false;
                            if (this.prescrizioneType === 'o2l') {
                                this.form.get('contenitoreId').enable();
                                this.form.get('contenitoreId').setValidators([Validators.required]);
                            }
                        }

                    }
                } else if (this.authService.isPrescrittoreUser()) {
                    if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.MODIFICA) {
                        this.disablePazienteFields();
                    } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RINNOVO) {
                        this.form.get('dataPrescrizione').enable();
                        this.form.get('validita').enable();
                        this.form.get('validita').setValue('');
                        this.disablePazienteFields();
                    } else if (this.action != this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RICHIESTA_SOSPENSIONE &&
                        this.action != this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RICHIESTA_CESSAZIONE &&
                        this.action != this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RICHIESTA_RIATTIVAZIONE) {
                        this.allSectionsDisabled = true;
                    }
                } else if (this.authService.isFornitoreUser() || this.authService.isTecnicoVentilazioneUser() || this.authService.isCommercialeUser()) {
                    this.prescrizioneSectionDisabled = true;
                }
                break;
            }
        }
        if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.VISUALIZZA) {
            this.submitSectionHidden = false;
            this.submitSectionDisabled = false;
            this.prescrizioneSectionDisabled = true;
        }
        if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.ANNULLAMENTO) {
            this.form.clearValidators();
            this.form.updateValueAndValidity();
        }
    }

    disablePazienteFields(): void {
        this.pazienteSectionDisabled = true;
        this.form.get('pazienteNome').disable();
        this.form.get('pazienteCognome').disable();
        this.form.get('pazienteEmail').disable();
        this.form.get('pazienteDataNascita').disable();
        this.form.get('pazienteCodice').disable();
        this.form.get('codicePartner').disable();
        this.form.get('pazienteCittaNascita').disable();
        this.form.get('pazienteSesso').disable();
        this.form.get('pazienteCittadinanza').disable();
        this.form.get('pazienteComune').disable();
        this.form.get('pazienteIndirizzo').disable();
        this.form.get('pazienteNumeroCivico').disable();
        this.form.get('pazienteCap').disable();
        this.form.get('pazienteComuneConsegna').disable();
        this.form.get('pazienteIndirizzoConsegna').disable();
        this.form.get('pazienteNumeroCivicoConsegna').disable();
        this.form.get('pazienteCapConsegna').disable();
        this.form.get('pazienteTelefono').disable();
        this.form.get('pazienteTelefonoFisso').disable();
        this.form.get('pazienteTelefonoFisso').disable();
        this.form.get('pazienteExtraAsl').disable();
        this.form.get('pazienteGiaInTerapia').disable();
        this.form.get('pazienteResidenzaExtraAsl').disable();
    }

    selectedPrescrizioneVentilatoreChanged(event): void {
        if (this.selectedPrescrizioneVentilatore) {
            this.selectedTask = null;
            this.tasks = null;
            this.selectedParametro = null;
            this.selectedParametroAllarme = null;
            if (this.previousPrescrizioneVentilatore) {
                this.previousPrescrizioneVentilatore.modelloMaschera = this.form.getRawValue().modelloMaschera;
                this.previousPrescrizioneVentilatore.tipoMaschera = this.form.getRawValue().tipoMaschera;
                this.previousPrescrizioneVentilatore.marcaMaschera = this.form.getRawValue().marcaMaschera;
                this.previousPrescrizioneVentilatore.tagliaMaschera = this.form.getRawValue().tagliaMaschera;
                this.mapSelectedVentilatoriMateriali.set(this.previousPrescrizioneVentilatore.id, this.selectedMateriali);
                this.mapSelectedVentilatoriMaterialiMedicazione.set(this.previousPrescrizioneVentilatore.id, this.selectedMaterialiMedicazione);
            }
            const values = {
                'tipoMaschera': this.selectedPrescrizioneVentilatore.tipoMaschera,
                'modelloMaschera': this.selectedPrescrizioneVentilatore.modelloMaschera,
                'marcaMaschera': this.selectedPrescrizioneVentilatore.marcaMaschera,
                'tagliaMaschera': this.selectedPrescrizioneVentilatore.tagliaMaschera
            };
            this.form.patchValue(values);
            this.previousPrescrizioneVentilatore = this.selectedPrescrizioneVentilatore;
            
            let obs: Observable<any>;
            
            if (!this.mapVentilatoriMateriali.get(this.selectedPrescrizioneVentilatore.ventilatore.id)) {
                obs = this.inizializzaMapMateriali(this.findProfilo(this.selectedPrescrizioneVentilatore.profilo.id), this.selectedPrescrizioneVentilatore.ventilatore.id,
                    this.selectedPrescrizioneVentilatore.id);
            } else {
                obs = of(null);
            }
            obs.subscribe(() => {
                this.materiali = this.mapVentilatoriMateriali.get(this.selectedPrescrizioneVentilatore.ventilatore.id);
                this.selectedMateriali = this.mapSelectedVentilatoriMateriali.get(this.selectedPrescrizioneVentilatore.id);
                this.materialiMedicazione = this.mapVentilatoriMaterialiMedicazione.get(this.selectedPrescrizioneVentilatore.ventilatore.id);
                this.selectedMaterialiMedicazione = this.mapSelectedVentilatoriMaterialiMedicazione.get(this.selectedPrescrizioneVentilatore.id);
                if (this.selectedPrescrizioneVentilatore.id) {
                    this.prescrizioniService.getTasks(this.selectedPrescrizioneVentilatore.id.toString()).subscribe((tasks: Array<Task>) => {
                        this.tasks = tasks;
                        if (event == null && this.queryParams.task) {
                            this.selectedTask = this.tasks.find(t => t.id.toString() == this.queryParams.task);
                            if (this.selectedTask) {
                                this.manageSelectTaskChanged().subscribe(t => {
                                    this.onEditTask(this.selectedTask.stato == this.taskService.STATO_TASK.COMPLETATO || this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.VISUALIZZA);
                                });
                            }
                        }
                    });
                }
            });
        }
    }

    onNuovaPrescrizioneVentilatore(): void {
        this.showPrescrizioneVentilatoreModal = true;
        this.prescrizioneVentilatoreModal.id = undefined;
        this.prescrizioneVentilatoreModal.ventilatore = '';
        this.prescrizioneVentilatoreModal.profilo = '';
        this.prescrizioneVentilatoreModal.backup = false;
        this.prescrizioneVentilatoreModal.modalita = '';
        this.prescrizioneVentilatoreModal.add = true;
        this.selectedParametro = null;
        this.selectedParametroAllarme = null;
        this.ventilatoriModal = new Array<Ventilatore>();
    }

    onDeletePrescrizioneVentilatore(): void {
        this.confirmDeleteDialog().subscribe((result: boolean) => {
            if (result) {
                let i: number = this.findSelectedPrescrizioneVentilatoreIndex();

                if (i !== -1) {
                    this.prescrizioniVentilatore.splice(i, 1);
                    this.selectedPrescrizioneVentilatore = null;
                    this.selectedParametro = null;
                    this.selectedParametroAllarme = null;
                    this.tasks = null;
                }
            }
        });
    }

    confirmDeleteDialog(): Observable<any> {
        return this.dialog.confirm({
            title: 'Comunicazione',
            content: 'Confermi la cancellazione?',
            acceptText: 'Sì',
            cancelText: 'Annulla',
            acceptType: 'warning',
            iconShape: null
        });
    }

    onSelectProfilo(value: any): void {
        this.profiliService.getVentilatori(value).subscribe(result => {
            this.prescrizioneVentilatoreModal.profilo = this.profili.find(p => p.id == Number(value));
            this.ventilatoriModal = result.map(o => o.ventilatore);
        });
    }

    onSelectVentilatore(value: any): void {
        this.selectedParametro = null;
        this.selectedParametroAllarme = null;
        this.prescrizioneVentilatoreModal.ventilatore = this.ventilatoriModal.find(v => v.id == Number(value));
    }

    findProfilo(id: number): Profilo {
        return this.profili.find(p => p.id == id);
    }

    public get profiliNonBloccati() {
        return this.profili ? this.profili.filter(p => !p.bloccato) : [];
    }
    
    inizializzaMapMateriali(profilo: Profilo, ventilatoreId: number, prescrizioneVentilatoreId: number): Observable<any> {
        const calls = {};
        let mapKitMateriali: Map<number, Array<MaterialeConsumabile>> = new Map<number, Array<MaterialeConsumabile>>();
        let mapKitMaterialiSelezionabili: Map<number, Array<MaterialeConsumabile>> = new Map<number, Array<MaterialeConsumabile>>();
        let mapKitMaterialiMedicazione: Map<number, Array<MaterialeConsumabile>> = new Map<number, Array<MaterialeConsumabile>>();
        let mapKitMaterialiMedicazioneSelezionabili: Map<number, Array<MaterialeConsumabile>> = new Map<number, Array<MaterialeConsumabile>>();
        
        if (profilo.kitMaterialeForeignKey) {
            calls['kitMateriale'] = this.kitMaterialiService.getMateriali(profilo.kitMaterialeForeignKey.toString());
        }
        if (profilo.kitMaterialeSelezionabiliForeignKey) {
            calls['kitMaterialeSelezionabili'] = this.kitMaterialiService.getMateriali(profilo.kitMaterialeSelezionabiliForeignKey.toString());
        }
        if (profilo.kitMaterialeMedicazioneForeignKey) {
            calls['kitMaterialeMedicazione'] = this.kitMaterialiService.getMateriali(profilo.kitMaterialeMedicazioneForeignKey.toString());
        }
        if (profilo.kitMaterialeMedicazioneSelezionabiliForeignKey) {
            calls['kitMaterialeMedicazioneSelezionabili'] = this.kitMaterialiService.getMateriali(profilo.kitMaterialeMedicazioneSelezionabiliForeignKey.toString());
        }
        if (Object.keys(calls).length > 0) {
            return forkJoin(calls).pipe(
                switchMap((values: any) => {
                    if (values.hasOwnProperty('kitMateriale')) {
                        mapKitMateriali.set(ventilatoreId, values['kitMateriale'].map(o => o.materialeConsumabile));
                    } else {
                        mapKitMateriali.set(ventilatoreId, []);
                    }
                    if (values.hasOwnProperty('kitMaterialeSelezionabili')) {
                        mapKitMaterialiSelezionabili.set(ventilatoreId, values['kitMaterialeSelezionabili'].map(o => o.materialeConsumabile));
                    } else {
                        mapKitMaterialiSelezionabili.set(ventilatoreId, []);
                    }
                    if (values.hasOwnProperty('kitMaterialeMedicazione')) {
                        mapKitMaterialiMedicazione.set(ventilatoreId, values['kitMaterialeMedicazione'].map(o => o.materialeConsumabile));
                    } else {
                        mapKitMaterialiMedicazione.set(ventilatoreId, []);
                    }
                    if (values.hasOwnProperty('kitMaterialeMedicazioneSelezionabili')) {
                        mapKitMaterialiMedicazioneSelezionabili.set(ventilatoreId, values['kitMaterialeMedicazioneSelezionabili'].map(o => o.materialeConsumabile));
                    } else {
                        mapKitMaterialiMedicazioneSelezionabili.set(ventilatoreId, []);
                    }

                    let materiali: Array<any> = mapKitMateriali.get(ventilatoreId).map(m => {
                        let o = {};

                        o['materiale'] = m;
                        o['selezionabile'] = false;
                        return o;
                    });

                    let materialiSelezionabili: Array<any> = mapKitMaterialiSelezionabili.get(ventilatoreId).map(m => {
                        let o = {};

                        o['materiale'] = m;
                        o['selezionabile'] = true;
                        return o;
                    });

                    let selectedMateriali: Array<any> = new Array<any>().concat(materiali);

                    materiali = materiali.concat(materialiSelezionabili);
                    this.mapVentilatoriMateriali.set(ventilatoreId, materiali);
                    this.mapSelectedVentilatoriMateriali.set(prescrizioneVentilatoreId, selectedMateriali);

                    let materialiMedicazione: Array<any> = mapKitMaterialiMedicazione.get(ventilatoreId).map(m => {
                        let o = {};

                        o['materiale'] = m;
                        o['selezionabile'] = false;
                        return o;
                    });

                    let materialiMedicazioneSelezionabili: Array<any> = mapKitMaterialiMedicazioneSelezionabili.get(ventilatoreId).map(m => {
                        let o = {};

                        o['materiale'] = m;
                        o['selezionabile'] = true;
                        return o;
                    });

                    let selectedMaterialiMedicazione: Array<any> = new Array<MaterialeConsumabile>().concat(materialiMedicazione);

                    materialiMedicazione = materialiMedicazione.concat(materialiMedicazioneSelezionabili);
                    this.mapVentilatoriMaterialiMedicazione.set(ventilatoreId, materialiMedicazione);
                    this.mapSelectedVentilatoriMaterialiMedicazione.set(prescrizioneVentilatoreId, selectedMaterialiMedicazione);
                    return of(null);
                }));
        } else {
            this.mapVentilatoriMateriali.set(ventilatoreId, []);
            this.mapSelectedVentilatoriMateriali.set(prescrizioneVentilatoreId, []);
            this.mapVentilatoriMaterialiMedicazione.set(ventilatoreId, []);
            this.mapSelectedVentilatoriMaterialiMedicazione.set(prescrizioneVentilatoreId, []);
            return of(null);
        }
    }
    
    addPrescrizioneVentilatore(): void {
        let dto: PrescrizioneVentilatoreDto = new PrescrizioneVentilatoreDto();
        
        dto.id = new Date().getTime();
        dto[this.NUOVA] = true;
        dto.ventilatore = this.prescrizioneVentilatoreModal.ventilatore;
        dto.profilo = this.prescrizioneVentilatoreModal.profilo;
        dto.quantita = this.prescrizioneVentilatoreModal.backup ? 2 : 1;
        dto.modalitaVentilatoria = this.modalitaVentilatorie.find(m => m.id == this.prescrizioneVentilatoreModal.modalita);
        this.inizializzaMapMateriali(dto.profilo, dto.ventilatore.id, dto.id).subscribe(() => {
            dto.parametriVentilatore = new Array<ParametroVentilatoreDto>();
            dto.parametriAllarme = new Array<ParametroAllarmeDto>();
            if (this.prescrizioneVentilatoreModal.add) {
                this.prescrizioniVentilatore.push(dto);
                this.selectedPrescrizioneVentilatore = this.prescrizioniVentilatore[this.prescrizioniVentilatore.length - 1];
            } else {
                this.prescrizioniVentilatore[this.prescrizioniVentilatore.findIndex(p => p.id == this.prescrizioneVentilatoreModal.id)] = dto;
            }
            this.showPrescrizioneVentilatoreModal = false;
        });
    }

    onNuovoParametro(): void {
        this.showParametroModal = true;
        this.parametroModal.parametro = '';
        this.parametroModal.valore = 0;
        this.parametroModal.unitaDiMisura = '';
        this.parametroModal.add = true;
        this.recalcAvailableParametri();
    }

    private recalcAvailableParametri(): void {
        if (this.selectedPrescrizioneVentilatore) {
            if (this.parametroModal.add) {
                this.availableParametri = this.parametriVentilatore.filter(p => this.selectedPrescrizioneVentilatore.parametriVentilatore.findIndex(m2 => m2.parametroVentilatore.id == p.id) == -1);
            } else {
                this.availableParametri = this.parametriVentilatore;
            }
        }
    }

    onDeleteParametro(): void {
        this.confirmDeleteDialog().subscribe((result: boolean) => {
            if (result) {
                let i: number = this.findSelectedPrescrizioneVentilatoreIndex();

                if (i !== -1) {
                    this.prescrizioniVentilatore[i].parametriVentilatore
                        .splice(this.prescrizioniVentilatore[i].parametriVentilatore.findIndex(p => p.parametroVentilatore.id == this.selectedParametro.parametroVentilatore.id), 1);
                    this.selectedParametro = null;
                }
            }
        });
    }

    onEditParametro(): void {
        this.showParametroModal = true;
        this.parametroModal.parametro = this.selectedParametro.parametroVentilatore.id;
        this.parametroModal.valore = this.selectedParametro.valore;
        this.parametroModal.unitaDiMisura = this.selectedParametro.parametroVentilatore.unitaDiMisura;
        this.parametroModal.add = false;
        this.recalcAvailableParametri();
    }

    onSelectParametroVentilatore(value: any): void {
        this.parametroModal.unitaDiMisura = this.parametriVentilatore.find(m => m.id == value).unitaDiMisura;
    }

    addParametroVentilatore(): void {
        if (this.parametroModal.add) {
            let dto: ParametroVentilatoreDto = new ParametroVentilatoreDto();

            dto.parametroVentilatore = this.parametriVentilatore.find(m => m.id == this.parametroModal.parametro);
            dto.valore = this.parametroModal.valore;
            this.selectedPrescrizioneVentilatore.parametriVentilatore.push(dto);
        } else {
            this.selectedPrescrizioneVentilatore
                .parametriVentilatore[this.selectedPrescrizioneVentilatore.parametriVentilatore.findIndex(p => p.parametroVentilatore.id == this.parametroModal.parametro)].valore =
                this.parametroModal.valore;
        }

        this.showParametroModal = false;
    }

    onNuovoParametroAllarme(): void {
        this.showParametroAllarmeModal = true;
        this.parametroAllarmeModal.parametro = '';
        this.parametroAllarmeModal.min = 0;
        this.parametroAllarmeModal.max = 0;
        this.parametroAllarmeModal.unitaDiMisura = '';
        this.parametroAllarmeModal.add = true;
        this.recalcAvailableParametriAllarme();
    }

    private recalcAvailableParametriAllarme(): void {
        if (this.selectedPrescrizioneVentilatore) {
            if (this.parametroAllarmeModal.add) {
                this.availableParametriAllarme = this.parametriAllarme.filter(p => this.selectedPrescrizioneVentilatore.parametriAllarme.findIndex(m2 => m2.parametroAllarme.id == p.id) == -1);
            } else {
                this.availableParametriAllarme = this.parametriAllarme;
            }
        }
    }

    onDeleteParametroAllarme(): void {
        this.confirmDeleteDialog().subscribe((result: boolean) => {
            if (result) {
                let i: number = this.findSelectedPrescrizioneVentilatoreIndex();

                if (i !== -1) {
                    this.prescrizioniVentilatore[i].parametriAllarme
                        .splice(this.prescrizioniVentilatore[i].parametriAllarme.findIndex(p => p.parametroAllarme.id == this.selectedParametroAllarme.parametroAllarme.id), 1);
                    this.selectedParametroAllarme = null;
                }
            }
        });
    }

    onEditParametroAllarme(): void {
        this.showParametroAllarmeModal = true;
        this.parametroAllarmeModal.parametro = this.selectedParametroAllarme.parametroAllarme.id;
        this.parametroAllarmeModal.min = this.selectedParametroAllarme.min;
        this.parametroAllarmeModal.max = this.selectedParametroAllarme.max;
        this.parametroAllarmeModal.unitaDiMisura = this.selectedParametroAllarme.parametroAllarme.unitaDiMisura;
        this.parametroAllarmeModal.add = false;
        this.recalcAvailableParametriAllarme();
    }

    onSelectParametroAllarme(value: any): void {
        this.parametroAllarmeModal.unitaDiMisura = this.parametriAllarme.find(m => m.id == value).unitaDiMisura;
    }

    addParametroAllarme(): void {
        if (this.parametroAllarmeModal.add) {
            let dto: ParametroAllarmeDto = new ParametroAllarmeDto();

            dto.parametroAllarme = this.parametriAllarme.find(m => m.id == this.parametroAllarmeModal.parametro);
            dto.min = this.parametroAllarmeModal.min;
            dto.max = this.parametroAllarmeModal.max;
            this.selectedPrescrizioneVentilatore.parametriAllarme.push(dto);
        } else {
            let i: number = this.selectedPrescrizioneVentilatore.parametriAllarme.findIndex(p => p.parametroAllarme.id == this.parametroAllarmeModal.parametro);

            this.selectedPrescrizioneVentilatore.parametriAllarme[i].min = this.parametroAllarmeModal.min;
            this.selectedPrescrizioneVentilatore.parametriAllarme[i].max = this.parametroAllarmeModal.max;
        }

        this.showParametroAllarmeModal = false;
    }

    selectedTaskChanged(event): void {
        if (this.selectedTask) {
            this.manageSelectTaskChanged().subscribe();
        }
    }

    manageSelectTaskChanged(): Observable<Task> {
        this.taskFetched = false;
        if (this.selectedTask.tipo === this.globals.TIPO_TASK_CONSEGNA_REPARTO) {
            return this.taskService.getTaskConsegnaReparto(this.selectedTask.id.toString()).pipe(
                switchMap((t: TaskConsegnaReparto) => {
                    this.selectedTaskConsegnaReparto = t;
                    this.taskFetched = true;
                    return of(t);
                }));
        } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_CONSEGNA_PAZIENTE) {
            return this.taskService.getTaskConsegnaPaziente(this.selectedTask.id.toString()).pipe(
                switchMap((t: TaskConsegnaPaziente) => {
                    this.selectedTaskConsegnaPaziente = t;
                    this.taskFetched = true;
                    return of(t);
                }));
        } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_SOSPENSIONE) {
            return this.taskService.getTaskSospensione(this.selectedTask.id.toString()).pipe(
                switchMap((t: TaskSospensione) => {
                    this.selectedTaskSospensione = t;
                    this.taskFetched = true;
                    return of(t);
                }));
        } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_ACCESSO_ORDINARIO) {
            return this.taskService.getTaskAccessoOrdinario(this.selectedTask.id.toString()).pipe(
                switchMap((t: TaskAccessoOrdinario) => {
                    this.selectedTaskAccessoOrdinario = t;
                    this.taskFetched = true;
                    return of(t);
                }));
        } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_ACCESSO_STRAORDINARIO) {
            return this.taskService.getTaskAccessoStraordinario(this.selectedTask.id.toString()).pipe(
                switchMap((t: TaskAccessoStraordinario) => {
                    this.selectedTaskAccessoStraordinario = t;
                    this.taskFetched = true;
                    return of(t);
                }));
        } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_GENERICO) {
            return this.taskService.getTaskGenerico(this.selectedTask.id.toString()).pipe(
                switchMap((t: TaskGenerico) => {
                    this.selectedTaskGenerico = t;
                    this.taskFetched = true;
                    return of(t);
                }));
        } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_COLLAUDO) {
            return this.taskService.getTaskCollaudo(this.selectedTask.id.toString()).pipe(
                switchMap((t: TaskCollaudo) => {
                    this.selectedTaskCollaudo = t;
                    this.taskFetched = true;
                    return of(t);
                }));
        } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_CONSEGNA_MATERIALI) {
            return this.taskService.getTaskConsegnaMateriali(this.selectedTask.id.toString()).pipe(
                switchMap((t: TaskCollaudo) => {
                    this.selectedTaskCollaudo = t;
                    this.taskFetched = true;
                    return of(t);
                }));
        } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_VERIFICA_ELETTRICA) {
            return this.taskService.getTaskVerificaElettrica(this.selectedTask.id.toString()).pipe(
                switchMap((t: TaskVerificaElettrica) => {
                    this.selectedTaskVerificaElettrica = t;
                    this.taskFetched = true;
                    return of(t);
                }));
        } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_FOLLOWUP) {
            return this.taskService.getTaskFollowup(this.selectedTask.id.toString()).pipe(
                switchMap((t: TaskFollowup) => {
                    this.selectedTaskFollowup = t;
                    this.taskFetched = true;
                    return of(t);
                }));
        } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_COMPLIANCE) {
            return this.taskService.getTaskCompliance(this.selectedTask.id.toString()).pipe(
                switchMap((t: TaskCompliance) => {
                    this.selectedTaskCompliance = t;
                    this.taskFetched = true;
                    return of(t);
                }));
        } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_REPORTISTICA) {
            return this.taskService.getTaskReportistica(this.selectedTask.id.toString()).pipe(
                switchMap((t: TaskReportistica) => {
                    this.selectedTaskReportistica = t;
                    this.taskFetched = true;
                    return of(t);
                }));
        } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_SOSTITUZIONE) {
            return this.taskService.getTaskSostituzione(this.selectedTask.id.toString()).pipe(
                switchMap((t: TaskSostituzione) => {
                    this.selectedTaskSostituzione = t;
                    this.taskFetched = true;
                    return of(t);
                }));
        } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_RITIRO_DISPOSITIVO) {
            return this.taskService.getTaskRitiroDispositivo(this.selectedTask.id.toString()).pipe(
                switchMap((t: TaskRitiroDispositivo) => {
                    this.selectedTaskRitiroDispositivo = t;
                    this.taskFetched = true;
                    return of(t);
                }));
        }
    }

    onNuovoTaskConsegnaReparto(): void {
        this.taskModal.tipo = this.globals.TIPO_TASK_CONSEGNA_REPARTO;
        this.onNuovoTask();
        this.showTaskModal = true;
    }

    private onNuovoTask(): void {
        this.taskModal.id = undefined;
        this.taskModal.title = this.utilsService.getTipiTaskDescription()[this.taskModal.tipo];
        this.taskModal.ventilatore = this.selectedPrescrizioneVentilatore.ventilatore.nome;
        this.taskModal.incaricato = '';
        this.taskModal.dataPrevista = undefined;
        this.taskModal.dataCompletamento = undefined;
        this.taskModal.noteUfficio = '';
        this.taskModal.note = null;
        this.taskModal.materialePronto = false;
        this.taskModal.stato = 0;
        this.taskModal.descrizione = undefined;
        this.documentiTask = [];
        this.ddtTask = [];
        this.taskModal.add = true;
        this.taskModal.readonly = false;
        this.modalSceltaTaskOpen = false;
    }

    onNuovoTaskConsegnaPaziente(): void {
        this.taskModal.tipo = this.globals.TIPO_TASK_CONSEGNA_PAZIENTE;
        this.onNuovoTask();
        this.showTaskModal = true;
    }

    onNuovoTaskSospensione(): void {
        this.taskModal.tipo = this.globals.TIPO_TASK_SOSPENSIONE;
        this.onNuovoTask();
        this.showTaskModal = true;
    }

    onNuovoTaskAccessoOrdinario(): void {
        this.taskModal.tipo = this.globals.TIPO_TASK_ACCESSO_ORDINARIO;
        this.onNuovoTask();
        this.showTaskModal = true;
    }

    onNuovoTaskAccessoStraordinario(): void {
        this.taskModal.tipo = this.globals.TIPO_TASK_ACCESSO_STRAORDINARIO;
        this.onNuovoTask();
        this.showTaskModal = true;
    }

    onNuovoTaskGenerico(): void {
        this.taskModal.tipo = this.globals.TIPO_TASK_GENERICO;
        this.onNuovoTask();
        this.taskModal.descrizione = '';
        this.showTaskModal = true;
    }

    onNuovoTaskVerificaElettrica(): void {
        this.taskModal.tipo = this.globals.TIPO_TASK_VERIFICA_ELETTRICA;
        this.onNuovoTask();
        this.showTaskModal = true;
    }

    onNuovoTaskCollaudo(): void {
        this.taskModal.tipo = this.globals.TIPO_TASK_COLLAUDO;
        this.onNuovoTask();
        this.showTaskModal = true;
    }

    onNuovoTaskConsegnaMateriali(): void {
        this.taskModal.tipo = this.globals.TIPO_TASK_CONSEGNA_MATERIALI;
        this.onNuovoTask();
        this.showTaskModal = true;
    }

    onNuovoTaskRitiroDispositivo(): void {
        this.taskModal.tipo = this.globals.TIPO_TASK_RITIRO_DISPOSITIVO;
        this.onNuovoTask();
        this.taskModal.motivoRitiro = '0';
        this.showTaskModal = true;
    }

    onNuovoTaskSostituzione(): void {
        this.taskModal.tipo = this.globals.TIPO_TASK_SOSTITUZIONE;
        this.onNuovoTask();
        this.showTaskModal = true;
    }

    onNuovoTaskCompliance(): void {
        this.taskModal.tipo = this.globals.TIPO_TASK_COMPLIANCE;
        this.onNuovoTask();
        this.showTaskModal = true;
    }

    onNuovoTaskReportistica(): void {
        this.taskModal.tipo = this.globals.TIPO_TASK_REPORTISTICA;
        this.onNuovoTask();
        this.showTaskModal = true;
    }

    onNuovoTaskFollowup(): void {
        this.taskModal.tipo = this.globals.TIPO_TASK_FOLLOWUP;
        this.onNuovoTask();
        this.showTaskModal = true;
    }

    onEditTask(readonly: boolean = false): void {
        this.taskModal.tipo = this.selectedTask.tipo;
        this.taskModal.title = this.utilsService.getTipiTaskDescription()[this.taskModal.tipo];
        switch (this.selectedTask.tipo) {
            case this.globals.TIPO_TASK_CONSEGNA_REPARTO:
            case this.globals.TIPO_TASK_CONSEGNA_PAZIENTE:
            case this.globals.TIPO_TASK_SOSPENSIONE:
            case this.globals.TIPO_TASK_COLLAUDO:
            case this.globals.TIPO_TASK_CONSEGNA_MATERIALI:
            case this.globals.TIPO_TASK_ACCESSO_ORDINARIO:
            case this.globals.TIPO_TASK_ACCESSO_STRAORDINARIO:
            case this.globals.TIPO_TASK_VERIFICA_ELETTRICA:
            case this.globals.TIPO_TASK_COMPLIANCE:
            case this.globals.TIPO_TASK_REPORTISTICA:
            case this.globals.TIPO_TASK_SOSTITUZIONE:
                break;
            case this.globals.TIPO_TASK_RITIRO_DISPOSITIVO:
                this.taskModal.motivoRitiro = this.selectedTaskRitiroDispositivo.motivoRitiro.toString();
                break;
            case this.globals.TIPO_TASK_FOLLOWUP:
                this.taskModal.followupPositivo = this.selectedTaskFollowup.followupPositivo;
                this.taskModal.chiarimenti = this.selectedTaskFollowup.chiarimenti;
                this.taskModal.ulterioreFollowup = this.selectedTaskFollowup.ulterioreFollowup;
                break;
            case this.globals.TIPO_TASK_GENERICO:
                this.taskModal.descrizione = this.selectedTaskGenerico.descrizione;
                break;
        }
        this.onEditBaseTask(readonly).subscribe(() => {
            this.showTaskModal = true;
        });
    }

    private onEditBaseTask(readonly: boolean): Observable<Array<TaskDocumento>> {
        this.taskModal.id = this.selectedTask.id;
        this.taskModal.ventilatore = this.selectedPrescrizioneVentilatore.ventilatore.nome;
        this.taskModal.incaricato = this.selectedTask.incaricatoForeignKey;
        this.taskModal.dataPrevista = new Date(this.selectedTask.dataPrevista);
        this.taskModal.dataCompletamento = this.selectedTask.dataCompletamento ? new Date(this.selectedTask.dataCompletamento) : undefined;
        this.taskModal.stato = this.selectedTask.stato;
        this.taskModal.noteUfficio = this.selectedTask.noteUfficio;
        this.taskModal.note = this.selectedTask.note;
        this.taskModal.materialePronto = this.selectedTask.materialePronto;
        this.taskModal.add = false;
        this.taskModal.readonly = readonly;
        return this.refreshDocumentiTask();
    }

    onConfirmCloseTaskModal(): void {
        if (this.taskModal.tipo == this.globals.TIPO_TASK_CONSEGNA_REPARTO) {
            this.addTaskConsegnaReparto();
        } else if (this.taskModal.tipo == this.globals.TIPO_TASK_CONSEGNA_PAZIENTE) {
            this.addTaskConsegnaPaziente();
        } else if (this.taskModal.tipo == this.globals.TIPO_TASK_SOSPENSIONE) {
            this.addTaskSospensione();
        } else if (this.taskModal.tipo == this.globals.TIPO_TASK_ACCESSO_ORDINARIO) {
            this.addTaskAccessoOrdinario();
        } else if (this.taskModal.tipo == this.globals.TIPO_TASK_ACCESSO_STRAORDINARIO) {
            this.addTaskAccessoStraordinario();
        } else if (this.taskModal.tipo == this.globals.TIPO_TASK_GENERICO) {
            this.addTaskGenerico();
        } else if (this.taskModal.tipo == this.globals.TIPO_TASK_COLLAUDO) {
            this.addTaskCollaudo();
        } else if (this.taskModal.tipo == this.globals.TIPO_TASK_CONSEGNA_MATERIALI) {
            this.addTaskConsegnaMateriali();
        } else if (this.taskModal.tipo == this.globals.TIPO_TASK_VERIFICA_ELETTRICA) {
            this.addTaskVerificaElettrica();
        } else if (this.taskModal.tipo == this.globals.TIPO_TASK_FOLLOWUP) {
            this.addTaskFollowup();
        } else if (this.taskModal.tipo == this.globals.TIPO_TASK_COMPLIANCE) {
            this.addTaskCompliance();
        } else if (this.taskModal.tipo == this.globals.TIPO_TASK_REPORTISTICA) {
            this.addTaskReportistica();
        } else if (this.taskModal.tipo == this.globals.TIPO_TASK_SOSTITUZIONE) {
            this.addTaskSostituzione();
        } else if (this.taskModal.tipo == this.globals.TIPO_TASK_RITIRO_DISPOSITIVO) {
            this.addTaskRitiroDispositivo();
        }
        if (this.queryParams['task']) {
            this.location.back();
        }
    }

    onCloseTaskModal(): void {
        this.showTaskModal = false;
        if (this.queryParams['task']) {
            this.location.back();
        }
    }

    addTaskConsegnaReparto(): void {
        let command: TaskConsegnaRepartoCommand = new TaskConsegnaRepartoCommand();

        this.addBaseTask(command);
        if (this.taskModal.add) {
            this.taskService.createTaskConsegnaReparto(command).subscribe(() => {
                this.selectedPrescrizioneVentilatore.statoTask = StatoTaskPrescrizione.CONSEGNA_CREATA;
                this.showTaskModal = false;
                this.selectedTaskConsegnaReparto = undefined;
                this.onAfterAddOrDelete();
            });
        } else {
            this.taskService.updateTaskConsegnaReparto(this.selectedTask.id.toString(), command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskConsegnaReparto = undefined;
                this.onAfterAddOrDelete();
            });
        }
    }

    private addBaseTask(command: TaskCommand): void {
        command.incaricatoId = this.incaricati.find(i => i.id == this.taskModal.incaricato).id;
        command.prescrizioneVentilatoreId = this.selectedPrescrizioneVentilatore.id;
        command.dataPrevista = this.utilsService.fieldToDate(this.utilsService.dateToField(this.taskModal.dataPrevista));
        command.dataCompletamento = this.utilsService.fieldToDate(this.utilsService.dateToField(this.taskModal.dataCompletamento));
        command.note = '';
        command.noteUfficio = this.taskModal.noteUfficio;
        command.materialePronto = this.taskModal.materialePronto;
    }

    addTaskConsegnaPaziente(): void {
        let command: TaskConsegnaPazienteCommand = new TaskConsegnaPazienteCommand();

        this.addBaseTask(command);
        if (this.taskModal.add) {
            this.taskService.createTaskConsegnaPaziente(command).subscribe(() => {
                this.selectedPrescrizioneVentilatore.statoTask = StatoTaskPrescrizione.CONSEGNA_CREATA;
                this.showTaskModal = false;
                this.selectedTaskConsegnaPaziente = undefined;
                this.onAfterAddOrDelete();
            });
        } else {
            this.taskService.updateTaskConsegnaPaziente(this.selectedTask.id.toString(), command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskConsegnaPaziente = undefined;
                this.onAfterAddOrDelete();
            });
        }
    }

    addTaskSospensione(): void {
        let command: TaskSospensioneCommand = new TaskSospensioneCommand();

        this.addBaseTask(command);
        if (this.taskModal.add) {
            this.taskService.createTaskSospensione(command).subscribe(() => {
                this.selectedPrescrizioneVentilatore.statoTask = StatoTaskPrescrizione.SOSPENSIONE_CREATA;
                this.showTaskModal = false;
                this.selectedTaskSospensione = undefined;
                this.onAfterAddOrDelete();
            });
        } else {
            this.taskService.updateTaskSospensione(this.selectedTask.id.toString(), command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskSospensione = undefined;
                this.onAfterAddOrDelete();
            });
        }
    }

    addTaskAccessoOrdinario(): void {
        let command: TaskAccessoOrdinarioCommand = new TaskAccessoOrdinarioCommand();

        this.addBaseTask(command);
        if (this.taskModal.add) {
            this.taskService.createTaskAccessoOrdinario(command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskAccessoOrdinario = undefined;
                this.onAfterAddOrDelete();
            });
        } else {
            this.taskService.updateTaskAccessoOrdinario(this.selectedTask.id.toString(), command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskAccessoOrdinario = undefined;
                this.onAfterAddOrDelete();
            });
        }
    }

    addTaskAccessoStraordinario(): void {
        let command: TaskAccessoStraordinarioCommand = new TaskAccessoStraordinarioCommand();

        this.addBaseTask(command);
        if (this.taskModal.add) {
            this.taskService.createTaskAccessoStraordinario(command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskAccessoStraordinario = undefined;
                this.onAfterAddOrDelete();
            });
        } else {
            this.taskService.updateTaskAccessoStraordinario(this.selectedTask.id.toString(), command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskAccessoStraordinario = undefined;
                this.onAfterAddOrDelete();
            });
        }
    }

    addTaskGenerico(): void {
        let command: TaskGenericoCommand = new TaskGenericoCommand();

        this.addBaseTask(command);
        command.descrizione = this.taskModal.descrizione;
        if (this.taskModal.add) {
            this.taskService.createTaskGenerico(command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskGenerico = undefined;
                this.onAfterAddOrDelete();
            });
        } else {
            this.taskService.updateTaskGenerico(this.selectedTask.id.toString(), command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskGenerico = undefined;
                this.onAfterAddOrDelete();
            });
        }
    }

    addTaskConsegnaMateriali(): void {
        let command: TaskConsegnaMaterialiCommand = new TaskConsegnaMaterialiCommand();

        this.addBaseTask(command);
        if (this.taskModal.add) {
            this.taskService.createTaskConsegnaMateriali(command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskConsegnaMateriali = undefined;
                this.onAfterAddOrDelete();
            });
        } else {
            this.taskService.updateTaskConsegnaMateriali(this.selectedTask.id.toString(), command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskConsegnaMateriali = undefined;
                this.onAfterAddOrDelete();
            });
        }
    }

    addTaskVerificaElettrica(): void {
        let command: TaskVerificaElettricaCommand = new TaskVerificaElettricaCommand();

        this.addBaseTask(command);
        if (this.taskModal.add) {
            this.taskService.createTaskVerificaElettrica(command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskVerificaElettrica = undefined;
                this.onAfterAddOrDelete();
            });
        } else {
            this.taskService.updateTaskVerificaElettrica(this.selectedTask.id.toString(), command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskVerificaElettrica = undefined;
                this.onAfterAddOrDelete();
            });
        }
    }

    addTaskRitiroDispositivo(): void {
        let command: TaskRitiroDispositivoCommand = new TaskRitiroDispositivoCommand();

        command.motivoRitiro = Number(this.taskModal.motivoRitiro);
        this.addBaseTask(command);
        if (this.taskModal.add) {
            this.taskService.createTaskRitiroDispositivo(command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskRitiroDispositivo = undefined;
                this.onAfterAddOrDelete();
            });
        } else {
            this.taskService.updateTaskRitiroDispositivo(this.selectedTask.id.toString(), command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskRitiroDispositivo = undefined;
                this.onAfterAddOrDelete();
            });
        }
    }

    addTaskSostituzione(): void {
        let command: TaskSostituzioneCommand = new TaskSostituzioneCommand();

        this.addBaseTask(command);
        if (this.taskModal.add) {
            this.taskService.createTaskSostituzione(command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskSostituzione = undefined;
                this.onAfterAddOrDelete();
            });
        } else {
            this.taskService.updateTaskSostituzione(this.selectedTask.id.toString(), command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskSostituzione = undefined;
                this.onAfterAddOrDelete();
            });
        }
    }

    addTaskFollowup(): void {
        let command: TaskFollowupCommand = new TaskFollowupCommand();

        this.addBaseTask(command);
        if (this.taskModal.add) {
            this.taskService.createTaskFollowup(command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskFollowup = undefined;
                this.onAfterAddOrDelete();
            });
        } else {
            this.taskService.updateTaskFollowup(this.selectedTask.id.toString(), command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskFollowup = undefined;
                this.onAfterAddOrDelete();
            });
        }
    }

    addTaskCompliance(): void {
        let command: TaskComplianceCommand = new TaskComplianceCommand();

        this.addBaseTask(command);
        if (this.taskModal.add) {
            this.taskService.createTaskCompliance(command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskCompliance = undefined;
                this.onAfterAddOrDelete();
            });
        } else {
            this.taskService.updateTaskCompliance(this.selectedTask.id.toString(), command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskCompliance = undefined;
                this.onAfterAddOrDelete();
            });
        }
    }

    addTaskReportistica(): void {
        let command: TaskReportisticaCommand = new TaskReportisticaCommand();

        this.addBaseTask(command);
        if (this.taskModal.add) {
            this.taskService.createTaskReportistica(command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskReportistica = undefined;
                this.onAfterAddOrDelete();
            });
        } else {
            this.taskService.updateTaskReportistica(this.selectedTask.id.toString(), command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskReportistica = undefined;
                this.onAfterAddOrDelete();
            });
        }
    }

    addTaskCollaudo(): void {
        let command: TaskCollaudoCommand = new TaskCollaudoCommand();

        this.addBaseTask(command);
        if (this.taskModal.add) {
            this.taskService.createTaskCollaudo(command).subscribe(() => {
                this.selectedPrescrizioneVentilatore.statoTask = StatoTaskPrescrizione.CONSEGNA_CREATA;
                this.showTaskModal = false;
                this.selectedTaskCollaudo = undefined;
                this.onAfterAddOrDelete();
            });
        } else {
            this.taskService.updateTaskCollaudo(this.selectedTask.id.toString(), command).subscribe(() => {
                this.showTaskModal = false;
                this.selectedTaskCollaudo = undefined;
                this.onAfterAddOrDelete();
            });
        }
    }

    onDeleteTask(): void {
        this.confirmDeleteDialog().subscribe((result: boolean) => {
            if (result) {
                if (this.selectedTask.tipo === this.globals.TIPO_TASK_CONSEGNA_REPARTO) {
                    this.taskService.deleteTaskConsegnaReparto(this.selectedTask.id.toString()).subscribe(() => {
                        this.selectedPrescrizioneVentilatore.statoTask = StatoTaskPrescrizione.NO_TASK;
                        this.selectedTaskConsegnaReparto = undefined;
                        this.onAfterAddOrDelete();
                    });
                } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_CONSEGNA_PAZIENTE) {
                    this.taskService.deleteTaskConsegnaPaziente(this.selectedTask.id.toString()).subscribe(() => {
                        this.selectedPrescrizioneVentilatore.statoTask = StatoTaskPrescrizione.NO_TASK;
                        this.selectedTaskConsegnaPaziente = undefined;
                        this.onAfterAddOrDelete();
                    });
                } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_SOSPENSIONE) {
                    this.taskService.deleteTaskSospensione(this.selectedTask.id.toString()).subscribe(() => {
                        this.selectedTaskSospensione = undefined;
                        this.onAfterAddOrDelete();
                    });
                } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_ACCESSO_ORDINARIO) {
                    this.taskService.deleteTaskAccessoOrdinario(this.selectedTask.id.toString()).subscribe(() => {
                        this.selectedTaskAccessoOrdinario = undefined;
                        this.onAfterAddOrDelete();
                    });
                } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_ACCESSO_STRAORDINARIO) {
                    this.taskService.deleteTaskAccessoStraordinario(this.selectedTask.id.toString()).subscribe(() => {
                        this.selectedTaskAccessoStraordinario = undefined;
                        this.onAfterAddOrDelete();
                    });
                } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_GENERICO) {
                    this.taskService.deleteTaskGenerico(this.selectedTask.id.toString()).subscribe(() => {
                        this.selectedTaskGenerico = undefined;
                        this.onAfterAddOrDelete();
                    });
                } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_CONSEGNA_MATERIALI) {
                    this.taskService.deleteTaskConsegnaMateriali(this.selectedTask.id.toString()).subscribe(() => {
                        this.selectedTaskConsegnaMateriali = undefined;
                        this.onAfterAddOrDelete();
                    });
                } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_VERIFICA_ELETTRICA) {
                    this.taskService.deleteTaskVerificaElettrica(this.selectedTask.id.toString()).subscribe(() => {
                        this.selectedTaskVerificaElettrica = undefined;
                        this.onAfterAddOrDelete();
                    });
                } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_FOLLOWUP) {
                    this.taskService.deleteTaskFollowup(this.selectedTask.id.toString()).subscribe(() => {
                        this.selectedTaskFollowup = undefined;
                        this.onAfterAddOrDelete();
                    });
                } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_COMPLIANCE) {
                    this.taskService.deleteTaskCompliance(this.selectedTask.id.toString()).subscribe(() => {
                        this.selectedTaskCompliance = undefined;
                        this.onAfterAddOrDelete();
                    });
                } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_REPORTISTICA) {
                    this.taskService.deleteTaskReportistica(this.selectedTask.id.toString()).subscribe(() => {
                        this.selectedTaskReportistica = undefined;
                        this.onAfterAddOrDelete();
                    });
                } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_SOSTITUZIONE) {
                    this.taskService.deleteTaskSostituzione(this.selectedTask.id.toString()).subscribe(() => {
                        this.selectedTaskSostituzione = undefined;
                        this.onAfterAddOrDelete();
                    });
                } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_RITIRO_DISPOSITIVO) {
                    this.taskService.deleteTaskRitiroDispositivo(this.selectedTask.id.toString()).subscribe(() => {
                        this.selectedTaskRitiroDispositivo = undefined;
                        this.onAfterAddOrDelete();
                    });
                } else if (this.selectedTask.tipo === this.globals.TIPO_TASK_COLLAUDO) {
                    this.taskService.deleteTaskCollaudo(this.selectedTask.id.toString()).subscribe(() => {
                        this.selectedTaskCollaudo = undefined;
                        this.onAfterAddOrDelete();
                    });
                }
            }
        });
    }

    private onAfterAddOrDelete(): void {
        this.selectedTask = null;
        this.prescrizioniService.getTasks(this.selectedPrescrizioneVentilatore.id.toString()).subscribe((tasks: Array<Task>) => {
            this.tasks = tasks;
        });
    }

    findSelectedPrescrizioneVentilatoreIndex(): number {
        return this.selectedPrescrizioneVentilatore ? this.prescrizioniVentilatore.findIndex(p => p.id == this.selectedPrescrizioneVentilatore.id) : -1;
    }

    calcLitriMinuto(): void {
        const flussoRiposoLitriMin: number = this.form.getRawValue().flussoRiposoLitriMin ? Number(this.form.getRawValue().flussoRiposoLitriMin) : 0;
        const flussoRiposoOre: number = this.form.getRawValue().flussoRiposoOre ? Number(this.form.getRawValue().flussoRiposoOre) : 0;
        const flussoNotturnoLitriMin: number = this.form.getRawValue().flussoNotturnoLitriMin ? Number(this.form.getRawValue().flussoNotturnoLitriMin) : 0;
        const flussoNotturnoOre: number = this.form.getRawValue().flussoNotturnoOre ? Number(this.form.getRawValue().flussoNotturnoOre) : 0;
        const flussoSottoSforzoLitriMin: number = this.form.getRawValue().flussoSottoSforzoLitriMin ? Number(this.form.getRawValue().flussoSottoSforzoLitriMin) : 0;
        const flussoSottoSforzoOre: number = this.form.getRawValue().flussoSottoSforzoOre ? Number(this.form.getRawValue().flussoSottoSforzoOre) : 0;
        const formValues = {
            totaleLitriMese: (flussoRiposoLitriMin * 60 * flussoRiposoOre + flussoNotturnoLitriMin * 60 * flussoNotturnoOre + flussoSottoSforzoLitriMin * 60 * flussoSottoSforzoOre) * 30
        };

        this.form.patchValue(formValues);
    }

    setPazienteFormInputGroup() {
        this.showPazienteFormInputGroup = true;
        this.contrattiService.getCodicePaziente(this.contratto.id.toString(), this.paziente.id.toString()).subscribe((resp: CodicePazienteResponse) => {
            const values = {
                pazienteNome: this.paziente.nome,
                pazienteCognome: this.paziente.cognome,
                pazienteCodice: this.id !== 'new' && resp ? resp.codice : '',
                codiceFiscale: this.paziente.codiceFiscale,
                pazienteCodicePartner: this.paziente.codicePartner,
                pazienteEmail: this.paziente.account.email,
                pazienteDataNascita: this.utilsService.dateToField(this.paziente.dataNascita),
                pazienteCittaNascita: this.paziente.cittaNascita,
                pazienteSesso: this.paziente.sesso.toUpperCase(),
                pazienteCittadinanza: Cittadinanza[this.paziente.cittadinanza],
                // pazienteNazione: this.paziente.nazione.nome,
                pazienteTelefono: this.paziente.telefono,
                pazienteTelefonoFisso: this.paziente.telefonoFisso,
                pazienteDistretto: this.paziente.comune.distretto.nome,
                pazienteExtraAsl: this.paziente.extraAsl,
                pazienteGiaInTerapia: this.paziente.giaInTerapia ? true : false,
                pazienteResidenzaExtraAsl: this.paziente.residenzaExtraAsl ? this.paziente.residenzaExtraAsl : '',
                pazienteComune: this.paziente.comune.nome,
                pazienteIndirizzo: this.paziente.indirizzo,
                pazienteNumeroCivico: this.paziente.numeroCivico,
                pazienteCap: this.paziente.cap,
                pazienteComuneConsegna: this.paziente.comuneConsegna,
                pazienteIndirizzoConsegna: this.paziente.indirizzoConsegna,
                pazienteNumeroCivicoConsegna: this.paziente.numeroCivicoConsegna,
                pazienteCapConsegna: this.paziente.capConsegna,
                pazienteNomeDelegato: this.paziente.nomeDelegato,
                pazienteCognomeDelegato: this.paziente.cognomeDelegato,
                pazienteEmailDelegato: this.paziente.mailDelegato ? this.paziente.mailDelegato : '',
                pazienteQualificaDelegato: this.paziente.qualificaDelegato,
                pazienteTelefonoDelegato: this.paziente.telefonoDelegato,
                pazienteStato: StatoPaziente[this.paziente.stato],
            };
            this.form.patchValue(values);
            this.form.get('codiceFiscale').disable();
            this.modalOpened = false;
        });
    }

    setOnFieldsChange() {
        const validitaControl = this.form.get('validita');

        if (validitaControl) {
            validitaControl.valueChanges.pipe(
                debounceTime(500),
                distinctUntilChanged()
            ).forEach(
                (value: any) => this.onValiditaChange(value)
            );
        }

        const codiceFiscaleControl = this.form.get('codiceFiscale');

        codiceFiscaleControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).forEach(
            (value: any) => this.onCodiceFiscaleChange(value)
        );

        if (this.prescrizioneType === 'o2l') {
            this.form.get('flussoRiposoLitriMin').valueChanges.pipe(
                debounceTime(500),
                distinctUntilChanged()
            ).forEach(
                (value: any) => this.calcLitriMinuto()
            );

            this.form.get('flussoRiposoOre').valueChanges.pipe(
                debounceTime(500),
                distinctUntilChanged()
            ).forEach(
                (value: any) => this.calcLitriMinuto()
            );

            this.form.get('flussoNotturnoLitriMin').valueChanges.pipe(
                debounceTime(500),
                distinctUntilChanged()
            ).forEach(
                (value: any) => this.calcLitriMinuto()
            );

            this.form.get('flussoNotturnoOre').valueChanges.pipe(
                debounceTime(500),
                distinctUntilChanged()
            ).forEach(
                (value: any) => this.calcLitriMinuto()
            );

            this.form.get('flussoSottoSforzoLitriMin').valueChanges.pipe(
                debounceTime(500),
                distinctUntilChanged()
            ).forEach(
                (value: any) => this.calcLitriMinuto()
            );

            this.form.get('flussoSottoSforzoOre').valueChanges.pipe(
                debounceTime(500),
                distinctUntilChanged()
            ).forEach(
                (value: any) => this.calcLitriMinuto()
            );

            this.form.get('flussoRiposo').valueChanges.subscribe(value => {
                if (value) {
                    this.form.controls['flussoRiposoLitriMin'].enable();
                    this.form.controls['flussoRiposoOre'].enable();
                } else {
                    this.form.controls['flussoRiposoLitriMin'].disable();
                    this.form.controls['flussoRiposoOre'].disable();
                }
            });

            this.form.get('flussoNotturno').valueChanges.subscribe(value => {
                if (value) {
                    this.form.controls['flussoNotturnoLitriMin'].enable();
                    this.form.controls['flussoNotturnoOre'].enable();
                } else {
                    this.form.controls['flussoNotturnoLitriMin'].disable();
                    this.form.controls['flussoNotturnoOre'].disable();
                }
            });

            this.form.get('flussoSottoSforzo').valueChanges.subscribe(value => {
                if (value) {
                    this.form.controls['flussoSottoSforzoLitriMin'].enable();
                    this.form.controls['flussoSottoSforzoOre'].enable();
                } else {
                    this.form.controls['flussoSottoSforzoLitriMin'].disable();
                    this.form.controls['flussoSottoSforzoOre'].disable();
                }
            });
        }

    }

    onValiditaChange(validita: string) {
        this.updateDataScadenza(this.utilsService.fieldToDate(this.form.getRawValue().dataPrescrizione), Number(validita));
    }

    onDataPrescrizioneChange(dataPrescrizione: Date): void {
        if (this.contratto.servizio !== 'ega' && this.contratto.servizio !== 'ai') {
            this.updateDataScadenza(dataPrescrizione, this.form.getRawValue().validita);
        }
    }

    onCodiceFiscaleChange(codiceFiscale: string) {
        if (this.id === 'new' && !this.form.get('codiceFiscale').invalid) {
            this.pazientiService.getByCodiceFiscale(codiceFiscale).subscribe((paziente: Paziente) => {
                if (paziente) {
                    if (paziente.stato != StatoPaziente.CESSATO) {
                        this.paziente = paziente;
                        this.setPazienteFormInputGroup();
                        this.pazientiService.getPrescrizioni(this.paziente.id, true).subscribe((prescrizioni) => {
                            if (prescrizioni.objects.length > 0) {
                                for (const p of prescrizioni.objects) {
                                    if (p.stato !== this.prescrizioniService.STATO_PRESCRIZIONE.ANNULLATA && p.stato !== this.prescrizioniService.STATO_PRESCRIZIONE.CESSATA
                                        && p.tipo === this.prescrizioneType && this.prescrizioneType !== 'o2c' && this.prescrizioneType !== 'vent') {
                                        this.showLoadPrescriptionModal = true;
                                        this.loadPrescriptionModalBlock = true;
                                        return;
                                    }
                                }
                                for (const p of prescrizioni.objects) {
                                    if (p.stato !== this.prescrizioniService.STATO_PRESCRIZIONE.ANNULLATA && p.stato !== this.prescrizioniService.STATO_PRESCRIZIONE.CESSATA) {
                                        this.prescriptionIdToImport = +p.id;
                                        this.prescriptionIdToImportType = p.tipo;
                                        this.showLoadPrescriptionModal = true;
                                        this.loadPrescriptionModalBlock = false;
                                        return;
                                    }
                                }
                            }
                        });
                    } else {
                        this.modalPazienteDecedutoOpened = true;
                    }
                } else {
                    this.modalOpened = true;
                }
            });
        }
    }

    updateDataScadenza(dataPrescrizione: Date, validita: number) {
        if (dataPrescrizione && validita) {
            const formValues = {
                dataScadenza: this.calculateNewDataScadenza(dataPrescrizione, validita)
            };

            this.form.patchValue(formValues);
        }
    }

    calculateNewDataScadenza(dataPrescrizione: Date, validita: number): string {
        const dataScadenza: Date = new Date(dataPrescrizione.getTime());

        dataScadenza.setMonth(dataPrescrizione.getMonth() + validita);
        return this.utilsService.dateToField(dataScadenza);
    }

    onSelectServizio(event) {
        const idContratto = event.target.value;
        for (const contract of this.userContratti) {
            if (contract.id === +idContratto) {
                this.contratto = contract;
                return;
            }
        }
    }

    onModalNoButton() {
        this.form.patchValue({ codiceFiscale: '' });
        this.modalOpened = false;
    }

    onModalYesButton() {
        const codiceFiscale = this.form.get('codiceFiscale').value;
        this.prescrizioneStatusService.setStatus({ 'contratto': this.contratto, 'codiceFiscale': codiceFiscale });
        this.router.navigate(['pazienti/new'], { queryParams: { 'fiscalCode': codiceFiscale } });
    }

    onPartnerModalConfirm() {
        if (this.partnerModalAssign) {
            if (!this.assignedPartnerId) {
                return;
            }
            for (const partner of this.partnerList) {
                if (partner.id === +this.assignedPartnerId) {
                    this.assignedPartner = partner;
                    this.isAssignedToPartner = true;
                    break;
                }
            }
            this.prescrizioniService.assegnaPartner(this.id, this.assignedPartnerId.toString(), this.dataAssegnazione, this.codicePartner).subscribe((x) => {
                if (x) {
                    this.assignedPartner = null;
                    this.isAssignedToPartner = false;
                }
                this.dataAssegnazione = null;
                this.codicePartner = null;
                this.assignedPartnerId = null;
                this.showPartnerModal = false;
                this.redirectToCallerView();
            }
            );
        } else if (this.assignedPartner) {
            this.prescrizioniService.deassegnaPartner(this.id, this.dataAssegnazione).subscribe((x) => {
                if (!x) {
                    this.assignedPartner = null;
                    this.isAssignedToPartner = false;
                    this.assignedPartnerId = null;
                }
                this.dataAssegnazione = null;
                this.codicePartner = null;
                this.showPartnerModal = false;
                this.redirectToCallerView();
            }
            );
        }
    }

    onDiagnosiChange(changedControlName, event) {
        const checked = event.target.checked;
        let valid = true;
        if (!checked) {
            valid = this.atLeastOneCheckboxIsSelected(this.diagnosiList);
        }
        if (changedControlName === 'diagnosiAltra') {
            if (checked) {
                this.form.get('descrizioneDiagnosi').enable();
            } else {
                this.form.get('descrizioneDiagnosi').disable();
            }
        } else if (this.bpco60Diagnosi.indexOf(changedControlName) > -1) {
            if (checked) {
                this.form.patchValue({ bpco60: true });
                this.form.get('bpco60').disable();
            } else {
                let groupValid = false;
                for (const d of this.bpco60Diagnosi) {
                    if (this.form.get(d).value) {
                        groupValid = true;
                        break;
                    }
                }
                if (!groupValid) {
                    this.form.patchValue({ bpco60: false });
                    this.form.get('bpco60').enable();
                }
            }
        } else if (this.fc60Diagnosi.indexOf(changedControlName) > -1) {
            if (checked) {
                this.form.patchValue({ fc60: true });
                this.form.get('fc60').disable();
            } else {
                let groupValid = false;
                for (const d of this.fc60Diagnosi) {
                    if (this.form.get(d).value) {
                        groupValid = true;
                        break;
                    }
                }
                if (!groupValid) {
                    this.form.patchValue({ fc60: false });
                    this.form.get('fc60').enable();
                }
            }
        } else if (changedControlName === 'scompensoCardiacoIpossiemia') {
            if (checked) {
                this.form.patchValue({ scompensoCardiaco: true });
                this.form.get('scompensoCardiaco').disable();
            } else {
                this.form.patchValue({ scompensoCardiaco: false });
                this.form.get('scompensoCardiaco').enable();
            }
        }
        if (!valid) {
            for (const d of this.diagnosiList) {
                this.form.get(d).updateValueAndValidity();
            }
        } else {
            for (const d of this.diagnosiList) {
                if (d !== changedControlName) {
                    this.form.get(d).updateValueAndValidity();
                }
            }
        }
    }


    atLeastOneCheckboxIsSelected(list: Array<string>) {
        for (const d of list) {
            if (this.form.get(d).value) {
                return true;
            }
        }
        return false;
    }

    onSorgenteOssigenoChange(changedControlName, event) {
        const sorgenteList = ['o2Liquido', 'portatile'];
        const checked = event.target.checked;
        let valid = true;
        if (!checked) {
            valid = this.atLeastOneCheckboxIsSelected(sorgenteList);
        }
        if (!valid) {
            for (const s of sorgenteList) {
                this.form.get(s).setValidators([Validators.pattern('true'), Validators.required]);
                this.form.get(s).updateValueAndValidity();
            }
        } else {
            for (const s of sorgenteList) {
                if (s !== changedControlName) {
                    this.form.get(s).clearValidators();
                    this.form.get(s).updateValueAndValidity();
                }
            }
        }
    }

    onFlussoOssigenoChange(changedControlName, event) {
        const flussoList = ['flussoRiposo', 'flussoNotturno', 'flussoSottoSforzo'];
        const linkedFields = {
            'flussoRiposo': ['flussoRiposoLitriMin', 'flussoRiposoOre'],
            'flussoNotturno': ['flussoNotturnoLitriMin', 'flussoNotturnoOre'],
            'flussoSottoSforzo': ['flussoSottoSforzoLitriMin', 'flussoSottoSforzoOre'],
        };
        const checked = event.target.checked;
        let valid = true;

        if (!checked) {
            valid = this.atLeastOneCheckboxIsSelected(flussoList);
            for (const f of linkedFields[changedControlName]) {
                const values = {};

                values[f] = 0;
                this.form.patchValue(values);
                this.form.get(f).clearValidators();
                this.form.get(f).updateValueAndValidity();
            }
        } else {
            this.form.get(linkedFields[changedControlName][0]).setValidators(Validators.min(0.25));
            this.form.get(linkedFields[changedControlName][0]).updateValueAndValidity();
            this.form.get(linkedFields[changedControlName][1]).setValidators(Validators.min(1));
            this.form.get(linkedFields[changedControlName][1]).updateValueAndValidity();
        }
        if (!valid) {
            for (const f of flussoList) {
                this.form.get(f).setValidators([Validators.pattern('true'), Validators.required]);
                this.form.get(f).updateValueAndValidity();
            }
        } else {
            for (const f of flussoList) {
                if (f !== changedControlName) {
                    this.form.get(f).clearValidators();
                    this.form.get(f).updateValueAndValidity();
                }
            }
        }
    }

    checkTerapiaHours(field: string, event) {
        const oreTerapiaList = ['flussoRiposoOre', 'flussoNotturnoOre', 'flussoSottoSforzoOre'];
        let tot = 0;
        for (const h of oreTerapiaList) {
            tot += +this.form.get(h).value;
        }
        this.form.patchValue({ 'maxHours': tot });
        this.form.get('maxHours').updateValueAndValidity();
        if (tot > 24) {
            this.flussoNotturnoOreError = true;
            this.flussoRiposoOreError = true;
            this.flussoSottoSforzoOreError = true;
        } else {
            this.flussoNotturnoOreError = false;
            this.flussoRiposoOreError = false;
            this.flussoSottoSforzoOreError = false;
        }
    }

    onDownloadDocumento(documentoId: number) {
        this.prescrizioniService.getDocumentoDownloadUrl('documenti', documentoId.toString()).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    onDownloadDocPaziente(documentoId: number) {
        this.prescrizioniService.getDocumentoDownloadUrl('docpazienti', documentoId.toString()).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    onDocumentoChange(event: any) {
        this.documento = event.target.files[0];
    }

    onDocPazienteChange(event: any) {
        this.docPaziente = event.target.files[0];
    }

    uploadDocumento() {
        this.prescrizioniService.aggiungiDocumento('documenti', this.id, this.documento, new PrescrizioneDocumentoCommand(this.id, this.documento.name))
            .subscribe((result) => {
                this.refreshDocumenti();
                this.documento = null;
                this.fileInputDocumento.nativeElement.value = null;
            }
            );
    }

    uploadDocPaziente() {
        this.prescrizioniService.aggiungiDocumento('docpazienti', this.id, this.docPaziente, new PrescrizioneDocumentoCommand(this.id, this.docPaziente.name))
            .subscribe((result) => {
                this.refreshDocPazienti();
                this.docPaziente = null;
                this.fileInputDocPaziente.nativeElement.value = null;
            }
            );
    }

    uploadDocumentoTask(tipo: string) {
        this.taskService.aggiungiDocumento(tipo, this.selectedTask.id.toString(), this.documento, new TaskDocumentoCommand(this.selectedTask.id.toString(), this.documento.name))
            .subscribe((result) => {
                this.refreshDocumentiTask().subscribe();
                this.documento = null;
                this.fileInputDocumentoTask.nativeElement.value = null;
                this.fileInputDdtTask.nativeElement.value = null;
            }
            );
    }

    onDeleteDocumento(doc: PrescrizioneDocumento) {
        this.confirmDeleteDialog().subscribe((result: boolean) => {
            if (result) {
                this.prescrizioniService.eliminaDocumento('documenti', this.id, doc.id.toString()).subscribe((result) => {
                    this.refreshDocumenti();
                });
            }
        });
    }

    onDeleteDocPaziente(doc: PrescrizioneDocumento) {
        this.confirmDeleteDialog().subscribe((result: boolean) => {
            if (result) {
                this.prescrizioniService.eliminaDocumento('docpazienti', this.id, doc.id.toString()).subscribe((result) => {
                    this.refreshDocPazienti();
                });
            }
        });
    }

    onDeleteDocumentoTask(tipo: string, doc: TaskDocumento) {
        this.showTaskModal = false;
        this.confirmDeleteDialog().subscribe((result: boolean) => {
            this.showTaskModal = true;
            if (result) {
                this.taskService.eliminaDocumento(tipo, this.selectedTask.id.toString(), doc.id.toString()).subscribe((result) => {
                    this.refreshDocumentiTask().subscribe();
                });
            }
        });
    }

    refreshDocumenti() {
        this.prescrizioniService.getDocumenti('documenti', this.id).subscribe(documenti => {
            this.documenti = documenti;
        });
    }

    refreshDocPazienti() {
        this.prescrizioniService.getDocumenti('docpazienti', this.id).subscribe(documenti => {
            this.docPazienti = documenti;
        });
    }

    refreshDocumentiTask(): Observable<Array<TaskDocumento>> {
        const requestList: Array<Observable<Array<any>>> = [
            this.taskService.getDocumenti('documenti', this.selectedTask.id.toString()),
            this.taskService.getDocumenti('ddt', this.selectedTask.id.toString())
        ];

        return forkJoin(requestList).pipe(
            switchMap(([documenti, ddt]) => {
                this.documentiTask = documenti;
                this.ddtTask = ddt;
                return of(undefined);
            })
        );
    }

    onDownloadTaskDocument(tipo: string, documentId: number) {
        this.taskService.getDocumentDownloadUrl(tipo, documentId.toString()).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    onDownloadRisultatoPrescrizione(prescrizioneId: number) {
        this.prescrizioniService.getRisultatoDownloadUrl(prescrizioneId.toString()).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    onDownloadDdt(ddtId: number) {
        this.prescrizioniService.getDocumentoDownloadUrl('ddt', ddtId.toString()).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    onDdtChange(event: any) {
        this.ddt = event.target.files[0];
    }

    uploadDdt() {
        this.prescrizioniService.aggiungiDocumento('ddt', this.id, this.ddt, new PrescrizioneDocumentoCommand(this.id, this.ddt.name))
            .subscribe((result) => {
                this.refreshDdt();
                this.ddt = null;
                this.fileInputDdt.nativeElement.value = null;
            }
            );
    }

    onDeleteDdt(doc: PrescrizioneDocumento) {
        this.confirmDeleteDialog().subscribe((result: boolean) => {
            if (result) {
                this.prescrizioniService.eliminaDocumento('ddt', this.id, doc.id.toString()).subscribe((result) => {
                    this.refreshDdt();
                });
            }
        });
    }

    refreshDdt() {
        this.prescrizioniService.getDocumenti('ddt', this.id).subscribe(ddts => {
            this.ddts = ddts;
        });
    }

    onDataAttivazioneChange(event) {
        if (event) {
            this.dataAttivazioneAlert = moment(this.prescrizione.dataAutorizzazione).add(this.contratto.livelloServizioAttivazione, 'hours').toDate();
            this.dataAttivazioneMassima = moment(this.prescrizione.dataAutorizzazione).add(30, 'days').toDate();
            if (moment(event).isAfter(this.dataAttivazioneMassima)) {
                this.dateAlertModalText = 'La data di attivazione non può essere maggiore del ' + moment(this.dataAttivazioneMassima).format('DD/MM/YYYY HH:mm');
                this.showDateAlertModal = true;
            } else if (moment(event).isAfter(this.dataAttivazioneAlert)) {
                this.dateAlertModalText = 'La data di attivazione è maggiore della data prevista da contratto: ' + moment(this.dataAttivazioneAlert).format('DD/MM/YYYY HH:mm');
                this.showDateAlertModal = true;
            }
            if (moment(this.utilsService.dateToEpoch(new Date(this.prescrizione.dataPrescrizione))).isAfter(moment(event))) {
                this.dateAlertModalText = 'La data di attivazione non può essere minore della data di prescrizione';
                this.showDateAlertModal = true;
            }
        }
    }

    checkAndSubmit() {
        if (this.action != this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.VISUALIZZA && (this.authService.isPrescrittoreUser() || this.authService.isApprovatoreUser() || this.authService.isPartnerUser())) {
            this.showConfirmationModal = true;
        } else {
            this.onSubmit();
        }
    }

    showAlertAndRedirectToCallerView(message: string): void {
        this.allSectionsDisabled = true;
        this.alertMessage = message;
        this.closed = false;
        this.badgesService.announceDaAutorizzareUpdate();
        window.scroll(0, 0);
        setTimeout(() => {
            this.closed = true;
            this.redirectToCallerView();
        }, 2000);
    }
    
    onSubmit() {
        if (this.showConfirmationModal) {
            this.showConfirmationModal = false;
        }
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();

        if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RINNOVO && !this.authService.isAmministratoreUser()) {
            if (formValue.dataPrescrizione === this.utilsService.dateToField(this.previousDate) ||
                this.utilsService.fieldToDate(formValue.dataPrescrizione) < this.utilsService.dateToEpoch(new Date())) {
                alert("Attenzione la data di rinnovo non può essere anteriore alla data odierna");
                this.submitButton = ClrLoadingState.DEFAULT;
                return;
            }
        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.AUTORIZZAZIONE) {
            this.executeAction(formValue).subscribe((res) => {
                this.submitButton = ClrLoadingState.SUCCESS;
                if (!res) {
                    this.showAlertAndRedirectToCallerView('La prescrizione è stata autorizzata');
                }
            });
            return;
        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RICHIESTA_CESSAZIONE) {
            this.executeAction(formValue).subscribe((res) => {
                this.submitButton = ClrLoadingState.SUCCESS;
                if (!res) {
                    this.showAlertAndRedirectToCallerView('Richiesta di cessazione inviata');
                }
            });
            return;
        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RICHIESTA_RIATTIVAZIONE) {
            this.executeAction(formValue).subscribe((res) => {
                this.submitButton = ClrLoadingState.SUCCESS;
                if (!res) {
                    this.showAlertAndRedirectToCallerView('Richiesta di riattivazione inviata');
                }
            });
            return;
        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RICHIESTA_SOSPENSIONE) {
            this.executeAction(formValue).subscribe((res) => {
                this.submitButton = ClrLoadingState.SUCCESS;
                if (!res) {
                    this.showAlertAndRedirectToCallerView('Richiesta di sospensione inviata');
                }
            });
            return;
        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.CONFERMA_MODIFICA) {
            this.executeAction(formValue).subscribe((res) => {
                this.submitButton = ClrLoadingState.SUCCESS;
                if (!res) {
                    this.showAlertAndRedirectToCallerView('Modifica confermata');
                }
            });
            return;
        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.CONFERMA_RINNOVO) {
            this.executeAction(formValue).subscribe((res) => {
                this.submitButton = ClrLoadingState.SUCCESS;
                if (!res) {
                    this.showAlertAndRedirectToCallerView('Rinnovo confermato');
                }
            });
            return;
        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.PROROGA) {
            this.executeAction(formValue).subscribe((res) => {
                this.submitButton = ClrLoadingState.SUCCESS;
                if (!res) {
                    this.showAlertAndRedirectToCallerView('Richiesta di proroga confermata');
                }
            });
            return;
        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.CONFERMA_PROROGA) {
            this.executeAction(formValue).subscribe((res) => {
                this.submitButton = ClrLoadingState.SUCCESS;
                if (!res) {
                    this.showAlertAndRedirectToCallerView('Proroga confermata');
                }
            });
            return;
        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RIATTIVAZIONE) {
            this.executeAction(formValue).subscribe((res) => {
                this.submitButton = ClrLoadingState.SUCCESS;
                if (!res) {
                    if (formValue.noteFornitore !== '') {
                        this.prescrizioniService.aggiungiNota(this.id, new PrescrizioneNotaCommand(this.id.toString(), formValue.noteFornitore)).subscribe();
                    }
                    this.showAlertAndRedirectToCallerView('La prescrizione è stata riattivata');
                }
            });
            return;
        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.SOSPENSIONE) {
            this.executeAction(formValue).subscribe((res) => {
                this.submitButton = ClrLoadingState.SUCCESS;
                if (!res) {
                    if (formValue.noteFornitore !== '') {
                        this.prescrizioniService.aggiungiNota(this.id, new PrescrizioneNotaCommand(this.id.toString(), formValue.noteFornitore)).subscribe();
                    }
                    this.showAlertAndRedirectToCallerView('La prescrizione è stata sospesa');
                }
            });
            return;
        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.CESSAZIONE) {
            this.executeAction(formValue).subscribe((res) => {
                this.submitButton = ClrLoadingState.SUCCESS;
                if (!res) {
                    if (formValue.noteFornitore !== '') {
                        this.prescrizioniService.aggiungiNota(this.id, new PrescrizioneNotaCommand(this.id.toString(), formValue.noteFornitore)).subscribe();
                    }
                    this.showAlertAndRedirectToCallerView('La prescrizione è stata cessata');
                }
            });
            return;
        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.EFFETTUAZIONE) {
            this.executeAction(formValue).subscribe((res) => {
                this.submitButton = ClrLoadingState.SUCCESS;
                if (!res) {
                    this.showAlertAndRedirectToCallerView('La prescrizione è stata effettuata');
                }
            });
            return;
        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.ANNULLAMENTO) {
            this.executeAction(formValue).subscribe((res) => {
                this.submitButton = ClrLoadingState.SUCCESS;
                if (!res) {
                    if (formValue.noteFornitore !== '') {
                        this.prescrizioniService.aggiungiNota(this.id, new PrescrizioneNotaCommand(this.id.toString(), formValue.noteFornitore)).subscribe();
                    }
                    this.showAlertAndRedirectToCallerView('La prescrizione è stata annullata');
                }
            });
            return;
        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.ANNULLAMENTO_RICHIESTA) {
            let obs: Observable<any>;
            
            obs = this.executeAction(formValue);
            obs.subscribe((res) => {
                this.submitButton = ClrLoadingState.SUCCESS;
                if (!res) {
                    this.showAlertAndRedirectToCallerView('La richiesta è stata annullata');
                }
            });
            return;
        } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.VISUALIZZA) {
            this.redirectToCallerView();
            return;
        }

        let prescrizioneO2lAttivaCommand: PrescrizioneO2lAttivaCommand;
        let prescrizioneO2cAttivaCommand: PrescrizioneO2cAttivaCommand;
        let prescrizioneSatAttivaCommand: PrescrizioneSatAttivaCommand;
        let prescrizioneVentAttivaCommand: PrescrizioneVentAttivaCommand;
        let prescrizioneOssigenoLiquidoCommand: PrescrizioneOssigenoLiquidoCommand;
        let prescrizioneConcentratoreCommand: PrescrizioneConcentratoreCommand;
        let prescrizioneSaturimetroCommand: PrescrizioneSaturimetroCommand;
        let prescrizioneVentilazioneCommand: PrescrizioneVentilazioneCommand;
        let prescrizioneEgaCommand: PrescrizioneEgaCommand;
        let prescrizioneAiCommand: PrescrizioneAssistenzaInfermieristicaCommand;
        let submit;

        if (this.contratto && this.contratto.servizio === 'o2l') {
            prescrizioneOssigenoLiquidoCommand = new PrescrizioneOssigenoLiquidoCommand(
                formValue.prescrittore,
                formValue.prescrittoreEsterno ? Number(formValue.prescrittoreEsterno) : undefined,
                this.paziente.id,
                formValue.contrattoId,
                formValue.contenitoreId,
                this.utilsService.fieldToDate(formValue.dataRicezione),
                this.utilsService.fieldToDate(formValue.dataPrescrizione),
                formValue.validita,
                this.utilsService.fieldToDate(formValue.dataScadenza),
                this.utilsService.fieldToDate(formValue.dataAttivazione),
                this.utilsService.fieldToDate(formValue.dataRiattivazione),
                this.utilsService.fieldToDate(formValue.dataEGAInAria),
                formValue.phEGAInAria,
                formValue.o2EGAInAria,
                formValue.co2EGAInAria,
                formValue.co3EGAInAria,
                this.utilsService.fieldToDate(formValue.dataEGASuppl),
                formValue.phEGASuppl,
                formValue.o2EGASuppl,
                formValue.co2EGASuppl,
                formValue.co3EGASuppl,
                this.utilsService.fieldToDate(formValue.dataWalkingTest),
                formValue.risultatoWalkingTest,
                this.utilsService.fieldToDate(formValue.dataSaturimetria),
                formValue.risultatoSaturimetria,
                formValue.bpco55,
                formValue.bpco60,
                formValue.bpco60Ipossiemia,
                formValue.bpco60Policitemia,
                formValue.bpco60Edemi,
                formValue.bpco60Ipertensione,
                formValue.fc55,
                formValue.fc60,
                formValue.fc60Ipossiemia,
                formValue.fc60Policitemia,
                formValue.fc60Edemi,
                formValue.fc60Ipertensione,
                formValue.malattieInterstiziali,
                formValue.ipertensionePolmonare,
                formValue.neoplasieMaligne,
                formValue.patologieNeuro,
                formValue.apneaOstruttiva,
                formValue.scompensoCardiaco,
                formValue.desaturazioneNotturna,
                formValue.desaturazioneEsercizio,
                formValue.osas, formValue.osasAltra, formValue.poliomielite, formValue.miopatieCongenite, formValue.miopatieMitocondriali, formValue.distrofiaMuscolare, formValue.guillainBarre, formValue.sla,
                formValue.atrofiaMuscolareSpinale, formValue.paralisiDiaframma, formValue.lesioniNervoFrenico, formValue.cifoscoliosi, formValue.deformitaToracica, formValue.ipoventilazioneCongenita,
                formValue.ipoventilazioneCentrale, formValue.leigh, formValue.ipoventilazionePostChirurgica, formValue.ipoventilazionePostRadioterapia, formValue.ipoventilazioneNeoplasie,
                formValue.ipoventilazionePostTraumatica, formValue.bpco, formValue.bronchiectasie, formValue.fibrosiCistica, formValue.fibrosiPolmonare, formValue.malattieCardiacheCongenite,
                formValue.malattieCardiacheAcquisite, formValue.diagnosiAltra, formValue.descrizioneDiagnosi,
                formValue.o2Liquido,
                formValue.portatile,
                formValue.secondoContenitore,
                formValue.flussoRiposo,
                formValue.flussoRiposoLitriMin,
                formValue.flussoRiposoOre,
                formValue.flussoNotturno,
                formValue.flussoNotturnoLitriMin,
                formValue.flussoNotturnoOre,
                formValue.flussoSottoSforzo,
                formValue.flussoSottoSforzoLitriMin,
                formValue.flussoSottoSforzoOre,
                formValue.totaleLitriMese,
                formValue.accessorioConsumabile,
                formValue.carrelloContenitore,
                formValue.stroller,
                formValue.bombola,
                formValue.trasportoContoTerzi,
                formValue.bollaConsegna,
                this.utilsService.fieldToDate(formValue.dataAttivazioneSecondoContenitore),
                formValue.bollaConsegnaSecondoContenitore,
                formValue.pazienteCodice,
                formValue.codicePartner,
                formValue.codiceAutorizzazione
            );

            prescrizioneO2lAttivaCommand = new PrescrizioneO2lAttivaCommand(
                formValue.pazienteCodice,
                formValue.codicePartner,
                formValue.pazienteTelefono,
                formValue.pazienteTelefonoFisso,
                formValue.pazienteIndirizzoConsegna,
                formValue.pazienteNumeroCivicoConsegna,
                formValue.pazienteCapConsegna,
                formValue.pazienteNomeDelegato,
                formValue.pazienteCognomeDelegato,
                formValue.pazienteQualificaDelegato,
                formValue.pazienteTelefonoDelegato,
                prescrizioneOssigenoLiquidoCommand
            );
        } else if (this.contratto && this.contratto.servizio === 'o2c') {
            prescrizioneConcentratoreCommand = new PrescrizioneConcentratoreCommand(
                formValue.prescrittore,
                formValue.prescrittoreEsterno ? Number(formValue.prescrittoreEsterno) : undefined,
                this.paziente.id,
                formValue.contrattoId,
                this.utilsService.fieldToDate(formValue.dataRicezione),
                this.utilsService.fieldToDate(formValue.dataPrescrizione),
                formValue.validita,
                this.utilsService.fieldToDate(formValue.dataScadenza),
                this.utilsService.fieldToDate(formValue.dataAttivazione),
                this.utilsService.fieldToDate(formValue.dataRiattivazione),
                this.utilsService.fieldToDate(formValue.dataEGAInAria),
                formValue.phEGAInAria,
                formValue.o2EGAInAria,
                formValue.co2EGAInAria,
                formValue.co3EGAInAria,
                this.utilsService.fieldToDate(formValue.dataEGASuppl),
                formValue.phEGASuppl,
                formValue.o2EGASuppl,
                formValue.co2EGASuppl,
                formValue.co3EGASuppl,
                this.utilsService.fieldToDate(formValue.dataWalkingTest),
                formValue.risultatoWalkingTest,
                this.utilsService.fieldToDate(formValue.dataSaturimetria),
                formValue.risultatoSaturimetria,
                formValue.bpco55,
                formValue.bpco60,
                formValue.bpco60Ipossiemia,
                formValue.bpco60Policitemia,
                formValue.bpco60Edemi,
                formValue.bpco60Ipertensione,
                formValue.fc55,
                formValue.fc60,
                formValue.fc60Ipossiemia,
                formValue.fc60Policitemia,
                formValue.fc60Edemi,
                formValue.fc60Ipertensione,
                formValue.malattieInterstiziali,
                formValue.ipertensionePolmonare,
                formValue.neoplasieMaligne,
                formValue.patologieNeuro,
                formValue.apneaOstruttiva,
                formValue.scompensoCardiaco,
                formValue.desaturazioneNotturna,
                formValue.desaturazioneEsercizio,
                formValue.osas, formValue.osasAltra, formValue.poliomielite, formValue.miopatieCongenite, formValue.miopatieMitocondriali, formValue.distrofiaMuscolare, formValue.guillainBarre, formValue.sla,
                formValue.atrofiaMuscolareSpinale, formValue.paralisiDiaframma, formValue.lesioniNervoFrenico, formValue.cifoscoliosi, formValue.deformitaToracica, formValue.ipoventilazioneCongenita,
                formValue.ipoventilazioneCentrale, formValue.leigh, formValue.ipoventilazionePostChirurgica, formValue.ipoventilazionePostRadioterapia, formValue.ipoventilazioneNeoplasie,
                formValue.ipoventilazionePostTraumatica, formValue.bpco, formValue.bronchiectasie, formValue.fibrosiCistica, formValue.fibrosiPolmonare, formValue.malattieCardiacheCongenite,
                formValue.malattieCardiacheAcquisite, formValue.diagnosiAltra, formValue.descrizioneDiagnosi,
                formValue.tipoConcentratore,
                formValue.litriMinuto,
                formValue.oreTerapia,
                formValue.pazienteCodice,
                formValue.codicePartner,
                formValue.codiceAutorizzazione,
                formValue.concentratore,
                formValue.bollaConsegna,
                formValue.noteConcentratore,
                formValue.bombolaBackup
            );

            prescrizioneO2cAttivaCommand = new PrescrizioneO2cAttivaCommand(
                formValue.pazienteCodice,
                formValue.codicePartner,
                formValue.pazienteTelefono,
                formValue.pazienteTelefonoFisso,
                formValue.pazienteIndirizzoConsegna,
                formValue.pazienteNumeroCivicoConsegna,
                formValue.pazienteCapConsegna,
                formValue.pazienteNomeDelegato,
                formValue.pazienteCognomeDelegato,
                formValue.pazienteQualificaDelegato,
                formValue.pazienteTelefonoDelegato,
                prescrizioneConcentratoreCommand
            );
        } else if (this.contratto && this.contratto.servizio === 'sat') {
            prescrizioneSaturimetroCommand = new PrescrizioneSaturimetroCommand(
                formValue.prescrittore,
                formValue.prescrittoreEsterno ? Number(formValue.prescrittoreEsterno) : undefined,
                this.paziente.id,
                formValue.contrattoId,
                this.utilsService.fieldToDate(formValue.dataRicezione),
                this.utilsService.fieldToDate(formValue.dataPrescrizione),
                formValue.validita,
                this.utilsService.fieldToDate(formValue.dataScadenza),
                this.utilsService.fieldToDate(formValue.dataAttivazione),
                this.utilsService.fieldToDate(formValue.dataRiattivazione),
                this.utilsService.fieldToDate(formValue.dataEGAInAria),
                formValue.phEGAInAria,
                formValue.o2EGAInAria,
                formValue.co2EGAInAria,
                formValue.co3EGAInAria,
                this.utilsService.fieldToDate(formValue.dataEGASuppl),
                formValue.phEGASuppl,
                formValue.o2EGASuppl,
                formValue.co2EGASuppl,
                formValue.co3EGASuppl,
                this.utilsService.fieldToDate(formValue.dataWalkingTest),
                formValue.risultatoWalkingTest,
                this.utilsService.fieldToDate(formValue.dataSaturimetria),
                formValue.risultatoSaturimetria,
                formValue.bpco55,
                formValue.bpco60,
                formValue.bpco60Ipossiemia,
                formValue.bpco60Policitemia,
                formValue.bpco60Edemi,
                formValue.bpco60Ipertensione,
                formValue.fc55,
                formValue.fc60,
                formValue.fc60Ipossiemia,
                formValue.fc60Policitemia,
                formValue.fc60Edemi,
                formValue.fc60Ipertensione,
                formValue.malattieInterstiziali,
                formValue.ipertensionePolmonare,
                formValue.neoplasieMaligne,
                formValue.patologieNeuro,
                formValue.apneaOstruttiva,
                formValue.scompensoCardiaco,
                formValue.desaturazioneNotturna,
                formValue.desaturazioneEsercizio,
                formValue.osas, formValue.osasAltra, formValue.poliomielite, formValue.miopatieCongenite, formValue.miopatieMitocondriali, formValue.distrofiaMuscolare, formValue.guillainBarre, formValue.sla,
                formValue.atrofiaMuscolareSpinale, formValue.paralisiDiaframma, formValue.lesioniNervoFrenico, formValue.cifoscoliosi, formValue.deformitaToracica, formValue.ipoventilazioneCongenita,
                formValue.ipoventilazioneCentrale, formValue.leigh, formValue.ipoventilazionePostChirurgica, formValue.ipoventilazionePostRadioterapia, formValue.ipoventilazioneNeoplasie,
                formValue.ipoventilazionePostTraumatica, formValue.bpco, formValue.bronchiectasie, formValue.fibrosiCistica, formValue.fibrosiPolmonare, formValue.malattieCardiacheCongenite,
                formValue.malattieCardiacheAcquisite, formValue.diagnosiAltra, formValue.descrizioneDiagnosi,
                formValue.pazienteCodice,
                formValue.codicePartner,
                formValue.codiceAutorizzazione,
                formValue.saturimetro,
                formValue.bollaConsegna
            );

            prescrizioneSatAttivaCommand = new PrescrizioneSatAttivaCommand(
                formValue.pazienteCodice,
                formValue.codicePartner,
                formValue.pazienteTelefono,
                formValue.pazienteTelefonoFisso,
                formValue.pazienteIndirizzoConsegna,
                formValue.pazienteNumeroCivicoConsegna,
                formValue.pazienteCapConsegna,
                formValue.pazienteNomeDelegato,
                formValue.pazienteCognomeDelegato,
                formValue.pazienteQualificaDelegato,
                formValue.pazienteTelefonoDelegato,
                prescrizioneSaturimetroCommand
            );
        } else if (this.contratto && this.contratto.servizio === 'vent') {
            prescrizioneVentilazioneCommand = new PrescrizioneVentilazioneCommand(
                formValue.prescrittore,
                formValue.prescrittoreEsterno ? Number(formValue.prescrittoreEsterno) : undefined,
                this.paziente.id,
                formValue.contrattoId,
                this.utilsService.fieldToDate(formValue.dataRicezione),
                this.utilsService.fieldToDate(formValue.dataPrescrizione),
                formValue.validita,
                this.utilsService.fieldToDate(formValue.dataScadenza),
                this.utilsService.fieldToDate(formValue.dataAttivazione),
                this.utilsService.fieldToDate(formValue.dataRiattivazione),
                this.utilsService.fieldToDate(formValue.dataEGAInAria),
                formValue.phEGAInAria,
                formValue.o2EGAInAria,
                formValue.co2EGAInAria,
                formValue.co3EGAInAria,
                this.utilsService.fieldToDate(formValue.dataEGASuppl),
                formValue.phEGASuppl,
                formValue.o2EGASuppl,
                formValue.co2EGASuppl,
                formValue.co3EGASuppl,
                this.utilsService.fieldToDate(formValue.dataWalkingTest),
                formValue.risultatoWalkingTest,
                this.utilsService.fieldToDate(formValue.dataSaturimetria),
                formValue.risultatoSaturimetria,
                formValue.bpco55,
                formValue.bpco60,
                formValue.bpco60Ipossiemia,
                formValue.bpco60Policitemia,
                formValue.bpco60Edemi,
                formValue.bpco60Ipertensione,
                formValue.fc55,
                formValue.fc60,
                formValue.fc60Ipossiemia,
                formValue.fc60Policitemia,
                formValue.fc60Edemi,
                formValue.fc60Ipertensione,
                formValue.malattieInterstiziali,
                formValue.ipertensionePolmonare,
                formValue.neoplasieMaligne,
                formValue.patologieNeuro,
                formValue.apneaOstruttiva,
                formValue.scompensoCardiaco,
                formValue.desaturazioneNotturna,
                formValue.desaturazioneEsercizio,
                formValue.osas, formValue.osasAltra, formValue.poliomielite, formValue.miopatieCongenite, formValue.miopatieMitocondriali, formValue.distrofiaMuscolare, formValue.guillainBarre, formValue.sla,
                formValue.atrofiaMuscolareSpinale, formValue.paralisiDiaframma, formValue.lesioniNervoFrenico, formValue.cifoscoliosi, formValue.deformitaToracica, formValue.ipoventilazioneCongenita,
                formValue.ipoventilazioneCentrale, formValue.leigh, formValue.ipoventilazionePostChirurgica, formValue.ipoventilazionePostRadioterapia, formValue.ipoventilazioneNeoplasie,
                formValue.ipoventilazionePostTraumatica, formValue.bpco, formValue.bronchiectasie, formValue.fibrosiCistica, formValue.fibrosiPolmonare, formValue.malattieCardiacheCongenite,
                formValue.malattieCardiacheAcquisite, formValue.diagnosiAltra, formValue.descrizioneDiagnosi,
                formValue.pazienteCodice,
                formValue.codicePartner,
                formValue.codiceAutorizzazione,
                formValue.consegnaPressoStruttura,
                formValue.ccf,
                formValue.commerciale
            );

            prescrizioneVentAttivaCommand = new PrescrizioneVentAttivaCommand(
                formValue.pazienteCodice,
                formValue.codicePartner,
                formValue.pazienteTelefono,
                formValue.pazienteTelefonoFisso,
                formValue.pazienteIndirizzoConsegna,
                formValue.pazienteNumeroCivicoConsegna,
                formValue.pazienteCapConsegna,
                formValue.pazienteNomeDelegato,
                formValue.pazienteCognomeDelegato,
                formValue.pazienteQualificaDelegato,
                formValue.pazienteTelefonoDelegato,
                prescrizioneVentilazioneCommand
            );
        } else if (this.contratto && this.contratto.servizio === 'ega') {
            prescrizioneEgaCommand = new PrescrizioneEgaCommand(
                formValue.prescrittore,
                formValue.prescrittoreEsterno ? Number(formValue.prescrittoreEsterno) : undefined,
                this.paziente.id,
                formValue.contrattoId,
                this.utilsService.fieldToDate(formValue.dataRicezione),
                this.utilsService.fieldToDate(formValue.dataPrescrizione),
                this.utilsService.fieldToDate(formValue.dataEGAInAria),
                formValue.phEGAInAria,
                formValue.o2EGAInAria,
                formValue.co2EGAInAria,
                formValue.co3EGAInAria,
                this.utilsService.fieldToDate(formValue.dataEGASuppl),
                formValue.phEGASuppl,
                formValue.o2EGASuppl,
                formValue.co2EGASuppl,
                formValue.co3EGASuppl,
                this.utilsService.fieldToDate(formValue.dataWalkingTest),
                formValue.risultatoWalkingTest,
                this.utilsService.fieldToDate(formValue.dataSaturimetria),
                formValue.risultatoSaturimetria,
                formValue.bpco55,
                formValue.bpco60,
                formValue.bpco60Ipossiemia,
                formValue.bpco60Policitemia,
                formValue.bpco60Edemi,
                formValue.bpco60Ipertensione,
                formValue.fc55,
                formValue.fc60,
                formValue.fc60Ipossiemia,
                formValue.fc60Policitemia,
                formValue.fc60Edemi,
                formValue.fc60Ipertensione,
                formValue.malattieInterstiziali,
                formValue.ipertensionePolmonare,
                formValue.neoplasieMaligne,
                formValue.patologieNeuro,
                formValue.apneaOstruttiva,
                formValue.scompensoCardiaco,
                formValue.desaturazioneNotturna,
                formValue.desaturazioneEsercizio,
                formValue.osas, formValue.osasAltra, formValue.poliomielite, formValue.miopatieCongenite, formValue.miopatieMitocondriali, formValue.distrofiaMuscolare, formValue.guillainBarre, formValue.sla,
                formValue.atrofiaMuscolareSpinale, formValue.paralisiDiaframma, formValue.lesioniNervoFrenico, formValue.cifoscoliosi, formValue.deformitaToracica, formValue.ipoventilazioneCongenita,
                formValue.ipoventilazioneCentrale, formValue.leigh, formValue.ipoventilazionePostChirurgica, formValue.ipoventilazionePostRadioterapia, formValue.ipoventilazioneNeoplasie,
                formValue.ipoventilazionePostTraumatica, formValue.bpco, formValue.bronchiectasie, formValue.fibrosiCistica, formValue.fibrosiPolmonare, formValue.malattieCardiacheCongenite,
                formValue.malattieCardiacheAcquisite, formValue.diagnosiAltra, formValue.descrizioneDiagnosi,
                formValue.tipoEga,
                formValue.pazienteCodice,
                formValue.codicePartner,
                formValue.codiceAutorizzazione
            );
        } else if (this.contratto && this.contratto.servizio === 'ai') {
            prescrizioneAiCommand = new PrescrizioneAssistenzaInfermieristicaCommand(
                formValue.prescrittore,
                formValue.prescrittoreEsterno ? Number(formValue.prescrittoreEsterno) : undefined,
                this.paziente.id,
                formValue.contrattoId,
                this.utilsService.fieldToDate(formValue.dataRicezione),
                this.utilsService.fieldToDate(formValue.dataPrescrizione),
                this.utilsService.fieldToDate(formValue.dataEGAInAria),
                formValue.phEGAInAria,
                formValue.o2EGAInAria,
                formValue.co2EGAInAria,
                formValue.co3EGAInAria,
                this.utilsService.fieldToDate(formValue.dataEGASuppl),
                formValue.phEGASuppl,
                formValue.o2EGASuppl,
                formValue.co2EGASuppl,
                formValue.co3EGASuppl,
                this.utilsService.fieldToDate(formValue.dataWalkingTest),
                formValue.risultatoWalkingTest,
                this.utilsService.fieldToDate(formValue.dataSaturimetria),
                formValue.risultatoSaturimetria,
                formValue.bpco55,
                formValue.bpco60,
                formValue.bpco60Ipossiemia,
                formValue.bpco60Policitemia,
                formValue.bpco60Edemi,
                formValue.bpco60Ipertensione,
                formValue.fc55,
                formValue.fc60,
                formValue.fc60Ipossiemia,
                formValue.fc60Policitemia,
                formValue.fc60Edemi,
                formValue.fc60Ipertensione,
                formValue.malattieInterstiziali,
                formValue.ipertensionePolmonare,
                formValue.neoplasieMaligne,
                formValue.patologieNeuro,
                formValue.apneaOstruttiva,
                formValue.scompensoCardiaco,
                formValue.desaturazioneNotturna,
                formValue.desaturazioneEsercizio,
                formValue.osas, formValue.osasAltra, formValue.poliomielite, formValue.miopatieCongenite, formValue.miopatieMitocondriali, formValue.distrofiaMuscolare, formValue.guillainBarre, formValue.sla,
                formValue.atrofiaMuscolareSpinale, formValue.paralisiDiaframma, formValue.lesioniNervoFrenico, formValue.cifoscoliosi, formValue.deformitaToracica, formValue.ipoventilazioneCongenita,
                formValue.ipoventilazioneCentrale, formValue.leigh, formValue.ipoventilazionePostChirurgica, formValue.ipoventilazionePostRadioterapia, formValue.ipoventilazioneNeoplasie,
                formValue.ipoventilazionePostTraumatica, formValue.bpco, formValue.bronchiectasie, formValue.fibrosiCistica, formValue.fibrosiPolmonare, formValue.malattieCardiacheCongenite,
                formValue.malattieCardiacheAcquisite, formValue.diagnosiAltra, formValue.descrizioneDiagnosi,
                formValue.pazienteCodice,
                formValue.codicePartner,
                formValue.codiceAutorizzazione,
                formValue.paramSpo2,
                formValue.paramEtCo2,
                formValue.paramFR,
                formValue.paramFC
            );
        } else {
            return;
        }
        if (this.id !== 'new') {
            if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RINNOVO) {
                if (this.prescrizioneType === 'o2l') {
                    submit = this.prescrizioniService.rinnovaPrescrizione(this.id.toString(), 'o2l', prescrizioneOssigenoLiquidoCommand);
                } else if (this.prescrizioneType === 'o2c') {
                    submit = this.prescrizioniService.rinnovaPrescrizione(this.id.toString(), 'o2c', prescrizioneConcentratoreCommand);
                } else if (this.prescrizioneType === 'sat') {
                    submit = this.prescrizioniService.rinnovaPrescrizione(this.id.toString(), 'sat', prescrizioneSaturimetroCommand);
                } else if (this.prescrizioneType === 'vent') {
                    submit = this.prescrizioniService.rinnovaPrescrizione(this.id.toString(), 'vent', this.getPrescrizioneVentilazione(prescrizioneVentilazioneCommand, true));
                }
            } else if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.ATTIVAZIONE) {
                if (this.prescrizioneType === 'o2l') {
                    submit = this.prescrizioniService.attivaPrescrizioneO2l(this.id, prescrizioneO2lAttivaCommand);
                } else if (this.prescrizioneType === 'o2c') {
                    submit = this.prescrizioniService.attivaPrescrizioneO2c(this.id, prescrizioneO2cAttivaCommand);
                } else if (this.prescrizioneType === 'sat') {
                    submit = this.prescrizioniService.attivaPrescrizioneSat(this.id, prescrizioneSatAttivaCommand);
                } else if (this.prescrizioneType === 'vent') {
                    submit = this.prescrizioniService.attivaPrescrizioneVent(this.id, prescrizioneVentAttivaCommand);
                }
            } else {
                if (this.prescrizioneType === 'o2l') {
                    submit = this.prescrizioniService.updatePrescrizioneO2l(this.id, prescrizioneOssigenoLiquidoCommand);
                } else if (this.prescrizioneType === 'o2c') {
                    submit = this.prescrizioniService.updatePrescrizioneO2c(this.id, prescrizioneConcentratoreCommand);
                } else if (this.prescrizioneType === 'sat') {
                    submit = this.prescrizioniService.updatePrescrizioneSat(this.id, prescrizioneSaturimetroCommand);
                } else if (this.prescrizioneType === 'ega') {
                    submit = this.prescrizioniService.updatePrescrizioneEga(this.id, prescrizioneEgaCommand);
                } else if (this.prescrizioneType === 'ai') {
                    submit = this.prescrizioniService.updatePrescrizioneAssistenzaInfermieristica(this.id, prescrizioneAiCommand);
                } else if (this.prescrizioneType === 'vent') {
                    submit = this.prescrizioniService.updatePrescrizioneVent(this.id, this.getPrescrizioneVentilazione(prescrizioneVentilazioneCommand, false));
                }
            }
            submit.subscribe(() => {
                const callList = [];
                
                if (formValue.noteFornitore !== '') {
                    const aggiungiNotaFornitore = this.prescrizioniService
                        .aggiungiNota(this.id, new PrescrizioneNotaCommand(this.id.toString(), formValue.noteFornitore));
                        
                    callList.push(aggiungiNotaFornitore);
                }
                if (formValue.notePrescrittore !== '') {
                    const aggiungiNotaPrescrittore = this.prescrizioniService
                        .aggiungiNotaPrescrittore(this.id, new PrescrizioneNotaPrescrittoreCommand(this.id.toString(), formValue.notePrescrittore));
                        
                    callList.push(aggiungiNotaPrescrittore);
                }
                if (this.action) {
                    const executeAction = this.executeAction(formValue);
                    
                    callList.push(executeAction);
                }
                forkJoin(callList)
                    .subscribe(() => {
                        if (this.prescrizioneType !== 'vent') {
                            this.allSectionsDisabled = true;
                        }
                        this.submitButton = ClrLoadingState.SUCCESS;
                        this.alertMessage = 'La prescrizione è stata aggiornata';
                        this.closed = false;
                        window.scroll(0, 0);
                        this.badgesService.announceDaProcessareUpdate();
                        setTimeout(() => {
                            this.closed = true;
                            this.redirectToCallerView();
                        }, 2000);
                    });
            });
        } else {
            if (this.prescrizioneType === 'o2l') {
                this.prescrizioniService.createPrescrizioneO2l(prescrizioneOssigenoLiquidoCommand).subscribe((id) => {
                    if (!id.error) {
                        this.postCreatePrescrizione(id, formValue, 'o2l');
                    } else {
                        this.submitButton = ClrLoadingState.ERROR;
                    }
                });
            } else if (this.prescrizioneType === 'o2c') {
                this.prescrizioniService.createPrescrizioneO2c(prescrizioneConcentratoreCommand).subscribe((id) => {
                    if (!id.error) {
                        this.postCreatePrescrizione(id, formValue, 'o2c');
                    } else {
                        this.submitButton = ClrLoadingState.ERROR;
                    }
                });
            } else if (this.prescrizioneType === 'sat') {
                this.prescrizioniService.createPrescrizioneSat(prescrizioneSaturimetroCommand).subscribe((id) => {
                    if (!id.error) {
                        this.postCreatePrescrizione(id, formValue, 'sat');
                    } else {
                        this.submitButton = ClrLoadingState.ERROR;
                    }
                });
            } else if (this.prescrizioneType === 'vent') {
                this.prescrizioniService.createPrescrizioneVent(this.getPrescrizioneVentilazione(prescrizioneVentilazioneCommand, false)).subscribe((id) => {
                    if (!id.error) {
                        this.postCreatePrescrizione(id, formValue, 'vent');
                    } else {
                        this.submitButton = ClrLoadingState.ERROR;
                    }
                });
            } else if (this.prescrizioneType === 'ega') {
                this.prescrizioniService.createPrescrizioneEga(prescrizioneEgaCommand).subscribe((id) => {
                    if (!id.error) {
                        this.postCreatePrescrizione(id, formValue, 'ega');
                    } else {
                        this.submitButton = ClrLoadingState.ERROR;
                    }
                });
            } else if (this.prescrizioneType === 'ai') {
                this.prescrizioniService.createPrescrizioneAssistenzaInfermieristica(prescrizioneAiCommand).subscribe((id) => {
                    if (!id.error) {
                        this.postCreatePrescrizione(id, formValue, 'ai');
                    } else {
                        this.submitButton = ClrLoadingState.ERROR;
                    }
                });
            }
        }
    }

    private postCreatePrescrizione(id: string, formValue: any, tipo: string): void {
        if (id) {
            if (formValue.notePrescrittore !== '') {
                this.prescrizioniService
                    .aggiungiNotaPrescrittore(id, new PrescrizioneNotaPrescrittoreCommand(id, formValue.notePrescrittore))
                    .subscribe(() => {
                        this.clearPostCreatePrescrizione(id, tipo);
                    });
            } else {
                this.clearPostCreatePrescrizione(id, tipo);
            }
        } else {
            // TODO: show error message
        }
    }

    private clearPostCreatePrescrizione(id: string, tipo: string): void {
        if (this.prescrizioneType !== 'vent') {
            this.allSectionsDisabled = true;
        }
        this.submitButton = ClrLoadingState.SUCCESS;
        this.alertMessage = 'La prescrizione è stata creata';
        this.closed = false;
        window.scroll(0, 0);
        setTimeout(() => {
            this.closed = true;
            this.redirectToCallerView();
        }, 2000);
    }

    private getPrescrizioneVentilazione(prescrizioneVentilazioneCommand: PrescrizioneVentilazioneCommand, rinnova: boolean): PrescrizioneVentilazioneCommandDto {
        if (this.previousPrescrizioneVentilatore) {
            this.previousPrescrizioneVentilatore.modelloMaschera = this.form.getRawValue().modelloMaschera;
            this.previousPrescrizioneVentilatore.tipoMaschera = this.form.getRawValue().tipoMaschera;
            this.previousPrescrizioneVentilatore.marcaMaschera = this.form.getRawValue().marcaMaschera;
            this.previousPrescrizioneVentilatore.tagliaMaschera = this.form.getRawValue().tagliaMaschera;
            this.mapSelectedVentilatoriMateriali.set(this.previousPrescrizioneVentilatore.id, this.selectedMateriali);
            this.mapSelectedVentilatoriMaterialiMedicazione.set(this.previousPrescrizioneVentilatore.id, this.selectedMaterialiMedicazione);
        }

        let prescrizione = new PrescrizioneVentilazioneCommandDto();

        prescrizione.prescrizione = prescrizioneVentilazioneCommand;
        if (!rinnova) {
            prescrizione.prescrizioniVentilatori = this.prescrizioniVentilatore.map(pv => {
                let dto = new PrescrizioneVentilatoreCommandDto();

                dto.id = pv[this.NUOVA] ? undefined: pv.id;
                dto.ventilatoreId = pv.ventilatore.id;
                dto.profiloId = pv.profilo.id;
                dto.quantita = pv.quantita;
                dto.modalitaId = pv.modalitaVentilatoria ? pv.modalitaVentilatoria.id : null;
                dto.modelloMaschera = pv.modelloMaschera;
                dto.marcaMaschera = pv.marcaMaschera;
                dto.tipoMaschera = pv.tipoMaschera;
                dto.tagliaMaschera = pv.tagliaMaschera;
                dto.parametriIds = pv.parametriVentilatore.map(pv => pv.parametroVentilatore.id);
                dto.valori = pv.parametriVentilatore.map(pv => pv.valore);
                if (!pv[this.NUOVA]) {
                    dto.materialiIds = null;
                } else {
                    dto.materialiIds = this.mapSelectedVentilatoriMateriali.get(pv.id).map(m => m.materiale.id);
                    dto.materialiIds = dto.materialiIds.concat(this.mapSelectedVentilatoriMaterialiMedicazione.get(pv.id).map(m => m.materiale.id));
                }
                dto.parametriAllarmeIds = pv.parametriAllarme.map(pa => pa.parametroAllarme.id);
                dto.mins = pv.parametriAllarme.map(pa => pa.min);
                dto.maxs = pv.parametriAllarme.map(pa => pa.max);
                return dto;
            });
        }
        return prescrizione;
    }

    getStatiDescription(): Array<string> {
        const states = Object.keys(StatoPrescrizione);
        return states.slice(states.length / 2);
    }

    getUpdatedFields(prescrizione: Prescrizione): Array<string> {
        const fields = [];
        for (const field of Object.keys(prescrizione)) {
            const foundAtIndex = field.indexOf('Mod');
            if (foundAtIndex > -1) {
                const fieldName = field.substring(0, foundAtIndex);
                if (prescrizione[field] === true) {
                    fields.push(fieldName);
                }
            }
        }
        return fields;
    }

    executeAction(formValue) {
        if (this.action) {
            switch (+this.action) {
                case this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.AUTORIZZAZIONE:
                    return this.prescrizioniService.autorizzaPrescrizione(this.id.toString());
                case this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.ATTIVAZIONE:
                    return of(true);
                case this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RIATTIVAZIONE:
                    return this.prescrizioniService.riattivaPrescrizione(
                        this.id.toString(), this.utilsService.fieldToDate(formValue.dataRiattivazione)
                    );
                case this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.PROROGA:
                    return this.prescrizioniService.prorogaPrescrizione(this.id.toString());
                case this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.CESSAZIONE:
                    return this.prescrizioniService.cessaPrescrizione(
                        this.id.toString(), this.utilsService.fieldToDate(formValue.dataCessazione)
                    );
                case this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.SOSPENSIONE:
                    return this.prescrizioniService.sospendiPrescrizione(this.id.toString(), this.utilsService.fieldToDate(formValue.dataSospensione));
                case this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.ANNULLAMENTO:
                    return this.prescrizioniService.annullaPrescrizione(this.id.toString(), this.utilsService.fieldToDate(formValue.dataAnnullamento));
                case this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.MODIFICA:
                    return of(true);
                case this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RICHIESTA_SOSPENSIONE:
                    return this.prescrizioniService.richiediSospensione(this.id.toString());
                case this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RICHIESTA_RIATTIVAZIONE:
                    return this.prescrizioniService.richiediRiattivazione(this.id.toString());
                case this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RICHIESTA_CESSAZIONE:
                    return this.prescrizioniService.richiediCessazione(this.id.toString());
                case this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.CONFERMA_MODIFICA:
                    return this.prescrizioniService.confermaModifica(this.id.toString());
                case this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.CONFERMA_RINNOVO:
                    return this.prescrizioniService.confermaRinnovo(this.id.toString());
                case this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.CONFERMA_PROROGA:
                    return this.prescrizioniService.confermaProroga(this.id.toString());
                case this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.ANNULLAMENTO_RICHIESTA:
                    return this.prescrizioniService.annullaRichiesta(this.id.toString());
                case this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.EFFETTUAZIONE:
                    return this.prescrizioniService.aggiornaRisultatoPrescrizione(this.id.toString(), this.risultato).pipe(
                        switchMap(() => {
                            return this.prescrizioniService.effettuaPrescrizione(this.id.toString(), this.utilsService.fieldToDate(formValue.dataEffettuazione));
                        })
                    );
                default:
                    return of(true);
            }
        }
    }

    onDateAlertModalClose() {
        this.showDateAlertModal = false;
    }

    enableFormFields(fields: string[]) {
        for (const f of fields) {
            this.form.get(f).enable();
        }
    }

    setRequiredFormFields(fields: string[]) {
        for (const f of fields) {
            this.form.get(f).setValidators([Validators.required]);
            this.form.get(f).updateValueAndValidity();
        }
    }

    setRequiredCheckboxFormFields(fields: string[]) {
        for (const f of fields) {
            this.form.get(f).setValidators([Validators.pattern('true'), Validators.required]);
            this.form.get(f).updateValueAndValidity();
        }
    }

    onSecondoContenitoreChange(event) {
        const checked = event.target.checked;
        if (checked) {
            this.setRequiredFormFields(['dataAttivazioneSecondoContenitore']);
        } else {
            this.form.get('dataAttivazioneSecondoContenitore').clearValidators();
            this.form.patchValue({ dataAttivazioneSecondoContenitore: null });
            this.form.get('dataAttivazioneSecondoContenitore').updateValueAndValidity();
        }
        this.form.get('dataAttivazioneSecondoContenitore').markAsTouched();
        this.form.get('dataAttivazioneSecondoContenitore').updateValueAndValidity();
    }

    checkRequired(item) {
        if (!item) {
            return false;
        }
        if (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.VISUALIZZA) {
            return false;
        }

        const controlName = item.getAttribute('formControlName');
        const control = this.form.get(controlName);

        if (control) {
            if (control.disabled) {
                return false;
            }
            if (control.validator) {
                const validator = this.form.get(controlName).validator({} as AbstractControl);
                if (validator && validator.required) {
                    if (control.invalid) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    onLoadPrescriptionModalNoButton() {
        this.showLoadPrescriptionModal = false;
    }

    onLoadPrescriptionModalCloseButton() {
        this.redirectToCallerView();
    }

    onLoadPrescriptionModalYesButton() {
        if (this.prescriptionIdToImportType === 'o2l') {
            this.prescrizioniService.getPrescrizioneOssigenoLiquido(this.prescriptionIdToImport.toString()).subscribe((prescription) => {
                this.loadPrescription(prescription);
                this.showLoadPrescriptionModal = false;
            });
        } else if (this.prescriptionIdToImportType === 'o2c') {
            this.prescrizioniService.getPrescrizioneConcentratore(this.prescriptionIdToImport.toString()).subscribe((prescription) => {
                this.loadPrescription(prescription);
                this.showLoadPrescriptionModal = false;
            });
        } else if (this.prescriptionIdToImportType === 'sat') {
            this.prescrizioniService.getPrescrizioneSaturimetro(this.prescriptionIdToImport.toString()).subscribe((prescription) => {
                this.loadPrescription(prescription);
                this.showLoadPrescriptionModal = false;
            });
        } else if (this.prescriptionIdToImportType === 'vent') {
            this.prescrizioniService.getPrescrizioneVentilazione(this.prescriptionIdToImport.toString()).subscribe((prescription) => {
                this.loadPrescription(prescription.prescrizione);
                this.showLoadPrescriptionModal = false;
            });
        } else if (this.prescriptionIdToImportType === 'ega') {
            this.prescrizioniService.getPrescrizioneEga(this.prescriptionIdToImport.toString()).subscribe((prescription) => {
                this.loadPrescription(prescription);
                this.showLoadPrescriptionModal = false;
            });
        } else if (this.prescriptionIdToImportType === 'ai') {
            this.prescrizioniService.getPrescrizioneAssistenzaInfermieristica(this.prescriptionIdToImport.toString()).subscribe((prescription) => {
                this.loadPrescription(prescription);
                this.showLoadPrescriptionModal = false;
            });
        } else {
            this.showLoadPrescriptionModal = false;
        }
    }

    getNoteFornitore(prescrizione: Prescrizione) {
        if (!this.authService.isPrescrittoreUser() && !this.authService.isApprovatoreUser() && !this.authService.isAppaltanteUser()) {
            this.prescrizioniService.getNote(prescrizione.id.toString(), 0, 1000).subscribe((note) => {
                this.noteFornitoreList = note.map(n => {
                    n.testo = n.testo.replace('\\n', '<br/>');
                    return n;
                });
            });
        }
    }

    getNotePrescrittore(prescrizione: Prescrizione) {
        this.prescrizioniService.getNotePrescrittore(prescrizione.id.toString(), 0, 1000).subscribe((note) => {
            this.notePrescrittoreList = note.map(n => {
                n.testo = n.testo.replace('\\n', '<br/>');
                return n;
            });
        });
    }

    private redirectToCallerView() {
        let taskId = this.queryParams['taskId'];

        if (taskId) {
            let queryParams = { tipo: this.queryParams['tipoTask'] };

            this.router.navigate(['/task/' + taskId], { queryParams: queryParams });
        } else {
            let queryParams = { pfilter: this.prescriptionFilter };

            this.router.navigate(['/prescrizioni-' + this.prescrizioneType], { queryParams: queryParams });
        }
    }

    private loadPrescription(prescription: Prescrizione) {
        const values = {
            dataWalkingTest: this.utilsService.dateToField(prescription.dataWalkingTest),
            risultatoWalkingTest: prescription.risultatoWalkingTest,
            dataSaturimetria: this.utilsService.dateToField(prescription.dataSaturimetria),
            risultatoSaturimetria: prescription.risultatoSaturimetria,
            bpco55: prescription.bpco55,
            bpco60: prescription.bpco60,
            bpco60Ipossiemia: prescription.bpco60Ipossiemia,
            bpco60Policitemia: prescription.bpco60Policitemia,
            bpco60Edemi: prescription.bpco60Edemi,
            bpco60Ipertensione: prescription.bpco60Ipertensione,
            fc55: prescription.fc55,
            fc60: prescription.fc60,
            fc60Ipossiemia: prescription.fc60Ipossiemia,
            fc60Policitemia: prescription.fc60Policitemia,
            fc60Edemi: prescription.fc60Edemi,
            fc60Ipertensione: prescription.fc60Ipertensione,
            malattieInterstiziali: prescription.malattieInterstiziali,
            ipertensionePolmonare: prescription.ipertensionePolmonare,
            neoplasieMaligne: prescription.neoplasieMaligne,
            patologieNeuro: prescription.patologieNeuro,
            apneaOstruttiva: prescription.apneaOstruttiva,
            scompensoCardiaco: prescription.scompensoCardiaco,
            scompensoCardiacoIpossiemia: prescription.scompensoCardiacoIpossiemia,
            desaturazioneNotturna: prescription.desaturazioneNotturna,
            desaturazioneEsercizio: prescription.desaturazioneEsercizio,
            osas: prescription.osas,
            osasAltra: prescription.osasAltra,
            poliomielite: prescription.poliomielite,
            miopatieCongenite: prescription.miopatieCongenite,
            miopatieMitocondriali: prescription.miopatieMitocondriali,
            distrofiaMuscolare: prescription.distrofiaMuscolare,
            guillainBarre: prescription.guillainBarre,
            sla: prescription.sla,
            atrofiaMuscolareSpinale: prescription.atrofiaMuscolareSpinale,
            paralisiDiaframma: prescription.paralisiDiaframma,
            lesioniNervoFrenico: prescription.lesioniNervoFrenico,
            cifoscoliosi: prescription.cifoscoliosi,
            deformitaToracica: prescription.deformitaToracica,
            ipoventilazioneCongenita: prescription.ipoventilazioneCongenita,
            ipoventilazioneCentrale: prescription.ipoventilazioneCentrale,
            leigh: prescription.leigh,
            ipoventilazionePostChirurgica: prescription.ipoventilazionePostChirurgica,
            ipoventilazionePostRadioterapia: prescription.ipoventilazionePostRadioterapia,
            ipoventilazioneNeoplasie: prescription.ipoventilazioneNeoplasie,
            ipoventilazionePostTraumatica: prescription.ipoventilazionePostTraumatica,
            bpco: prescription.bpco,
            bronchiectasie: prescription.bronchiectasie,
            fibrosiCistica: prescription.fibrosiCistica,
            fibrosiPolmonare: prescription.fibrosiPolmonare,
            malattieCardiacheCongenite: prescription.malattieCardiacheCongenite,
            malattieCardiacheAcquisite: prescription.malattieCardiacheAcquisite,
            diagnosiAltra: prescription.diagnosiAltra,
            descrizioneDiagnosi: prescription.descrizioneDiagnosi,
            diagnosiSelected: this.atLeastOneCheckboxIsSelected(this.diagnosiList)
        };
        if (prescription.dataEGAInAria) {
            values['dataEGAInAria'] = this.utilsService.dateToField(prescription.dataEGAInAria);
            values['phEGAInAria'] = prescription.phEGAInAria;
            values['o2EGAInAria'] = prescription.o2EGAInAria;
            values['co2EGAInAria'] = prescription.co2EGAInAria;
            values['co3EGAInAria'] = prescription.co3EGAInAria;
        } else if (prescription.dataEGASuppl) {
            values['dataEGASuppl'] = this.utilsService.dateToField(prescription.dataEGASuppl);
            values['phEGASuppl'] = prescription.phEGASuppl;
            values['o2EGASuppl'] = prescription.o2EGASuppl;
            values['co2EGASuppl'] = prescription.co2EGASuppl;
            values['co3EGASuppl'] = prescription.co3EGASuppl;
        }
        this.form.patchValue(values);
        if (this.atLeastOneCheckboxIsSelected(this.diagnosiList)) {
            for (const f of this.diagnosiList) {
                this.form.get(f).clearValidators();
                this.form.get(f).updateValueAndValidity();
            }
        }
    }

    onPrint() {
        if (this.prescrizione) {
            this.reportsService.getReportPrescrizioneDownloadLink(this.prescrizione.tipo, this.prescrizione.id.toString()).subscribe((url) => {
                if (url) {
                    // window.location.href = url;
                    window.open(url);
                }
            });
        }
    }

    isAuthorizedOnPrescription(action: PrescrizioneAction): boolean {
        if (this.prescrizione) {
            const authorizedActions = this.prescrizioneActionsService.getAuthorizedActions(this.prescrizione);

            return authorizedActions.indexOf(action) > -1;
        } else {
            return false;
        }
    }

    onAction(action: PrescrizioneAction) {
        this.prescrizioneSectionDisabled = false;
        const url = '/prescrizioni/' + this.prescrizione.id;
        const queryParams = { type: this.prescrizione.tipo };

        queryParams['action'] = action;
        this.router.navigate([url], { queryParams: queryParams });
    }

    onRisultatoChange(event: any) {
        this.risultato = event.target.files[0];
    }

    onGotoPaziente(): void {
        const url = '/pazienti/' + this.paziente.id;
        const queryParams = { prescrizioneId: this.id, prescrizioneType: this.prescrizioneType, prescrizioneAction: this.action };

        this.router.navigate([url], { queryParams: queryParams });
    }

    onSelectPrescrittore(id: string): void {
        let prescrittore = this.prescrittori.find(p => p.id == Number(id));

        if (prescrittore) {
            this.form.patchValue({
                struttura: prescrittore.struttura && prescrittore.nome ? prescrittore.nome : '',
                reparto: prescrittore.reparto && prescrittore.nome ? prescrittore.nome : ''
            });
        }
    }

    onShowMaschereModal(): void {
        this.selectedMaschere = new Array<Maschera>();
        this.showMaschereModal = true;
    }

    onConfirmCloseMaschereModal(): void {
        const values = {
            'tipoMaschera': this.selectedMaschere[0].tipologia,
            'modelloMaschera': this.selectedMaschere[0].modello + ' ' + this.selectedMaschere[0].codice,
            'marcaMaschera': this.selectedMaschere[0].marca,
            'tagliaMaschera': this.selectedMaschere[0].taglia
        };
        this.form.patchValue(values);
        this.showMaschereModal = false;
    }

    getCommerciali(): Array<Incaricato> {
        return this.incaricati ? this.incaricati.filter(i => i.tipoIncaricato == TipoIncaricato.COMMERCIALE) : [];
    }

    toggleShowBlock(block: string) {
        this[block] = !this[block];
    }

    isFormButtonDisabled(): boolean {
        return this.action != this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.ANNULLAMENTO && (!this.form.valid || (this.action === this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.EFFETTUAZIONE && !this.risultato));
    }

    showFormButton(): boolean {
        return this.action &&
            (this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.CONFERMA_MODIFICA ||
                this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.CONFERMA_RINNOVO ||
                this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.ANNULLAMENTO ||
                this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.ATTIVAZIONE ||
                this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.AUTORIZZAZIONE ||
                this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.PROROGA ||
                this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.CONFERMA_PROROGA ||
                this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RICHIESTA_SOSPENSIONE ||
                this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.SOSPENSIONE ||
                this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RINNOVO ||
                this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RICHIESTA_CESSAZIONE ||
                this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.CESSAZIONE ||
                this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RICHIESTA_RIATTIVAZIONE ||
                this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.RIATTIVAZIONE ||
                this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.ANNULLAMENTO_RICHIESTA ||
                this.action == this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.EFFETTUAZIONE);
    }
}
