import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'importStatus'
})
export class ImportStatusPipe implements PipeTransform {

  transform(value: number): any {
    switch (value) {
        case 0:
            return 'CREATO';
        case 1:
            return 'INIZIATO';
        case 2:
            return 'ERRORE';
        case 3:
            return 'COMPLETATO';
        case 4:
            return 'ATTENZIONE';
        default:
            return value;
    }
  }

}
