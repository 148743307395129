import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Contratto} from '../model/query/contratto';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {QueryResult} from '../model/query/query-result';
import {ResultUrl} from '../model/result-url';
import {ContrattoApprovatore} from '../model/query/contratto-approvatore';
import {ContrattoOperatore} from '../model/query/contratto-operatore';
import {ContrattoAppaltante} from '../model/query/contratto-appaltante';
import {ContrattoPartner} from '../model/query/contratto-partner';
import {ContrattoPrescrittore} from '../model/query/contratto-prescrittore';
import {ContrattoMaterialeConsumabile} from '../model/query/contratto-materiale-consumabile';
import {Ventilatore} from '../model/query/ventilatore';
import {ContrattoProfilo} from '../model/query/contratto-profilo';
import {ContrattoMaschera} from '../model/query/contratto-maschera';
import {PrescrizioneDocumento} from '../model/query/prescrizione-documento';
import {ContrattoFattura} from '../model/query/contratto-fattura';
import {ContrattoFatturaCommand} from '../model/command/contratto-fattura-command';
import {CodicePazienteResponse} from '../model/codice-paziente-response';
import {environment} from '../../environments/environment';

@Injectable()
export class ContrattiService extends BaseService<Contratto> {

    private storageBasePath: string = environment.storageUrl;

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('contratti');
    }
    
    getCodicePaziente(idContratto: string, idPaziente: string): Observable<CodicePazienteResponse> {
        return this.httpClient.get<CodicePazienteResponse>(this.apiUrl + '/' + idContratto + '/codice-paziente/' + idPaziente).pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

    getContrattiAzienda(aziendaId: number): Observable<Contratto[]> {
        return this.getAll(0, 1000, ["aziendaForeignKey"], ["eq"], [aziendaId], null, null).pipe(
            switchMap((result: QueryResult<Contratto>) => {
                return of(result.objects);
            }),
        );
    }

    getAssignedApprovatori(idContratto: string, page: number, size: number): Observable<ContrattoApprovatore[]> {
        const params = new HttpParams()
            .set('page', page.toString() ? page.toString() : '0')
            .set('size', size.toString() ? size.toString() : '100000000');
        return this.httpClient.get<ContrattoApprovatore[]>(this.apiUrl + '/' + idContratto + '/approvatori', {params: params}).pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

    getAssignedOperatori(idContratto: string, page: number, size: number): Observable<ContrattoOperatore[]> {
        const params = new HttpParams()
            .set('page', page.toString() ? page.toString() : '0')
            .set('size', size.toString() ? size.toString() : '100000000');
        return this.httpClient.get<ContrattoOperatore[]>(this.apiUrl + '/' + idContratto + '/operatori', {params: params}).pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

    getAssignedAppaltanti(idContratto: string, page: number, size: number): Observable<ContrattoAppaltante[]> {
        const params = new HttpParams()
            .set('page', page.toString() ? page.toString() : '0')
            .set('size', size.toString() ? size.toString() : '100000000');
        return this.httpClient.get<ContrattoAppaltante[]>(this.apiUrl + '/' + idContratto + '/appaltanti', {params: params}).pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

    getAssignedPartners(idContratto: string, page: number, size: number): Observable<ContrattoPartner[]> {
        const params = new HttpParams()
            .set('page', page.toString() ? page.toString() : '0')
            .set('size', size.toString() ? size.toString() : '100000000');
        return this.httpClient.get<ContrattoPartner[]>(this.apiUrl + '/' + idContratto + '/partners', {params: params}).pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

    getAssignedPrescrittori(idContratto: string, page: number, size: number): Observable<ContrattoPrescrittore[]> {
        const params = new HttpParams()
            .set('page', page.toString() ? page.toString() : '0')
            .set('size', size.toString() ? size.toString() : '100000000');
        return this.httpClient.get<ContrattoPrescrittore[]>(this.apiUrl + '/' + idContratto + '/prescrittori', {params: params}).pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

    getMateriali(idContratto: string): Observable<ContrattoMaterialeConsumabile[]> {
        return this.httpClient.get<ContrattoMaterialeConsumabile[]>(this.apiUrl + '/' + idContratto + '/materiali').pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

    getProfili(idContratto: string): Observable<ContrattoProfilo[]> {
        return this.httpClient.get<ContrattoProfilo[]>(this.apiUrl + '/' + idContratto + '/profili').pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

    getMaschere(idContratto: string): Observable<ContrattoMaschera[]> {
        return this.httpClient.get<ContrattoMaschera[]>(this.apiUrl + '/' + idContratto + '/maschere').pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

    getVentilatori(idContratto: string): Observable<Ventilatore[]> {
        return this.httpClient.get<Ventilatore[]>(this.apiUrl + '/' + idContratto + '/ventilatori').pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

    aggiungiFattura(contrattoId: string, file: File, command: ContrattoFatturaCommand): Observable<number> {
        let id: number;

        return this.httpClient.post(this.apiUrl + '/' + contrattoId + '/fatture', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<ContrattoFattura> => {
                id = this.extractIdFromLocation(res);
                if (id) {
                    return this.httpClient.get<ContrattoFattura>(this.apiUrl + '/' + contrattoId + '/fatture/' + id);
                } else {
                    return of(null);
                }
            }),
            switchMap((fattura: ContrattoFattura) => {
                if (fattura != null) {
                    return this.httpClient.put(this.storageBasePath + fattura.fileUrl, file);
                } else {
                    return of(null);
                }
            }),
            switchMap(() => {
                return of(id);
            }),
            catchError(this.handleError(' - aggiungiFattura', null))
        );
    }

    eliminaFattura(contrattoId: string, fatturaId: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/' + contrattoId + '/fatture/' + fatturaId).pipe(
            catchError(this.handleError(this.apiUrl + ' - eliminaFattura', null))
        );
    }

    getFatture(id: string): Observable<Array<PrescrizioneDocumento>> {
        return this.httpClient.get<Array<PrescrizioneDocumento>>(this.apiUrl + '/' + id + '/fatture').pipe(
            catchError(this.handleError(this.apiUrl + ' - getFatture', null))
        );
    }

    getFatturaDownloadUrl(fatturaId: string): Observable<string> {
        return this.httpClient.get<ResultUrl>(this.apiUrl + '/fatture/' + fatturaId + '/download-url').pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError(this.apiUrl + ' - getFatturaDownloadUrl', null))
        );
    }
}
