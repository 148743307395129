import {AbstractCommand} from './abstract-command';

export class PrescrizioneNotaCommand extends AbstractCommand {
    prescrizioneId: string;
    testo: string;

    constructor(prescrizioneId: string, testo: string) {
        super();
        this.prescrizioneId = prescrizioneId;
        this.testo = testo;
    }
}
