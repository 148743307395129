import {Component} from '@angular/core';
import {ModalitaVentilatorieService} from '../../core/modalita-ventilatorie.service';
import {Router} from '@angular/router';
import {DatagridComponent} from '../common/datagrid.component';
import {ModalitaVentilatoria} from '../../model/query/modalita-ventilatoria';
import {Action} from '../../model/action';
import {ActionService} from '../../core/action.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-modalita-ventilatorie',
    templateUrl: './modalita-ventilatorie.component.html',
    styleUrls: ['./modalita-ventilatorie.component.css']
})
export class ModalitaVentilatorieComponent extends DatagridComponent<ModalitaVentilatoria> {

    constructor(
        private modalitaVentilatorieService: ModalitaVentilatorieService,
        public actionService: ActionService,
        dialog: DialogService,
        router: Router
    ) {
        super(modalitaVentilatorieService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/modalita-ventilatorie/';
    }

    onAction(entity: ModalitaVentilatoria, action: Action) {
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }



}
