import {Component} from '@angular/core';
import {KitMaterialiService} from '../../core/kit-materiali.service';
import {Router} from '@angular/router';
import {DatagridComponent} from '../common/datagrid.component';
import {KitMateriale} from '../../model/query/kit-materiale';
import {Action} from '../../model/action';
import {ActionService} from '../../core/action.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-kit-materiali',
    templateUrl: './kit-materiali.component.html',
    styleUrls: ['./kit-materiali.component.css']
})
export class KitMaterialiComponent extends DatagridComponent<KitMateriale> {

    constructor(
        private kitMaterialiService: KitMaterialiService,
        public actionService: ActionService,
        dialog: DialogService,
        router: Router
    ) {
        super(kitMaterialiService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/kit-materiali/';
    }

    onAction(entity: KitMateriale, action: Action) {
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }



}
