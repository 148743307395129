export class GetAllRequest {
    fields: Array<string>;
    operators: Array<string>;
    values: Array<any>;
    sort: string;
    sortType: string;
    page: number;
    size: number;

    constructor(fields: Array<string>, operators: Array<string>, values: Array<any>, sort: string, sortType: string, page: number, size: number) {
        this.fields = fields;
        this.operators = operators;
        this.values = values;
        this.sort = sort;
        this.sortType = sortType;
        this.page = page;
        this.size = size;
    }
}
