import {Component} from '@angular/core';
import {Action} from '../../model/action';
import {DatagridComponent} from '../common/datagrid.component';
import {Saturimetro} from '../../model/query/saturimetro';
import {Router} from '@angular/router';
import {ActionService} from '../../core/action.service';
import {SaturimetriService} from '../../core/saturimetri.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-saturimetri',
    templateUrl: './saturimetri.component.html',
    styleUrls: ['./saturimetri.component.css']
})
export class SaturimetriComponent extends DatagridComponent<Saturimetro> {

    constructor(
        private saturimetriService: SaturimetriService,
        public actionService: ActionService,
        dialog: DialogService,
        router: Router
    ) {
        super(saturimetriService, router, dialog);
    }

    onAction(entity: Saturimetro, action: Action) {
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }

    protected getEditUrl(): string {
        return '/saturimetri/';
    }
}
