import {Injectable} from '@angular/core';
import {ReportCostiCommand} from '../model/command/report-costi-command';
import {catchError, switchMap} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, of, of as observableOf} from 'rxjs';
import {NavigationExtras, Router} from '@angular/router';
import {ResultUrl} from '../model/result-url';
import {ReportWithPrescrittoreAndDistrettoCommand} from '../model/command/report-with-prescrittore-and-distretto-command';
import {ReportWithPrescrittoreAndDistrettoDownloadCommand} from '../model/command/report-with-prescrittore-and-distretto-download-command';
import {ReportCommand} from '../model/command/report-command';
import {ReportCostiDownloadCommand} from '../model/command/report-costi-download-command';
import {ReportComplianceCommand} from '../model/command/report-compliance-command';
import {ReportComplianceDownloadCommand} from '../model/command/report-compliance-download-command';
import {ReportPazientiCommand} from '../model/command/report-pazienti-command';
import {ReportPazientiDownloadCommand} from '../model/command/report-pazienti-download-command';
import {ReportConsegneCommand} from '../model/command/report-consegne-command';
import {ReportTaskCommand} from '../model/command/report-task-command';
import {ReportPazientiVentCommand} from '../model/command/report-pazienti-vent-command';
import {ReportPazientiVentDownloadCommand} from '../model/command/report-pazienti-vent-download-command';

@Injectable({
    providedIn: 'root'
})
export class ReportsService {

    private baseApiUrl = environment.apiUrl;

    constructor(private httpClient: HttpClient, private router: Router) {
    }

    fetchReportCosti(command: ReportCostiCommand) {
        return this.httpClient.post<any>(this.baseApiUrl + 'report/get-report-costi', command).pipe(
            catchError(this.handleError('reports service - get-report-costi', null))
        );
    }

    fetchReportCostiDistinctPazientiCount(command: ReportCostiCommand) {
        return this.httpClient.post<any>(this.baseApiUrl + 'report/get-report-costi/distinct-pazienti-count', command).pipe(
            catchError(this.handleError('reports service - get-report-costi/distinct-pazienti-count', null))
        );
    }

    getReportCostiDownloadLink(command: ReportCostiDownloadCommand) {
        return this.httpClient.post<ResultUrl>(this.baseApiUrl + 'report/download-report-costi', command).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError('reports service - download-report-costi', null))
        );
    }

    fetchReportCompliance(command: ReportComplianceCommand) {
        return this.httpClient.post<any>(this.baseApiUrl + 'report/get-report-compliance', command).pipe(
            catchError(this.handleError('reports service - get-report-compliance', null))
        );
    }

    getReportComplianceDownloadLink(command: ReportComplianceDownloadCommand) {
        return this.httpClient.post<ResultUrl>(this.baseApiUrl + 'report/download-report-compliance', command).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError('reports service - download-report-compliance', null))
        );
    }

    fetchReportPazienti(command: ReportPazientiCommand) {
        return this.httpClient.post<any>(this.baseApiUrl + 'report/get-report-pazienti', command).pipe(
            catchError(this.handleError('reports service - get-report-pazienti', null))
        );
    }

    getReportPazientiDownloadLink(command: ReportPazientiDownloadCommand) {
        return this.httpClient.post<ResultUrl>(this.baseApiUrl + 'report/download-report-pazienti', command).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError('reports service - download-report-pazienti', null))
        );
    }

    fetchReportPazientiAttivi(command: ReportWithPrescrittoreAndDistrettoCommand) {
        return this.httpClient.post<any>(this.baseApiUrl + 'report/get-report-pazienti-attivi', command).pipe(
            catchError(this.handleError('reports service - get-report-pazienti-attivi', null))
        );
    }

    getReportPazientiAttiviDownloadLink(command: ReportWithPrescrittoreAndDistrettoDownloadCommand) {
        return this.httpClient.post<ResultUrl>(this.baseApiUrl + 'report/download-report-pazienti-attivi', command).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError('reports service - download-report-pazienti-attivi', null))
        );
    }

    fetchReportPazientiAttiviOra(command: ReportWithPrescrittoreAndDistrettoCommand) {
        return this.httpClient.post<any>(this.baseApiUrl + 'report/get-report-pazienti-attivi-ora', command).pipe(
            catchError(this.handleError('reports service - get-report-pazienti-attivi-ora', null))
        );
    }

    getReportPazientiAttiviOraDownloadLink(command: ReportWithPrescrittoreAndDistrettoDownloadCommand) {
        return this.httpClient.post<ResultUrl>(this.baseApiUrl + 'report/download-report-pazienti-attivi-ora', command).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError('reports service - download-report-pazienti-attivi-ora', null))
        );
    }

    fetchReportPazientiAttivati(command: ReportWithPrescrittoreAndDistrettoCommand) {
        return this.httpClient.post<any>(this.baseApiUrl + 'report/get-report-pazienti-attivati', command).pipe(
            catchError(this.handleError('reports service - get-report-pazienti-attivati', null))
        );
    }

    getReportPazientiAttivatiDownloadLink(command: ReportWithPrescrittoreAndDistrettoDownloadCommand) {
        return this.httpClient.post<ResultUrl>(this.baseApiUrl + 'report/download-report-pazienti-attivati', command).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError('reports service - download-report-pazienti-attivati', null))
        );
    }

    fetchReportConsegne(command: ReportConsegneCommand) {
        return this.httpClient.post<any>(this.baseApiUrl + 'report/get-report-consegne', command).pipe(
            catchError(this.handleError('reports service - get-report-consegne', null))
        );
    }

    getReportConsegneDownloadLink(command: ReportConsegneCommand) {
        return this.httpClient.post<ResultUrl>(this.baseApiUrl + 'report/download-report-consegne', command).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError('reports service - download-report-consegne', null))
        );
    }

    fetchReportScadenze(command: ReportCommand) {
        return this.httpClient.post<any>(this.baseApiUrl + 'report/get-report-scadenze', command).pipe(
            catchError(this.handleError('reports service - get-report-scadenze', null))
        );
    }

    getReportScadenzeDownloadLink(command: ReportCommand) {
        return this.httpClient.post<ResultUrl>(this.baseApiUrl + 'report/download-report-scadenze', command).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError('reports service - download-report-scadenze', null))
        );
    }

    fetchReportPazientiDeceduti(command: ReportWithPrescrittoreAndDistrettoCommand) {
        return this.httpClient.post<any>(this.baseApiUrl + 'report/get-report-pazienti-deceduti', command).pipe(
            catchError(this.handleError('reports service - get-report-pazienti-deceduti', null))
        );
    }

    getReportPazientiDecedutiDownloadLink(command: ReportWithPrescrittoreAndDistrettoDownloadCommand) {
        return this.httpClient.post<ResultUrl>(this.baseApiUrl + 'report/download-report-pazienti-deceduti', command).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError('reports service - download-report-pazienti-deceduti', null))
        );
    }

    getReportPrescrizioneDownloadLink(tipo: string, id: string) {
        return this.httpClient.post<ResultUrl>(this.baseApiUrl + 'report/download-report-prescrizione/' + tipo + '/' + id, {}).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError('reports service - download-report-prescrizione', null))
        );
    }

    fetchReportTask(command: ReportTaskCommand) {
        return this.httpClient.post<any>(this.baseApiUrl + 'report/get-report-task-calendar', command).pipe(
            catchError(this.handleError('reports service - get-report-task-calendar', null))
        );
    }

    fetchReportPlanning(command: ReportTaskCommand) {
        return this.httpClient.post<any>(this.baseApiUrl + 'report/get-report-task-planning', command).pipe(
            catchError(this.handleError('reports service - get-report-task-planning', null))
        );
    }

    fetchReportPazientiVent(command: ReportPazientiVentCommand) {
        return this.httpClient.post<any>(this.baseApiUrl + 'report/get-report-pazienti-vent', command).pipe(
            catchError(this.handleError('reports service - get-report-pazienti-vent', null))
        );
    }

    getReportPazientiVentDownloadLink(command: ReportPazientiVentDownloadCommand) {
        return this.httpClient.post<ResultUrl>(this.baseApiUrl + 'report/download-report-pazienti-vent', command).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError('reports service - download-report-pazienti-vent', null))
        );
    }

    fetchReportUtenti() {
        return this.httpClient.post<any>(this.baseApiUrl + 'report/get-report-utenti', {}).pipe(
            catchError(this.handleError('reports service - get-report-utenti', null))
        );
    }

    getReportUtentiDownloadLink() {
        return this.httpClient.post<ResultUrl>(this.baseApiUrl + 'report/download-report-utenti', {}).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError('reports service - download-report-utenti', null))
        );
    }

    getReportElencoAttivitaDownloadLink() {
        return this.httpClient.post<ResultUrl>(this.baseApiUrl + 'report/download-report-elenco-attivita', {}).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError('reports service - download-report-elenco-attivita', null))
        );
    }

    private handleError<T>(operation = 'operation', result?: T, errorMessage?: string) {
        return (error: any): Observable<T> => {

            console.error(operation + ' failed with: ' + error);
            console.log(error);

            if (error.status === 500) {
                const navigationExtras: NavigationExtras = {
                    queryParams: {'error_status': error.status, 'error_message': error.error.message}
                };
                this.router.navigate(['/error'], navigationExtras);
            } else if (errorMessage) {
                this.openErrorMessageWindow(errorMessage);
            } else {
                this.openErrorMessageWindow(error.error ? error.error.message : 'Error ' + error.status);
            }
            return observableOf(result as T);
        };
    }

    private openErrorMessageWindow(message: string): void {
        alert(message);
    }

}
