import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {MaschereService} from '../../../core/maschere.service';
import {Maschera} from '../../../model/query/maschera';
import {MascheraCommand} from '../../../model/command/maschera-command';
import {ActionService} from '../../../core/action.service';
import {UtilsService} from '../../../core/utils.service';
import {Action} from '../../../model/action';

@Component({
    selector: 'app-maschera-detail',
    templateUrl: './maschera-detail.component.html'
})
export class MascheraDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    alertMessage: string;
    alertClosed = true;
    action: Action;
    readOnly = false;

    constructor(
        private route: ActivatedRoute,
        private maschereService: MaschereService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        private utilsService: UtilsService,
        public actionService: ActionService,
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        if (this.id !== 'new') {
            this.maschereService.get(this.id).subscribe((maschera: Maschera) => {
                this.initializeFormFields(maschera);
                if (this.action != null && this.action == this.actionService.ACTION.VIEW) {
                    this.readOnly = true;
                }
            });
        } else {
            this.initializeFormFields(null);
        }
    }

    createForm(): void {
        const formGroup = {
            codice: ['', [Validators.required, Validators.maxLength(255)]],
            tipologia: ['', [Validators.required, Validators.maxLength(255)]],
            modello: ['', [Validators.required, Validators.maxLength(255)]],
            marca: ['', [Validators.required, Validators.maxLength(255)]],
            taglia: ['', [Validators.required, Validators.maxLength(255)]]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(maschera: Maschera): void {
        let formValues;

        if (maschera) {
            formValues = {
                codice: maschera.codice,
                tipologia: maschera.tipologia,
                modello: maschera.modello,
                marca: maschera.marca,
                taglia: maschera.taglia
            };
        } else {
            formValues = {
                codice: '',
                tipologia: '',
                modello: '',
                marca: '',
                taglia: ''
            };
        }
        this.form.setValue(formValues);
    }


    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command: MascheraCommand = new MascheraCommand(formValue.codice, formValue.tipologia, formValue.modello, formValue.marca, formValue.taglia);
        
        if (this.id === 'new') {
            this.maschereService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'La maschera è stata creata';
                    this.alertClosed = false;
                }
            });
        } else {
            this.maschereService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'La maschera è stata aggiornata';
                    this.alertClosed = false;
                }
            });
        }

    }
}
