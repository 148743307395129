import {Component} from '@angular/core';
import {MaschereService} from '../../core/maschere.service';
import {Router} from '@angular/router';
import {DatagridComponent} from '../common/datagrid.component';
import {Maschera} from '../../model/query/maschera';
import {Action} from '../../model/action';
import {ActionService} from '../../core/action.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-maschere',
    templateUrl: './maschere.component.html'
})
export class MaschereComponent extends DatagridComponent<Maschera> {

    constructor(
        private maschereService: MaschereService,
        public actionService: ActionService,
        dialog: DialogService,
        router: Router
    ) {
        super(maschereService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/maschere/';
    }

    onAction(entity: Maschera, action: Action) {
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }



}
