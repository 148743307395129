import {Component, Input} from '@angular/core';
import {Subject} from 'rxjs';
import {ClrDatagridFilter, ClrDatagridFilterInterface} from '@clr/angular';
import {AppaltiService} from '../core/appalti.service';
import {Appalto} from '../model/query/appalto';

@Component({
    selector: 'app-appalto-filter',
    templateUrl: './appalto.filter.component.html'
})
export class AppaltoFilterComponent implements ClrDatagridFilterInterface<any> {
    state: {
    };
    @Input('attribute') attribute: string;
    
    appalti: Array<Appalto> = new Array<Appalto>();
    
    constructor(private filterContainer: ClrDatagridFilter, private appaltiService: AppaltiService) {
        filterContainer.setFilter(this);
        this.appaltiService.getAll(0, 1000, [], [], [], "dataStipula", "asc").subscribe(appalti => this.appalti = appalti.objects);
    }
    
    accepts(transaction: any) {
        return false;
    }

    changes = new Subject<any>();
    
    isActive(): boolean {
        return true;
    }

    onValueChange(param: string) {
        if (param) {
            this.state = {
                property: this.attribute,
                value: param
            }
        } else {
            this.state = {};
        }
        this.changes.next(this.state);
    }
}