import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {PrescrittoriEsterniService} from '../../../core/prescrittori-esterni.service';
import {ClrLoadingState} from '@clr/angular';
import {PrescrittoreEsterno} from '../../../model/query/prescrittore-esterno';
import {PrescrittoreEsternoCommand} from '../../../model/command/prescrittore-esterno-command';
import {Action} from '../../../model/action';
import {ActionService} from '../../../core/action.service';

@Component({
    selector: 'app-prescrittore-esterno-detail',
    templateUrl: './prescrittore-esterno-detail.component.html',
    styleUrls: ['./prescrittore-esterno-detail.component.css']
})
export class PrescrittoreEsternoDetailComponent implements OnInit {

    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    fromPrescrizione: string;
    prescrittore: PrescrittoreEsterno;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;

    constructor(
        private location: Location,
        private route: ActivatedRoute,
        private router: Router,
        public prescrittoriEsterniService: PrescrittoriEsterniService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        public actionService: ActionService,
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.fromPrescrizione = this.route.snapshot.queryParamMap.get('fromPrescrizione');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        if (this.id !== 'new') {
            this.prescrittoriEsterniService.get(this.id).subscribe((prescrittore) => {
                this.prescrittore = prescrittore;
                this.initializeFormFields(prescrittore);
            });
        } else {
            this.initializeFormFields(null);
        }
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command = new PrescrittoreEsternoCommand(
            formValue.nome
        );
        if (!this.id || this.id === 'new') {
            this.prescrittoriEsterniService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'Prescrittore esterno salvato!';
                    this.alertClosed = false;
                }
                if (this.fromPrescrizione == 'true') {
                    this.location.back();
                }
            });
        } else {
            this.prescrittoriEsterniService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Prescrittore esterno salvato!';
                    this.alertClosed = false;
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(prescrittore: PrescrittoreEsterno): void {
        let formValues;

        if (prescrittore) {
            formValues = {
                nome: prescrittore.nome
            };
        } else {
            formValues = {
                nome: ''
            };
        }
        this.form.setValue(formValues);
    }

}
