import {Component} from '@angular/core';
import {ParametriVentilatoreService} from '../../core/parametri-ventilatore.service';
import {Router} from '@angular/router';
import {DatagridComponent} from '../common/datagrid.component';
import {ParametroVentilatore} from '../../model/query/parametro-ventilatore';
import {Action} from '../../model/action';
import {ActionService} from '../../core/action.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-parametri-ventilatore',
    templateUrl: './parametri-ventilatore.component.html',
    styleUrls: ['./parametri-ventilatore.component.css']
})
export class ParametriVentilatoreComponent extends DatagridComponent<ParametroVentilatore> {

    constructor(
        private parametriVentilatoreService: ParametriVentilatoreService,
        public actionService: ActionService,
        dialog: DialogService,
        router: Router
    ) {
        super(parametriVentilatoreService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/parametri-ventilatore/';
    }

    onAction(entity: ParametroVentilatore, action: Action) {
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }



}
