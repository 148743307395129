import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {AttivitaTitolazione} from '../model/query/attivita-titolazione';
import {AttivitaTitolazioneCommand} from '../model/command/attivita-titolazione';
import {Router} from '@angular/router';
import {ResultUrl} from '../model/result-url';

@Injectable()
export class AttivitaTitolazioneService extends BaseService<AttivitaTitolazione> {
    attivitaTitolazione: Array<AttivitaTitolazione>;
    
    private storageBasePath: string = environment.storageUrl;
    
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('attivita-titolazione');
    }

    aggiungiDocumento(id: string, file: File, fileName: string): Observable<number> {
        return this.httpClient.post(this.apiUrl + '/' + id + '/documento', {fileName: fileName}, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                return this.httpClient.get<AttivitaTitolazione>(this.apiUrl + '/' + id);
            }),
            switchMap((attivita: AttivitaTitolazione) => {
                if (attivita != null) {
                    return this.httpClient.put(this.storageBasePath + attivita.fileUrl, file);
                } else {
                    return of(null);
                }
            }),
            switchMap(() => {
                return of(id);
            }),
            catchError(this.handleError(' - aggiungiDocumento', null))
        );
    }

    annulla(id: string, command: AttivitaTitolazioneCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + id + '/annulla', command).pipe(
            catchError(this.handleError(this.apiUrl + ' - annulla', null))
        );
    }

    getDocumentDownloadUrl(id: string): Observable<string> {
        return this.httpClient.get<ResultUrl>(this.apiUrl + '/' + id + '/download-url').pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError(this.apiUrl + ' - getDocumentoDownloadUrl', null))
        );
    }

    getNumeroAttivitaDaProcessare() {
        return this.httpClient.get(this.apiUrl + '/da-processare').pipe(
            catchError(this.handleError(this.apiUrl + ' - da processare', null))
        );
    }

}
