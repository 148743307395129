import {PrescrizioneConScadenzaCommand} from './prescrizione-con-scadenza-command';

export class PrescrizioneVentilazioneCommand extends PrescrizioneConScadenzaCommand {
    consegnaPressoStruttura: boolean;
    ccf: string;
    incaricatoId: number;

    constructor(
        prescrittoreId: number,
        prescrittoreEsternoId: number,
        pazienteId: number,
        contrattoId: number,
        dataRicezione: Date,
        dataPrescrizione: Date,
        validita: number,
        dataScadenza: Date,
        dataAttivazione: Date,
        dataRiattivazione: Date,
        dataEGAInAria: Date,
        phEGAInAria: number,
        o2EGAInAria: number,
        co2EGAInAria: number,
        co3EGAInAria: number,
        dataEGASuppl: Date,
        phEGASuppl: number,
        o2EGASuppl: number,
        co2EGASuppl: number,
        co3EGASuppl: number,
        dataWalkingTest: Date,
        risultatoWalkingTest: number,
        dataSaturimetria: Date,
        risultatoSaturimetria: number,
        bpco55: boolean,
        bpco60: boolean,
        bpco60Ipossiemia: boolean,
        bpco60Policitemia: boolean,
        bpco60Edemi: boolean,
        bpco60Ipertensione: boolean,
        fc55: boolean,
        fc60: boolean,
        fc60Ipossiemia: boolean,
        fc60Policitemia: boolean,
        fc60Edemi: boolean,
        fc60Ipertensione: boolean,
        malattieInterstiziali: boolean,
        ipertensionePolmonare: boolean,
        neoplasieMaligne: boolean,
        patologieNeuro: boolean,
        apneaOstruttiva: boolean,
        scompensoCardiaco: boolean,
        desaturazioneNotturna: boolean,
        desaturazioneEsercizio: boolean,
        osas: boolean,
        osasAltra: boolean,
        poliomielite: boolean,
        miopatieCongenite: boolean,
        miopatieMitocondriali: boolean,
        distrofiaMuscolare: boolean,
        guillainBarre: boolean,
        sla: boolean,
        atrofiaMuscolareSpinale: boolean,
        paralisiDiaframma: boolean,
        lesioniNervoFrenico: boolean,
        cifoscoliosi: boolean,
        deformitaToracica: boolean,
        ipoventilazioneCongenita: boolean,
        ipoventilazioneCentrale: boolean,
        leigh: boolean,
        ipoventilazionePostChirurgica: boolean,
        ipoventilazionePostRadioterapia: boolean,
        ipoventilazioneNeoplasie: boolean,
        ipoventilazionePostTraumatica: boolean,
        bpco: boolean,
        bronchiectasie: boolean,
        fibrosiCistica: boolean,
        fibrosiPolmonare: boolean,
        malattieCardiacheCongenite: boolean,
        malattieCardiacheAcquisite: boolean,
        diagnosiAltra: boolean,
        descrizioneDiagnosi: string,
        codice: string,
        codicePartner: string,
        codiceAutorizzazione: string,
        consegnaPressoStruttura: boolean,
        ccf: string,
        incaricatoId: number
    ) {
        super(
            prescrittoreId,
            prescrittoreEsternoId,
            pazienteId,
            contrattoId,
            dataRicezione,
            dataPrescrizione,
            validita,
            dataScadenza,
            dataAttivazione,
            dataRiattivazione,
            dataEGAInAria,
            phEGAInAria,
            o2EGAInAria,
            co2EGAInAria,
            co3EGAInAria,
            dataEGASuppl,
            phEGASuppl,
            o2EGASuppl,
            co2EGASuppl,
            co3EGASuppl,
            dataWalkingTest,
            risultatoWalkingTest,
            dataSaturimetria,
            risultatoSaturimetria,
            bpco55,
            bpco60,
            bpco60Ipossiemia,
            bpco60Policitemia,
            bpco60Edemi,
            bpco60Ipertensione,
            fc55,
            fc60,
            fc60Ipossiemia,
            fc60Policitemia,
            fc60Edemi,
            fc60Ipertensione,
            malattieInterstiziali,
            ipertensionePolmonare,
            neoplasieMaligne,
            patologieNeuro,
            apneaOstruttiva,
            scompensoCardiaco,
            desaturazioneNotturna,
            desaturazioneEsercizio,
            osas, osasAltra, poliomielite, miopatieCongenite, miopatieMitocondriali, distrofiaMuscolare, guillainBarre, sla,
            atrofiaMuscolareSpinale, paralisiDiaframma, lesioniNervoFrenico, cifoscoliosi, deformitaToracica, ipoventilazioneCongenita,
            ipoventilazioneCentrale, leigh, ipoventilazionePostChirurgica, ipoventilazionePostRadioterapia, ipoventilazioneNeoplasie,
            ipoventilazionePostTraumatica, bpco, bronchiectasie, fibrosiCistica, fibrosiPolmonare, malattieCardiacheCongenite,
            malattieCardiacheAcquisite, diagnosiAltra, descrizioneDiagnosi,
            codice,
            codicePartner,
            codiceAutorizzazione
        );
        this.consegnaPressoStruttura = consegnaPressoStruttura;
        this.ccf = ccf;
        this.incaricatoId = incaricatoId;
    }
}
