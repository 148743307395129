import {ChiusuraTaskConFirmaDto} from './chiusura-task-con-firma-dto';

export class ChiusuraTaskAccessoStraordinarioDto extends ChiusuraTaskConFirmaDto {
    oreFunzionamento: number;
    reperibilita: boolean;
    anomaliaSegnalata: string;
    anomaliaRiscontrata: string;
    intervento: string;
    nuovaMatricola: string;
    risoltoTelefonicamente: boolean;
    
    constructor(
        dataCompletamento: Date,
        completato: boolean,
        note: string,
        firmaTecnico: string,
        firmaAssistito: string,
        firmaDelegato: string,
        oreFunzionamento: number,
        reperibilita: boolean,
        anomaliaSegnalata: string,
        anomaliaRiscontrata: string,
        intervento: string,
        nuovaMatricola: string,
        risoltoTelefonicamente: boolean
    ) {
        super(dataCompletamento, completato, note, firmaTecnico, firmaAssistito, firmaDelegato);
        this.oreFunzionamento = oreFunzionamento;
        this.reperibilita = reperibilita;
        this.anomaliaSegnalata = anomaliaSegnalata;
        this.anomaliaRiscontrata = anomaliaRiscontrata;
        this.intervento = intervento;
        this.nuovaMatricola = nuovaMatricola;
        this.risoltoTelefonicamente = risoltoTelefonicamente;
    }
}
