import {from as observableFrom, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

declare let Keycloak: any;

@Injectable()
export class AuthService {
    private static auth: any = {};
    private static roleName: any = {
        'amministratore': 'Amministratore',
        'prescrittore': 'Prescrittore',
        'autorizzatore': 'Azienda sanitaria',
        'partner': 'Partner',
        'paziente': 'Paziente',
        'operatore': 'Operatore',
        'addetto_consegna': 'Addetto consegna',
        'addetto_produzione': 'Addetto produzione',
        'responsabile_produzione': 'Responsabile produzione',
        'manutentore': 'Manutentore',
        'magazziniere': 'Magazziniere',
        'commerciale': 'Commerciale',
        'tecnico_ventilazione': 'Tecnico ventilazione',
        'appaltante': 'Appaltante',
        'infermiere': 'Infermiere',
        'fornitore': 'Backoffice fornitore',
        'operatore_callcenter': 'Operatore call center'
    };
    private availableRoles = Object.keys(AuthService.roleName);

    static init(): Promise<any> {
        const keycloakAuth: any = Keycloak({
            url: environment.keycloakRootUrl,
            realm: 'domolife',
            clientId: 'domolife',
            'ssl-required': 'none',
            'public-client': true,
            responseMode: 'fragment',
            flow: 'standard'
        });

        AuthService.auth.loggedIn = false;

        return new Promise((resolve, reject) => {
            keycloakAuth.init({onLoad: 'login-required', checkLoginIframe: false})
                .success(() => {
                    AuthService.auth.loggedIn = true;
                    AuthService.auth.authz = keycloakAuth;
                    AuthService.auth.logoutUrl = keycloakAuth.authServerUrl
                        + '/realms/domolife/protocol/openid-connect/logout?redirect_uri='
                        + encodeURI(document.baseURI);
                    resolve(AuthService.auth);
                })
                .error(() => {
                    reject();
                });
        });
    }

    public constructor() {

    }

    public getLogoutUrl(): string {
        return AuthService.auth.logoutUrl;
    }

    public getUserId(): string {
        return AuthService.auth.authz.tokenParsed.sub;
    }

    public getUsername(): string {
        return AuthService.auth.authz.tokenParsed.preferred_username;
    }

    public getFullName(): string {
        return AuthService.auth.authz.tokenParsed.name;
    }

    public getFirstName(): string {
        return AuthService.auth.authz.tokenParsed.given_name;
    }

    public setFirstName(firstName: string): void {
        AuthService.auth.authz.tokenParsed.given_name = firstName;
    }

    public getLastName(): string {
        return AuthService.auth.authz.tokenParsed.family_name;
    }

    public setLastName(lastName: string): void {
        AuthService.auth.authz.tokenParsed.family_name = lastName;
    }

    public getEmail(): string {
        return AuthService.auth.authz.tokenParsed.email;
    }

    public setEmail(email: string): void {
        AuthService.auth.authz.tokenParsed.email = email;
    }

    public isAmministratoreUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('amministratore') > -1;
    }

    public isPrescrittoreUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('prescrittore') > -1;
    }

    public isApprovatoreUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('autorizzatore') > -1;
    }

    public isAppaltanteUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('appaltante') > -1;
    }

    public isPartnerUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('partner') > -1;
    }

    public isPazienteUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('paziente') > -1;
    }

    public isOperatoreUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('operatore') > -1;
    }

    public isOperatoreCallCenterUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('operatore_callcenter') > -1;
    }

    public isAddettoConsegnaUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('addetto_consegna') > -1;
    }

    public isAddettoProduzioneUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('addetto_produzione') > -1;
    }

    public isResponsabileProduzioneUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('responsabile_produzione') > -1;
    }

    public isManutentoreUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('manutentore') > -1;
    }

    public isMagazziniereUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('magazziniere') > -1;
    }

    public isCommercialeUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('commerciale') > -1;
    }

    public isTecnicoVentilazioneUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('tecnico_ventilazione') > -1;
    }

    public isInfermiereUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('infermiere') > -1;
    }

    public isFornitoreUser(): boolean {
        return AuthService.auth.authz.tokenParsed.roles.indexOf('fornitore') > -1;
    }

    public getRole(): string {
        return AuthService.auth.authz.tokenParsed.roles.find(r => r != 'offline_access' && r != 'uma_authorization' && r != 'default-roles-domolife');
    }

    public getRoleName(): string {
        return AuthService.roleName[this.getRole()];
    }

    public getToken(minValidity: number): Observable<any> {
        const promise = new Promise((resolve, reject) => {
            if (AuthService.auth.authz && AuthService.auth.authz.token) {
                AuthService.auth.authz.updateToken(minValidity)
                    .success(() => {
                        resolve(<string>AuthService.auth.authz.token);
                    })
                    .error(() => {
                        reject('Failed to refresh token');
                    });
            } else {
                reject('Not logged in');
            }
        });

        return observableFrom(promise);
    }

}
