import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Contenitore} from '../model/query/contenitore';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {QueryResult} from '../model/query/query-result';

@Injectable()
export class ContenitoriService extends BaseService<Contenitore> {
    contenitori: QueryResult<Contenitore>;
    
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('contenitori');
    }

    getContenitori(): Observable<QueryResult<Contenitore>> {
        if (this.contenitori) {
            return of(this.contenitori);
        } else {
            return this.httpClient.get<QueryResult<Contenitore>>(this.apiUrl).pipe(
                map((a: QueryResult<Contenitore>) => {
                    this.contenitori = a;
                    return a;
                }),
                catchError(this.handleError(this.apiUrl + '- : get contenitori', null))
            );
        }
    }

}
