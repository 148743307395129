import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../core/admin.service';
import { LoginEvent } from '../../model/query/login-event';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-login-events',
    templateUrl: './login-events.component.html'
})
export class LoginEventsComponent implements OnInit {
    searchControl: FormControl;
    debounce: number = 400;
    events: Array<LoginEvent>;
    filteredEvents: Array<LoginEvent>;
    currentPage: number = 1;

    constructor(
        private adminService: AdminService
    ) {
    }

    ngOnInit() {
        this.searchControl = new FormControl('');
        this.searchControl.valueChanges
            .pipe(debounceTime(this.debounce), distinctUntilChanged())
            .subscribe(searchUser => this.manageFilter(searchUser));
        this.adminService.getLoginEvents(0, 1000000).subscribe(events => {
            this.events = events;
            this.filteredEvents = events;
        });
    }

    manageFilter(searchUser: string): void {
        this.currentPage = 1;
        if (searchUser) {
            this.filteredEvents = this.events.filter(e => e.username.indexOf(searchUser) >= 0);
        } else {
            this.filteredEvents = this.events;
        }
    }
}
