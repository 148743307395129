import {Component, OnInit} from '@angular/core';
import {MeService} from '../../core/me.service';

@Component({
    selector: 'app-password',
    templateUrl: './password.component.html',
    styles: []
})
export class PasswordComponent implements OnInit {
    password: string;
    password2: string;
    alertMessage: string;
    alertClosed = true;

    constructor(private meService: MeService) {
    }

    ngOnInit() {
    }

    changePassword(): void {
        if (this.password && this.password === this.password2) {
            this.meService.changePassword(this.password).subscribe((res) => {
                if (!res) {
                    this.password = '';
                    this.password2 = '';
                    this.alertMessage = 'La password è stata cambiata con successo';
                    this.alertClosed = false;
                    window.scroll(0, 0);
                }
            });
        } else {
        }
    }
}
