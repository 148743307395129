import { Component, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Globals } from '../../../core/globals.service';
import { ClrLoadingState } from '@clr/angular';
import { Contratto } from '../../../model/query/contratto';
import { ContrattiService } from '../../../core/contratti.service';
import { Appalto } from '../../../model/query/appalto';
import { AppaltiService } from '../../../core/appalti.service';
import { ContrattoDto } from '../../../model/command/contratto-dto';
import { ContrattoPartnerDto } from '../../../model/command/contratto-partner-dto';
import { ContrattoCommand } from '../../../model/command/contratto-command';
import { UtilsService } from '../../../core/utils.service';
import { Approvatore } from '../../../model/query/approvatore';
import { ApprovatoriService } from '../../../core/approvatori.service';
import { Operatore } from '../../../model/query/operatore';
import { OperatoriService } from '../../../core/operatori.service';
import { Appaltante } from '../../../model/query/appaltante';
import { AppaltantiService } from '../../../core/appaltanti.service';
import { PartnersService } from '../../../core/partners.service';
import { PrescrittoriService } from '../../../core/prescrittori.service';
import { Prescrittore } from '../../../model/query/prescrittore';
import { ContrattoPartner } from '../../../model/query/contratto-partner';
import { ContrattoMaterialeConsumabile } from '../../../model/query/contratto-materiale-consumabile';
import { ContrattoProfilo } from '../../../model/query/contratto-profilo';
import { Maschera } from '../../../model/query/maschera';
import { ContrattoFatturaCommand } from '../../../model/command/contratto-fattura-command';
import { ContrattoFattura } from '../../../model/query/contratto-fattura';
import { environment } from '../../../../environments/environment';
import { Action } from '../../../model/action';
import { ActionService } from '../../../core/action.service';
import { MaterialiService } from '../../../core/materiali.service';
import { ProfiliService } from '../../../core/profili.service';
import { MaschereService } from '../../../core/maschere.service';

@Component({
    selector: 'app-contratto-detail',
    templateUrl: './contratto-detail.component.html',
    styleUrls: ['./contratto-detail.component.css']
})
export class ContrattoDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    contratto: Contratto;
    selectedAppalto: Appalto;
    appalti: Appalto[];
    serviziDescription: any;
    appaltoId: string;

    selectedLeftApprovatori: Approvatore[] = new Array<Approvatore>();
    selectedRightApprovatori: Approvatore[] = new Array<Approvatore>();
    rightApprovatori: Approvatore[] = new Array<Approvatore>();
    leftApprovatori: Approvatore[] = new Array<Approvatore>();

    selectedLeftOperatori: Operatore[] = new Array<Operatore>();
    selectedRightOperatori: Operatore[] = new Array<Operatore>();
    rightOperatori: Operatore[] = new Array<Operatore>();
    leftOperatori: Operatore[] = new Array<Operatore>();

    selectedLeftAppaltanti: Appaltante[] = new Array<Appaltante>();
    selectedRightAppaltanti: Appaltante[] = new Array<Appaltante>();
    rightAppaltanti: Appaltante[] = new Array<Appaltante>();
    leftAppaltanti: Appaltante[] = new Array<Appaltante>();

    selectedLeftPrescrittori: Prescrittore[] = new Array<Prescrittore>();
    selectedRightPrescrittori: Prescrittore[] = new Array<Prescrittore>();
    rightPrescrittori: Prescrittore[] = new Array<Prescrittore>();
    leftPrescrittori: Prescrittore[] = new Array<Prescrittore>();

    selectedLeftPartners: ContrattoPartner[] = new Array<ContrattoPartner>();
    selectedRightPartners: ContrattoPartner[] = new Array<ContrattoPartner>();
    rightPartners: ContrattoPartner[] = new Array<ContrattoPartner>();
    leftPartners: ContrattoPartner[] = new Array<ContrattoPartner>();

    selectedLeftMateriali: ContrattoMaterialeConsumabile[] = new Array<ContrattoMaterialeConsumabile>();
    selectedRightMateriali: ContrattoMaterialeConsumabile[] = new Array<ContrattoMaterialeConsumabile>();
    rightMateriali: ContrattoMaterialeConsumabile[] = new Array<ContrattoMaterialeConsumabile>();
    leftMateriali: ContrattoMaterialeConsumabile[] = new Array<ContrattoMaterialeConsumabile>();
    
    selectedLeftProfili: ContrattoProfilo[] = new Array<ContrattoProfilo>();
    selectedRightProfili: ContrattoProfilo[] = new Array<ContrattoProfilo>();
    rightProfili: ContrattoProfilo[] = new Array<ContrattoProfilo>();
    leftProfili: ContrattoProfilo[] = new Array<ContrattoProfilo>();

    selectedLeftMaschere: Maschera[] = new Array<Maschera>();
    selectedRightMaschere: Maschera[] = new Array<Maschera>();
    rightMaschere: Maschera[] = new Array<Maschera>();
    leftMaschere: Maschera[] = new Array<Maschera>();

    showPartnerModal = false;
    showMaterialeModal = false;
    showProfiloModal = false;
    fattura: File;
    fatture = new Array<ContrattoFattura>();
    storageBasePath: string;

    alertMessage: string;
    alertClosed = true;

    action: Action;
    readOnly = false;
    showAlertSwapModal = false;

    constructor(
        private route: ActivatedRoute,
        private contrattiService: ContrattiService,
        private appaltiService: AppaltiService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        private utilsService: UtilsService,
        private approvatoriService: ApprovatoriService,
        private operatoriService: OperatoriService,
        private appaltantiService: AppaltantiService,
        private partnersService: PartnersService,
        private prescrittoriService: PrescrittoriService,
        private materialiService: MaterialiService,
        private profiliService: ProfiliService,
        private maschereService: MaschereService,
        public actionService: ActionService,
    ) {
        this.createForm();
        this.setOnFieldsChange();
        this.appalti = new Array<Appalto>();
        this.serviziDescription = this.utilsService.getServiziDescriptionMap();
        this.storageBasePath = environment.storageUrl;
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
                if (params.appaltoId) {
                    this.appaltoId = params.appaltoId;
                }
            }
        });
        this.appaltiService.getAll(0, 10000000, [], [], [], null, null).subscribe(appalti => {
            this.appalti = appalti.objects;
            if (this.id !== 'new') {
                this.contrattiService.get(this.id).subscribe(contratto => {
                    this.contratto = contratto;
                    this.selectedAppalto = this.appalti.find(a => a.id == contratto.appaltoForeignKey);
                    this.initializeFormFields(contratto);
                    if (this.action != null && this.action == this.actionService.ACTION.VIEW) {
                        this.readOnly = true;
                    }
                    this.approvatoriService.getAll(0, 10000000, ['aziendaForeignKey'], ['eq'], [this.contratto.aziendaForeignKey], null, null).subscribe(approvatori => {
                        this.leftApprovatori = approvatori.objects;
                        this.contrattiService.getAssignedApprovatori(this.id, 0, 10000000).subscribe((contrattiApprovatori) => {
                            for (const obj of contrattiApprovatori) {
                                this.rightApprovatori.push(obj.approvatore);
                                this.utilsService.removeFromArray(obj.approvatore, this.leftApprovatori);
                            }
                        });
                    });
                    this.operatoriService.getAll(0, 10000000, null, null, null, null, null).subscribe(operatori => {
                        this.leftOperatori = operatori.objects;
                        this.contrattiService.getAssignedOperatori(this.id, 0, 10000000).subscribe((contrattiOperatori) => {
                            for (const obj of contrattiOperatori) {
                                this.rightOperatori.push(obj.operatore);
                                this.utilsService.removeFromArray(obj.operatore, this.leftOperatori);
                            }
                        });
                    });
                    this.appaltantiService.getAll(0, 10000000, ['aziendaForeignKey'], ['eq'], [this.contratto.aziendaForeignKey], null, null).subscribe(appaltanti => {
                        this.leftAppaltanti = appaltanti.objects;
                        this.contrattiService.getAssignedAppaltanti(this.id, 0, 10000000).subscribe((contrattiAppaltanti) => {
                            for (const obj of contrattiAppaltanti) {
                                this.rightAppaltanti.push(obj.appaltante);
                                this.utilsService.removeFromArray(obj.appaltante, this.leftAppaltanti);
                            }
                        });
                    });
                    this.prescrittoriService.getAll(0, 10000000, ['aziendaForeignKey'], ['eq'], [this.contratto.aziendaForeignKey], null, null).subscribe(prescrittori => {
                        this.leftPrescrittori = prescrittori.objects;
                        this.contrattiService.getAssignedPrescrittori(this.id, 0, 10000000).subscribe((contrattiPrescrittori) => {
                            for (const obj of contrattiPrescrittori) {
                                this.rightPrescrittori.push(obj.prescrittore);
                                this.utilsService.removeFromArray(obj.prescrittore, this.leftPrescrittori);
                            }
                        });
                    });
                    this.partnersService.getAll(0, 10000000, [], [], [], null, null).subscribe(partners => {
                        for (const obj of partners.objects) {
                            const cp = new ContrattoPartner();
                            cp.partner = obj;
                            cp.percentuale = 0;
                            this.leftPartners.push(cp);
                        }
                        this.contrattiService.getAssignedPartners(this.id, 0, 10000000).subscribe((contrattiPartners) => {
                            for (const obj of contrattiPartners) {
                                this.rightPartners.push(obj);
                                this.removeContrattoPartnerFromArray(obj, this.leftPartners);
                            }
                        });
                    });
                    if (this.contratto.servizio === 'vent') {
                        this.materialiService.getAll(0, 10000000, [], [], [], null, null).subscribe(materiali => {
                            for (const obj of materiali.objects) {
                                const cm = new ContrattoMaterialeConsumabile();
                                cm.materialeConsumabile = obj;
                                cm.quantitaMin = 0;
                                cm.quantitaMax = 0;
                                this.leftMateriali.push(cm);
                            }
                            this.contrattiService.getMateriali(this.id).subscribe((contrattiMateriali) => {
                                for (const obj of contrattiMateriali) {
                                    this.rightMateriali.push(obj);
                                    this.removeContrattoMaterialeFromArray(obj, this.leftMateriali);
                                }
                            });
                        });
                        this.profiliService.getAll(0, 10000000, [], [], [], null, null).subscribe(profili => {
                            for (const obj of profili.objects) {
                                const cm = new ContrattoProfilo();
                                cm.profilo = obj;
                                cm.prezzo = 0;
                                cm.giornaliero = true;
                                this.leftProfili.push(cm);
                            }
                            this.contrattiService.getProfili(this.id).subscribe((contrattiProfili) => {
                                for (const obj of contrattiProfili) {
                                    this.rightProfili.push(obj);
                                    this.removeContrattoProfiloFromArray(obj, this.leftProfili);
                                }
                            });
                        });
                        this.maschereService.getAll(0, 10000000, [], [], [], null, null).subscribe(maschere => {
                            this.leftMaschere = maschere.objects;
                            this.contrattiService.getMaschere(this.id).subscribe((contrattiMaschere) => {
                                for (const obj of contrattiMaschere) {
                                    this.rightMaschere.push(obj.maschera);
                                    this.utilsService.removeFromArray(obj.maschera, this.leftMaschere);
                                }
                            });
                        });
                    }
                    this.contrattiService.getFatture(this.id).subscribe(fatture => {
                        this.fatture = fatture;
                    });
                });
            } else {
                if (this.appaltoId) {
                    this.onSelectAppalto(Number(this.appaltoId));
                    
                    const formValues = {
                        selectedAppaltoId: this.appaltoId
                    };

                    this.form.patchValue(formValues);

                }
                this.partnersService.getAll(0, 1000000, [], [], [], null, null).subscribe(partners => {
                    for (const obj of partners.objects) {
                        const cp = new ContrattoPartner();
                        cp.partner = obj;
                        cp.percentuale = 0;
                        this.leftPartners.push(cp);
                    }
                });
                this.materialiService.getAll(0, 1000000, [], [], [], null, null).subscribe(materiali => {
                    for (const obj of materiali.objects) {
                        const cm = new ContrattoMaterialeConsumabile();
                        cm.materialeConsumabile = obj;
                        cm.quantitaMin = 0;
                        cm.quantitaMax = 0;
                        this.leftMateriali.push(cm);
                    }
                });
                this.profiliService.getAll(0, 1000000, [], [], [], null, null).subscribe(profili => {
                    for (const obj of profili.objects) {
                        const cm = new ContrattoProfilo();
                        cm.profilo = obj;
                        cm.prezzo = 0;
                        cm.giornaliero = true;
                        this.leftProfili.push(cm);
                    }
                });
                this.maschereService.getAll(0, 10000000, [], [], [], null, null).subscribe(maschere => {
                    this.leftMaschere = maschere.objects;
                });
            }
        });
    }

    setOnFieldsChange() {
        const durataControl = this.form.get('durata');

        durataControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).forEach(
            (value: any) => this.onDurataChange(value)
        );
    }

    onDurataChange(durata: string) {
        this.updateDataScadenza(this.utilsService.fieldToDate(this.form.getRawValue().dataStipula), Number(durata));
    }

    onDataStipulaChange(dataStipula: Date): void {
        this.updateDataScadenza(dataStipula, this.form.getRawValue().durata);
    }

    updateDataScadenza(dataStipula: Date, durata: number) {
        const dataScadenza: Date = new Date(dataStipula.getTime());
        dataScadenza.setMonth(dataStipula.getMonth() + durata);
        const formValues = {
            dataScadenza: this.utilsService.dateToField(dataScadenza)
        };
        this.form.patchValue(formValues);
    }

    onSelectAppalto(id: number) {
        if (id) {
            this.selectedAppalto = this.appalti.find(a => a.id == id);
            
            const formValues = {
                azienda: this.selectedAppalto.azienda.nome
            };
            
            this.form.patchValue(formValues);
            this.approvatoriService.getAll(0, 10000000, ['aziendaForeignKey'], ['eq'], [this.selectedAppalto.aziendaForeignKey], null, null).subscribe(approvatori => {
                this.leftApprovatori = approvatori.objects;
            });
            this.operatoriService.getAll(0, 10000000, null, null, null, null, null).subscribe(operatori => {
                this.leftOperatori = operatori.objects;
            });
            this.appaltantiService.getAll(0, 10000000, ['aziendaForeignKey'], ['eq'], [this.selectedAppalto.aziendaForeignKey], null, null).subscribe( appaltanti => {
                this.leftAppaltanti =  appaltanti.objects;
            });
            this.prescrittoriService.getAll(0, 1000000, ['aziendaForeignKey'], ['eq'], [this.selectedAppalto.aziendaForeignKey], null, null).subscribe(prescrittori => {
                this.leftPrescrittori = prescrittori.objects;
            });

        } else {
            this.selectedAppalto = null;
        }
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const approvatoriIds = this.rightApprovatori.map((obj) => {
            return obj.id;
        });
        const operatoriIds = this.rightOperatori.map((obj) => {
            return obj.id;
        });
        const appaltantiIds = this.rightAppaltanti.map((obj) => {
            return obj.id;
        });
        const prescrittoriIds = this.rightPrescrittori.map((obj) => {
            return obj.id;
        });
        const partnersDtos = this.rightPartners.map((obj) => {
            return new ContrattoPartnerDto(obj.partner.id, obj.percentuale);
        });
        const materialiIds = this.rightMateriali.map((obj) => {
            return obj.materialeConsumabile.id;
        });
        const quantitaMin = this.rightMateriali.map((obj) => {
            return obj.quantitaMin;
        });
        const quantitaMax = this.rightMateriali.map((obj) => {
            return obj.quantitaMax;
        });
        const profiliIds = this.rightProfili.map((obj) => {
            return obj.profilo.id;
        });
        const maschereIds = this.rightMaschere.map((obj) => {
            return obj.id;
        });
        const prezzi = this.rightProfili.map((obj) => {
            return obj.prezzo;
        });
        const giornalieri = this.rightProfili.map((obj) => {
            return obj.giornaliero;
        });
        const dto: ContrattoDto = new ContrattoDto(
            new ContrattoCommand(
                formValue.servizio,
                formValue.autoApprovazione,
                Number(formValue.prezzo1),
                Number(formValue.prezzo2),
                Number(formValue.prezzo3),
                Number(formValue.livelloServizioAttivazione),
                Number(formValue.livelloServizioIntervento),
                this.utilsService.fieldToDate(formValue.dataStipula),
                Number(formValue.durata),
                this.utilsService.fieldToDate(formValue.dataScadenza),
                formValue.rti,
                formValue.prescrittoreXml,
                formValue.cig,
                this.selectedAppalto.azienda.id,
                this.selectedAppalto.id,
                formValue.note,
                Number(formValue.percentualeEvaporazioneContenitori),
                Number(formValue.percentualeEvaporazioneStroller),
                formValue.regione,
                formValue.tipoErogazione,
                formValue.tipoCanale,
                formValue.tipoContatto,
                formValue.codiceEsenzione,
                formValue.tipoEsenzione,
                formValue.subjectCreazione,
                formValue.msgCreazione,
                formValue.subjectAttivazione,
                formValue.msgAttivazione,
                formValue.subjectSospensione,
                formValue.msgSospensione,
                formValue.subjectModifica,
                formValue.msgModifica,
                formValue.subjectRinnovo,
                formValue.msgRinnovo,
                formValue.subjectRiattivazione,
                formValue.msgRiattivazione,
                formValue.subjectCessazione,
                formValue.msgCessazione,
                formValue.recipient
            ),
            approvatoriIds,
            operatoriIds,
            appaltantiIds,
            prescrittoriIds,
            partnersDtos,
            materialiIds,
            quantitaMin,
            quantitaMax,
            profiliIds,
            prezzi,
            giornalieri,
            maschereIds
        );
        if (this.id === 'new') {
            this.contrattiService.create(dto).subscribe((res) => {
                this.submitButton = ClrLoadingState.SUCCESS;
                if (!res) {
                    this.alertMessage = 'Il contratto è stato creato';
                    this.alertClosed = false;
                    window.scroll(0, 0);
                }
            });
        } else {
            this.contrattiService.update(this.id, dto).subscribe((res) => {
                this.submitButton = ClrLoadingState.SUCCESS;
                if (!res) {
                    this.alertMessage = 'Il contratto è stato aggiornato';
                    this.alertClosed = false;
                    window.scroll(0, 0);
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            selectedAppaltoId: ['', [Validators.required]],
            azienda: [{ value: '', disabled: true }],
            servizio: ['', [Validators.required, Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            autoApprovazione: [false],
            prezzo1: [0, [Validators.required, Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            prezzo2: [0, [Validators.required, Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            prezzo3: [0, [Validators.required, Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            percentualeEvaporazioneContenitori: [0, [Validators.min(0), Validators.max(100)]],
            percentualeEvaporazioneStroller: [0, [Validators.min(0), Validators.max(100)]],
            livelloServizioAttivazione: [0, [Validators.required, Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            livelloServizioIntervento: [0, [Validators.required, Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            dataStipula: ['', [Validators.required]],
            durata: [0, [Validators.required, Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            dataScadenza: [{ value: '', disabled: true }],
            rti: [false],
            prescrittoreXml: [true],
            cig: ['', [Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            regione: [''],
            tipoErogazione: [''],
            tipoCanale: [''],
            tipoContatto: [''],
            codiceEsenzione: [''],
            tipoEsenzione: [''],
            note: [''],
            subjectCreazione: [''],
            msgCreazione: [''],
            subjectAttivazione: [''],
            msgAttivazione: [''],
            subjectSospensione: [''],
            msgSospensione: [''],
            subjectModifica: [''],
            msgModifica: [''],
            subjectRinnovo: [''],
            msgRinnovo: [''],
            subjectRiattivazione: [''],
            msgRiattivazione: [''],
            subjectCessazione: [''],
            msgCessazione: [''],
            recipient: ['']
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(contratto: Contratto): void {
        let formValues;

        if (contratto) {
            formValues = {
                selectedAppaltoId: contratto.appaltoForeignKey,
                azienda: contratto.azienda.nome,
                servizio: contratto.servizio,
                autoApprovazione: contratto.autoApprovazione,
                prezzo1: contratto.prezzo1,
                prezzo2: contratto.prezzo2,
                prezzo3: contratto.prezzo3,
                percentualeEvaporazioneContenitori: contratto.percentualeEvaporazioneContenitori ? contratto.percentualeEvaporazioneContenitori : 0,
                percentualeEvaporazioneStroller: contratto.percentualeEvaporazioneStroller ? contratto.percentualeEvaporazioneStroller : 0,
                livelloServizioAttivazione: contratto.livelloServizioAttivazione,
                livelloServizioIntervento: contratto.livelloServizioIntervento,
                dataStipula: this.utilsService.dateToField(contratto.dataStipula),
                durata: contratto.durata,
                dataScadenza: this.utilsService.dateToField(contratto.dataScadenza),
                rti: contratto.rti,
                prescrittoreXml: contratto.prescrittoreXml,
                cig: contratto.cig,
                regione: contratto.regione || '',
                tipoErogazione: contratto.tipoErogazione || '',
                tipoCanale: contratto.tipoCanale || '',
                tipoContatto: contratto.tipoContatto || '',
                codiceEsenzione: contratto.codiceEsenzione || '',
                tipoEsenzione: contratto.tipoEsenzione || '',
                note: contratto.note ? contratto.note : '',
                subjectCreazione: contratto.subjectCreazione ? contratto.subjectCreazione : '',
                msgCreazione: contratto.msgCreazione ? contratto.msgCreazione : '',
                subjectAttivazione: contratto.subjectAttivazione ? contratto.subjectAttivazione : '',
                msgAttivazione: contratto.msgAttivazione ? contratto.msgAttivazione : '',
                subjectSospensione: contratto.subjectSospensione ? contratto.subjectSospensione : '',
                msgSospensione: contratto.msgSospensione ? contratto.msgSospensione : '',
                subjectModifica: contratto.subjectModifica ? contratto.subjectModifica : '',
                msgModifica: contratto.msgModifica ? contratto.msgModifica : '',
                subjectRinnovo: contratto.subjectRinnovo ? contratto.subjectRinnovo : '',
                msgRinnovo: contratto.msgRinnovo ? contratto.msgRinnovo : '',
                subjectRiattivazione: contratto.subjectRiattivazione ? contratto.subjectRiattivazione : '',
                msgRiattivazione: contratto.msgRiattivazione ? contratto.msgRiattivazione : '',
                subjectCessazione: contratto.subjectCessazione ? contratto.subjectCessazione : '',
                msgCessazione: contratto.msgCessazione ? contratto.msgCessazione : '',
                recipient: contratto.recipient ? contratto.recipient : '',
            };
            this.form.get('selectedAppaltoId').disable();
            this.form.get('servizio').disable();
        } else {
            formValues = {
                selectedAppaltoId: '',
                azienda: '',
                servizio: '',
                autoApprovazione: false,
                prezzo1: 0,
                prezzo2: 0,
                prezzo3: 0,
                percentualeEvaporazioneContenitori: 0,
                percentualeEvaporazioneStroller: 0,
                livelloServizioAttivazione: 0,
                livelloServizioIntervento: 0,
                dataStipula: this.utilsService.dateToField(new Date()),
                durata: 0,
                dataScadenza: '',
                rti: false,
                prescrittoreXml: true,
                cig: 0,
                regione: '',
                tipoErogazione: '',
                tipoCanale: '',
                tipoContatto: '',
                codiceEsenzione: '',
                tipoEsenzione: '',
                note: '',
                subjectCreazione: '',
                msgCreazione: '',
                subjectAttivazione: '',
                msgAttivazione: '',
                subjectSospensione: '',
                msgSospensione: '',
                subjectModifica: '',
                msgModifica: '',
                subjectRinnovo: '',
                msgRinnovo: '',
                subjectRiattivazione: '',
                msgRiattivazione: '',
                subjectCessazione: '',
                msgCessazione: '',
                recipient: ''
            };
        }
        this.form.setValue(formValues);
    }


    onFatturaChange(event: any) {
        this.fattura = event.target.files[0];
    }

    uploadFattura() {
        this.contrattiService.aggiungiFattura(this.id, this.fattura, new ContrattoFatturaCommand(this.id, this.fattura.name))
            .subscribe((result) => {
                this.refreshFatture();
                this.fattura = null;
            }
            );
    }

    onDeleteFattura(fat: ContrattoFattura) {
        if (confirm('Confermi la cancellazione?')) {
            this.contrattiService.eliminaFattura(this.id, fat.id.toString()).subscribe((result) => {
                this.refreshFatture();
            });
        }
    }

    refreshFatture() {
        this.contrattiService.getFatture(this.id).subscribe(fatture => {
            this.fatture = fatture;
        });
    }

    onDownloadFattura(fatturaId: number) {
        this.contrattiService.getFatturaDownloadUrl(fatturaId.toString()).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    swapApprovatori() {
        const model = this.utilsService.swapAssignment({
            leftArray: this.leftApprovatori,
            leftSelectedArray: this.selectedLeftApprovatori,
            rightArray: this.rightApprovatori,
            rightSelectedArray: this.selectedRightApprovatori
        });
        this.leftApprovatori = model.leftArray;
        this.selectedLeftApprovatori = model.leftSelectedArray;
        this.rightApprovatori = model.rightArray;
        this.selectedRightApprovatori = model.rightSelectedArray;
    }

    swapOperatori() {
        const model = this.utilsService.swapAssignment({
            leftArray: this.leftOperatori,
            leftSelectedArray: this.selectedLeftOperatori,
            rightArray: this.rightOperatori,
            rightSelectedArray: this.selectedRightOperatori
        });
        this.leftOperatori = model.leftArray;
        this.selectedLeftOperatori = model.leftSelectedArray;
        this.rightOperatori = model.rightArray;
        this.selectedRightOperatori = model.rightSelectedArray;
    }

    swapAppaltanti() {
        const model = this.utilsService.swapAssignment({
            leftArray: this.leftAppaltanti,
            leftSelectedArray: this.selectedLeftAppaltanti,
            rightArray: this.rightAppaltanti,
            rightSelectedArray: this.selectedRightAppaltanti
        });
        this.leftAppaltanti = model.leftArray;
        this.selectedLeftAppaltanti = model.leftSelectedArray;
        this.rightAppaltanti = model.rightArray;
        this.selectedRightAppaltanti = model.rightSelectedArray;
    }

    swapPrescrittori() {
        const model = this.utilsService.swapAssignment({
            leftArray: this.leftPrescrittori,
            leftSelectedArray: this.selectedLeftPrescrittori,
            rightArray: this.rightPrescrittori,
            rightSelectedArray: this.selectedRightPrescrittori
        });
        this.leftPrescrittori = model.leftArray;
        this.selectedLeftPrescrittori = model.leftSelectedArray;
        this.rightPrescrittori = model.rightArray;
        this.selectedRightPrescrittori = model.rightSelectedArray;
    }

    swapPartners() {
        const model = this.swapContrattoPartnerAssignment({
            leftArray: this.leftPartners,
            leftSelectedArray: this.selectedLeftPartners,
            rightArray: this.rightPartners,
            rightSelectedArray: this.selectedRightPartners
        });
        this.leftPartners = model.leftArray;
        this.selectedLeftPartners = model.leftSelectedArray;
        this.rightPartners = model.rightArray;
        this.selectedRightPartners = model.rightSelectedArray;
        this.showPartnerModal = false;
    }


    swapContrattoPartnerAssignment(model: {
        leftArray: Array<ContrattoPartner>,
        leftSelectedArray: Array<ContrattoPartner>,
        rightArray: Array<ContrattoPartner>,
        rightSelectedArray: Array<ContrattoPartner>
    }) {
        for (const obj of model.leftSelectedArray) {
            if (obj.percentuale == 0) {
                this.showAlertSwapModal = true;
                return model;
            }
        }
        if (!this.percentageIsCorrect(model)) {
            return;
        }
        for (const obj of model.leftSelectedArray) {
            model.rightArray.push(obj);
            this.removeContrattoPartnerFromArray(obj, model.leftArray);
        }
        for (const obj of model.rightSelectedArray) {
            model.leftArray.push(obj);
            this.removeContrattoPartnerFromArray(obj, model.rightArray);
        }
        model.leftSelectedArray = new Array<ContrattoPartner>();
        model.rightSelectedArray = new Array<ContrattoPartner>();

        return model;
    }

    swapMateriali() {
        const model = this.swapContrattoMaterialeAssignment({
            leftArray: this.leftMateriali,
            leftSelectedArray: this.selectedLeftMateriali,
            rightArray: this.rightMateriali,
            rightSelectedArray: this.selectedRightMateriali
        });
        this.leftMateriali = model.leftArray;
        this.selectedLeftMateriali = model.leftSelectedArray;
        this.rightMateriali = model.rightArray;
        this.selectedRightMateriali = model.rightSelectedArray;
        this.showMaterialeModal = false;
    }

    swapMaschere() {
        const model = this.utilsService.swapAssignment({
            leftArray: this.leftMaschere,
            leftSelectedArray: this.selectedLeftMaschere,
            rightArray: this.rightMaschere,
            rightSelectedArray: this.selectedRightMaschere
        });
        this.leftMaschere = model.leftArray;
        this.selectedLeftMaschere = model.leftSelectedArray;
        this.rightMaschere = model.rightArray;
        this.selectedRightMaschere = model.rightSelectedArray;
    }

    swapProfili() {
        const model = this.swapContrattoProfiloAssignment({
            leftArray: this.leftProfili,
            leftSelectedArray: this.selectedLeftProfili,
            rightArray: this.rightProfili,
            rightSelectedArray: this.selectedRightProfili
        });
        this.leftProfili = model.leftArray;
        this.selectedLeftProfili = model.leftSelectedArray;
        this.rightProfili = model.rightArray;
        this.selectedRightProfili = model.rightSelectedArray;
        this.showProfiloModal = false;
    }

    swapContrattoProfiloAssignment(model: {
        leftArray: Array<ContrattoProfilo>,
        leftSelectedArray: Array<ContrattoProfilo>,
        rightArray: Array<ContrattoProfilo>,
        rightSelectedArray: Array<ContrattoProfilo>
    }) {
        for (const obj of model.leftSelectedArray) {
            model.rightArray.push(obj);
            this.removeContrattoProfiloFromArray(obj, model.leftArray);
        }
        for (const obj of model.rightSelectedArray) {
            model.leftArray.push(obj);
            this.removeContrattoProfiloFromArray(obj, model.rightArray);
        }
        model.leftSelectedArray = new Array<ContrattoProfilo>();
        model.rightSelectedArray = new Array<ContrattoProfilo>();

        return model;
    }

    swapContrattoMaterialeAssignment(model: {
        leftArray: Array<ContrattoMaterialeConsumabile>,
        leftSelectedArray: Array<ContrattoMaterialeConsumabile>,
        rightArray: Array<ContrattoMaterialeConsumabile>,
        rightSelectedArray: Array<ContrattoMaterialeConsumabile>
    }) {
        for (const obj of model.leftSelectedArray) {
            if (obj.quantitaMin == 0) {
                this.showAlertSwapModal = true;
                return model;
            }
        }
        for (const obj of model.leftSelectedArray) {
            model.rightArray.push(obj);
            this.removeContrattoMaterialeFromArray(obj, model.leftArray);
        }
        for (const obj of model.rightSelectedArray) {
            model.leftArray.push(obj);
            this.removeContrattoMaterialeFromArray(obj, model.rightArray);
        }
        model.leftSelectedArray = new Array<ContrattoMaterialeConsumabile>();
        model.rightSelectedArray = new Array<ContrattoMaterialeConsumabile>();

        return model;
    }

    removeContrattoPartnerFromArray(obj: ContrattoPartner, array: Array<ContrattoPartner>) {
        const index = array.findIndex(x => x.partner.id === obj.partner.id);

        if (index != -1) {
            array.splice(index, 1);
        }
    }

    removeContrattoMaterialeFromArray(obj: ContrattoMaterialeConsumabile, array: Array<ContrattoMaterialeConsumabile>) {
        const index = array.findIndex(x => x.materialeConsumabile.id === obj.materialeConsumabile.id);

        if (index != -1) {
            array.splice(index, 1);
        }
    }

    removeContrattoProfiloFromArray(obj: ContrattoProfilo, array: Array<ContrattoProfilo>) {
        const index = array.findIndex(x => x.profilo.id === obj.profilo.id);

        if (index != -1) {
            array.splice(index, 1);
        }
    }

    findById(obj: any, array: Array<any>) {
        return array.find(x => x.id === obj.id);
    }

    percentageIsCorrect(model) {
        let swapPercent = 0;
        let rightPercent = 0;
        for (const obj of model.leftSelectedArray) {
            swapPercent += obj.percentuale;
        }
        if (swapPercent === 0) {
            return true;
        }
        for (const obj of model.rightArray) {
            rightPercent += obj.percentuale;
        }
        for (const obj of model.rightSelectedArray) {
            rightPercent -= obj.percentuale;
        }
        if (swapPercent <= 100 - rightPercent) {
            return true;
        }
        this.showPartnerModal = false;
        this.showAlertSwapModal = true;
        return false;
    }

}
