import {Component} from '@angular/core';
import {DatagridComponent} from '../common/datagrid.component';
import {Operatore} from '../../model/query/operatore';
import {Router} from '@angular/router';
import {OperatoriService} from '../../core/operatori.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-operatori',
    templateUrl: './operatori.component.html',
    styleUrls: ['./operatori.component.css']
})
export class OperatoriComponent extends DatagridComponent<Operatore> {

    constructor(
        private operatoriService: OperatoriService,
        dialog: DialogService,
        router: Router
    ) {
        super(operatoriService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/operatori/';
    }

}
