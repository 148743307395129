import {AbstractCommand} from './abstract-command';

export class PrescrizioneCommand extends AbstractCommand {
    prescrittoreId: number;
    prescrittoreEsternoId: number;
    pazienteId: number;
    contrattoId: number;
    dataRicezione: Date;
    dataPrescrizione: Date;
    codice: string;
    codicePartner: string;
    codiceAutorizzazione: string;
    dataEGAInAria: Date;
    phEGAInAria: number;
    o2EGAInAria: number;
    co2EGAInAria: number;
    co3EGAInAria: number;
    dataEGASuppl: Date;
    phEGASuppl: number;
    o2EGASuppl: number;
    co2EGASuppl: number;
    co3EGASuppl: number;
    dataWalkingTest: Date;
    risultatoWalkingTest: number;
    dataSaturimetria: Date;
    risultatoSaturimetria: number;
    bpco55: boolean;
    bpco60: boolean;
    bpco60Ipossiemia: boolean;
    bpco60Policitemia: boolean;
    bpco60Edemi: boolean;
    bpco60Ipertensione: boolean;
    fc55: boolean;
    fc60: boolean;
    fc60Ipossiemia: boolean;
    fc60Policitemia: boolean;
    fc60Edemi: boolean;
    fc60Ipertensione: boolean;
    malattieInterstiziali: boolean;
    ipertensionePolmonare: boolean;
    neoplasieMaligne: boolean;
    patologieNeuro: boolean;
    apneaOstruttiva: boolean;
    scompensoCardiaco: boolean;
    desaturazioneNotturna: boolean;
    desaturazioneEsercizio: boolean;
    osas: boolean;
    osasAltra: boolean;
    poliomielite: boolean;
    miopatieCongenite: boolean;
    miopatieMitocondriali: boolean;
    distrofiaMuscolare: boolean;
    guillainBarre: boolean;
    sla: boolean;
    atrofiaMuscolareSpinale: boolean;
    paralisiDiaframma: boolean;
    lesioniNervoFrenico: boolean;
    cifoscoliosi: boolean;
    deformitaToracica: boolean;
    ipoventilazioneCongenita: boolean;
    ipoventilazioneCentrale: boolean;
    leigh: boolean;
    ipoventilazionePostChirurgica: boolean;
    ipoventilazionePostRadioterapia: boolean;
    ipoventilazioneNeoplasie: boolean;
    ipoventilazionePostTraumatica: boolean;
    bpco: boolean;
    bronchiectasie: boolean;
    fibrosiCistica: boolean;
    fibrosiPolmonare: boolean;
    malattieCardiacheCongenite: boolean;
    malattieCardiacheAcquisite: boolean;
    diagnosiAltra: boolean;
    descrizioneDiagnosi: string;

    constructor(
        prescrittoreId: number,
        prescrittoreEsternoId: number,
        pazienteId: number,
        contrattoId: number,
        dataRicezione: Date,
        dataPrescrizione: Date,
        dataEGAInAria: Date,
        phEGAInAria: number,
        o2EGAInAria: number,
        co2EGAInAria: number,
        co3EGAInAria: number,
        dataEGASuppl: Date,
        phEGASuppl: number,
        o2EGASuppl: number,
        co2EGASuppl: number,
        co3EGASuppl: number,
        dataWalkingTest: Date,
        risultatoWalkingTest: number,
        dataSaturimetria: Date,
        risultatoSaturimetria: number,
        bpco55: boolean,
        bpco60: boolean,
        bpco60Ipossiemia: boolean,
        bpco60Policitemia: boolean,
        bpco60Edemi: boolean,
        bpco60Ipertensione: boolean,
        fc55: boolean,
        fc60: boolean,
        fc60Ipossiemia: boolean,
        fc60Policitemia: boolean,
        fc60Edemi: boolean,
        fc60Ipertensione: boolean,
        malattieInterstiziali: boolean,
        ipertensionePolmonare: boolean,
        neoplasieMaligne: boolean,
        patologieNeuro: boolean,
        apneaOstruttiva: boolean,
        scompensoCardiaco: boolean,
        desaturazioneNotturna: boolean,
        desaturazioneEsercizio: boolean,
        osas: boolean,
        osasAltra: boolean,
        poliomielite: boolean,
        miopatieCongenite: boolean,
        miopatieMitocondriali: boolean,
        distrofiaMuscolare: boolean,
        guillainBarre: boolean,
        sla: boolean,
        atrofiaMuscolareSpinale: boolean,
        paralisiDiaframma: boolean,
        lesioniNervoFrenico: boolean,
        cifoscoliosi: boolean,
        deformitaToracica: boolean,
        ipoventilazioneCongenita: boolean,
        ipoventilazioneCentrale: boolean,
        leigh: boolean,
        ipoventilazionePostChirurgica: boolean,
        ipoventilazionePostRadioterapia: boolean,
        ipoventilazioneNeoplasie: boolean,
        ipoventilazionePostTraumatica: boolean,
        bpco: boolean,
        bronchiectasie: boolean,
        fibrosiCistica: boolean,
        fibrosiPolmonare: boolean,
        malattieCardiacheCongenite: boolean,
        malattieCardiacheAcquisite: boolean,
        diagnosiAltra: boolean,
        descrizioneDiagnosi: string,
        codice: string,
        codicePartner: string,
        codiceAutorizzazione: string
    ) {
        super();
        this.prescrittoreId = prescrittoreId;
        this.prescrittoreEsternoId = prescrittoreEsternoId;
        this.pazienteId = pazienteId;
        this.contrattoId = contrattoId;
        this.dataRicezione = dataRicezione;
        this.dataPrescrizione = dataPrescrizione;
        this.dataEGAInAria = dataEGAInAria;
        this.phEGAInAria = phEGAInAria;
        this.o2EGAInAria = o2EGAInAria;
        this.co2EGAInAria = co2EGAInAria;
        this.co3EGAInAria = co3EGAInAria;
        this.dataEGASuppl = dataEGASuppl;
        this.phEGASuppl = phEGASuppl;
        this.o2EGASuppl = o2EGASuppl;
        this.co2EGASuppl = co2EGASuppl;
        this.co3EGASuppl = co3EGASuppl;
        this.dataWalkingTest = dataWalkingTest;
        this.risultatoWalkingTest = risultatoWalkingTest;
        this.dataSaturimetria = dataSaturimetria;
        this.risultatoSaturimetria = risultatoSaturimetria;
        this.bpco55 = bpco55;
        this.bpco60 = bpco60;
        this.bpco60Ipossiemia = bpco60Ipossiemia;
        this.bpco60Policitemia = bpco60Policitemia;
        this.bpco60Edemi = bpco60Edemi;
        this.bpco60Ipertensione = bpco60Ipertensione;
        this.fc55 = fc55;
        this.fc60 = fc60;
        this.fc60Ipossiemia = fc60Ipossiemia;
        this.fc60Policitemia = fc60Policitemia;
        this.fc60Edemi = fc60Edemi;
        this.fc60Ipertensione = fc60Ipertensione;
        this.malattieInterstiziali = malattieInterstiziali;
        this.ipertensionePolmonare = ipertensionePolmonare;
        this.neoplasieMaligne = neoplasieMaligne;
        this.patologieNeuro = patologieNeuro;
        this.apneaOstruttiva = apneaOstruttiva;
        this.scompensoCardiaco = scompensoCardiaco;
        this.desaturazioneNotturna = desaturazioneNotturna;
        this.desaturazioneEsercizio = desaturazioneEsercizio;
        this.osas = osas;
        this.osasAltra = osasAltra;
        this.poliomielite = poliomielite;
        this.miopatieCongenite = miopatieCongenite;
        this.miopatieMitocondriali = miopatieMitocondriali;
        this.distrofiaMuscolare = distrofiaMuscolare;
        this.guillainBarre = guillainBarre;
        this.sla = sla;
        this.atrofiaMuscolareSpinale = atrofiaMuscolareSpinale;
        this.paralisiDiaframma = paralisiDiaframma;
        this.lesioniNervoFrenico = lesioniNervoFrenico;
        this.cifoscoliosi = cifoscoliosi;
        this.deformitaToracica = deformitaToracica;
        this.ipoventilazioneCongenita = ipoventilazioneCongenita;
        this.ipoventilazioneCentrale = ipoventilazioneCentrale;
        this.leigh = leigh;
        this.ipoventilazionePostChirurgica = ipoventilazionePostChirurgica;
        this.ipoventilazionePostRadioterapia = ipoventilazionePostRadioterapia;
        this.ipoventilazioneNeoplasie = ipoventilazioneNeoplasie;
        this.ipoventilazionePostTraumatica = ipoventilazionePostTraumatica;
        this.bpco = bpco;
        this.bronchiectasie = bronchiectasie;
        this.fibrosiCistica = fibrosiCistica;
        this.fibrosiPolmonare = fibrosiPolmonare;
        this.malattieCardiacheCongenite = malattieCardiacheCongenite;
        this.malattieCardiacheAcquisite = malattieCardiacheAcquisite;
        this.diagnosiAltra = diagnosiAltra;
        this.descrizioneDiagnosi = descrizioneDiagnosi,
        this.codice = codice;
        this.codicePartner = codicePartner;
        this.codiceAutorizzazione = codiceAutorizzazione;
    }
}
