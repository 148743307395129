import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {AuthService} from './app/core/auth.service';

if (environment.production) {
    enableProdMode();
}

AuthService.init()
    .then(() => platformBrowserDynamic().bootstrapModule(AppModule))
    .catch(e => {
        console.log(e);
    });
