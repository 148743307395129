import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {Action} from '../model/action';

@Injectable({
    providedIn: 'root'
})
export class ActionService {

    readonly ACTION: typeof Action = Action;

    constructor(private authService: AuthService) {
    }

    public getAuthPrescrittoriActions(): Action[] {
        const authorizedActions = [];
        if (this.authService.isAmministratoreUser()) {
            authorizedActions.push(this.ACTION.VIEW);
            authorizedActions.push(this.ACTION.EDIT);
            authorizedActions.push(this.ACTION.DELETE);
        } else if (this.authService.isOperatoreUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isPartnerUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isApprovatoreUser()) {
            // nothing
        } else if (this.authService.isAppaltanteUser()) {
            // nothing
        } else if (this.authService.isPrescrittoreUser()) {
            // nothing
        } else if (this.authService.isPazienteUser()) {
            // nothing
        }
        return authorizedActions;
    }

    public getAuthAziendeActions(): Action[] {
        const authorizedActions = [];
        if (this.authService.isAmministratoreUser()) {
            authorizedActions.push(this.ACTION.VIEW);
            authorizedActions.push(this.ACTION.EDIT);
            authorizedActions.push(this.ACTION.DELETE);
        } else if (this.authService.isOperatoreUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isPartnerUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isApprovatoreUser()) {
            // nothing
        } else if (this.authService.isAppaltanteUser()) {
            // nothing
        } else if (this.authService.isPrescrittoreUser()) {
            // nothing
        } else if (this.authService.isPazienteUser()) {
            // nothing
        }
        return authorizedActions;
    }

    public getAuthApprovatoriActions(): Action[] {
        const authorizedActions = [];
        if (this.authService.isAmministratoreUser()) {
            authorizedActions.push(this.ACTION.VIEW);
            authorizedActions.push(this.ACTION.EDIT);
            authorizedActions.push(this.ACTION.DELETE);
        } else if (this.authService.isOperatoreUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isPartnerUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isApprovatoreUser()) {
            // nothing
        } else if (this.authService.isAppaltanteUser()) {
            // nothing
        } else if (this.authService.isPrescrittoreUser()) {
            // nothing
        } else if (this.authService.isPazienteUser()) {
            // nothing
        }
        return authorizedActions;
    }

    public getAuthAppaltantiActions(): Action[] {
        const authorizedActions = [];
        if (this.authService.isAmministratoreUser()) {
            authorizedActions.push(this.ACTION.VIEW);
            authorizedActions.push(this.ACTION.EDIT);
            authorizedActions.push(this.ACTION.DELETE);
        } else if (this.authService.isOperatoreUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isPartnerUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isApprovatoreUser()) {
            // nothing
        } else if (this.authService.isAppaltanteUser()) {
            // nothing
        } else if (this.authService.isPrescrittoreUser()) {
            // nothing
        } else if (this.authService.isPazienteUser()) {
            // nothing
        }
        return authorizedActions;
    }

    public getAuthPartnersActions(): Action[] {
        const authorizedActions = [];
        if (this.authService.isAmministratoreUser()) {
            authorizedActions.push(this.ACTION.VIEW);
            authorizedActions.push(this.ACTION.EDIT);
            authorizedActions.push(this.ACTION.DELETE);
        } else if (this.authService.isOperatoreUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isPartnerUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isApprovatoreUser()) {
            // nothing
        } else if (this.authService.isAppaltanteUser()) {
            // nothing
        } else if (this.authService.isPrescrittoreUser()) {
            // nothing
        } else if (this.authService.isPazienteUser()) {
            // nothing
        }
        return authorizedActions;
    }

    public getAuthPazientiActions(): Action[] {
        const authorizedActions = [];
        if (this.authService.isAmministratoreUser()) {
            authorizedActions.push(this.ACTION.VIEW);
            authorizedActions.push(this.ACTION.EDIT);
            authorizedActions.push(this.ACTION.DELETE);
        } else if (this.authService.isOperatoreUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isPartnerUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isApprovatoreUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isAppaltanteUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isPrescrittoreUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isPazienteUser()) {
            // nothing
        }
        return authorizedActions;
    }

    public getAuthContrattiActions(): Action[] {
        const authorizedActions = [];
        if (this.authService.isAmministratoreUser()) {
            authorizedActions.push(this.ACTION.VIEW);
            authorizedActions.push(this.ACTION.EDIT);
            authorizedActions.push(this.ACTION.DELETE);
        } else if (this.authService.isOperatoreUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isPartnerUser()) {
            authorizedActions.push(this.ACTION.VIEW);
        } else if (this.authService.isApprovatoreUser()) {
            // nothing
        } else if (this.authService.isAppaltanteUser()) {
            // nothing
        } else if (this.authService.isPrescrittoreUser()) {
            // nothing
        } else if (this.authService.isPazienteUser()) {
            // nothing
        }
        return authorizedActions;
    }
}
