import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Action} from '../../../model/action';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {ActionService} from '../../../core/action.service';
import {StruttureService} from '../../../core/strutture.service';
import {AziendeService} from '../../../core/aziende.service';
import {Azienda} from '../../../model/query/azienda';
import {Struttura} from '../../../model/query/struttura';
import {StrutturaCommand} from '../../../model/command/struttura-command';

@Component({
    selector: 'app-struttura-detail',
    templateUrl: './struttura-detail.component.html'
})
export class StrutturaDetailComponent implements OnInit {

    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    aziende: Array<Azienda> = new Array<Azienda>();
    selectedAziendaId: number;
    struttura: Struttura;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;

    constructor(
        private route: ActivatedRoute,
        private aziendeService: AziendeService,
        public struttureService: StruttureService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        public actionService: ActionService,
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        this.aziendeService.getAll(0, 10000000, [], [], [], null, null).subscribe(aziende => {
            this.aziende = aziende.objects;
            if (this.id !== 'new') {
                this.struttureService.get(this.id).subscribe((struttura) => {
                    this.struttura = struttura;
                    this.initializeFormFields(struttura);
                });
            } else {
                this.initializeFormFields(null);
            }
        });
    }

    onSelectAzienda(id: number) {
        this.selectedAziendaId = id;
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command = new StrutturaCommand(
            formValue.nome,
            Number(formValue.selectedAziendaId)
        );
        if (!this.id || this.id === 'new') {
            this.struttureService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'Struttura salvata!';
                    this.alertClosed = false;
                }
            });
        } else {
            this.struttureService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Struttura salvata!';
                    this.alertClosed = false;
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            selectedAziendaId: ['', [Validators.required]],
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(struttura: Struttura): void {
        let formValues;

        if (struttura) {
            formValues = {
                selectedAziendaId: struttura.aziendaForeignKey,
                nome: struttura.nome
            };
        } else {
            formValues = {
                selectedAziendaId: '',
                nome: ''
            };
        }
        this.form.setValue(formValues);
    }

}
