import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {AziendeCorrentiService} from '../../../core/aziende-correnti.service';
import {AziendeService} from '../../../core/aziende.service';
import {Azienda} from '../../../model/query/azienda';
import {AziendaCommand} from '../../../model/command/azienda-command';
import {ActionService} from '../../../core/action.service';
import {Action} from '../../../model/action';

@Component({
    selector: 'app-azienda-detail',
    templateUrl: './azienda-detail.component.html',
    styleUrls: ['./azienda-detail.component.css']
})
export class AziendaDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    alertMessage: string;
    alertClosed = true;
    action: Action;
    readOnly = false;


    constructor(
        private route: ActivatedRoute,
        private aziendeCorrentiService: AziendeCorrentiService,
        private aziendeService: AziendeService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        public actionService: ActionService
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
            if (this.id !== 'new') {
                this.aziendeService.get(this.id).subscribe((azienda: Azienda) => {
                    this.initializeFormFields(azienda);
                    if (this.action != null && this.action == this.actionService.ACTION.VIEW) {
                        this.readOnly = true;
                    }
                });
            } else {
                this.initializeFormFields(null);
            }
        });
    }

    createForm(): void {
        const formGroup = {
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            codice: ['', [Validators.required]],
            // percentualeEvaporazioneContenitori: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
            // percentualeEvaporazioneStroller: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(azienda: Azienda): void {
        let formValues;

        if (azienda) {
            formValues = {
                nome: azienda.nome,
                codice: azienda.codice,
                // percentualeEvaporazioneContenitori: azienda.percentualeEvaporazioneContenitori ? azienda.percentualeEvaporazioneContenitori : 0,
                // percentualeEvaporazioneStroller: azienda.percentualeEvaporazioneStroller ? azienda.percentualeEvaporazioneStroller : 0,
            };
        } else {
            formValues = {
                nome: '',
                codice: '',
                // percentualeEvaporazioneContenitori: 0,
                // percentualeEvaporazioneStroller: 0,
            };
        }
        this.form.setValue(formValues);
    }


    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command: AziendaCommand =
            new AziendaCommand(
                formValue.nome,
                formValue.codice
            );
        if (this.id === 'new') {
            this.aziendeService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.aziendeCorrentiService.refresh();
                    this.alertMessage = 'L\'azienda è stata creata';
                    this.alertClosed = false;
                }
            });
        } else {
            this.aziendeService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.aziendeCorrentiService.refresh();
                    this.alertMessage = 'L\'azienda è stata aggiornata';
                    this.alertClosed = false;
                }
            });
        }

    }

}
