import {ChiusuraTaskConFirmaDto} from './chiusura-task-con-firma-dto';

export class ChiusuraTaskConsegnaDto extends ChiusuraTaskConFirmaDto {
    usaDispositiviAutonomamente: boolean;
    comprendeIstruzioni: boolean;
    coadiuvato: boolean;
    altriDispositivi: boolean;
    adeguamenti: string;
    ddt: string;
    
    constructor(
        dataCompletamento: Date,
        completato: boolean,
        note: string,
        ddt: string,
        firmaTecnico: string,
        firmaAssistito: string,
        firmaDelegato: string,
        usaDispositiviAutonomamente: boolean,
        comprendeIstruzioni: boolean,
        coadiuvato: boolean,
        altriDispositivi: boolean,
        adeguamenti: string
    ) {
        super(dataCompletamento, completato, note, firmaTecnico, firmaAssistito, firmaDelegato);
        this.usaDispositiviAutonomamente = usaDispositiviAutonomamente;
        this.comprendeIstruzioni = comprendeIstruzioni;
        this.coadiuvato = coadiuvato;
        this.altriDispositivi = altriDispositivi;
        this.adeguamenti = adeguamenti;
        this.ddt = ddt;
    }
}
