import {AbstractCommand} from './abstract-command';
import {StatoTask} from '../stato-task';

export class TaskCommand extends AbstractCommand {
    tipo: string;
    incaricatoId: number;
    prescrizioneVentilatoreId: number;
    stato: StatoTask;
    dataPrevista: Date;
    dataCompletamento: Date;
    note: string;
    noteUfficio: string;
    descrizione: string;
    materialePronto: boolean;
}
