import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Contratto} from '../model/query/contratto';
import {Prescrittore} from '../model/query/prescrittore';
import {Azienda} from '../model/query/azienda';
import {QueryResult} from '../model/query/query-result';
import {Prescrizione} from '../model/query/prescrizione';
import {PrescrittoreResponse} from '../model/query/prescrittore-response';
import {GetAllRequest} from '../model/get-all-request';

@Injectable()
export class PrescrittoriService extends BaseService<Prescrittore> {

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('prescrittori');
    }

    getWithEnabledInfo(id: string): Observable<PrescrittoreResponse> {
        return this.httpClient.get<PrescrittoreResponse>(this.apiUrl + '/' + id + '/with-enabled-info').pipe(
            catchError(this.handleError(this.apiUrl + ' -with-enabled-info', null))
        );
    }

    getContratti(id: string): Observable<Array<Contratto>> {
        return this.httpClient.get<Array<Contratto>>(this.apiUrl + '/' + id + '/contratti').pipe(
            catchError(this.handleError(this.apiUrl + '- : get contratti', null))
        );
    }

    getAziende(id: string): Observable<Array<Azienda>> {
        return this.httpClient.get<Array<Azienda>>(this.apiUrl + '/' + id + '/aziende').pipe(
            catchError(this.handleError(this.apiUrl + '- : get aziende', null))
        );
    }
}
