import { Component, OnInit } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { ClrDatagridStateInterface } from '@clr/angular';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReportsService } from '../../../core/reports.service';
import { ReportCostiCommand } from '../../../model/command/report-costi-command';
import { Subscription } from 'rxjs';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { UtilsService } from '../../../core/utils.service';
import { ReportCostiDownloadCommand } from '../../../model/command/report-costi-download-command';
import { AuthService } from '../../../core/auth.service';
import { PartnersService } from '../../../core/partners.service';
import { Partner } from '../../../model/query/partner';
import { PazientiService } from '../../../core/pazienti.service';
import { Paziente } from '../../../model/query/paziente';
import { AziendeCorrentiService } from '../../../core/aziende-correnti.service';
import { Azienda } from '../../../model/query/azienda';
import { DialogService } from '../../dialog';
import {MeService} from '../../../core/me.service';

@Component({
    selector: 'app-report-costi',
    templateUrl: './report-costi.component.html',
    styleUrls: ['./report-costi.component.css']
})
export class ReportCostiComponent {
    subscription: Subscription;
    subscription2: Subscription;

    type: string;
    total: number = 0;
    loading = false;
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: any;

    objectList: any[] = [];
    object: any;
    currentState: ClrDatagridStateInterface;

    year: any;
    month: any;

    dateFrom: string;
    dateTo: string;

    servizi: any;
    servizio: string;

    azienda: Azienda;
    distrettoId: number;
    distretti: { id: number, nome: string, aziendaForeignKey: number }[];
    partners: Partner[];
    partnerId: number;
    pazienti: Paziente[];
    pazienteId: number;
    extraAsl: boolean = false;

    totMC = 0;
    totCostoFarmaco = 0;
    totCostoServizio = 0;
    totCostoTotale = 0;

    currentYear = moment.utc().get('y');
    currentMonth = moment.utc().get('M');

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private reportsService: ReportsService,
        public utilsService: UtilsService,
        private meService: MeService,
        private partnersService: PartnersService,
        private pazientiService: PazientiService,
        public authService: AuthService,
        private aziendeCorrentiService: AziendeCorrentiService,
        private dialog: DialogService
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.subscription = this.aziendeCorrentiService.getAzienda().subscribe(azienda => {
            if (azienda != null) {
                this.azienda = azienda;
                this.partnerId = null;
                this.distrettoId = null;
                this.pazienteId = null;
                this.aziendeCorrentiService.getDistretti().subscribe(results => this.distretti = results);

                const getPazienti = this.pazientiService
                    .getAll(0, 1000000, ["comune.distretto.aziendaForeignKey"], ["eq"], [azienda.id.toString()], "cognome", "asc");
                const getPartners = this.partnersService.getAll(0, 1000000, null, null, null, null, null);

                forkJoin([getPazienti, getPartners]).subscribe(
                    (results) => {
                        this.pazienti = results[0].objects;
                        this.partners = results[1].objects;
                        this.initialize();
                    });
            }
        });
        this.subscription2 = this.route.queryParams.subscribe(params => {
            this.type = params.type;
            this.initialize();
        });
    }
    
    initialize(): void {
        this.objectList = [];
        this.total = 0;
        this.totMC = 0;
        this.totCostoFarmaco = 0;
        this.totCostoServizio = 0;
        this.totCostoTotale = 0;
        this.year = this.currentYear;
        this.month = this.currentMonth;
        this.dateFrom = undefined;
        this.dateTo = undefined;
        if (this.type) {
            if (this.type == 'o2') {
                this.servizi = this.utilsService.getServiziOssigenoDescription(this.meService);
                this.servizio = null;
            } else if (this.type == 'vent') {
                this.servizi = this.utilsService.getServiziVentilazioneDescription(this.meService);
                this.servizio = 'vent';
            } else if (this.type == 'serv') {
                this.servizio = null;
                this.servizi = this.utilsService.getServiziServiziDescription(this.meService);
            }
            this.initForm();
        }
    }
    
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.subscription2) {
            this.subscription2.unsubscribe();
        }
    }

    refresh(state: ClrDatagridStateInterface) {
        if (this.azienda && this.type && this.dateFrom && this.dateTo) {
            this.submitButton = ClrLoadingState.LOADING;
            this.loading = true;
            const command = new ReportCostiCommand(
                this.azienda.id,
                -1,
                null,
                this.dateFrom,
                this.dateTo,
                this.type,
                this.servizio ? this.servizio : null,
                this.distrettoId ? +this.distrettoId : null,
                this.partnerId ? +this.partnerId : null,
                this.pazienteId ? this.pazienteId : null,
                this.extraAsl
            );
            this.reportsService.fetchReportCosti(command).subscribe((result) => {
                if (result) {
                    this.total = 0;
                    this.totMC = 0;
                    this.totCostoFarmaco = 0;
                    this.totCostoServizio = 0;
                    this.totCostoTotale = 0;
                    this.objectList = result.objects;
                    for (const obj of result.objects) {
                        this.totMC += obj.mcConsegnati;
                        this.totCostoFarmaco += obj.costoFarmaco;
                        this.totCostoServizio += obj.costoServizio;
                        this.totCostoTotale += obj.costoTotale;
                    }
                    this.reportsService.fetchReportCostiDistinctPazientiCount(command).subscribe((result) => {
                        if (result) {
                            this.total = result.count;
                        }
                    });
                }
                this.loading = false;
                this.submitButton = ClrLoadingState.DEFAULT;
            });
        }
        this.currentState = state;
    }

    createForm(): void {
        const formGroup = {
            mese: [{ value: 0 }, [Validators.required]],
            anno: [{ value: '' }, [Validators.required]],
            servizio: [{ value: '' }],
            distretto: [{ value: '' }],
            partner: [{ value: '' }],
            paziente: [{ value: '' }],
            extraAsl: [{ value: false }]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initForm() {
        const formValues = {
            mese: this.month,
            anno: this.year,
            servizio: this.servizio || '',
            distretto: '',
            partner: '',
            paziente: '',
            extraAsl: false
        };
        this.form.setValue(formValues);
    }

    openDownloadAlert() {
        this.onSubmit("xls");
    }

    onSubmit(type: any) {
        const formValue = this.form.getRawValue();

        this.year = formValue.anno;
        this.month = formValue.mese;
        
        let mFrom : moment.Moment = moment.utc({ y: this.year, M: this.month }).startOf('month');

        if (!mFrom.isAfter(moment.utc())) {
            this.distrettoId = formValue.distretto;
            this.partnerId = formValue.partner;
            this.pazienteId = formValue.paziente;
            this.servizio = formValue.servizio;
            this.extraAsl = formValue.extraAsl;
            this.dateFrom = mFrom.format('YYYY-MM-DD');
            if (this.year == this.currentYear && this.month == this.currentMonth) {
                this.dateTo = moment.utc().format('YYYY-MM-DD');
            } else {
                this.dateTo = moment.utc({ y: this.year, M: this.month }).endOf('month').format('YYYY-MM-DD');
            }
            this.refresh(this.currentState);
            if (type) {
                const command = new ReportCostiDownloadCommand(
                    this.azienda.id,
                    null,
                    null,
                    this.dateFrom,
                    this.dateTo,
                    this.type,
                    this.servizio ? this.servizio : null,
                    this.distrettoId,
                    this.partnerId,
                    this.pazienteId,
                    this.extraAsl,
                    type,
                );
                this.reportsService.getReportCostiDownloadLink(command).subscribe((url) => {
                    if (url) {
                        window.open(url);
                    }
                });
            }
        }            
    }
}

