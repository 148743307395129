import {ChiusuraTaskDto} from './chiusura-task-dto';

export class ChiusuraTaskVerificaElettricaDto extends ChiusuraTaskDto {
    firmaTecnico: string;
    dataVerifica: Date;
    strumento: string;
    costruttore: string;
    serialNumber: string;
    partiApplicate: string;
    verificaGenerale: boolean;
    controlloComponenti: boolean;
    tensioneL1L2: number;
    tensioneL1PE: number;
    tensioneL2PE: number;
    dispersionePI: number;
    dispersionePAPI: number;
    dispersionePN: number;
    dispersionePAPN: number;
    dispersionePISuperato: boolean;
    dispersionePAPISuperato: boolean;
    dispersionePNSuperato: boolean;
    dispersionePAPNSuperato: boolean;
    correnteAssorbita: number;
    potenzaAssorbita: number;
    risultatoTest: boolean;
    
    constructor(
        dataCompletamento: Date,
        completato: boolean,
        note: string,
        firmaTecnico: string,
        dataVerifica: Date,
        strumento: string,
        costruttore: string,
        serialNumber: string,
        partiApplicate: string,
        verificaGenerale: boolean,
        controlloComponenti: boolean,
        tensioneL1L2: number,
        tensioneL1PE: number,
        tensioneL2PE: number,
        dispersionePI: number,
        dispersionePAPI: number,
        dispersionePN: number,
        dispersionePAPN: number,
        dispersionePISuperato: boolean,
        dispersionePAPISuperato: boolean,
        dispersionePNSuperato: boolean,
        dispersionePAPNSuperato: boolean,
        correnteAssorbita: number,
        potenzaAssorbita: number,
        risultatoTest: boolean
    ) {
        super(dataCompletamento, completato, note);
        this.firmaTecnico = firmaTecnico;
        this.dataVerifica = dataVerifica;
        this.strumento = strumento;
        this.costruttore = costruttore;
        this.serialNumber = serialNumber;
        this.partiApplicate = partiApplicate;
        this.verificaGenerale = verificaGenerale;
        this.controlloComponenti = controlloComponenti;
        this.tensioneL1L2 = tensioneL1L2;
        this.tensioneL1PE = tensioneL1PE;
        this.tensioneL2PE = tensioneL2PE;
        this.dispersionePI = dispersionePI;
        this.dispersionePAPI = dispersionePAPI;
        this.dispersionePN = dispersionePN;
        this.dispersionePAPN = dispersionePAPN;
        this.dispersionePISuperato = dispersionePISuperato;
        this.dispersionePAPISuperato = dispersionePAPISuperato;
        this.dispersionePNSuperato = dispersionePNSuperato;
        this.dispersionePAPNSuperato = dispersionePAPNSuperato;
        this.correnteAssorbita = correnteAssorbita;
        this.potenzaAssorbita = potenzaAssorbita;
        this.risultatoTest = risultatoTest;
    }
}
