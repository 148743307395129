import {Observable, of as observableOf} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Partner} from '../model/query/partner';
import {Prescrizione} from '../model/query/prescrizione';
import {ContrattoPartner} from '../model/query/contratto-partner';
import {Azienda} from '../model/query/azienda';
import {QueryResult} from '../model/query/query-result';
import {GetAllRequest} from '../model/get-all-request';

@Injectable()
export class PartnersService extends BaseService<Partner> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('partners');
    }

    getAziende(partnerId: string): Observable<Array<Azienda>> {
        return this.httpClient.get<Array<ContrattoPartner>>(
            this.baseApiUrl + 'partners/' + partnerId + '/get-all-contratti').pipe(
                switchMap((contratti: Array<ContrattoPartner>): Observable<Array<Azienda>> => { 
                    return observableOf(contratti.map(c => c.contratto.azienda).filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i));
                }),
                catchError(this.handleError(this.baseApiUrl + 'aziende - get all', null))
        );
    }
}
