import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Concentratore} from '../model/query/concentratore';
import {TipoConcentratore} from '../model/tipo-concentratore';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class ConcentratoriService extends BaseService<Concentratore> {

    readonly TIPO_CONCENTRATORE: typeof TipoConcentratore = TipoConcentratore;

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('concentratori');
    }

    getAllPortatili(): Observable<Concentratore[]> {
        return this.getAll(0, 10000000, null, null, null, null, null).pipe(
            switchMap((concentratori) => {
                const list: Concentratore[] = [];
                if (concentratori) {
                    for (const c of concentratori.objects) {
                        if (c.tipo == this.TIPO_CONCENTRATORE.PORTATILE) {
                            list.push(c);
                        }
                    }
                }
                return of(list);
            }));
    }

    getAllFissi(): Observable<Concentratore[]> {
        return this.getAll(0, 10000000, null, null, null, null, null).pipe(
            switchMap((concentratori) => {
                const list: Concentratore[] = [];
                if (concentratori) {
                    for (const c of concentratori.objects) {
                        if (c.tipo == this.TIPO_CONCENTRATORE.FISSO) {
                            list.push(c);
                        }
                    }
                }
                return of(list);
            }));
    }

    // getAllFissi() {
    //     return this.getAll(0, 10000000, null, null, null, null, null)
    //         .subscribe((concentratori) => {
    //             const list: Concentratore[] = [];
    //             if (concentratori) {
    //                 for (const c of concentratori.objects) {
    //                     if (c.tipo == this.TIPO_CONCENTRATORE.FISSO) {
    //                         list.push(c);
    //                     }
    //                 }
    //             }
    //             return list;
    //         });
    // }

}
