import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {ClrDatagridStateInterface} from '@clr/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {QueryResult} from '../../model/query/query-result';
import {DatagridComponent} from '../common/datagrid.component';
import {AttivitaTitolazione} from '../../model/query/attivita-titolazione';
import {MeService} from '../../core/me.service';
import {UtilsService} from '../../core/utils.service';
import {AttivitaTitolazioneService} from '../../core/attivita-titolazione.service';
import {DialogService} from '../../ui/dialog';
import {AuthService} from '../../core/auth.service';
import {Action} from '../../model/action';
import {ActionService} from '../../core/action.service';
import * as moment from 'moment';

@Component({
    selector: 'app-attivita-titolazione',
    templateUrl: './attivita-titolazione.component.html'
})
export class AttivitaTitolazioneComponent extends DatagridComponent<AttivitaTitolazione> {
    completate: boolean = false;
    currentYear = moment.utc().get('y');
    currentMonth = moment.utc().get('M');
    userId: number;
    pfilter: string;
    dateFrom: string;
    dateTo: string;

    constructor(
        protected route: ActivatedRoute,
        public authService: AuthService,
        public attivitaTitolazioneService: AttivitaTitolazioneService,
        private meService: MeService,
        private utilsService: UtilsService,
        public actionService: ActionService,
        dialog: DialogService,
        router: Router
    ) {
        super(attivitaTitolazioneService, router, dialog);
        if (this.authService.isTecnicoVentilazioneUser()) {
            this.meService.getMe().subscribe(user => {
                this.userId = user.id;
            });
        } else if (this.authService.isAmministratoreUser()) {
            this.route.queryParams.subscribe((params) => {
                if (params && params.pfilter) {
                    this.pfilter = params.pfilter;
                } else {
                    this.pfilter = null;
                }
                this.refresh(this.datagridState);
            });
        }
    }

    protected fetchData(
        state: ClrDatagridStateInterface,
        fields: Array<string>,
        operators: Array<string>,
        values: Array<any>
    ): Observable<QueryResult<AttivitaTitolazione>> {
        if (!state.sort) {
            state.sort = {'by': 'dataInserimento', 'reverse': false};
        }
        
        if (this.pfilter && this.pfilter == 'da_processare') {
            fields.push('incaricatoForeignKey');
            operators.push('eq');
            values.push(null);
        }
        if (this.dateFrom && this.dateTo) {
            fields.push('dataInserimento');
            operators.push('ge');
            values.push(this.dateFrom);
            fields.push('dataInserimento');
            operators.push('le');
            values.push(this.dateTo);
        }
        
        let i = fields.indexOf('nonEseguibile');
        
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = values[i] === '%true%';
        }
        i = fields.indexOf('stato');
        if (i != -1) {
            if (values[i] == '%0%') {
                fields[i] = 'incaricatoForeignKey';
                operators[i] = 'eq';
                values[i] = null;
            } else if (values[i] == '%1%') {
                fields[i] = 'nonEseguibile';
                operators[i] = 'eq';
                values[i] = true;
            } else if (values[i] == '%2%') {
                fields[i] = 'dataSchedulata';
                operators[i] = 'eq';
                values[i] = null;
                fields.push('incaricatoForeignKey');
                operators.push('ne');
                values.push(null);
                fields.push('nonEseguibile');
                operators.push('eq');
                values.push(false);
            } else if (values[i] == '%3%') {
                fields[i] = 'dataCompletamento';
                operators[i] = 'eq';
                values[i] = null;
                fields.push('incaricatoForeignKey');
                operators.push('ne');
                values.push(null);
                fields.push('nonEseguibile');
                operators.push('eq');
                values.push(false);
                fields.push('dataSchedulata');
                operators.push('ne');
                values.push(null);
            } else if (values[i] == '%4%') {
                fields[i] = 'dataCompletamento';
                operators[i] = 'ne';
                values[i] = null;
                fields.push('incaricatoForeignKey');
                operators.push('ne');
                values.push(null);
                fields.push('nonEseguibile');
                operators.push('eq');
                values.push(false);
                fields.push('dataSchedulata');
                operators.push('ne');
                values.push(null);
            }
        }
        return super.fetchData(state, fields, operators, values);
    }

    protected getEditUrl(): string {
        return  '/attivita-titolazione/';
    }

    onEdit(entity: AttivitaTitolazione): void {
        const url = this.getEditUrl() + entity.id;
        
        this.router.navigate([url]);
    }

    onFiltraAttivitaTitolazione(range: any): void {
        if (range.year && range.month) {
            this.dateFrom = moment.utc({y: range.year, M: range.month}).startOf('month').format('YYYY-MM-DD HH:mm:SS');
            this.dateTo = moment.utc({y: range.year, M: range.month}).endOf('month').format('YYYY-MM-DD HH:mm:SS');
        } else if (range.year) {
            this.dateFrom = moment.utc({y: range.year, M: 0}).startOf('month').format('YYYY-MM-DD HH:mm:SS');
            this.dateTo = moment.utc({y: range.year, M: 11}).endOf('month').format('YYYY-MM-DD HH:mm:SS');
        } else {
            this.dateFrom = undefined;
            this.dateTo = undefined;
        }
        this.refresh(this.datagridState);
    }

    onDownloadDocumento(id: number) {
        this.attivitaTitolazioneService.getDocumentDownloadUrl(id.toString()).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    onAction(entity: AttivitaTitolazione, action: Action) {
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }
    
    canModify(attivita: AttivitaTitolazione): boolean {
        if (this.authService.isTecnicoVentilazioneUser()) {
            return attivita.incaricatoForeignKey && !attivita.nonEseguibile && (!attivita.dataCompletamento || !attivita.fileName);
        } else if (this.authService.isAmministratoreUser()) {
            return !attivita.incaricatoForeignKey;
        } else {
            return false;
        }
    }
    
    getStato(attivita: AttivitaTitolazione): string {
        if (!attivita.incaricatoForeignKey) {
            return "Non assegnata";
        } else if (attivita.nonEseguibile) {
            return "Non eseguibile";
        }  else if (!attivita.dataSchedulata) {
            return "Assegnata";
        } else if (!attivita.dataCompletamento) {
            return "Schedulata";
        } else {
            return "Completata";
        }
    }
}
