import {ReportCommand} from './report-command';

export class ReportScadenzeCommand extends ReportCommand {
    distrettoId: number;
    partnerId: number;

    constructor(
        aziendaId: number,
        page: number,
        size: number,
        from: string,
        to: string,
        distrettoId: number,
        partnerId: number
    ) {
        super(aziendaId, page, size, from, to);
        this.distrettoId = distrettoId;
        this.partnerId = partnerId;
    }
}
