import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {FornitoriService} from '../../../core/fornitori.service';
import {Fornitore} from '../../../model/query/fornitore';
import {FornitoreCreateCommand} from '../../../model/command/fornitore-create-command';
import {FornitoreUpdateCommand} from '../../../model/command/fornitore-update-command';

@Component({
    selector: 'app-fornitore-detail',
    templateUrl: './fornitore-detail.component.html',
    styleUrls: ['./fornitore-detail.component.css']
})
export class FornitoreDetailComponent implements OnInit {

    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    alertClosed = true;
    alertMessage: String;

    constructor(
        private route: ActivatedRoute,
        private fornitoriService: FornitoriService,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        if (this.id !== 'new') {
            this.fornitoriService.get(this.id).subscribe(entity => {
                this.initializeFormFields(entity);
            });
        } else {
            this.initializeFormFields(null);
        }
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command: FornitoreCreateCommand =
            new FornitoreCreateCommand(formValue.nome, formValue.cognome, formValue.username, formValue.email);
            
        if (!this.id || this.id === 'new') {
            this.fornitoriService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'Backoffice fornitore creato!';
                    this.alertClosed = false;
                }
            });
        } else {
            this.fornitoriService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Backoffice fornitore aggiornato!';
                    this.alertClosed = false;
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            cognome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            username: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            email: ['', [Validators.required, Validators.maxLength(255), Validators.email]],
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(entity: Fornitore): void {
        let formValues;

        if (entity) {
            formValues = {
                nome: entity.nome,
                cognome: entity.cognome,
                username: entity.account.username,
                email: entity.account.email
            };
            this.form.get('username').disable();
        } else {
            formValues = {
                nome: '',
                cognome: '',
                username: '',
                email: ''
            };
        }
        this.form.setValue(formValues);
    }

}
