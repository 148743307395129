import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Incaricato} from '../model/query/incaricato';
import {Observable} from 'rxjs';
import {TipoIncaricato} from '../model/tipo-incaricato';

@Injectable()
export class IncaricatiService extends BaseService<Incaricato> {
    readonly TIPO_INCARICATO: typeof TipoIncaricato = TipoIncaricato;

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('incaricati');
    }

    getList(tipo?: number): Observable<Incaricato[]> {
        return this.httpClient.get<Array<Incaricato>>(tipo === 0 || tipo === 1 ? this.apiUrl + '?tipo=' + tipo : this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + ' - get', null))
        );
    }
}
