import {PrescrizioneVentilazioneCommand} from './prescrizione-ventilazione-command';

export class PrescrizioneVentAttivaCommand {
    codicePaziente: string;
    codicePartner: string;
    telefono: string;
    telefonoFisso: string;
    indirizzoConsegna: string;
    numeroCivicoConsegna: string;
    capConsegna: string;
    nomeDelegato: string;
    cognomeDelegato: string;
    qualificaDelegato: string;
    telefonoDelegato: string;
    prescrizione: PrescrizioneVentilazioneCommand;

    constructor(
        codicePaziente: string,
        codicePartner: string,
        telefono: string,
        telefonoFisso: string,
        indirizzoConsegna: string,
        numeroCivicoConsegna: string,
        capConsegna: string,
        nomeDelegato: string,
        cognomeDelegato: string,
        qualificaDelegato: string,
        telefonoDelegato: string,
        prescrizione: PrescrizioneVentilazioneCommand
    ) {
        this.codicePartner = codicePartner;
        this.codicePaziente = codicePaziente;
        this.telefono = telefono;
        this.telefonoFisso = telefonoFisso;
        this.indirizzoConsegna = indirizzoConsegna;
        this.numeroCivicoConsegna = numeroCivicoConsegna;
        this.capConsegna = capConsegna;
        this.nomeDelegato = nomeDelegato;
        this.cognomeDelegato = cognomeDelegato;
        this.qualificaDelegato = qualificaDelegato;
        this.telefonoDelegato = telefonoDelegato;
        this.prescrizione = prescrizione;
    }
}
