export let dateFields = {
        dataPrescrizione: "Data prima prescrizione",
    };

export let datiCliniciFields = {
        dataEGAInAria: "Data EGA in aria",
        phEGAInAria: "ph EGA in aria",
        o2EGAInAria: "O2 EGA in aria",
        co2EGAInAria: "CO2 EGA in aria",
        co3EGAInAria: "CO3 EGA in aria",
        dataEGASuppl: "Data EGA suppl O2",
        phEGASuppl: "ph EGA suppl O2",
        o2EGASuppl: "O2 EGA suppl O2",
        co2EGASuppl: "CO2 EGA suppl O2",
        co3EGASuppl: "CO3 EGA suppl O2",
        dataWalkingTest: "Data walking test",
        risultatoWalkingTest: "Risultato walking test",
        dataSaturimetria: "Data Saturimetria",
        risultatoSaturimetria: "Risultato saturimetria"
    };

export let diagnosiFields = {
        bpco55: "BPCO INDICAZIONE ASSOLUTA con PaO2 < 55 mmHg",
        bpco60: "BPCO con PaO2 compresa tra 55 e 60 mmHg in presenza",
        bpco60Ipossiemia: "IPOSSIEMIA NOTTURNA",
        bpco60Policitemia: "POLICITEMIA SECONDARIA",
        bpco60Edemi: "EDEMI PERIFERICI",
        bpco60Ipertensione: "IPERTENSIONE POLMONARE",
        fc55: "FIBROSI CISTICA con PaO2 < 55 mmHg",
        fc60: "FIBROSI CISTICA con PaO2 compresa tra 55 e 60 mmHg in presenza",
        fc60Ipossiemia: "IPOSSIEMIA NOTTURNA",
        fc60Policitemia: "POLICITEMIA SECONDARIA",
        fc60Edemi: "EDEMI PERIFERICI",
        fc60Ipertensione: "IPERTENSIONE POLMONARE",
        malattieInterstiziali: "MALATTIE INTERSTIZIALI POLMONARI con PaO2 < 60 mmHg",
        ipertensionePolmonare: "IPERTENSIONE POLMONARE con PaO2 < 60 mmHg",
        neoplasieMaligne: "NEOPLASIE MALIGNE POLMONARI O ALTRE MALATTIE REMINALI CON DISPNEA GRAVE",
        patologieNeuro: "PATOLOGIE NEUROMUSCOLARI E SCHELETRICHE dopo valutazione specialistica",
        apneaOstruttiva: "APNEA OSTRUTTIVA NOTTURNA CON DESATURAZIONE NOTTURNA",
        scompensoCardiaco: "SCOMPENSO CARDIACO con PaO2 < 55 mmHg",
        scompensoCardiacoIpossiemia: "IPOSSIEMIA NOTTURNA",
        desaturazioneNotturna: "DESATURAZIONE NOTTURNA SaO2 < 90% per 30% del sonno",
        desaturazioneEsercizio: "DESATURAZIONE DURANTE ESERCIZIO SaO2 < 90% nel cammino"
    };

export let terapiaFields = {
        o2Liquido: "O2 liquido",
        portatile: "Portatile",
        secondoContenitore: "Richiesta secondo contenitore",
        flussoRiposoLitriMin: "Flusso di O2 a riposo l/min",
        flussoRiposoOre: "Flusso di O2 a riposo ore",
        flussoNotturnoLitriMin: "Flusso di O2 notturno l/min",
        flussoNotturnoOre: "Flusso di O2 notturno ore",
        flussoSottoSforzoLitriMin: "Flusso sotto sforzo l/min",
        flussoSottoSforzoOre: "Flusso sotto sforzo ore",
        totaleLitriMese: "Totale litri mese",
        litriMinuto: "l/min",
        oreTerapia: "Ore",
    };

