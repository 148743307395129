// *************************************************
// * domolife_system Project - © 2020
// *
// * Author: Armando Taglieri (jadbit)
// * Created on: 3/30/20 4:09 PM
// *************************************************

import {ReportCommand} from './report-command';

export class ReportComplianceCommand extends ReportCommand {

    distrettoId: number;
    partnerId: number;
    pazienteId: number;

    constructor(
        aziendaId: number,
        page: number,
        size: number,
        from: string,
        to: string,
        distrettoId: number,
        partnerId: number,
        pazienteId: number
    ) {
        super(aziendaId, page, size, from, to);
        this.distrettoId = distrettoId;
        this.partnerId = partnerId;
        this.pazienteId = pazienteId;
    }
}
