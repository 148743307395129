import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[patternOnly]'
})
export class PatternOnlyDirective {
    private decimalRegex: RegExp = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);
    private intRegex: RegExp = new RegExp(/^[0-9]+$/g);
    private specialKeys: Array<string> = ['Backspace', 'Delete', 'Tab', 'Home', 'End', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];

    constructor(private el: ElementRef) {
    }

    @Input('numberOnly') numberOnly: string;
    @Input('acceptDecimal') acceptDecimal: string;
    @Input('pattern') pattern: string;

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        // Allow special keys
        if (event.ctrlKey || event.altKey || this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }

        let current: string = this.el.nativeElement.value;
        let next: string = current.concat(event.key);

        if (next && !String(next).match(this.numberOnly ? (this.acceptDecimal ? this.decimalRegex : this.intRegex) : this.pattern)) {
            event.preventDefault();
        }
    }
}