import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {KitMateriale} from '../model/query/kit-materiale';
import {MaterialeKit} from '../model/query/materiale-kit';
import {QueryResult} from '../model/query/query-result';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class KitMaterialiService extends BaseService<KitMateriale> {

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('kit-materiali');
    }

    getKitMateriali(): Observable<QueryResult<KitMateriale>> {
        return this.httpClient.get<QueryResult<KitMateriale>>(this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + '- : get kit materiali', null))
        );
    }

    getMateriali(idKit: string): Observable<MaterialeKit[]> {
        return this.httpClient.get<MaterialeKit[]>(this.apiUrl + '/' + idKit + '/materiali-consumabili').pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

}
