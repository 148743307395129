import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {ReportTaskBaseComponent} from "../report-task-base.component";
import {ReportsService} from '../../../core/reports.service';
import {UtilsService} from '../../../core/utils.service';
import {AuthService} from "../../../core/auth.service";
import {Globals} from '../../../core/globals.service';
import {ReportTaskCommand} from '../../../model/command/report-task-command';
import {PazientiService} from "../../../core/pazienti.service";
import {IncaricatiService} from "../../../core/incaricati.service";
import {TaskService} from "../../../core/task.service";
import {Incaricato} from "../../../model/query/incaricato";
import {AziendeCorrentiService} from '../../../core/aziende-correnti.service';
import {Azienda} from '../../../model/query/azienda';
import {Task} from '../../../model/query/task';
import {TaskDocumento} from '../../../model/query/task-documento';
import {DialogService} from '../../dialog';
import {PrescrizioneAction} from '../../../model/prescrizione-action';

@Component({
    selector: 'app-report-planning',
    templateUrl: './report-planning.component.html',
    styleUrls: ['./report-planning.component.css']
})
export class ReportPlanningComponent extends ReportTaskBaseComponent {
    year: any;
    month: any;
    dateFrom: string;
    dateTo: string;
    incaricati: Incaricato[];
    incaricatoId: number;
    tipoTask: string;
    weeks = ['', '', '', '', '', ''];

    constructor(
        protected formBuilder: FormBuilder,
        protected reportsService: ReportsService,
        protected pazientiService: PazientiService,
        protected incaricatiService: IncaricatiService,
        protected aziendeCorrentiService: AziendeCorrentiService,
        public utilsService: UtilsService,
        public globals: Globals,
        public taskService: TaskService,
        public authService: AuthService,
        protected dialog: DialogService,
        private router: Router
    ) {
        super(formBuilder, aziendeCorrentiService, pazientiService, incaricatiService, globals, utilsService, taskService, authService, dialog);
        moment.locale('it');
    }

    initializeDateVariables(): void {
        this.year = moment.utc().get('y');
        this.month = moment.utc().get('M');
        this.dateFrom = moment.utc({y: this.year, M: this.month}).startOf('month').format('YYYY-MM-DD');
        this.dateTo = moment.utc({y: this.year, M: this.month}).endOf('month').format('YYYY-MM-DD');
    }

    initializeData(): Observable<any> {
        this.incaricatoId = null;
        return this.incaricatiService.getAll(0, 1000000, null, null, null, "cognome", "asc").pipe(
            tap(result => this.incaricati = result.objects)
        );
    }

    fetchReport(): Observable<any> {
        let from = moment.utc({y: this.year, M: this.month}).startOf('month');
        this.dateFrom = from.format('YYYY-MM-DD');
        this.dateTo = moment.utc({y: this.year, M: this.month}).endOf('month').format('YYYY-MM-DD');
        
        let next = from.clone();
        
        next.add(((7 - from.day()) % 7) + 1, 'days');
        
        let to = next.clone();
        
        to.add(-1, 'days');
        for (let i = 0; i < 6; ++i) {
            this.weeks[i] = from.format('DD MMM') + ' - ' +  to.format('DD MMM');
            from = next.clone();
            next.add(7, 'days');
            to.add(7, 'days');
        }
        
        const command = new ReportTaskCommand(
            this.azienda.id,
            this.currentPage,
            this.sizePage,
            this.dateFrom,
            this.dateTo,
            this.incaricatoId,
            this.tipoTask
        );
        
        return this.reportsService.fetchReportPlanning(command);
    }
    
    createForm(): void {
        const formGroup = {
            mese: [{value: ''}, [Validators.required]],
            anno: [{value: ''}, [Validators.required]],
            incaricato: [{ value: "" }],
            tipoTask: [{ value: "" }]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initForm() {
        const formValues = {
            mese: this.month,
            anno: this.year,
            incaricato: '',
            tipoTask: ''
        };
        this.form.setValue(formValues);
    }

    readFormValues(formValue: any): void {
        this.year = formValue.anno;
        this.month = formValue.mese;
        this.incaricatoId = formValue.incaricato ? formValue.incaricato : null;
        this.tipoTask = formValue.tipoTask ? formValue.tipoTask : null;
    }

    onEditTask(task: any) {
        const url = '/prescrizioni/' + task.prescrizioneId;
        const queryParams = {type: 'vent'};
        
        queryParams['action'] = PrescrizioneAction.MODIFICA;
        queryParams['prescrizioneVentilatore'] = task.prescrizioneVentilatoreId;
        queryParams['task'] = task.id;
        this.router.navigate([url], {queryParams: queryParams});
    }
}
