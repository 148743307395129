export class PrescrizioneVentilatoreCommandDto {
    id: number;
    ventilatoreId: number;
    profiloId: number;
    quantita: number;
    modalitaId: number;
    parametriIds: Array<number>;
    valori: Array<number>;
    materialiIds: Array<number>;
    parametriAllarmeIds: Array<number>;
    mins: Array<number>;
    maxs: Array<number>;
    modelloMaschera: string;
    marcaMaschera: string;
    tipoMaschera: string;
    tagliaMaschera: string;
}
