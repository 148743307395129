import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {IncaricatiService} from '../../../core/incaricati.service';
import {Incaricato} from '../../../model/query/incaricato';
import {IncaricatoCreateCommand} from '../../../model/command/incaricato-create-command';
import {IncaricatoUpdateCommand} from '../../../model/command/incaricato-update-command';
import {ActionService} from '../../../core/action.service';
import {Action} from '../../../model/action';

@Component({
    selector: 'app-incaricato-detail',
    templateUrl: './incaricato-detail.component.html',
    styleUrls: ['./incaricato-detail.component.css']
})
export class IncaricatoDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    alertMessage: string;
    alertClosed = true;
    action: Action;
    readOnly = false;


    constructor(
        private route: ActivatedRoute,
        private incaricatiService: IncaricatiService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        public actionService: ActionService,
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        if (this.id !== 'new') {
            this.incaricatiService.get(this.id).subscribe((incaricato: Incaricato) => {
                this.initializeFormFields(incaricato);
                if (this.action != null && this.action == this.actionService.ACTION.VIEW) {
                    this.readOnly = true;
                }
            });
        } else {
            this.initializeFormFields(null);
        }
    }

    createForm(): void {
        const formGroup = {
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            cognome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            username: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            email: ['', [Validators.required, Validators.maxLength(255), Validators.email]],
            tipoIncaricato: ''
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(incaricato: Incaricato): void {
        let formValues;

        if (incaricato) {
            formValues = {
                nome: incaricato.nome,
                cognome: incaricato.cognome,
                username: incaricato.account.username,
                email: incaricato.account.email,
                tipoIncaricato: incaricato.tipoIncaricato
            };
            this.form.get('username').disable();
            this.form.get('tipoIncaricato').disable();
        } else {
            formValues = {
                nome: '',
                cognome: '',
                username: '',
                email: '',
                tipoIncaricato: this.incaricatiService.TIPO_INCARICATO.COMMERCIALE
            };
        }
        this.form.setValue(formValues);
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command: IncaricatoCreateCommand =
            new IncaricatoCreateCommand(formValue.nome, formValue.cognome, formValue.username, formValue.email, formValue.tipoIncaricato);
            
        if (this.id === 'new') {
            this.incaricatiService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = "Incaricato creato!";
                    this.alertClosed = false;
                }
            });
        } else {
            this.incaricatiService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = "Incaricato aggiornato!";
                    this.alertClosed = false;
                }
            });
        }

    }
}
