import {AbstractCommand} from './abstract-command';

export class SaturimetroCommand extends AbstractCommand {
    nome: string;

    constructor(
        nome: string
    ) {
        super();
        this.nome = nome;
    }
}
