import {AbstractCommand} from './abstract-command';

export class TaskDocumentoCommand extends AbstractCommand {
    taskId: string;
    fileName: string;

    constructor(taskId: string, fileName: string) {
        super();
        this.taskId = taskId;
        this.fileName = fileName;
    }
}
