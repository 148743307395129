import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Operatore} from '../model/query/operatore';
import {Azienda} from '../model/query/azienda';
import {Contratto} from '../model/query/contratto';

@Injectable()
export class OperatoriService extends BaseService<Operatore> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('operatori');
    }

    getAziende(id: string): Observable<Array<Azienda>> {
        return this.httpClient.get<Array<Azienda>>(this.apiUrl + '/' + id + '/aziende').pipe(
            catchError(this.handleError(this.apiUrl + '- : get aziende', null))
        );
    }

    getContratti(id: string): Observable<Array<Contratto>> {
        return this.httpClient.get<Array<Contratto>>(this.apiUrl + '/' + id + '/contratti').pipe(
            catchError(this.handleError(this.apiUrl + '- : get contratti', null))
        );
    }
}
