import {Component} from '@angular/core';
import {Subscription} from 'rxjs';
import {ClrDatagridStateInterface} from '@clr/angular';
import {FormBuilder, Validators} from '@angular/forms';
import * as moment from 'moment';
import {ReportsService} from '../../../core/reports.service';
import {UtilsService} from '../../../core/utils.service';
import {AuthService} from '../../../core/auth.service';
import {Prescrittore} from '../../../model/query/prescrittore';
import {Struttura} from '../../../model/query/struttura';
import {Profilo} from '../../../model/query/profilo';
import {Ventilatore} from '../../../model/query/ventilatore';
import {PrescrittoriService} from '../../../core/prescrittori.service';
import {StruttureService} from '../../../core/strutture.service';
import {AziendeService} from '../../../core/aziende.service';
import {PrescrizioniService} from '../../../core/prescrizioni.service';
import {ReportPazientiVentCommand} from '../../../model/command/report-pazienti-vent-command';
import {ReportPazientiVentDownloadCommand} from '../../../model/command/report-pazienti-vent-download-command';
import {forkJoin} from 'rxjs';
import {QueryResult} from '../../../model/query/query-result';
import {StatoPrescrizione} from '../../../model/stato-prescrizione';
import {AziendeCorrentiService} from '../../../core/aziende-correnti.service';
import {Azienda} from '../../../model/query/azienda';
import {DialogService} from '../../dialog';
import {MeService} from '../../../core/me.service';

@Component({
    selector: 'app-report-pazienti-vent',
    templateUrl: './report-pazienti-vent.component.html',
    styleUrls: ['./report-pazienti-vent.component.css']
})
export class ReportPazientiVentComponent {
    subscription: Subscription;
    total: number = 0;
    loading = false;
    form: any;
    year: any;
    month: any;
    dateFrom: string;
    dateTo: string;

    objectList: any[] = [];
    object: any;
    currentState: ClrDatagridStateInterface;

    azienda: Azienda;
    prescrittoreId: number;
    strutturaId: number;
    profiloId: number;
    ventilatoreId: number;
    stato: StatoPrescrizione;
    prescrittori: Prescrittore[];
    strutture: Struttura[];
    profili: Profilo[];
    ventilatori: Ventilatore[];
    statiDescription: Array<string>;

    constructor(
        private formBuilder: FormBuilder,
        private reportsService: ReportsService,
        public utilsService: UtilsService,
        private meService: MeService,
        private prescrittoriService: PrescrittoriService,
        private struttureService: StruttureService,
        private aziendeService: AziendeService,
        private aziendeCorrentiService: AziendeCorrentiService,
        public authService: AuthService,
        private dialog: DialogService
    ) {
        this.createForm();
        this.statiDescription = this.getStatiDescription();
        this.subscription = this.aziendeCorrentiService.getAzienda().subscribe(azienda => {
            if (azienda != null) {
                this.prescrittoreId = null;
                this.strutturaId = null;
                this.stato = StatoPrescrizione.ATTIVATA;

                const requestList = [
                    this.prescrittoriService.getAll(0, 100000000, ['aziendaForeignKey'], ['eq'], [azienda.id], 'cognome', 'asc'),
                    this.struttureService.getAll(0, 1000000, ['aziendaForeignKey'], ['eq'], [azienda.id], 'nome', 'asc'),
                    this.aziendeService.getProfili(azienda.id.toString()),
                    this.aziendeService.getVentilatori(azienda.id.toString())
                ];
                
                forkJoin(requestList).subscribe(([prescrittoriResults, struttureResults, profiliResults, ventilatoriResults]) => {
                    this.prescrittori = (<QueryResult<Prescrittore>> prescrittoriResults).objects;
                    this.strutture = (<QueryResult<Struttura>> struttureResults).objects;
                    this.profili = (<Array<Profilo>> profiliResults);
                    this.ventilatori = (<Array<Ventilatore>> ventilatoriResults);
                    this.initForm();
                    this.azienda = azienda;
                });
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    refresh(state: ClrDatagridStateInterface) {
        if (this.azienda) {
            this.loading = true;
            const command = new ReportPazientiVentCommand(
                this.azienda.id,
                -1,
                0,
                this.dateFrom,
                this.dateTo,
                this.prescrittoreId ? +this.prescrittoreId : null,
                this.strutturaId ? +this.strutturaId : null,
                this.profiloId ? +this.profiloId : null,
                this.ventilatoreId ? +this.ventilatoreId : null,
                this.stato ? this.stato : null
            );
            this.reportsService.fetchReportPazientiVent(command).subscribe((result) => {
                if (result) {
                    this.total = result.count;
                    this.objectList = result.objects;
                }
                this.loading = false;
            });
        }
        this.currentState = state;
    }

    getStatiDescription(): Array<string> {
        const states = Object.keys(StatoPrescrizione);
        return states.slice(states.length / 2);
    }

    createForm(): void {
        const formGroup = {
            mese: [{value: ''}],
            anno: [{value: ''}],
            prescrittore: [{value: this.prescrittoreId}],
            struttura: [{value: this.strutturaId}],
            profilo: [{value: this.profiloId}],
            ventilatore: [{value: this.ventilatoreId}],
            stato: [{value: this.stato}]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initForm() {
        const formValues = {
            mese: '',
            anno: '',
            prescrittore: '',
            struttura: '',
            profilo: '',
            ventilatore: '',
            stato: '2'
        };
        this.form.setValue(formValues);
    }


    openDownloadAlert() {
        this.onSubmit("xls");
    }

    onSubmit(type: any) {
        const formValue = this.form.getRawValue();
        
        this.total = 0;
        this.objectList = [];
        
        this.year = formValue.anno;
        this.month = formValue.mese;

        let mFrom : moment.Moment = this.year ? moment.utc({ y: this.year, M: this.month }).startOf('month') : undefined;

        if (!mFrom || !mFrom.isAfter(moment.utc())) {
            this.prescrittoreId = formValue.prescrittore ? formValue.prescrittore : null;
            this.strutturaId = formValue.struttura ? formValue.struttura : null;
            this.profiloId = formValue.profilo ? formValue.profilo : null;
            this.ventilatoreId = formValue.ventilatore ? formValue.ventilatore : null;
            this.stato = formValue.stato ? formValue.stato : null;
            this.dateFrom = mFrom ? mFrom.format('YYYY-MM-DD') : null;
            this.dateTo = this.dateFrom ? moment.utc({y: this.year, M: this.month}).endOf('month').format('YYYY-MM-DD') : null;
            this.refresh(this.currentState);
            if (type) {
                const command = new ReportPazientiVentDownloadCommand(
                    this.azienda.id,
                    null,
                    null,
                    this.dateFrom,
                    this.dateTo,
                    this.prescrittoreId ? +this.prescrittoreId : null,
                    this.strutturaId ? +this.strutturaId : null,
                    this.profiloId ? +this.profiloId : null,
                    this.ventilatoreId ? +this.ventilatoreId : null,
                    this.stato,
                    type
                );
                this.reportsService.getReportPazientiVentDownloadLink(command).subscribe((url) => {
                    if (url) {
                        // window.location.href = url;
                        window.open(url);
                    }
                });
            }
        }
    }
}
