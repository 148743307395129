import {Component} from '@angular/core';
import {Subscription} from 'rxjs';
import {forkJoin} from 'rxjs';
import {ClrDatagridStateInterface} from '@clr/angular';
import * as moment from 'moment';
import {FormBuilder, Validators} from '@angular/forms';
import {ReportsService} from '../../../core/reports.service';
import {UtilsService} from '../../../core/utils.service';
import { AuthService } from "../../../core/auth.service";
import {ReportConsegneCommand} from '../../../model/command/report-consegne-command';
import {ReportDownloadConsegneCommand} from '../../../model/command/report-download-consegne-command';
import {Distretto} from '../../../model/query/distretto';
import {PazientiService} from "../../../core/pazienti.service";
import {Paziente} from "../../../model/query/paziente";
import {AziendeCorrentiService} from '../../../core/aziende-correnti.service';
import {Azienda} from '../../../model/query/azienda';
import { PartnersService } from "../../../core/partners.service";
import { Partner } from "../../../model/query/partner";
import {DialogService} from '../../dialog';

@Component({
    selector: 'app-report-consegne',
    templateUrl: './report-consegne.component.html',
    styleUrls: ['./report-consegne.component.css']
})
export class ReportConsegneComponent {
    subscription: Subscription;

    total: number;
    loading = false;
    form: any;

    objectList: any[] = [];
    object: any;
    currentState: ClrDatagridStateInterface;

    azienda: Azienda;
    year: any;
    month: any;
    dateFrom: string;
    dateTo: string;
    distrettoId: number;
    pazienti: Paziente[];
    pazienteId: number;
    partners: Partner[];
    partnerId: number;
    days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    distretti: Distretto[];

    sizePage: number = 20;
    currentPage: number = 0;
    pages: number = 0;

    constructor(
        private formBuilder: FormBuilder,
        private reportsService: ReportsService,
        private partnersService: PartnersService,
        private pazientiService: PazientiService,
        private aziendeCorrentiService: AziendeCorrentiService,
        public utilsService: UtilsService,
        public authService: AuthService,
        private dialog: DialogService
    ) {
        this.createForm();
        this.subscription = this.aziendeCorrentiService.getAzienda().subscribe(azienda => {
           if (azienda != null) {
                this.year = moment.utc().get('y');
                this.month = moment.utc().get('M');
                this.partnerId = null;
                this.distrettoId = null;
                this.pazienteId = null;
                this.aziendeCorrentiService.getDistretti().subscribe(results => this.distretti = results);

                const getPazienti = this.pazientiService.getAll(0, 1000000, ["comune.distretto.aziendaForeignKey"], ["eq"], [azienda.id.toString()], "cognome", "asc");
                const getPartners = this.partnersService.getAll(0, 1000000, null, null, null, null, null);
                
                forkJoin([getPazienti, getPartners]).subscribe(
                    (results) => {
                        this.pazienti = results[0].objects;
                        this.partners = results[1].objects;
                        this.initForm();
                        this.azienda = azienda;
                    });
            }
        });
            
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    next(){
        if(this.currentPage+1 == this.pages) return;
        this.currentPage = this.currentPage +1;
        this.refreshNormalTable();
    }

    prev(){
        if(this.currentPage == 0) return;
        this.currentPage = this.currentPage -1;
        this.refreshNormalTable();
    }


    onChangeSizePage(){
        this.currentPage = 0
        this.refreshNormalTable();
    }

    refreshNormalTable(){

        if (this.azienda && this.dateFrom && this.dateTo) {
            this.loading = true;
            const command = new ReportConsegneCommand(
                this.azienda.id,
                this.currentPage,
                this.sizePage,
                this.dateFrom,
                this.dateTo,
                this.distrettoId,
                this.pazienteId,
                this.partnerId ? +this.partnerId : null
            );
            this.objectList = [];
            this.pages = 0;
            this.reportsService.fetchReportConsegne(command).subscribe((result) => {
                if (result) {
                    this.total = result.count;
                    if (result.objects && result.objects.length > 0) {
                        this.pages =  Math.ceil(this.total / this.sizePage)
                        this.objectList = result.objects;
                    }
                }
                this.loading = false;
            });
        }
    }

    createForm(): void {
        const formGroup = {
            mese: [{value: ''}, [Validators.required]],
            anno: [{value: ''}, [Validators.required]],
            distretto: [{value: this.distrettoId}],
            partner: [{ value: "" }],
            paziente: [{ value: "" }]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initForm() {
        const formValues = {
            mese: this.month,
            anno: this.year,
            distretto: '',
            partner: "",
            paziente: ''
        };
        this.form.setValue(formValues);
    }

    openDownloadAlert() {
        this.onSubmit("xls");
    }

    onSubmit(type: any) {
        const formValue = this.form.getRawValue();
        
        this.total = 0;
        this.objectList = [];
        
        this.currentPage = 0;
        this.sizePage = 20;
        this.year = formValue.anno;
        this.month = formValue.mese;

        let mFrom : moment.Moment = moment.utc({ y: this.year, M: this.month }).startOf('month');

        if (!mFrom.isAfter(moment.utc())) {
            this.distrettoId = formValue.distretto ? formValue.distretto : null;
            this.pazienteId = formValue.paziente;
            this.partnerId = formValue.partner;
            this.dateFrom = mFrom.format('YYYY-MM-DD');
            this.dateTo = moment.utc({y: this.year, M: this.month}).endOf('month').format('YYYY-MM-DD');
            this.refreshNormalTable();

            if (type) {
                const command = new ReportDownloadConsegneCommand(
                    this.azienda.id,
                    this.dateFrom,
                    this.dateTo,
                    this.distrettoId,
                    this.pazienteId,
                    this.partnerId,
                    type
                );
                this.reportsService.getReportConsegneDownloadLink(command).subscribe((url) => {
                    if (url) {
                        // window.location.href = url;
                        window.open(url);
                    }
                });
            }
        }
    }
}
