import {Component, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit, HostListener} from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
    selector: 'app-fs-dialog',
    templateUrl: './fs-dialog.component.html',
    styleUrls: ['./fs-dialog.component.css']
})
export class FsDialogComponent {
    signaturePad!: SignaturePad;
    @ViewChild('canvas', {static: false, read: ElementRef}) canvasEl!: ElementRef;
    @Output() closeDialog = new EventEmitter<any>();
    isModalOpen: boolean = true;

    constructor() {}

    ngAfterViewInit() {
        this.signaturePad = new SignaturePad(this.canvasEl.nativeElement, {minWidth: 5});
        this.resizeCanvas();
    }

    doSave(): void {
        let imgData = this.signaturePad.toDataURL("image/svg+xml");
        var ratio = this.canvasEl.nativeElement.width / this.canvasEl.nativeElement.height;
        var width = 640;
        var height = 640 / ratio;

        var img = new Image;
        var closeDialog = this.closeDialog;

        img.onload = function () {
            var canvas2 = document.createElement("canvas");
            
            canvas2.width = width;
            canvas2.height = height;
            
            var ctx = canvas2.getContext("2d");
            
            ctx.drawImage(img, 0, 0, width, height);
            canvas2.toBlob(blob => {
                closeDialog.emit({file: new File([blob], "firma.png"), url: canvas2.toDataURL().substring(22)});
            });
        };
        img.src = imgData;
    }

    doClose(): void {
        this.closeDialog.emit(null);
    }

    clearPad() {
        this.signaturePad.clear();
    }

    @HostListener('window:resize')
    resizeCanvas(): void {
        this.canvasEl.nativeElement.style.width = '100%';
        this.canvasEl.nativeElement.style.height = '100%';
        this.canvasEl.nativeElement.width = this.canvasEl.nativeElement.offsetWidth;
        this.canvasEl.nativeElement.height = this.canvasEl.nativeElement.offsetHeight;

        var width = this.canvasEl.nativeElement.width;
        var height = this.canvasEl.nativeElement.height;
        var ratio = Math.max(window.devicePixelRatio || 1, 1);

        if (ratio <= 2) {
            this.canvasEl.nativeElement.width = width * ratio;
            this.canvasEl.nativeElement.height = height * ratio;
            this.canvasEl.nativeElement
                .getContext("2d")
                .scale(ratio, ratio);
        }
    }
}
