import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../core/auth.service';
import {AziendeCorrentiService} from '../../../core/aziende-correnti.service';
import {Router} from '@angular/router';
import {forkJoin, from, Observable} from 'rxjs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
import {catchError} from 'rxjs/operators';
import {Azienda} from '../../../model/query/azienda';
import {environment} from '../../../../environments/environment';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styles: []
})
export class HeaderComponent implements OnInit {
    aziende: Array<Azienda>;
    azienda: string;
    development: boolean;

    constructor(
        public authService: AuthService,
        private aziendeCorrentiService: AziendeCorrentiService,
        public router: Router
    ) {
        this.development= environment.development;
    }

    ngOnInit() {
        this.aziendeCorrentiService.getAziende().subscribe(aziende => {
            this.aziende = aziende;
            if (aziende.length > 0) {
                this.azienda = aziende[0].id.toString();
            }
        });
    }

    onSelectAzienda(value: any): void {
        this.aziendeCorrentiService.setAzienda(value);
        this.azienda = value;
    }
    
    printPage() {
        let pdf: any;

        window.scrollTo(0, 0);

        const body = document.body, html = document.documentElement;
        const pageHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const numberOfImages = Math.ceil(pageHeight / body.offsetHeight);
        const imageHeight = Math.trunc(pageHeight / numberOfImages);
        const observables: Observable<HTMLCanvasElement>[] = [];

        document.body.classList.add('busy-cursor');
        for (let i = 0; i < numberOfImages; i++) {
            const observable = from(html2canvas(document.getElementById('printable-content'), {
                width: window.innerWidth,
                height: imageHeight,
                allowTaint: true,
                useCORS: true,
                imageTimeout: 0,
                // foreignObjectRendering: true,
                x: 0,
                scrollX: 0,
                y: i === 0 ? 0 : imageHeight * i,
                scrollY: i === 0 ? 0 : imageHeight * i,
                onclone: (element) => {
                    element.getElementById('printable-content').classList.remove('marginFromSidebar');
                    element.getElementsByClassName('marginFromStickyHeader')[0].classList.remove('marginFromStickyHeader');
                }
            }));
            observables.push(observable);
        }

        const docDefinition = {
            pageMargins: 15,
            content: []
        };

        forkJoin(observables).subscribe(
            data => {
                console.log(data);
                let i = 0;
                for (const canvas of data) {
                    // this.downloadCanvas(canvas, 'generatedCanvas.png');
                    if (i === 0) {
                        docDefinition['pageSize'] = {
                            width: canvas.width,
                            height: canvas.height,
                        };
                    }
                    const imageObject = {
                        image: canvas.toDataURL(),
                        width: canvas.width,
                    };
                    if (i < numberOfImages - 1) {
                        imageObject['pageBreak'] = 'after';
                    }
                    docDefinition['content'].push(imageObject);
                    i++;
                }
                pdf = pdfMake.createPdf(docDefinition);
                
                var win = window.open('', '_blank');
                
                pdf.open({}, win);
                document.body.classList.remove('busy-cursor');
            },
            err => catchError(err)
        );
    }

    downloadCanvas(canvas, fileName) {
        const canvasDataUrl = canvas.toDataURL().replace(/^data:image\/[^;]*/, 'data:application/octet-stream');
        const link = document.createElement('a');
        link.setAttribute('href', canvasDataUrl);
        link.setAttribute('target', '_blank');
        link.setAttribute('download', fileName);
        if (document.createEvent) {
            const evtObj = document.createEvent('MouseEvents');
            evtObj.initEvent('click', true, true);
            link.dispatchEvent(evtObj);
        } else if (link.click) {
            link.click();
        }
    }

}
