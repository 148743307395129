import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {Cittadinanza} from '../../../model/cittadinanza';
import {Paziente} from '../../../model/query/paziente';
import {PazientiService} from '../../../core/pazienti.service';
import {Comune} from '../../../model/query/comune';
import {Nazione} from '../../../model/query/nazione';
import {FiscalCodeValidator} from '../../../core/utils.service';
import {PazienteCreateCommand} from '../../../model/command/paziente-create-command';
import {PazienteUpdateCommand} from '../../../model/command/paziente-update-command';
import * as moment from 'moment';
import {ComuniService} from '../../../core/comuni.service';
import {NazioniService} from '../../../core/nazioni.service';
import {AuthService} from '../../../core/auth.service';
import {MeService} from '../../../core/me.service';
import {AziendeService} from '../../../core/aziende.service';
import {Azienda} from '../../../model/query/azienda';
import {forkJoin} from 'rxjs';
import {Prescrittore} from '../../../model/query/prescrittore';
import {QueryResult} from '../../../model/query/query-result';
import {ZonaDiConsegna} from '../../../model/query/zona-di-consegna';
import {Action} from '../../../model/action';
import {ActionService} from '../../../core/action.service';
import {UtilsService} from '../../../core/utils.service';
import {PrescrizioniService} from '../../../core/prescrizioni.service';
import {PrescrizioneActionsService} from '../../../core/prescrizione-actions.service';
import {DialogService} from '../../dialog';

@Component({
    selector: 'app-paziente-detail',
    templateUrl: './paziente-detail.component.html',
    styleUrls: ['./paziente-detail.component.css']
})
export class PazienteDetailComponent implements OnInit {
    readonly CITTADINANZA: typeof Cittadinanza = Cittadinanza;

    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    selectedCittadinanza: Cittadinanza;
    paziente: Paziente;
    comuni: Comune[];
    nazioni: Nazione[];
    nazioneId = '';
    modalOpened: boolean;
    showModalCheckFiscalCode = false;
    alertMessage: string;
    alertClosed = true;
    fromPrescrizione = false;
    initialFiscalCode: string;
    isPrescrittore = false;
    isPartner = false;
    aziendaId: number;
    aziendaNome: string;
    aziende: Azienda[];
    allFieldsDisabled = false;
    action: Action;
    statoPaziente: number;
    serviziAttivi = [];
    prescrizioni = [];
    prescrizioniServizi = [];
    serviziDescription: any;
    tipiPrescrizioniDescription: any;
    selectedObjects = [];
    selectedServiziObjects = [];
    prescrizioneId: string;
    prescrizioneType: string;
    prescrizioneAction: string;
    maxDataNascita: string;
    showComuneConsegnaModal: boolean = false;
    province: Array<string>;
    comuniProvincia: Array<Comune> = new Array<Comune>();
    comuneDiConsegnaId: number;
    modalComuneDiConsegnaId: string;
    zoneDiConsegna: Array<ZonaDiConsegna> = new Array<ZonaDiConsegna>();

    constructor(
        private route: ActivatedRoute,
        private pazientiService: PazientiService,
        private comuniService: ComuniService,
        private nazioniService: NazioniService,
        public prescrizioniService: PrescrizioniService,
        private prescrizioneActionsService: PrescrizioneActionsService,
        private utilsService: UtilsService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        private router: Router,
        public authService: AuthService,
        public aziendeService: AziendeService,
        public meService: MeService,
        public actionService: ActionService,
        private dialog: DialogService
    ) {
        this.isPrescrittore = this.authService.isPrescrittoreUser();
        this.isPartner = this.authService.isPartnerUser();
        this.comuni = new Array<Comune>();
        this.nazioni = new Array<Nazione>();
        this.createForm();
        this.maxDataNascita = this.utilsService.formatDate(new Date(), 'YYYY-MM-DD');
        this.serviziDescription = this.utilsService.getServiziDescription();
        this.tipiPrescrizioniDescription = this.utilsService.getServiziDescription();
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.id = params.id;
            this.route.queryParams.subscribe(params => {
                if (params) {
                    if (params.action) {
                        this.action = params.action;
                    }
                    if (params.prescrizioneId) {
                        this.prescrizioneId = params.prescrizioneId;
                        this.prescrizioneType = params.prescrizioneType;
                        this.prescrizioneAction = params.prescrizioneAction;
                    }
                    if (params.fiscalCode) {
                        this.fromPrescrizione = true;
                        this.initialFiscalCode = params.fiscalCode;
                    }
                }
                this.init();
            });
        });
    }

    init() {
        const requestList = [
            this.meService.getMe(),
            this.aziendeService.getAll(0, 1000000, null, null, null, null, null),
            this.nazioniService.getList(),
            this.comuniService.getProvince(),
            this.pazientiService.getZoneDiConsegna()
        ];
        
        if (this.id !== 'new') {
            requestList.push(this.pazientiService.getNumeroPrescrizioni(+this.id));
            requestList.push(this.pazientiService.getPrescrizioni(+this.id, true));
            requestList.push(this.pazientiService.getPrescrizioni(+this.id, false));
            requestList.push(this.pazientiService.getServiziAttivi(+this.id));
            requestList.push(this.pazientiService.get(this.id));
        }
        forkJoin(requestList).subscribe((results: [Prescrittore, QueryResult<Azienda>, QueryResult<Nazione>, Array<string>, Array<ZonaDiConsegna>, number, QueryResult<any>, QueryResult<any>, any, Paziente]) => {
            const paziente: Paziente = results[9];
            const aziende: QueryResult<Azienda> = results[1];
            const nazioni: QueryResult<Nazione> = results[2];
            const prescrizioniConScadenza: QueryResult<any> = results[6];
            const prescrizioniServizi: QueryResult<any> = results[7];
            const servizi: any = results[8];
            const totale: number = results[5];
            const province: Array<string> = results[3];
            const zoneDiConsegna: Array<ZonaDiConsegna> = results[4];
            
            this.paziente = paziente;
            this.comuneDiConsegnaId = paziente && paziente.comuneDiConsegna ? paziente.comuneDiConsegna.id : undefined;
            this.aziende = aziende.objects;
            this.nazioni = nazioni.objects;
            this.province = province;
            this.zoneDiConsegna = zoneDiConsegna;
            if (totale > 0) {
                if (!this.authService.isAmministratoreUser() && !this.authService.isPrescrittoreUser() && !this.authService.isOperatoreUser()) {
                    this.allFieldsDisabled = true;
                }
            }
            if (prescrizioniConScadenza) {
                this.prescrizioni = prescrizioniConScadenza.objects;
            }
            if (prescrizioniServizi) {
                this.prescrizioniServizi = prescrizioniServizi.objects;
            }
            if (servizi) {
                for (const s of servizi) {
                    if (!this.serviziAttivi.includes(this.serviziDescription[s])) {
                        this.serviziAttivi.push(this.serviziDescription[s]);
                    }
                }
            }
            
            let observableComuni: Observable<Array<Comune>>;
            
            if (this.isPrescrittore) {
                const prescrittore: Prescrittore = results[0];
                
                if (prescrittore.azienda && prescrittore.azienda.id) {
                    this.aziendaId = prescrittore.azienda.id;
                    this.aziendaNome = prescrittore.azienda.nome;
                    observableComuni = this.comuniService.getAziendaComuni(this.aziendaId);
                }
            } else if (paziente) {
                observableComuni = this.comuniService.getAziendaComuni(paziente.comune.distretto.azienda.id);
            }
            this.onSelectCittadinanza(paziente && paziente.cittadinanza ? paziente.cittadinanza : Cittadinanza.ITALIANA);
            if (observableComuni) {
                observableComuni.subscribe((comuni) => {
                    this.comuni = comuni;
                    this.sortComuni();
                    if (paziente) {
                        this.nazioneId = paziente.nazione && paziente.nazione.id ? paziente.nazione.id.toString() : '';
                        this.initializeFormFields(paziente);
                        if ((this.action != null && this.action == this.actionService.ACTION.VIEW) || paziente.dataFineValidita) {
                            this.allFieldsDisabled = true;
                        }
                    } else {
                        this.initializeFormFields(null);
                    }
                });
            } else {
                this.comuni = [];
                this.initializeFormFields(null);
            }
        });
    }

    checkCodiceFiscale(value: string) {
        if (this.id === 'new') {
            if (this.form.get('codiceFiscale').valid) {
                if (this.initialFiscalCode && this.initialFiscalCode !== value) {
                    this.showModalCheckFiscalCode = true;
                    this.form.patchValue({codiceFiscale: ''});
                    return;
                } else {
                    this.showModalCheckFiscalCode = false;
                    this.form.get('codiceFiscale').disable();
                }
                this.pazientiService.getByCodiceFiscale(value).subscribe((paziente: Paziente) => {
                    if (paziente) {
                        this.modalOpened = true;
                    }
                });
            }
        }
    }

    onYesButton() {
        this.modalOpened = false;
        this.pazientiService.getByCodiceFiscale(this.form.get('codiceFiscale').value).subscribe((paziente: Paziente) => {
            if (paziente) {
                this.id = paziente.id.toString();
                this.initializeFormFields(paziente);
            }
        });
    }

    onNoButton() {
        this.modalOpened = false;
        this.router.navigate(['/pazienti']);
    }

    onSelectCittadinanza(value: Cittadinanza) {
        this.selectedCittadinanza = value;
        if (this.selectedCittadinanza == this.CITTADINANZA.STRANIERA) {
            this.form.get("nazioneId").enable();
            this.form.patchValue({'nazioneId': ''});
        } else {
            this.form.get("nazioneId").disable();
            if (this.selectedCittadinanza == this.CITTADINANZA.ITALIANA) {
                this.form.patchValue({'nazioneId': this.nazioni.find(n => n.nome = 'Italia').id});
            }
        }            
    }

    onSelectAzienda(event) {
        this.form.patchValue({'distretto': ''});
        this.form.patchValue({'comuneId': ''});
        this.comuniService.getAziendaComuni(event.target.value).subscribe((comuni) => {
            this.comuni = comuni;
        });
    }

    onSelectComune(value: number) {
        const comune: Comune = this.findComune(value);
        
        if (comune) {
            const values = {
                // azienda: comune.distretto.azienda.nome,
                distretto: comune.distretto.nome
            };

            this.form.patchValue(values);
        }
    }

    private findComune(id: number) {
        return this.comuni.find(c => c.id === id);
    }
    
    onSelectExtraAsl() {
        const formValue = this.form.getRawValue();

        if (!formValue.extraAsl) {
            this.form.get('indirizzo').setValidators([Validators.required]);
            this.form.get('indirizzo').updateValueAndValidity();
            this.form.get('numeroCivico').setValidators([Validators.required]);
            this.form.get('numeroCivico').updateValueAndValidity();
        } else {
            this.form.get('indirizzo').clearValidators();
            this.form.get('indirizzo').updateValueAndValidity();
            this.form.get('numeroCivico').clearValidators();
            this.form.get('numeroCivico').updateValueAndValidity();
        }
    }

    onSubmit() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const formValue = this.form.getRawValue();
            if (!this.id || this.id === 'new') {
                const command: PazienteCreateCommand = new PazienteCreateCommand(
                    formValue.codiceFiscale,
                    formValue.email ? formValue.email : formValue.codiceFiscale + '@mail.com',
                    formValue.nome,
                    formValue.cognome,
                    this.utilsService.fieldToDatetimeString(formValue.dataNascita),
                    formValue.cittaNascita,
                    formValue.sesso.toLowerCase(),
                    formValue.codiceFiscale,
                    formValue.selectedCittadinanza,
                    formValue.selectedCittadinanza == '1' ? formValue.nazioneId : '',
                    formValue.telefono,
                    formValue.telefonoFisso,
                    formValue.comuneId,
                    formValue.extraAsl,
                    formValue.residenzaExtraAsl,
                    formValue.indirizzo,
                    formValue.numeroCivico,
                    formValue.cap,
                    formValue.consegnaResidenza ? formValue.comuneId : this.comuneDiConsegnaId,
                    formValue.consegnaResidenza ? this.findComune(Number(formValue.comuneId)).nome : formValue.comuneConsegna,
                    formValue.consegnaResidenza ? formValue.indirizzo : formValue.indirizzoConsegna,
                    formValue.consegnaResidenza ? formValue.numeroCivico : formValue.numeroCivicoConsegna,
                    formValue.capConsegna,
                    formValue.nomeDelegato,
                    formValue.cognomeDelegato,
                    formValue.qualificaDelegato,
                    formValue.telefonoDelegato,
                    formValue.mailDelegato,
                    formValue.giaInTerapia,
                    formValue.zonaDiConsegna ? Number(formValue.zonaDiConsegna) : undefined,
                    formValue.codiceDistributivo
                );
                
                this.submitButton = ClrLoadingState.LOADING;
                this.pazientiService.create(command).subscribe((res) => {
                    this.submitButton = ClrLoadingState.DEFAULT;
                    if (!res) {
                        this.alertMessage = 'Il paziente è stato salvato';
                        this.alertClosed = false;
                        window.scroll(0, 0);
                        setTimeout(() => {
                            this.alertClosed = true;
                            if (this.fromPrescrizione) {
                                this.router.navigate(['prescrizioni/new']);
                            } else {
                                this.router.navigate(['pazienti']);
                            }
                        }, 1500);
                    }
                });
            } else {
                if (+formValue.azienda !== this.paziente.comune.distretto.azienda.id) {
                    this.dialog.confirm({
                        title: 'Cambio azienda sanitaria',
                        content: "Attenzione! Stai cambiando l'azienda sanitaria del paziente. L'anagrafica corrente verrà archiviata e non sarà più utilizzabile. Sei sicuro?",
                        acceptText: 'Sì',
                        cancelText: 'Annulla',
                        acceptType: 'warning',
                        iconShape: null
                    }).subscribe((result: boolean) => {
                        if (result) {
                            this.updatePaziente(formValue, true);
                        }
                    });
                } else {
                    this.updatePaziente(formValue, false);
                }                    
            }
        }
    }

    updatePaziente(formValue: any, duplicate: boolean): void {
            const command: any = duplicate ? 
                new PazienteUpdateCommand(
                    formValue.nome,
                    formValue.cognome,
                    this.utilsService.fieldToDatetimeString(formValue.dataNascita),
                    formValue.cittaNascita,
                    formValue.sesso.toLowerCase(),
                    formValue.codiceFiscale,
                    formValue.selectedCittadinanza,
                    formValue.selectedCittadinanza == '1' ? formValue.nazioneId : '',
                    formValue.telefono,
                    formValue.telefonoFisso,
                    formValue.comuneId,
                    formValue.extraAsl,
                    formValue.residenzaExtraAsl,
                    formValue.indirizzo,
                    formValue.numeroCivico,
                    formValue.cap,
                    formValue.consegnaResidenza ? formValue.comuneId : this.comuneDiConsegnaId,
                    formValue.consegnaResidenza ? this.findComune(Number(formValue.comuneId)).nome : formValue.comuneConsegna,
                    formValue.consegnaResidenza ? formValue.indirizzo : formValue.indirizzoConsegna,
                    formValue.consegnaResidenza ? formValue.numeroCivico : formValue.numeroCivicoConsegna,
                    formValue.capConsegna,
                    formValue.nomeDelegato,
                    formValue.cognomeDelegato,
                    formValue.qualificaDelegato,
                    formValue.telefonoDelegato,
                    formValue.mailDelegato,
                    formValue.giaInTerapia,
                    formValue.zonaDiConsegna ? Number(formValue.zonaDiConsegna) : undefined,
                    formValue.codiceDistributivo
                ) : 
                new PazienteCreateCommand(
                    formValue.codiceFiscale,
                    formValue.email && formValue.email.indexOf('@mail.com') == -1 ? formValue.email : formValue.codiceFiscale + '@mail.com',
                    formValue.nome,
                    formValue.cognome,
                    this.utilsService.fieldToDatetimeString(formValue.dataNascita),
                    formValue.cittaNascita,
                    formValue.sesso.toLowerCase(),
                    formValue.codiceFiscale,
                    formValue.selectedCittadinanza,
                    formValue.selectedCittadinanza == '1' ? formValue.nazioneId : '',
                    formValue.telefono,
                    formValue.telefonoFisso,
                    formValue.comuneId,
                    formValue.extraAsl,
                    formValue.residenzaExtraAsl,
                    formValue.indirizzo,
                    formValue.numeroCivico,
                    formValue.cap,
                    formValue.consegnaResidenza ? formValue.comuneId : this.comuneDiConsegnaId,
                    formValue.consegnaResidenza ? this.findComune(Number(formValue.comuneId)).nome : formValue.comuneConsegna,
                    formValue.consegnaResidenza ? formValue.indirizzo : formValue.indirizzoConsegna,
                    formValue.consegnaResidenza ? formValue.numeroCivico : formValue.numeroCivicoConsegna,
                    formValue.capConsegna,
                    formValue.nomeDelegato,
                    formValue.cognomeDelegato,
                    formValue.qualificaDelegato,
                    formValue.telefonoDelegato,
                    formValue.mailDelegato,
                    formValue.giaInTerapia,
                    formValue.zonaDiConsegna ? Number(formValue.zonaDiConsegna) : undefined,
                    formValue.codiceDistributivo
                );

            this.submitButton = ClrLoadingState.LOADING;
            
            let observable: any;
            
            if (duplicate) {
                observable = this.pazientiService.duplicate(this.id, command);
            } else {
                observable = this.pazientiService.updateExtended(this.id, command);
            }
            observable.subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Il paziente è stato aggiornato';
                    this.alertClosed = false;
                    if (this.prescrizioneId) {
                        const url = '/prescrizioni/' + this.prescrizioneId;
                        const queryParams = this.prescrizioneId !== 'new' ? {type: this.prescrizioneType, action: this.prescrizioneAction} : {type: this.prescrizioneType, pazienteId: this.id};

                        this.router.navigate([url], {queryParams: queryParams});
                    } else {
                        this.router.navigate(['pazienti']);
                    }
                }
            });
    }
    
    createForm(): void {
        const formGroup = {
            selectedCittadinanza: [Cittadinanza.ITALIANA],
            azienda: [{value: ''}, [Validators.required]],
            distretto: [{value: ''}],
            comuneId: [{value: ''}, [Validators.required]],
            email: ['', [Validators.maxLength(255), Validators.email]],
            codiceFiscale: ['', [Validators.required, FiscalCodeValidator()]],
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            cognome: ['', [Validators.required, Validators.maxLength(255)]],
            dataNascita: ['', [Validators.required]],
            cittaNascita: ['', [Validators .required, Validators.maxLength(255)]],
            sesso: ['', [Validators.required]],
            nazioneId: [''],
            telefono: [{value: ''}, [Validators.required]],
            telefonoFisso: [{value: ''}, [Validators.required]],
            extraAsl: [false],
            residenzaExtraAsl: [''],
            indirizzo: ['', [Validators.required]],
            numeroCivico: [''],
            cap: [''],
            comuneConsegna: [{value: '', disabled: true}, [Validators.required]],
            indirizzoConsegna: ['', [Validators.required]],
            numeroCivicoConsegna: [''],
            capConsegna: [''],
            nomeDelegato: [''],
            cognomeDelegato: [''],
            qualificaDelegato: [''],
            telefonoDelegato: [''],
            mailDelegato: [''],
            giaInTerapia: [false],
            consegnaResidenza: [false],
            zonaDiConsegna: [''],
            codiceDistributivo: ['']
        };
        this.form = this.formBuilder.group(formGroup);

    }

    initializeFormFields(paziente: Paziente): void {
        let formValues;
        const  fieldsToDisable = [
            'distretto'
        ];
        
        if (paziente) {
            formValues = {
                selectedCittadinanza: paziente.cittadinanza ? paziente.cittadinanza : Cittadinanza.ITALIANA,
                azienda: paziente.comune.distretto.azienda.id,
                distretto: paziente.comune.distretto.nome,
                email: paziente.account.email,
                codiceFiscale: paziente.codiceFiscale,
                comuneId: paziente.comune.id,
                nome: paziente.nome,
                cognome: paziente.cognome,
                dataNascita: this.utilsService.dateToField(paziente.dataNascita),
                cittaNascita: paziente.cittaNascita,
                sesso: paziente.sesso.toUpperCase(),
                nazioneId: paziente.nazione && paziente.nazione.id ? paziente.nazione.id : '',
                telefono: paziente.telefono ? paziente.telefono : '',
                telefonoFisso: paziente.telefonoFisso ? paziente.telefonoFisso : '',
                extraAsl: paziente.extraAsl,
                residenzaExtraAsl: paziente.residenzaExtraAsl ? paziente.residenzaExtraAsl : '',
                indirizzo: paziente.indirizzo,
                numeroCivico: paziente.numeroCivico ? paziente.numeroCivico : '',
                cap: paziente.cap ? paziente.cap : '',
                comuneConsegna: paziente.comuneConsegna ? paziente.comuneConsegna : '',
                indirizzoConsegna: paziente.indirizzoConsegna ? paziente.indirizzoConsegna : '',
                numeroCivicoConsegna: paziente.numeroCivicoConsegna ? paziente.numeroCivicoConsegna : '',
                capConsegna: paziente.capConsegna ? paziente.capConsegna : '',
                nomeDelegato: paziente.nomeDelegato ? paziente.nomeDelegato : '',
                cognomeDelegato: paziente.cognomeDelegato ? paziente.cognomeDelegato : '',
                qualificaDelegato: paziente.qualificaDelegato ? paziente.qualificaDelegato : '',
                telefonoDelegato: paziente.telefonoDelegato ? paziente.telefonoDelegato : '',
                mailDelegato: paziente.mailDelegato ? paziente.mailDelegato : '',
                giaInTerapia: paziente.giaInTerapia ? paziente.giaInTerapia : false,
                consegnaResidenza: this.indirizziUguali(paziente),
                zonaDiConsegna: paziente.zonaDiConsegna ? paziente.zonaDiConsegna : '',
                codiceDistributivo: paziente.codiceDistributivo ? paziente.codiceDistributivo : ''
            };
            if (!this.authService.isAmministratoreUser() && this.isPrescrittore) {
                 fieldsToDisable.push('codiceFiscale');
                 fieldsToDisable.push('azienda');
                 fieldsToDisable.push('comuneId');
            }
        } else {
            formValues = {
                selectedCittadinanza: Cittadinanza.ITALIANA,
                azienda: this.aziendaId ? this.aziendaId : '',
                distretto: '',
                email: '',
                codiceFiscale: this.fromPrescrizione ? this.initialFiscalCode : '',
                comuneId: '',
                nome: '',
                cognome: '',
                dataNascita: '',
                cittaNascita: '',
                sesso: '',
                nazioneId: '',
                telefono: '',
                telefonoFisso: '',
                extraAsl: false,
                residenzaExtraAsl: '',
                indirizzo: '',
                numeroCivico: '',
                cap: '',
                comuneConsegna: '',
                indirizzoConsegna: '',
                numeroCivicoConsegna: '',
                capConsegna: '',
                nomeDelegato: '',
                cognomeDelegato: '',
                qualificaDelegato: 'Caregiver',
                telefonoDelegato: '',
                mailDelegato: '',
                giaInTerapia: false,
                consegnaResidenza: false,
                zonaDiConsegna: '',
                codiceDistributivo: ''
            };
            if (!this.authService.isAmministratoreUser() && this.isPrescrittore) {
                 fieldsToDisable.push('azienda');
            }
        }
        this.form.setValue(formValues);
        for (const field of  fieldsToDisable) {
            this.form.get(field).disable();
        }
        this.setOnFieldsChange();
        this.onTelefonoChange();
        if (paziente) {
            this.form.markAllAsTouched();
        }
        this.onSelectExtraAsl();
    }
    
    indirizziUguali(paziente: Paziente): boolean {
        return paziente.comune.id == paziente.comuneDiConsegna.id && paziente.indirizzo == paziente.indirizzoConsegna && paziente.numeroCivico == paziente.numeroCivicoConsegna;
    }

    setOnFieldsChange() {
        const telefonoControl = this.form.get('telefono');

        if (telefonoControl) {
            telefonoControl.valueChanges.pipe(
                debounceTime(500),
                distinctUntilChanged()
            ).forEach(
                (value: any) => this.onTelefonoChange()
            );
        }
        
        const telefonoFissoControl = this.form.get('telefonoFisso');
        
        telefonoFissoControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).forEach(
            (value: any) => this.onTelefonoChange()
        );
    }

    onTelefonoChange() {
        const formValue = this.form.getRawValue();
        
        if (formValue.telefono || formValue.telefonoFisso) {
            this.form.get('telefono').clearValidators();
            this.form.get('telefonoFisso').clearValidators();
            this.form.get('telefono').updateValueAndValidity();
            this.form.get('telefonoFisso').updateValueAndValidity();
        } else {
            this.form.get('telefono').setValidators([Validators.required]);
            this.form.get('telefonoFisso').setValidators([Validators.required]);
        }
    }
    
    sortComuni() {
        if (this.comuni) {
            this.comuni = this.comuni.sort((a, b) => {
                if (a.nome > b.nome) {
                    return 1;
                }
                if (a.nome < b.nome) {
                    return -1;
                }
                return 0;
            });
        }
    }

    onVisualizzaPrescrizione(prescrizione: any) {
        const url = '/prescrizioni/' + prescrizione.id;
        const queryParams = {type: prescrizione.tipo, prescriptionFilter: null};
        
        queryParams['action'] = this.prescrizioneActionsService.AZIONE_PRESCRIZIONE.VISUALIZZA;
        this.router.navigate([url], {queryParams: queryParams});
    }

    onPreviousVersion(): void {
        if (this.paziente.progressivo > 1) {
            const queryParams = {d: Date.now()};

            this.pazientiService.getVersion(this.paziente.codiceFiscale, this.paziente.progressivo - 1).subscribe(id => {
                if (id) {
                    this.router.navigate(['/pazienti/' + id], {queryParams: queryParams});
                }
            });
        }
    }

    onNextVersion(): void {
        if (this.paziente.dataFineValidita) {
            const queryParams = {d: Date.now()};

            this.pazientiService.getVersion(this.paziente.codiceFiscale, this.paziente.progressivo + 1).subscribe(id => {
                if (id) {
                    this.router.navigate(['/pazienti/' + id], {queryParams: queryParams});
                }
            });
        }
    }
    
    onSelezionaComuneConsegna(): void {
        this.showComuneConsegnaModal = true;
    }
    
    onConfermaComuneConsegna(): void {
        this.comuneDiConsegnaId = Number(this.modalComuneDiConsegnaId);

        let formValues = {
            comuneConsegna: this.comuniProvincia.find(c => c.id.toString() === this.modalComuneDiConsegnaId).nome,
        }

        this.form.patchValue(formValues);
        this.showComuneConsegnaModal = false;
    }

    onSelectProvincia(value: any): void {
        this.comuniService.getComuniByProvincia(value).subscribe(comuni => {
            this.comuniProvincia = comuni;
        });
    }
    
    onConsegnaResidenzaChange(event): void {
        const checked = event.target.checked;
        
        if (checked) {
            this.form.get('comuneConsegna').clearValidators();
            this.form.get('comuneConsegna').updateValueAndValidity();
            this.form.get('indirizzoConsegna').clearValidators();
            this.form.get('indirizzoConsegna').updateValueAndValidity();
            this.form.get('numeroCivicoConsegna').clearValidators();
            this.form.get('numeroCivicoConsegna').updateValueAndValidity();
        } else {
            this.form.get('comuneConsegna').setValidators([Validators.required]);
            this.form.get('comuneConsegna').updateValueAndValidity();
            this.form.get('indirizzoConsegna').setValidators([Validators.required]);
            this.form.get('indirizzoConsegna').updateValueAndValidity();
            this.form.get('numeroCivicoConsegna').setValidators([Validators.required]);
            this.form.get('numeroCivicoConsegna').updateValueAndValidity();
        }
    }
}
