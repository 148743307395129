import {AbstractCommand} from './abstract-command';

export class MascheraCommand extends AbstractCommand {
    codice: string;
    tipologia: string;
    modello: string;
    marca: string;
    taglia: string;

    constructor(
        codice: string,
        tipologia: string,
        modello: string,
        marca: string,
        taglia: string
    ) {
        super();
        this.codice = codice;
        this.marca = marca;
        this.modello = modello;
        this.taglia = taglia;
        this.tipologia = tipologia;
    }

}
