import {Component} from '@angular/core';
import {ProfiliService} from '../../core/profili.service';
import {Router} from '@angular/router';
import {DatagridComponent} from '../common/datagrid.component';
import {Profilo} from '../../model/query/profilo';
import {Action} from '../../model/action';
import {ActionService} from '../../core/action.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-profili',
    templateUrl: './profili.component.html'
})
export class ProfiliComponent extends DatagridComponent<Profilo> {

    constructor(
        private profiliService: ProfiliService,
        dialog: DialogService,
        public actionService: ActionService,
        router: Router
    ) {
        super(profiliService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/profili/';
    }

    onAction(entity: Profilo, action: Action) {
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }



}
