import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './core/auth.service';
import {ClrCommonStringsService} from '@clr/angular';
import {itLocale} from './italiano';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    constructor(private router: Router, private authService: AuthService, commonStrings: ClrCommonStringsService) {
        commonStrings.localize(itLocale);
        if (this.authService.isApprovatoreUser()) {
            this.router.navigate(['/prescrizioni'], {queryParams: {pfilter: 'da_autorizzare'}});
        } else if (this.authService.isOperatoreCallCenterUser()) {
            this.router.navigate(['/pazienti-call-center']);
        } else if (this.authService.isAddettoConsegnaUser() || this.authService.isAddettoProduzioneUser() || this.authService.isMagazziniereUser()) {
            this.router.navigate(['/contenitori-guasti']);
        } else if (this.authService.isCommercialeUser() || this.authService.isTecnicoVentilazioneUser()) {
            this.router.navigate(['/task']);
        } else if (this.authService.isManutentoreUser()) {
            this.router.navigate(['/contenitori-da-riparare']);
        } else if (this.authService.isResponsabileProduzioneUser()) {
            this.router.navigate(['/contenitori-da-verificare']);
        }
    }
}
