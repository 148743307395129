import { OnInit, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrDatagridStateInterface } from '@clr/angular';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { QueryResult } from '../../model/query/query-result';
import { DatagridComponent } from '../common/datagrid.component';
import { DatagridRequest } from '../common/datagrid.component';
import { Prescrizione } from '../../model/query/prescrizione';
import { AuthService } from '../../core/auth.service';
import { MeService } from '../../core/me.service';
import { AziendeCorrentiService } from '../../core/aziende-correnti.service';
import { PrescrizioniService } from '../../core/prescrizioni.service';
import { UtilsService } from '../../core/utils.service';
import { PrescrittoriService } from '../../core/prescrittori.service';
import { PartnersService } from '../../core/partners.service';
import { PrescrizioneActionsService } from '../../core/prescrizione-actions.service';
import { PrescrizioneAction } from '../../model/prescrizione-action';
import { Azienda } from '../../model/query/azienda';
import { IdListDTO } from '../../model/id-list-dto';
import { DialogService } from '../../ui/dialog';

@Directive()
export class PrescrizioniBaseComponent extends DatagridComponent<Prescrizione> implements OnInit {
    subscription: Subscription;
    tipiPrescrizioniDescription: any;
    today = new Date();
    prescriptionFilter: string;
    tipo: string;
    dataLoaded = true;
    defaultState: any;
    azienda: Azienda;

    constructor(
        protected route: ActivatedRoute,
        public prescrizioniService: PrescrizioniService,
        protected prescrittoriService: PrescrittoriService,
        protected partnersService: PartnersService,
        protected meService: MeService,
        protected aziendeCorrentiService: AziendeCorrentiService,
        protected utilsService: UtilsService,
        public authService: AuthService,
        public prescrizioneActionsService: PrescrizioneActionsService,
        dialog: DialogService,
        router: Router) {
        super(prescrizioniService, router, dialog);
        this.tipiPrescrizioniDescription = this.utilsService.getServiziDescription();
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscription = this.aziendeCorrentiService.getAzienda().subscribe(azienda => {
            this.azienda = azienda;
            if (azienda != null) {
                this.route.queryParams.subscribe((params) => {
                    if (params && params.pfilter) {
                        this.prescriptionFilter = params.pfilter;
                    } else {
                        this.prescriptionFilter = null;
                    }
                    if (params && params.tipo) {
                        this.tipo = params.tipo;
                    }
                    this.refresh(this.datagridState);
                });
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    protected manageRequest(request: DatagridRequest<this>) {
        if (request.component.azienda) {
            super.manageRequest(request);
        }
    }

    protected fetchData(state: ClrDatagridStateInterface, fields: Array<string>, operators: Array<string>,
        values: Array<any>): Observable<QueryResult<Prescrizione>> {
        let i = fields.indexOf('stato');

        if (i != -1) {
            operators[i] = 'eq';
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        i = fields.indexOf('paziente.comune.distrettoForeignKey');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        i = fields.indexOf('prescrittore.id');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        i = fields.indexOf('prescrittoreEsterno.id');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        i = fields.indexOf('dataPrescrizione');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = values[i].substring(1, values[i].length - 1);
        }
        i = fields.indexOf('dataScadenza');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = values[i].substring(1, values[i].length - 1);
        }
        i = fields.indexOf('azienda');
        if (i == -1) {
            fields.push("contratto.aziendaForeignKey");
            operators.push("eq");
            values.push(this.azienda.id);
        } else {
            values[i] = this.azienda.id;
        }
        i = fields.indexOf('tipoConcentratore');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        return this.meService.getMe().pipe(
            switchMap(user => {
                if (this.prescriptionFilter && this.prescriptionFilter === 'da_processare') {
                    return this.prescrizioniService.getPrescrizioniDaProcessare(this.tipo, fields, operators, values, state);
                } else if (this.prescriptionFilter && this.prescriptionFilter === 'da_autorizzare') {
                    return this.prescrizioniService.getPrescrizioniDaAutorizzare(fields, operators, values, state);
                } else {
                    if (this.authService.isPrescrittoreUser() && this.tipo === 'vent') {
                        fields.push('prescrittore.id');
                        operators.push('eq');
                        values.push(user.id);
                    }
                    if (!state.sort) {
                        state.sort = { 'by': 'paziente.cognome', 'reverse': false };
                    }
                    return this.prescrizioniService.getAllPrescrizioni(
                        this.tipo,
                        state.page.current ? state.page.current - 1 : 0,
                        state.page.size ? state.page.size : 10,
                        fields,
                        operators,
                        values,
                        state.sort ? state.sort.by.toString() : null,
                        state.sort ? (state.sort.reverse ? 'desc' : 'asc') : null
                    );
                }
            })
        );
    }

    isAuthorizedOnSelectedPrescription(action: PrescrizioneAction): boolean {
        const authorizedActions = this.prescrizioneActionsService.getAuthorizedActions(this.selectedObjects[0]);
        return authorizedActions.indexOf(action) > -1;
    }

    isAuthorizedOnPrescription(prescription: Prescrizione, action: PrescrizioneAction): boolean {
        const authorizedActions = this.prescrizioneActionsService.getAuthorizedActions(prescription);
        return authorizedActions.indexOf(action) > -1;
    }

    getPrescription(id) {
        return this.prescrizioniService.get(id).subscribe((p) => {
            return p;
        });
    }

    onNewPrescription() {
        let queryParams = { type: this.tipo };

        if (this.selectedObjects.length === 1) {
            queryParams['pazienteId'] = this.selectedObjects[0].paziente.id;
        }
        this.router.navigate(['/prescrizioni/new'], { queryParams: queryParams });
    }

    canOpenStorico(): boolean {
        return this.selectedObjects[0].tipo === 'o2l' || this.selectedObjects[0].tipo === 'o2c' || this.selectedObjects[0].tipo === 'sat' || this.selectedObjects[0].tipo === 'vent';
    }

    onVisualizzaStorico() {
        this.router.navigate(['/prescrizione-storico/' + this.selectedObjects[0].id], { queryParams: { type: this.selectedObjects[0].tipo } });
    }

    onAction(entity: Prescrizione, action: PrescrizioneAction) {
        const url = this.getEditUrl() + entity.id;
        const queryParams = this.getEditQueryParams(entity);
        queryParams['action'] = action;
        this.router.navigate([url], { queryParams: queryParams });
    }

    protected getEditUrl(): string {
        return '/prescrizioni/';
    }

    protected getEditQueryParams(entity: Prescrizione): any {
        return { type: entity.tipo, prescriptionFilter: this.prescriptionFilter };
    }

    onDeleteMultiple(prescrizioni: Array<Prescrizione>): void {
        if (prescrizioni.length > 0) {
            this.dialog.confirm({
                title: 'Comunicazione',
                content: prescrizioni.length > 1 ? 'Confermi la cancellazione di ' + prescrizioni.length + ' prescrizioni?' : 'Confermi la cancellazione della prescrizione?',
                acceptText: 'Sì',
                cancelText: 'Annulla',
                acceptType: 'warning',
                iconShape: null
            }).subscribe((result: boolean) => {
                if (result) {
                    let idsDTO: IdListDTO = new IdListDTO();
                    
                    idsDTO.ids = prescrizioni.map(p => p.id);
                    this.prescrizioniService.deleteList(idsDTO).subscribe(x => {
                        if (!x) {
                            this.selectedObjects = new Array<any>();
                            this.dialog.info({
                                title: 'Info',
                                content: prescrizioni.length > 1 ? 'Eliminate!' : 'Eliminata!',
                            }).subscribe(() => this.refresh(this.datagridState));
                        }
                    });
                }
            });
        }
    }
}
