import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {QueryResult} from '../model/query/query-result';
import {Profilo} from '../model/query/profilo';
import {VentilatoreProfilo} from '../model/query/ventilatore-profilo';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ProfiliService extends BaseService<Profilo> {

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('profili');
    }

    duplica(id: number, codice: string, nome: string): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/duplica', {profiloId: id, codice: codice, nome: nome}).pipe(
            catchError(this.handleError(this.apiUrl + ' - duplica', null))
        );
    }

    getProfili(): Observable<QueryResult<Profilo>> {
        return this.httpClient.get<QueryResult<Profilo>>(this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + '- : get profili', null))
        );
    }

    getVentilatori(id: number): Observable<Array<VentilatoreProfilo>> {
        return this.httpClient.get<Array<VentilatoreProfilo>>(this.apiUrl + '/' + id + '/ventilatori').pipe(
            catchError(this.handleError(this.apiUrl + '- : get ventilatori', null))
        );
    }
}
