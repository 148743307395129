import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {QueryResult} from '../model/query/query-result';
import {Ventilatore} from '../model/query/ventilatore';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class VentilatoriService extends BaseService<Ventilatore> {

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('ventilatori');
    }

    getVentilatori(): Observable<QueryResult<Ventilatore>> {
        return this.httpClient.get<QueryResult<Ventilatore>>(this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + '- : get ventilatori', null))
        );
    }

}
