import {AccountCommand} from './account-command';

export class ApprovatoreCreateCommand extends AccountCommand {

    approvatore: any;

    constructor(aziendaId: number, nome: string, cognome: string, username: string, email: string) {
        super(username, email);
        this.approvatore = {
            'aziendaId': aziendaId,
            'nome': nome,
            'cognome': cognome,
        };
    }
}
