import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {ImportJob} from '../model/query/import-job';
import {Observable, of} from 'rxjs';
import {ImportJobCommand} from '../model/command/import-job-command';
import {catchError, switchMap} from 'rxjs/operators';

@Injectable()
export class ImportJobsService extends BaseService<ImportJob> {

    private storageBasePath: string = environment.storageUrl;
    baseApiUrl = environment.apiUrl;
    apiUrl = this.baseApiUrl + 'import_jobs';

    constructor(
        router: Router,
        httpClient: HttpClient
    ) {
        super(httpClient, router);
    }

    createJob(file: File, command: ImportJobCommand): Observable<number> {
        let id: number;

        return this.httpClient.post(this.apiUrl, command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<ImportJob> => {
                id = this.extractIdFromLocation(res);
                if (id) {
                    return this.get(id.toString());
                } else {
                    return of(null);
                }
            }),
            switchMap((job: ImportJob) => {
                if (job != null) {
                    return this.httpClient.put(this.storageBasePath + job.fileUrl, file);
                } else {
                    return of(null);
                }
            }),
            switchMap(() => {
                return of(id);
            }),
            catchError(this.handleError('createJob', null))
        );
    }
}
