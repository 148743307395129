import { Component } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ClrDatagridStateInterface } from '@clr/angular';
import { TaskService } from '../../core/task.service';
import { ReportsService } from '../../core/reports.service';
import { Router } from '@angular/router';
import { QueryResult } from '../../model/query/query-result';
import { DatagridComponent } from '../common/datagrid.component';
import { Task } from '../../model/query/task';
import { MeService } from '../../core/me.service';
import { UtilsService } from '../../core/utils.service';
import { PrescrizioneActionsService } from '../../core/prescrizione-actions.service';
import { PrescrizioneAction } from '../../model/prescrizione-action';
import { DialogService } from '../../ui/dialog';
import { AuthService } from '../../core/auth.service';
import { AziendeCorrentiService } from '../../core/aziende-correnti.service';
import { Azienda } from '../../model/query/azienda';
import * as moment from 'moment';

@Component({
    selector: 'app-task',
    templateUrl: './task.component.html'
})
export class TaskComponent extends DatagridComponent<Task> {
    azienda: Azienda;
    tecnicoId: Number;
    mine: boolean = false;
    expired: boolean = this.authService.isTecnicoVentilazioneUser();
    subscription: Subscription;
    tipiTaskDescription: any;
    statiTaskDescription: any;
    completate: boolean = false;
    currentYear = moment.utc().get('y');
    currentMonth = moment.utc().get('M');
    dateFrom: string;
    dateTo: string;

    constructor(
        public authService: AuthService,
        private aziendeCorrentiService: AziendeCorrentiService,
        private reportsService: ReportsService,
        public taskService: TaskService,
        private meService: MeService,
        private utilsService: UtilsService,
        dialog: DialogService,
        public prescrizioneActionsService: PrescrizioneActionsService,
        router: Router
    ) {
        super(taskService, router, dialog);
        this.tipiTaskDescription = this.utilsService.getTipiTaskDescription();
        this.statiTaskDescription = this.utilsService.getStatiTaskDescription();
    }

    ngOnInit() {
        super.ngOnInit();
        this.meService.getMe().subscribe(me => {
            if (this.authService.isCommercialeUser() || this.authService.isTecnicoVentilazioneUser()) {
                this.mine = true;
                this.tecnicoId = me.id;
            }
            this.subscription = this.aziendeCorrentiService.getAzienda().subscribe(azienda => {
                this.azienda = azienda;
                if (azienda != null) {
                    this.refresh(this.datagridState);
                }
            });
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    protected fetchData(
        state: ClrDatagridStateInterface,
        fields: Array<string>,
        operators: Array<string>,
        values: Array<any>
    ): Observable<QueryResult<Task>> {
        if (this.mine && fields.indexOf('incaricato.id') == -1) {
            fields.push("incaricato.id");
            operators.push("eq");
            values.push(this.tecnicoId.toString());
        }

        let i = fields.indexOf('stato');

        if (i != -1) {
            operators[i] = 'eq';
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        if (this.dateFrom && this.dateTo) {
            fields.push('dataPrevista');
            operators.push('ge');
            values.push(this.dateFrom);
            fields.push('dataPrevista');
            operators.push('le');
            values.push(this.dateTo);
        }
        i = fields.indexOf('azienda');
        if (i == -1) {
            fields.push("prescrizioneVentilatore.prescrizione.paziente.comune.distretto.aziendaForeignKey");
            operators.push("eq");
            values.push(this.azienda.id);
        } else {
            values[i] = this.azienda.id;
        }
        i = fields.indexOf('incaricato.id');
        if (i != -1 && !this.mine) {
            operators[i] = 'eq';
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        i = fields.indexOf('dataPrevista');
        if (i != -1 && !this.dateFrom && !this.dateTo) {
            operators[i] = 'eq';
            values[i] = values[i].substring(1, values[i].length - 1);
        }
        i = fields.indexOf('dataCompletamento');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = values[i].substring(1, values[i].length - 1);
        }
        if (!this.expired) {
            fields.push("dataPrevista");
            operators.push("ge");
            values.push(moment.utc().format('YYYY-MM-DD 00:00:00'));
        }
        if (this.authService.isCommercialeUser() || this.authService.isTecnicoVentilazioneUser()) {
            fields.push("tipo");
            operators.push("ne");
            values.push('fup');
        }
        return super.fetchData(state, fields, operators, values);
    }

    protected getEditUrl(): string {
        return '/task/';
    }

    onAction(entity: Task, action: PrescrizioneAction) {
        const url = '/prescrizioni/' + entity.prescrizioneVentilatore.prescrizione.id;
        const queryParams = { type: 'vent' };

        queryParams['action'] = action;
        queryParams['prescrizioneVentilatore'] = entity.prescrizioneVentilatore.id;
        queryParams['task'] = entity.id;
        this.router.navigate([url], { queryParams: queryParams });
    }

    onEdit(entity: Task): void {
        const url = this.getEditUrl() + entity.id;
        let queryParams: any = this.getEditQueryParams(entity);

        if (!queryParams) {
            queryParams = {};
        }
        queryParams['tipo'] = entity.tipo;
        this.router.navigate([url], { queryParams: queryParams });
    }

    onFiltraTask(range: any): void {
        if (range.year && range.month) {
            this.dateFrom = moment.utc({ y: range.year, M: range.month }).startOf('month').format('YYYY-MM-DD HH:mm:SS');
            this.dateTo = moment.utc({ y: range.year, M: range.month }).endOf('month').format('YYYY-MM-DD HH:mm:SS');
        } else if (range.year) {
            this.dateFrom = moment.utc({ y: range.year, M: 0 }).startOf('month').format('YYYY-MM-DD HH:mm:SS');
            this.dateTo = moment.utc({ y: range.year, M: 11 }).endOf('month').format('YYYY-MM-DD HH:mm:SS');
        } else {
            this.dateFrom = undefined;
            this.dateTo = undefined;
        }
        this.refresh(this.datagridState);
    }

    onMineChange(mine: boolean): void {
        this.mine = mine;
    }

    onExpiredChange(expired: boolean): void {
        this.expired = expired;
        this.refresh(this.datagridState);
    }
    
    onDownloadElencoAttivita(): void {
        this.reportsService.getReportElencoAttivitaDownloadLink().subscribe((url) => {
            if (url) {
                // window.location.href = url;
                window.open(url);
            }
        });
    }
}
