import {AbstractCommand} from './abstract-command';

export class ConcentratoreCommand extends AbstractCommand {

    nome: string;
    tipo: number;

    constructor(
        nome: string,
        tipo: number,
    ) {
        super();
        this.nome = nome;
        this.tipo = tipo;
    }
}
