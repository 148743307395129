import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Action} from '../../../model/action';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {ActionService} from '../../../core/action.service';
import {RepartiService} from '../../../core/reparti.service';
import {StruttureService} from '../../../core/strutture.service';
import {AziendeService} from '../../../core/aziende.service';
import {Azienda} from '../../../model/query/azienda';
import {Struttura} from '../../../model/query/struttura';
import {Reparto} from '../../../model/query/reparto';
import {RepartoCommand} from '../../../model/command/reparto-command';

@Component({
    selector: 'app-reparto-detail',
    templateUrl: './reparto-detail.component.html'
})
export class RepartoDetailComponent implements OnInit {

    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    aziende: Array<Azienda> = new Array<Azienda>();
    strutture: Array<Struttura> = new Array<Struttura>();
    selectedAziendaId: number;
    selectedStrutturaId: number;
    reparto: Reparto;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;

    constructor(
        private route: ActivatedRoute,
        private aziendeService: AziendeService,
        public repartiService: RepartiService,
        public struttureService: StruttureService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        public actionService: ActionService,
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        this.aziendeService.getAll(0, 10000000, [], [], [], null, null).subscribe(aziende => {
            this.aziende = aziende.objects;
            if (this.id !== 'new') {
                this.repartiService.get(this.id).subscribe((reparto) => {
                    this.reparto = reparto;
                    this.onSelectAzienda(reparto.struttura.aziendaForeignKey);
                    this.onSelectStruttura(reparto.struttura.id);
                    this.initializeFormFields(reparto);
                });
            } else {
                this.initializeFormFields(null);
            }
        });
    }

    onSelectAzienda(id: number) {
        this.selectedAziendaId = id;
        this.struttureService.getAll(0, 10000, ['aziendaForeignKey'], ['eq'], [id], 'nome', 'asc').subscribe(strutture => {
            this.strutture = strutture.objects;
        });
    }

    onSelectStruttura(id: number) {
        this.selectedStrutturaId = id;
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command = new RepartoCommand(
            formValue.nome,
            Number(formValue.selectedStrutturaId)
        );
        if (!this.id || this.id === 'new') {
            this.repartiService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'Reparto salvato!';
                    this.alertClosed = false;
                }
            });
        } else {
            this.repartiService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Reparto salvato!';
                    this.alertClosed = false;
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            selectedAziendaId: ['', [Validators.required]],
            selectedStrutturaId: ['', [Validators.required]],
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(reparto: Reparto): void {
        let formValues;

        if (reparto) {
            formValues = {
                selectedAziendaId: reparto.struttura.aziendaForeignKey,
                selectedStrutturaId: reparto.struttura.id,
                nome: reparto.nome
            };
        } else {
            formValues = {
                selectedAziendaId: '',
                selectedStrutturaId: '',
                nome: ''
            };
        }
        this.form.setValue(formValues);
    }

}
