import {AccountCommand} from './account-command';
import {TipoIncaricato} from '../tipo-incaricato';

export class IncaricatoCreateCommand extends AccountCommand {
    incaricato: any;
    
    constructor(nome: string, cognome: string, username: string, email: string, tipoIncaricato: string) {
        super(username, email);
        this.incaricato = {
            'nome': nome,
            'cognome': cognome,
            'tipoIncaricato': tipoIncaricato
        };
    }
}
