import {Component} from '@angular/core';
import {Subscription} from 'rxjs';
import {ClrDatagridStateInterface} from '@clr/angular';
import * as moment from 'moment';
import {FormBuilder, Validators} from '@angular/forms';
import {ReportsService} from '../../../core/reports.service';
import {UtilsService} from '../../../core/utils.service';
import {AuthService} from '../../../core/auth.service';
import {Prescrittore} from '../../../model/query/prescrittore';
import {PrescrittoriService} from '../../../core/prescrittori.service';
import {ReportWithPrescrittoreAndDistrettoCommand} from '../../../model/command/report-with-prescrittore-and-distretto-command';
import {ReportWithPrescrittoreAndDistrettoDownloadCommand} from '../../../model/command/report-with-prescrittore-and-distretto-download-command';
import {forkJoin} from 'rxjs';
import {AziendeCorrentiService} from '../../../core/aziende-correnti.service';
import {Azienda} from '../../../model/query/azienda';
import { PartnersService } from "../../../core/partners.service";
import { Partner } from "../../../model/query/partner";
import {DialogService} from '../../dialog';
import {MeService} from '../../../core/me.service';

@Component({
    selector: 'app-report-pazienti-attivi',
    templateUrl: './report-pazienti-attivi.component.html',
    styleUrls: ['./report-pazienti-attivi.component.css']
})
export class ReportPazientiAttiviComponent {
    subscription: Subscription;
    tipiPrescrizioniDescription: any;
    total: number;
    loading = false;
    form: any;

    objectList: any[] = [];
    object: any;
    currentState: ClrDatagridStateInterface;

    azienda: Azienda;
    year: any;
    month: any;
    prescrittoreId: number;
    distrettoId: number;
    partners: Partner[];
    partnerId: number;
    prescrittori: Prescrittore[];
    distretti: { id: number, nome: string, aziendaForeignKey: number }[];
    servizi: any;
    servizio: string;

    dateFrom: string;
    dateTo: string;

    constructor(
        private formBuilder: FormBuilder,
        private reportsService: ReportsService,
        public utilsService: UtilsService,
        private meService: MeService,
        public authService: AuthService,
        private partnersService: PartnersService,
        private prescrittoriService: PrescrittoriService,
        private aziendeCorrentiService: AziendeCorrentiService,
        private dialog: DialogService
    ) {
        this.tipiPrescrizioniDescription = this.utilsService.getServiziDescription();
        this.servizi = utilsService.getServiziConScadenzaDescription(this.meService);
        this.createForm();
        this.subscription = this.aziendeCorrentiService.getAzienda().subscribe(azienda => {
            if (azienda != null) {
                this.year = moment.utc().get('y');
                this.month = moment.utc().get('M');
                this.partnerId = null;
                this.distrettoId = null;
                this.prescrittoreId = null;
                this.servizio = Object.keys(this.servizi).length > 0 ? (Object.keys(this.servizi).indexOf('') >= 0 ? '' : Object.keys(this.servizi)[0]) : '';
                this.aziendeCorrentiService.getDistretti().subscribe(results => this.distretti = results);
                
                const getPrescrittori = this.prescrittoriService.getAll(0, 100000000, ['aziendaForeignKey'], ['eq'], [azienda.id], 'cognome', 'asc');
                const getPartners = this.partnersService.getAll(0, 1000000, null, null, null, null, null);
                    
                forkJoin([getPrescrittori, getPartners]).subscribe(
                    (results) => {
                        this.prescrittori = results[0].objects;
                        this.partners = results[1].objects;
                        this.initForm();
                        this.azienda = azienda;
                    });
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    refresh(state: ClrDatagridStateInterface) {
        if (this.azienda && this.dateFrom && this.dateTo) {
            this.loading = true;
            const command = new ReportWithPrescrittoreAndDistrettoCommand(
                this.azienda.id,
                state.page.current ? state.page.current - 1 : 0,
                state.page.size ? state.page.size : 50,
                this.dateFrom,
                this.dateTo,
                this.prescrittoreId ? +this.prescrittoreId : null,
                this.distrettoId ? +this.distrettoId : null,
                this.partnerId ? +this.partnerId : null,
                this.servizio ? this.servizio : null
            );
            this.reportsService.fetchReportPazientiAttivi(command).subscribe((result) => {
                if (result) {
                    this.total = result.count;
                    this.objectList = result.objects;
                }
                this.loading = false;
            });
        }
        this.currentState = state;
    }

    createForm(): void {
        const formGroup = {
            mese: [{value: ''}, [Validators.required]],
            anno: [{value: ''}, [Validators.required]],
            prescrittore: [{value: this.prescrittoreId}],
            distretto: [{value: this.distrettoId}],
            partner: [{ value: "" }],
            servizio: [{value: ''}]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initForm() {
        const formValues = {
            mese: this.month,
            anno: this.year,
            prescrittore: '',
            distretto: '',
            partner: "",
            servizio: this.servizio
        };
        this.form.setValue(formValues);
    }

    openDownloadAlert() {
        this.onSubmit("xls");
    }

    onSubmit(type: any) {
        const formValue = this.form.getRawValue();
        
        this.total = 0;
        this.objectList = [];
        
        this.currentState.page.current = 0;
        this.currentState.page.size = 50;
        this.year = formValue.anno;
        this.month = formValue.mese;

        let mFrom : moment.Moment = moment.utc({ y: this.year, M: this.month }).startOf('month');

        if (!mFrom.isAfter(moment.utc())) {
            this.prescrittoreId = formValue.prescrittore ? formValue.prescrittore : null;
            this.distrettoId = formValue.distretto ? formValue.distretto : null;
            this.partnerId = formValue.partner ? formValue.partner : null;
            this.dateFrom = mFrom.format('YYYY-MM-DD');
            this.dateTo = moment.utc({y: this.year, M: this.month}).endOf('month').format('YYYY-MM-DD');
            this.refresh(this.currentState);
            if (type) {
                const command = new ReportWithPrescrittoreAndDistrettoDownloadCommand(
                    this.azienda.id,
                    null,
                    null,
                    this.dateFrom,
                    this.dateTo,
                    this.prescrittoreId,
                    this.distrettoId,
                    this.partnerId,
                    this.servizio ? this.servizio : null,
                    type
                );
                this.reportsService.getReportPazientiAttiviDownloadLink(command).subscribe((url) => {
                    if (url) {
                        // window.location.href = url;
                        window.open(url);
                    }
                });
            }
        }
    }

    onServizioChange(event) {
        this.servizio = event.target.value;
    }
}
