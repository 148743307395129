import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Azienda} from '../model/query/azienda';
import {Comune} from '../model/query/comune';

@Injectable()
export class ComuniService extends BaseService<Azienda> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('comuni');
    }

    getComuni(): Observable<Array<Comune>> {
        return this.httpClient.get<Array<Comune>>(this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + '- : get comuni', null))
        );
    }

    getAziendaComuni(aziendaId: number): Observable<Array<Comune>> {
        const params = new HttpParams()
            .set('aziendaId', aziendaId);
        return this.httpClient.get<Array<Comune>>(this.apiUrl, {params: params}).pipe(
            catchError(this.handleError(this.apiUrl + '- : get comuni', null))
        );
    }

    getProvince(): Observable<Array<String>> {
        return this.httpClient.get<Array<String>>(this.apiUrl + '/province').pipe(
            catchError(this.handleError(this.apiUrl + '- : get province', null))
        );
    }

    getComuniByProvincia(provincia: string): Observable<Array<Comune>> {
        const params = new HttpParams()
            .set('provincia', provincia);
        return this.httpClient.get<Array<Comune>>(this.apiUrl + '/by-provincia', {params: params}).pipe(
            catchError(this.handleError(this.apiUrl + '- : get comuni', null))
        );
    }
}
