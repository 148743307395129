// *************************************************
// * domolife_system Project - © 2020
// *
// * Author: Armando Taglieri (jadbit)
// * Created on: 3/30/20 11:31 PM
// *************************************************

import {ReportCommand} from './report-command';

export class ReportTaskCommand extends ReportCommand {
    userId: number;
    tipo: string;
    
    constructor(
        aziendaId: number,
        page: number,
        size: number,
        from: string,
        to: string,
        userId: number,
        tipo: string
    ) {
        super(aziendaId, page, size, from, to);
        this.userId = userId;
        this.tipo = tipo;
    }
}
