import {Component} from '@angular/core';
import {Subject} from 'rxjs';
import {ClrDatagridFilter, ClrDatagridFilterInterface} from '@clr/angular';

@Component({
    selector: 'app-tipo-concentratore-filter',
    templateUrl: './tipo-concentratore.filter.component.html'
})
export class TipoConcentratoreFilterComponent implements ClrDatagridFilterInterface<any> {
    state: {
    };
    
    constructor(private filterContainer: ClrDatagridFilter) {
        filterContainer.setFilter(this);
    }
    
    accepts(transaction: any) {
        return false;
    }

    changes = new Subject<any>();
    
    isActive(): boolean {
        return true;
    }

    onValueChange(param: string) {
        if (param) {
            this.state = {
                property: "tipoConcentratore",
                value: param
            }
        } else {
            this.state = {};
        }
        this.changes.next(this.state);
    }
}