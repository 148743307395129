import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {ClrLoadingState} from '@clr/angular';
import {AppaltantiService} from '../../../core/appaltanti.service';
import {AziendeService} from '../../../core/aziende.service';
import {Appaltante} from '../../../model/query/appaltante';
import {Azienda} from '../../../model/query/azienda';
import {Contratto} from '../../../model/query/contratto';
import {AppaltanteCreateCommand} from '../../../model/command/appaltante-create-command';
import {AppaltanteUpdateCommand} from '../../../model/command/appaltante-update-command';
import {Action} from '../../../model/action';
import {ActionService} from '../../../core/action.service';
import {UtilsService} from '../../../core/utils.service';

@Component({
    selector: 'app-appaltante-detail',
    templateUrl: './appaltante-detail.component.html'
})
export class AppaltanteDetailComponent implements OnInit {
    tipiPrescrizioniDescription: any;
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    selectedObjects = [];
    selectedAziendaId: string;
    aziende: Array<Azienda> = new Array<Azienda>();
    contratti: Array<Contratto> = new Array<Contratto>();
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private appaltantiService: AppaltantiService,
        private aziendeService: AziendeService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        private utilsService: UtilsService,
        public actionService: ActionService,
    ) {
        this.tipiPrescrizioniDescription = this.utilsService.getServiziDescription();
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        this.aziendeService.getAll(0, 1000000, [], [], [], null, null).subscribe(aziende => {
            this.aziende = aziende.objects;
            if (this.id !== 'new') {
                this.appaltantiService.get(this.id).subscribe((appaltante: Appaltante) => {
                    this.selectedAziendaId = appaltante.aziendaForeignKey.toString();
                    this.appaltantiService.getContratti(this.id).subscribe(contratti => {
                        this.contratti = contratti;
                        this.initializeFormFields(appaltante);
                        if (this.action != null && this.action == this.actionService.ACTION.VIEW) {
                            this.readOnly = true;
                        }
                    });
                });
            } else {
                this.selectedAziendaId = null;
                this.initializeFormFields(null);
            }
        });
    }

    onSelectAzienda(id: string) {
        if (id) {
            this.selectedAziendaId = id;
        } else {
            this.selectedAziendaId = null;
        }
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command: AppaltanteCreateCommand =
            new AppaltanteCreateCommand(Number(this.selectedAziendaId), formValue.nome, formValue.cognome, formValue.username, formValue.email);
            
        if (!this.id || this.id === 'new') {
            this.appaltantiService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'Appaltante creato!';
                    this.alertClosed = false;
                }
            });
        } else {
            this.appaltantiService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Appaltante aggiornato!';
                    this.alertClosed = false;
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            selectedAziendaId: ['', [Validators.required]],
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            cognome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            username: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            email: ['', [Validators.required, Validators.maxLength(255), Validators.email]],
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(appaltante: Appaltante): void {
        let formValues;

        if (appaltante) {
            formValues = {
                selectedAziendaId: this.selectedAziendaId,
                nome: appaltante.nome,
                cognome: appaltante.cognome,
                username: appaltante.account.username,
                email: appaltante.account.email
            };
            this.form.get('username').disable();
        } else {
            formValues = {
                selectedAziendaId: this.selectedAziendaId,
                nome: '',
                cognome: '',
                username: '',
                email: ''
            };
        }
        this.form.setValue(formValues);
    }

    onVisualizzaContratto(contratto: any) {
        const url = '/contratti/' + contratto.id;
        
        this.router.navigate([url]);
    }
}
