import {AccountCommand} from './account-command';

export class FornitoreCreateCommand extends AccountCommand {

    fornitore: any;

    constructor(nome: string, cognome: string, username: string, email: string) {
        super(username, email);
        this.fornitore = {
            'nome': nome,
            'cognome': cognome,
        };
    }
}
