import {AbstractCommand} from './abstract-command';

export class AppaltoCommand extends AbstractCommand {
    dataStipula: Date;
    aziendaId: number;
    descrizione: string;

    constructor(
        dataStipula: Date,
        aziendaId: number,
        descrizione: string
    ) {
        super();
        this.dataStipula = dataStipula;
        this.aziendaId = aziendaId;
        this.descrizione = descrizione;
    }
}
