import {Component} from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {Observable} from 'rxjs';
import {QueryResult} from '../../model/query/query-result';
import {Action} from '../../model/action';
import {DatagridComponent} from '../common/datagrid.component';
import {Struttura} from '../../model/query/struttura';
import {Router} from '@angular/router';
import {ActionService} from '../../core/action.service';
import {StruttureService} from '../../core/strutture.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-strutture',
    templateUrl: './strutture.component.html'
})
export class StruttureComponent extends DatagridComponent<Struttura> {

    constructor(
        private struttureService: StruttureService,
        public actionService: ActionService,
        dialog: DialogService,
        router: Router
    ) {
        super(struttureService, router, dialog);
    }

    protected fetchData(state: ClrDatagridStateInterface, fields: Array<string>, operators: Array<string>,
                        values: Array<any>): Observable<QueryResult<Struttura>> {
        let i = fields.indexOf('aziendaForeignKey');
        
        if (i != -1) {
            operators[i] = "eq";
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        return super.fetchData(state, fields, operators, values);
    }

    onAction(entity: Struttura, action: Action) {
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }

    protected getEditUrl(): string {
        return '/strutture/';
    }
}
