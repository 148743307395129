import {AbstractCommand} from './abstract-command';

export class ProfiloCommand extends AbstractCommand {
    codice: string;
    codicePrestazione: string;
    nome: string;
    kitMaterialeId: number;
    kitMaterialeSelezionabiliId: number;
    kitMaterialeMedicazioneId: number;
    kitMaterialeMedicazioneSelezionabiliId: number;
    bloccato: boolean;

    constructor(
        codice: string,
        codicePrestazione: string,
        nome: string,
        kitMaterialeId: number,
        kitMaterialeSelezionabiliId: number,
        kitMaterialeMedicazioneId: number,
        kitMaterialeMedicazioneSelezionabiliId: number,
        bloccato: boolean
    ) {
        super();
        this.codice = codice;
        this.codicePrestazione = codicePrestazione;
        this.nome = nome;
        this.kitMaterialeId = kitMaterialeId;
        this.kitMaterialeSelezionabiliId = kitMaterialeSelezionabiliId;
        this.kitMaterialeMedicazioneId = kitMaterialeMedicazioneId;
        this.kitMaterialeMedicazioneSelezionabiliId = kitMaterialeMedicazioneSelezionabiliId;
        this.bloccato = bloccato;
    }

}
