// *************************************************
// * domolife_system Project - © 2020
// *
// * Author: Armando Taglieri (jadbit)
// * Created on: 3/31/20 11:31 PM
// *************************************************



import {ReportConsegneCommand} from './report-consegne-command';

export class ReportDownloadConsegneCommand extends ReportConsegneCommand {

    reportType: string;

    constructor(
        aziendaId: number,
        from: string,
        to: string,
        distrettoId: number,
        pazienteId: number,
        partnerId: number,
        reportType: string
    ) {
        super(aziendaId, null, null, from, to, distrettoId, pazienteId, partnerId);
        this.reportType = reportType;
    }
}
