import {ChiusuraTaskConFirmaDto} from './chiusura-task-con-firma-dto';

export class ChiusuraTaskAccessoOrdinarioDto extends ChiusuraTaskConFirmaDto {
    oreFunzionamento: number;
    
    constructor(
        dataCompletamento: Date,
        completato: boolean,
        note: string,
        firmaTecnico: string,
        firmaAssistito: string,
        firmaDelegato: string,
        oreFunzionamento: number
    ) {
        super(dataCompletamento, completato, note, firmaTecnico, firmaAssistito, firmaDelegato);
        this.oreFunzionamento = oreFunzionamento;
    }
}
