import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {StatoPrescrizione} from '../model/stato-prescrizione';
import {ResultUrl} from '../model/result-url';
import {Prescrizione} from '../model/query/prescrizione';
import {PrescrizioneRevision} from '../model/query/prescrizione-revision';
import {PrescrizioneDocumento} from '../model/query/prescrizione-documento';
import {PrescrizioneNota} from '../model/query/prescrizione-nota';
import {PrescrizioneNotaPrescrittore} from '../model/query/prescrizione-nota-prescrittore';
import {PrescrizioneOssigenoLiquido} from '../model/query/prescrizione-ossigeno-liquido';
import {PrescrizioneOssigenoLiquidoCommand} from '../model/command/prescrizione-ossigeno-liquido-command';
import {PrescrizioneDocumentoCommand} from '../model/command/prescrizione-documento-command';
import {PrescrizioneNotaCommand} from '../model/command/prescrizione-nota-command';
import {PrescrizioneNotaPrescrittoreCommand} from '../model/command/prescrizione-nota-prescrittore-command';
import {QueryResult} from '../model/query/query-result';
import {GetAllRequest} from '../model/get-all-request';
import {GetAllPrescrizioniRequest} from '../model/get-all-prescrizioni-request';
import {GetAllDaProcessareRequest} from '../model/get-all-daprocessare-request';
import {ClrDatagridStateInterface} from '@clr/angular';
import {PrescrizioneO2lAttivaCommand} from '../model/command/prescrizione-o2l-attiva-command';
import {PrescrizioneConcentratore} from '../model/query/prescrizione-concentratore';
import {PrescrizioneSaturimetro} from '../model/query/prescrizione-saturimetro';
import {PrescrizioneVentilazioneDto} from '../model/query/prescrizione-ventilazione-dto';
import {PrescrizioneVentilatoreDto} from '../model/query/prescrizione-ventilatore-dto';
import {TipoConcentratore} from '../model/tipo-concentratore';
import {TipoEga} from '../model/tipo-ega';
import {PrescrizioneO2cAttivaCommand} from '../model/command/prescrizione-o2c-attiva-command';
import {PrescrizioneConcentratoreCommand} from '../model/command/prescrizione-concentratore-command';
import {PrescrizioneSatAttivaCommand} from '../model/command/prescrizione-sat-attiva-command';
import {PrescrizioneSaturimetroCommand} from '../model/command/prescrizione-saturimetro-command';
import {PrescrizioneVentAttivaCommand} from '../model/command/prescrizione-vent-attiva-command';
import {PrescrizioneVentilazioneCommandDto} from '../model/command/prescrizione-ventilazione-command-dto';
import {PrescrizioneEga} from '../model/query/prescrizione-ega';
import {PrescrizioneEgaCommand} from '../model/command/prescrizione-ega-command';
import {PrescrizioneAssistenzaInfermieristica} from '../model/query/prescrizione-assistenza-infermieristica';
import {PrescrizioneAssistenzaInfermieristicaCommand} from '../model/command/prescrizione-assistenza-infermieristica-command';
import {Task} from '../model/query/task';
import {IdListDTO} from '../model/id-list-dto';


@Injectable()
export class PrescrizioniService extends BaseService<Prescrizione> {
    readonly STATO_PRESCRIZIONE: typeof StatoPrescrizione = StatoPrescrizione;
    readonly TIPO_CONCENTRATORE: typeof TipoConcentratore = TipoConcentratore;
    readonly TIPO_EGA: typeof TipoEga = TipoEga;

    private storageBasePath: string = environment.storageUrl;

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('prescrizioni');
    }

    getAllPrescrizioni(
        tipo: string,
        page: number,
        size: number,
        fields: Array<string>,
        operators: Array<string>,
        values: Array<any>,
        sort: string,
        sortType: string
    ): Observable<QueryResult<any>> {
        return this.httpClient.post<QueryResult<any>>(
            this.apiUrl + '/get-all/' + tipo,
            new GetAllRequest(fields, operators, values, sort, sortType, page, size)).pipe(
            catchError(this.handleError(this.apiUrl, null))
        );
    }

    getPrescrizioneOssigenoLiquido(id: string): Observable<PrescrizioneOssigenoLiquido> {
        return this.httpClient.get<PrescrizioneOssigenoLiquido>(this.apiUrl + '/o2l/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get o2l', null))
        );
    }

    getPrescrizioneConcentratore(id: string): Observable<PrescrizioneConcentratore> {
        return this.httpClient.get<PrescrizioneConcentratore>(this.apiUrl + '/o2c/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get o2c', null))
        );
    }

    getPrescrizioneSaturimetro(id: string): Observable<PrescrizioneSaturimetro> {
        return this.httpClient.get<PrescrizioneSaturimetro>(this.apiUrl + '/sat/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get sat', null))
        );
    }

    getPrescrizioneVentilazione(id: string): Observable<PrescrizioneVentilazioneDto> {
        return this.httpClient.get<PrescrizioneVentilazioneDto>(this.apiUrl + '/vent/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get vent', null))
        );
    }

    getPrescrizioneVentilatore(id: string): Observable<PrescrizioneVentilatoreDto> {
        return this.httpClient.get<PrescrizioneVentilatoreDto>(this.apiUrl + '/vent/prescrizione-ventilatore/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get vent/prescrizione-ventilatore', null))
        );
    }

    getPrescrizioneEga(id: string): Observable<PrescrizioneEga> {
        return this.httpClient.get<PrescrizioneEga>(this.apiUrl + '/ega/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get ega', null))
        );
    }

    getPrescrizioneAssistenzaInfermieristica(id: string): Observable<PrescrizioneAssistenzaInfermieristica> {
        return this.httpClient.get<PrescrizioneAssistenzaInfermieristica>(this.apiUrl + '/ai/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get ai', null))
        );
    }

    getRevisioniPrescrizioneOssigenoLiquido(id: string): Observable<Array<PrescrizioneRevision>> {
        return this.httpClient.get<Array<PrescrizioneRevision>>(this.apiUrl + '/o2l/' + id + '/revisions').pipe(
            catchError(this.handleError(this.apiUrl + ' - get revisions o2l', null))
        );
    }

    getRevisioniPrescrizioneConcentratore(id: string): Observable<Array<PrescrizioneRevision>> {
        return this.httpClient.get<Array<PrescrizioneRevision>>(this.apiUrl + '/o2c/' + id + '/revisions').pipe(
            catchError(this.handleError(this.apiUrl + ' - get revisions o2C', null))
        );
    }

    getRevisioniPrescrizioneSaturimetro(id: string): Observable<Array<PrescrizioneRevision>> {
        return this.httpClient.get<Array<PrescrizioneRevision>>(this.apiUrl + '/sat/' + id + '/revisions').pipe(
            catchError(this.handleError(this.apiUrl + ' - get revisions sat', null))
        );
    }

    getRevisioniPrescrizioneVentilazione(id: string): Observable<Array<PrescrizioneRevision>> {
        return this.httpClient.get<Array<PrescrizioneRevision>>(this.apiUrl + '/vent/' + id + '/revisions').pipe(
            catchError(this.handleError(this.apiUrl + ' - get revisions vent', null))
        );
    }

    getRevisioniPrescrizioneEga(id: string): Observable<Array<PrescrizioneRevision>> {
        return this.httpClient.get<Array<PrescrizioneRevision>>(this.apiUrl + '/ega/' + id + '/revisions').pipe(
            catchError(this.handleError(this.apiUrl + ' - get revisions ega', null))
        );
    }

    getRevisioniPrescrizioneAssistenzaInfermieristica(id: string): Observable<Array<PrescrizioneRevision>> {
        return this.httpClient.get<Array<PrescrizioneRevision>>(this.apiUrl + '/ai/' + id + '/revisions').pipe(
            catchError(this.handleError(this.apiUrl + ' - get revisions ega', null))
        );
    }

    getPrescrizioniDaProcessare(tipo: string, fields: Array<string>, operators: Array<string>, values: Array<any>, state: ClrDatagridStateInterface): Observable<QueryResult<Prescrizione>> {
        const request = new GetAllDaProcessareRequest(tipo, fields, operators, values, state.sort ? state.sort.by.toString() : 'updatedOn',
            state.sort ? (state.sort.reverse ? 'desc' : 'asc') : 'asc', state.page.current ? state.page.current -1 : 0,
            state.page.size ? state.page.size : 10);
        return this.httpClient.post<QueryResult<Prescrizione>>(this.apiUrl + '/get-all-da-processare', request).pipe(
            catchError(this.handleError(this.apiUrl + ' - get-all-da-processare', new QueryResult<Prescrizione>())));
    }

    getPrescrizioniDaAutorizzare(fields: Array<string>, operators: Array<string>, values: Array<any>, state: ClrDatagridStateInterface): Observable<QueryResult<Prescrizione>> {
        fields.push('autorizzata');
        operators.push('eq');
        values.push(false);
        const request = new GetAllPrescrizioniRequest(true, fields, operators, values, state.sort ? state.sort.by.toString() : 'updatedOn',
            state.sort ? (state.sort.reverse ? 'desc' : 'asc') : 'asc', state.page.current ? state.page.current -1 : 0,
            state.page.size ? state.page.size : 10);
        return this.httpClient.post<QueryResult<Prescrizione>>(this.apiUrl + '/get-all', request).pipe(
            catchError(this.handleError(this.apiUrl + ' - getAll', new QueryResult<Prescrizione>())));
    }

    public createPrescrizioneO2l(command: PrescrizioneOssigenoLiquidoCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/o2l', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    public createPrescrizioneO2c(command: PrescrizioneConcentratoreCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/o2c', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    public createPrescrizioneSat(command: PrescrizioneSaturimetroCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/sat', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    public createPrescrizioneVent(command: PrescrizioneVentilazioneCommandDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/vent', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    public createPrescrizioneEga(command: PrescrizioneEgaCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/ega', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    public createPrescrizioneAssistenzaInfermieristica(command: PrescrizioneAssistenzaInfermieristicaCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/ai', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    public updatePrescrizioneO2l(id: string, command: PrescrizioneOssigenoLiquidoCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/o2l/' + id, command).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    public updatePrescrizioneO2c(id: string, command: PrescrizioneConcentratoreCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/o2c/' + id, command).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    public updatePrescrizioneSat(id: string, command: PrescrizioneSaturimetroCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/sat/' + id, command).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    public updatePrescrizioneVent(id: string, dto: PrescrizioneVentilazioneCommandDto): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/vent/' + id, dto).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    public updatePrescrizioneEga(id: string, command: PrescrizioneEgaCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/ega/' + id, command).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    public updatePrescrizioneAssistenzaInfermieristica(id: string, command: PrescrizioneAssistenzaInfermieristicaCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/ai/' + id, command).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    aggiungiDocumento(tipo: string, prescrizioneId: string, file: File, command: PrescrizioneDocumentoCommand): Observable<number> {
        let id: number;

        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/' + tipo, command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<PrescrizioneDocumento> => {
                id = this.extractIdFromLocation(res);
                if (id) {
                    return this.httpClient.get<PrescrizioneDocumento>(this.apiUrl + '/' + prescrizioneId + '/' + tipo + '/' + id);
                } else {
                    return of(null);
                }
            }),
            switchMap((documento: PrescrizioneDocumento) => {
                if (documento != null) {
                    return this.httpClient.put(this.storageBasePath + documento.fileUrl, file);
                } else {
                    return of(null);
                }
            }),
            switchMap(() => {
                return of(id);
            }),
            catchError(this.handleError(' - aggiungiDocumento', null))
        );
    }

    eliminaDocumento(tipo: string, prescrizioneId: string, documentoId: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/' + prescrizioneId + '/' + tipo + '/' + documentoId).pipe(
            catchError(this.handleError(this.apiUrl + ' - eliminaDocumento', null))
        );
    }

    getDocumenti(tipo: string, id: string): Observable<Array<PrescrizioneDocumento>> {
        return this.httpClient.get<Array<PrescrizioneDocumento>>(this.apiUrl + '/' + id + '/' + tipo).pipe(
            catchError(this.handleError(this.apiUrl + ' - getDocumenti', null))
        );
    }

    getDocumentoDownloadUrl(tipo: string, documentoId: string): Observable<string> {
        return this.httpClient.get<ResultUrl>(this.apiUrl + '/' + tipo + '/' + documentoId + '/download-url').pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError(this.apiUrl + ' - getDocumentoDownloadUrl', null))
        );
    }
    
    aggiungiNota(prescrizioneId: string, command: PrescrizioneNotaCommand): Observable<number> {
        let id: number;

        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/note', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<number> => {
                id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError('aggiungiNota', null))
        );
    }

    eliminaNota(prescrizioneId: string, notaId: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/' + prescrizioneId + '/note/' + notaId).pipe(
            catchError(this.handleError(this.apiUrl + ' - elimina nota', null))
        );
    }

    getNote(id: string, page: number, size: number): Observable<Array<PrescrizioneNota>> {
        const params: HttpParams = new HttpParams().set('page', page.toString()).set('size', size.toString());

        return this.httpClient.get<Array<PrescrizioneNota>>(this.apiUrl + '/' + id + '/note', {params: params}).pipe(
            catchError(this.handleError(this.apiUrl + '- : get note', null))
        );
    }

    aggiungiNotaPrescrittore(prescrizioneId: string, command: PrescrizioneNotaPrescrittoreCommand): Observable<number> {
        let id: number;

        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/note-prescrittore', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<number> => {
                id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError('aggiungiNotaPrescrittore', null))
        );
    }

    eliminaNotaPrescrittore(prescrizioneId: string, notaId: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/' + prescrizioneId + '/note-prescrittore/' + notaId).pipe(
            catchError(this.handleError(this.apiUrl + ' - elimina nota prescrittore', null))
        );
    }

    getNotePrescrittore(id: string, page: number, size: number): Observable<Array<PrescrizioneNotaPrescrittore>> {
        const params: HttpParams = new HttpParams().set('page', page.toString()).set('size', size.toString());

        return this.httpClient.get<Array<PrescrizioneNotaPrescrittore>>(this.apiUrl + '/' + id + '/note-prescrittore', {params: params}).pipe(
            catchError(this.handleError(this.apiUrl + '- : get note prescrittore', null))
        );
    }

    assegnaPartner(prescrizioneId: string, partnerId: string, dataAssegnazione: Date, codicePartner: string): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/partner/assign/' + partnerId, {'data': dataAssegnazione, 'codicePartner': codicePartner}).pipe(
            catchError(this.handleError(this.apiUrl + ' - assegna partner', null))
        );
    }

    deassegnaPartner(prescrizioneId: string, dataDeassegnazione: Date): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/partner/remove', {'data': dataDeassegnazione}).pipe(
            catchError(this.handleError(this.apiUrl + ' - deassegna partner', null))
        );
    }

    autorizzaPrescrizione(prescrizioneId: string): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/autorizza', {}).pipe(
            catchError(this.handleError(this.apiUrl + ' - autorizza', null))
        );
    }

    attivaPrescrizioneO2l(prescrizioneId: string, command: PrescrizioneO2lAttivaCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/attiva-o2l', command).pipe(
            catchError(this.handleError(this.apiUrl + ' - attiva-o2l', null))
        );
    }

    attivaPrescrizioneO2c(prescrizioneId: string, command: PrescrizioneO2cAttivaCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/attiva-o2c', command).pipe(
            catchError(this.handleError(this.apiUrl + ' - attiva-o2c', null))
        );
    }

    attivaPrescrizioneSat(prescrizioneId: string, command: PrescrizioneSatAttivaCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/attiva-sat', command).pipe(
            catchError(this.handleError(this.apiUrl + ' - attiva-sat', null))
        );
    }

    attivaPrescrizioneVent(prescrizioneId: string, command: PrescrizioneVentAttivaCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/attiva-vent', command).pipe(
            catchError(this.handleError(this.apiUrl + ' - attiva-vent', null))
        );
    }

    richiediSospensione(prescrizioneId: string): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/richiedi-sospensione', {}).pipe(
            catchError(this.handleError(this.apiUrl + ' - richiedi-sospensione', null))
        );
    }

    richiediRiattivazione(prescrizioneId: string): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/richiedi-riattivazione', {}).pipe(
            catchError(this.handleError(this.apiUrl + ' - richiedi-riattivazione', null))
        );
    }

    richiediCessazione(prescrizioneId: string): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/richiedi-cessazione', {}).pipe(
            catchError(this.handleError(this.apiUrl + ' - richiedi-cessazione', null))
        );
    }

    sospendiPrescrizione(prescrizioneId: string, dataSospensione: Date): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/sospendi', {'data': dataSospensione}).pipe(
            catchError(this.handleError(this.apiUrl + ' - sospendi', null))
        );
    }

    riattivaPrescrizione(prescrizioneId: string, dataRiattivazione: Date): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/riattiva', {
            'data': dataRiattivazione
        }).pipe(
            catchError(this.handleError(this.apiUrl + ' - riattiva', null))
        );
    }

    cessaPrescrizione(prescrizioneId: string, dataCessazione: Date): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/cessa', {
            'data': dataCessazione
        }).pipe(
            catchError(this.handleError(this.apiUrl + ' - cessa', null))
        );
    }

    annullaPrescrizione(prescrizioneId: string, dataAnnullamento: Date): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/annulla', {'data': dataAnnullamento}).pipe(
            catchError(this.handleError(this.apiUrl + ' - annulla', null))
        );
    }

    confermaModifica(prescrizioneId: string): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/conferma-modifica', {}).pipe(
            catchError(this.handleError(this.apiUrl + ' - conferma-modifica', null))
        );
    }

    rinnovaPrescrizione(prescrizioneId: string, tipo: string, payload: any): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + tipo + '/' + prescrizioneId + '/rinnova', payload).pipe(
            catchError(this.handleError(this.apiUrl + ' - rinnova', null))
        );
    }

    confermaRinnovo(prescrizioneId: string): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/conferma-rinnovo', {}).pipe(
            catchError(this.handleError(this.apiUrl + ' - conferma-rinnovo', null))
        );
    }

    prorogaPrescrizione(prescrizioneId: string): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/proroga', {}).pipe(
            catchError(this.handleError(this.apiUrl + ' - proroga', null))
        );
    }

    confermaProroga(prescrizioneId: string): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/conferma-proroga', {}).pipe(
            catchError(this.handleError(this.apiUrl + ' - conferma-proroga', null))
        );
    }

    aggiornaRisultatoPrescrizione(prescrizioneId: string, file: File): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/risultato', {fileRisultatoName: file.name}).pipe(
            switchMap((response: any) => {
                return this.httpClient.put(this.storageBasePath + response.url, file);
            }),
            catchError(this.handleError('aggiornaRisultato', null))
        );
    }


    getRisultatoDownloadUrl(prescrizioneId: string): Observable<string> {
        return this.httpClient.get<ResultUrl>(this.apiUrl + '/' + prescrizioneId + '/risultato/download-url').pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError(this.apiUrl + ' - getRisultatoDownloadUrl', null))
        );
    }
    
    effettuaPrescrizione(prescrizioneId: string, dataEffettuazione: Date): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/effettua',  {dataEffettuazione: dataEffettuazione}).pipe(
            catchError(this.handleError('effettuaPrescrizione', null))
        );
    }

    getExportDD(from: string, to: string, contrattoId: number) {
        return this.httpClient.post(this.apiUrl + '/o2l/export-file-dd', {'from': from, 'to': to, 'contrattoId': contrattoId}).pipe(
            catchError(this.handleError(this.apiUrl + ' - export file dd', null))
        );
    }

    getExportF1(from: string, to: string, contrattoId: number) {
        return this.httpClient.post(this.apiUrl + '/o2l/export-file-f1', {'from': from, 'to': to, 'contrattoId': contrattoId}).pipe(
            catchError(this.handleError(this.apiUrl + ' - export file f1', null))
        );
    }

    getExportF2(from: string, to: string, contrattoId: number) {
        return this.httpClient.post(this.apiUrl + '/o2l/export-file-f2', {'from': from, 'to': to, 'contrattoId': contrattoId}).pipe(
            catchError(this.handleError(this.apiUrl + ' - export file f2', null))
        );
    }

    getTasks(idPrescrizioneVentilatore: string): Observable<Array<Task>> {
        return this.httpClient.get<Array<Task>>(this.apiUrl + '/prescrizioni_ventilatori/' + idPrescrizioneVentilatore + '/tasks').pipe(
            catchError(this.handleError(this.apiUrl + ' - getTasks', null))
        );
    }

    annullaRichiesta(prescrizioneId: string): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + prescrizioneId + '/annulla-richiesta', {}).pipe(
            catchError(this.handleError(this.apiUrl + ' - conferma-proroga', null))
        );
    }

    deleteList(command: IdListDTO): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/delete-list', command).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete-list', null))
        );
    }

    exportToExcelFile(tipo: string) {
        return this.httpClient.post<ResultUrl>(this.apiUrl + '/' + tipo + '/export-to-excel-file', {}).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError('export-to-excel-file', null))
        );
    }
}
