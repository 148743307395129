// *************************************************
// * domolife_system Project - © 2020
// *
// * Author: Armando Taglieri (jadbit)
// * Created on: 3/30/20 11:31 PM
// *************************************************

import {StatoPrescrizione} from '../stato-prescrizione';

export class ReportPazientiCommand {
    page: number;
    size: number;
    prescrittoreId: number;
    aziendaId: number;
    distrettoId: number;
    partnerId: number;
    stato: StatoPrescrizione;
    tipo: string
    constructor(
        page: number,
        size: number,
        prescrittoreId: number,
        aziendaId: number,
        distrettoId: number,
        partnerId: number,
        stato: StatoPrescrizione,
        tipo: string
    ) {
        this.page = page;
        this.size = size;
        this.prescrittoreId = prescrittoreId;
        this.aziendaId = aziendaId;
        this.distrettoId = distrettoId;
        this.partnerId = partnerId;
        this.stato = stato;
        this.tipo = tipo;
    }
}
