import {AbstractCommand} from './abstract-command';
import {Cittadinanza} from '../cittadinanza';

export class PazienteUpdateCommand extends AbstractCommand {
    nome: string;
    cognome: string;
    // codicePartner: string;
    dataNascita: string;
    cittaNascita: string;
    provinciaNascita: string;
    sesso: string;
    codiceFiscale: string;
    cittadinanza: Cittadinanza;
    nazioneId: string;
    telefono: string;
    telefonoFisso: string;
    comuneId: string;
    extraAsl: boolean;
    residenzaExtraAsl: string;
    indirizzo: string;
    numeroCivico: string;
    cap: string;
    comuneDiConsegnaId: string;
    comuneConsegna: string;
    indirizzoConsegna: string;
    numeroCivicoConsegna: string;
    capConsegna: string;
    nomeDelegato: string;
    cognomeDelegato: string;
    qualificaDelegato: string;
    telefonoDelegato: string;
    mailDelegato: string;
    giaInTerapia: boolean;
    zonaDiConsegna: number;
    codiceDistributivo: string;
    
    constructor(
        nome: string,
        cognome: string,
        // codicePartner: string,
        dataNascita: string,
        cittaNascita: string,
        // provinciaNascita: string,
        sesso: string,
        codiceFiscale: string,
        cittadinanza: Cittadinanza,
        nazioneId: string,
        telefono: string,
        telefonoFisso: string,
        comuneId: string,
        extraAsl: boolean,
        residenzaExtraAsl: string,
        indirizzo: string,
        numeroCivico: string,
        cap: string,
        comuneDiConsegnaId: string,
        comuneConsegna: string,
        indirizzoConsegna: string,
        numeroCivicoConsegna: string,
        capConsegna: string,
        nomeDelegato: string,
        cognomeDelegato: string,
        qualificaDelegato: string,
        telefonoDelegato: string,
        mailDelegato: string,
        giaInTerapia: boolean,
        zonaDiConsegna: number,
        codiceDistributivo: string
    ) {
        super();
        this.nome = nome;
        this.cognome = cognome;
        // this.codicePartner = codicePartner;
        this.dataNascita = dataNascita;
        this.cittaNascita = cittaNascita;
        // this.provinciaNascita = provinciaNascita;
        this.sesso = sesso;
        this.codiceFiscale = codiceFiscale;
        this.cittadinanza = cittadinanza;
        this.nazioneId = nazioneId;
        this.telefono = telefono;
        this.telefonoFisso = telefonoFisso;
        this.comuneId = comuneId;
        this.extraAsl = extraAsl;
        this.residenzaExtraAsl = residenzaExtraAsl;
        this.indirizzo = indirizzo;
        this.numeroCivico = numeroCivico;
        this.cap = cap;
        this.comuneDiConsegnaId = comuneDiConsegnaId;
        this.comuneConsegna = comuneConsegna;
        this.indirizzoConsegna = indirizzoConsegna;
        this.numeroCivicoConsegna = numeroCivicoConsegna;
        this.capConsegna = capConsegna;
        this.nomeDelegato = nomeDelegato;
        this.cognomeDelegato = cognomeDelegato;
        this.qualificaDelegato = qualificaDelegato;
        this.telefonoDelegato = telefonoDelegato;
        this.mailDelegato = mailDelegato;
        this.giaInTerapia = giaInTerapia;
        this.zonaDiConsegna = zonaDiConsegna;
        this.codiceDistributivo = codiceDistributivo;
    }
}
