import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Struttura} from '../model/query/struttura';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {QueryResult} from '../model/query/query-result';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class StruttureService extends BaseService<Struttura> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('strutture');
    }

    getStrutture(): Observable<QueryResult<Struttura>> {
        return this.httpClient.get<QueryResult<Struttura>>(this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + '- : get strutture', null))
        );
    }

}
