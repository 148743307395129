import {Component} from '@angular/core';
import {AziendeService} from '../../core/aziende.service';
import {Router} from '@angular/router';
import {DatagridComponent} from '../common/datagrid.component';
import {Azienda} from '../../model/query/azienda';
import {Action} from '../../model/action';
import {ActionService} from '../../core/action.service';
import {AuthService} from '../../core/auth.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-aziende',
    templateUrl: './aziende.component.html',
    styleUrls: ['./aziende.component.css']
})
export class AziendeComponent extends DatagridComponent<Azienda> {

    constructor(
        public authService: AuthService,
        private aziendeService: AziendeService,
        dialog: DialogService,
        public actionService: ActionService,
        router: Router
    ) {
        super(aziendeService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/aziende/';
    }

    onAction(entity: Azienda, action: Action) {
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }



}
