import {Component} from '@angular/core';
import {ParametriAllarmeService} from '../../core/parametri-allarme.service';
import {Router} from '@angular/router';
import {DatagridComponent} from '../common/datagrid.component';
import {ParametroAllarme} from '../../model/query/parametro-allarme';
import {Action} from '../../model/action';
import {ActionService} from '../../core/action.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-parametri-allarme',
    templateUrl: './parametri-allarme.component.html',
    styleUrls: ['./parametri-allarme.component.css']
})
export class ParametriAllarmeComponent extends DatagridComponent<ParametroAllarme> {

    constructor(
        private parametriAllarmeService: ParametriAllarmeService,
        public actionService: ActionService,
        dialog: DialogService,
        router: Router
    ) {
        super(parametriAllarmeService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/parametri-allarme/';
    }

    onAction(entity: ParametroAllarme, action: Action) {
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }



}
