import {AbstractCommand} from './abstract-command';

export class ContrattoFatturaCommand extends AbstractCommand {
    contrattoId: string;
    fileName: string;

    constructor(contrattoId: string, fileName: string) {
        super();
        this.contrattoId = contrattoId;
        this.fileName = fileName;
    }
}
