import {Component} from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {Observable} from 'rxjs';
import {QueryResult} from '../../model/query/query-result';
import {Action} from '../../model/action';
import {DatagridComponent} from '../common/datagrid.component';
import {Reparto} from '../../model/query/reparto';
import {Router} from '@angular/router';
import {ActionService} from '../../core/action.service';
import {RepartiService} from '../../core/reparti.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-reparti',
    templateUrl: './reparti.component.html'
})
export class RepartiComponent extends DatagridComponent<Reparto> {
    selectedAziendaId: any;
    
    constructor(
        private repartiService: RepartiService,
        public actionService: ActionService,
        dialog: DialogService,
        router: Router
    ) {
        super(repartiService, router, dialog);
    }

    protected fetchData(state: ClrDatagridStateInterface, fields: Array<string>, operators: Array<string>,
                        values: Array<any>): Observable<QueryResult<Reparto>> {
        let i = fields.indexOf('strutturaForeignKey');
        
        if (i != -1) {
            operators[i] = "eq";
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        i = fields.indexOf('struttura.aziendaForeignKey');
        if (i != -1) {
            operators[i] = "eq";
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
            this.selectedAziendaId = values[i];
        } else {
            this.selectedAziendaId = undefined;
        }
        return super.fetchData(state, fields, operators, values);
    }

    onAction(entity: Reparto, action: Action) {
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }

    protected getEditUrl(): string {
        return '/reparti/';
    }
}
