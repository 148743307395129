import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
    error_status: string;
    error_message: string;
    
    constructor(
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.route.queryParamMap.subscribe(params => {
            this.error_status = params.get('error_status');
            this.error_message = params.get('error_message');
        });

    }
}
