// *************************************************
// * domolife_system Project - © 2020
// *
// * Author: Armando Taglieri (jadbit)
// * Created on: 3/31/20 11:31 PM
// *************************************************

import {ReportScadenzeCommand} from './report-scadenze-command';

export class ReportScadenzeDownloadCommand extends ReportScadenzeCommand {

    reportType: string;

    constructor(
        aziendaId: number,
        page: number,
        size: number,
        from: string,
        to: string,
        distrettoId: number,
        partnerId: number,
        reportType: string
    ) {
        super(aziendaId, page, size, from, to, distrettoId, partnerId);
        this.reportType = reportType;
    }
}
