export enum StatoPrescrizione
{
    RICHIESTA_ATTIVAZIONE,
    RICHIESTA_RIATTIVAZIONE,
    ATTIVATA,
    RICHIESTA_SOSPENSIONE,
    SOSPESA,
    RICHIESTA_CESSAZIONE,
    CESSATA,
    ANNULLATA,
    EFFETTUATA
}
