import {Component} from '@angular/core';
import {Action} from '../../model/action';
import {DatagridComponent} from '../common/datagrid.component';
import {Appalto} from '../../model/query/appalto';
import {Router} from '@angular/router';
import {ActionService} from '../../core/action.service';
import {AppaltiService} from '../../core/appalti.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-appalti',
    templateUrl: './appalti.component.html',
    styleUrls: ['./appalti.component.css']
})
export class AppaltiComponent extends DatagridComponent<Appalto> {

    constructor(
        private appaltiService: AppaltiService,
        dialog: DialogService,
        public actionService: ActionService,
        router: Router
    ) {
        super(appaltiService, router, dialog);
    }

    onAction(entity: Appalto, action: Action) {
        console.log(action);
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }

    protected getEditUrl(): string {
        return '/appalti/';
    }
}
