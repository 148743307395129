import {BehaviorSubject, Observable} from 'rxjs';

export class DataObserver<T> {
    data: T;
    data$: BehaviorSubject<T>;

    constructor(t: T) {
        this.data = t;
        this.data$ = <BehaviorSubject<T>>new BehaviorSubject(t);
    }

    getData(): T {
        return this.data;
    }

    getObservable(): Observable<T> {
        return this.data$.asObservable();
    }

    update(t: T): void {
        this.data = t;
        this.data$.next(t);
    }

    updateNoSend(t: T): void {
        this.data = t;
    }

    push(): void {
        this.data$.next(this.data);
    }

    complete(t: T): void {
        this.data = t;
        this.data$.next(t);
        this.data$.complete();
    }
}
