import {Component} from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {Observable, Subscription} from 'rxjs';
import {QueryResult} from '../../model/query/query-result';
import {DatagridComponent} from '../common/datagrid.component';
import {DatagridRequest} from '../common/datagrid.component';
import {Prescrittore} from '../../model/query/prescrittore';
import {PrescrittoriService} from '../../core/prescrittori.service';
import {Router} from '@angular/router';
import {Action} from '../../model/action';
import {ActionService} from '../../core/action.service';
import {Azienda} from '../../model/query/azienda';
import {AziendeCorrentiService} from '../../core/aziende-correnti.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-prescrittori',
    templateUrl: './prescrittori.component.html',
    styles: []
})
export class PrescrittoriComponent extends DatagridComponent<Prescrittore> {
    subscription: Subscription;
    azienda: Azienda;
    
    constructor(
        private prescrittoriService: PrescrittoriService,
        private aziendeCorrentiService: AziendeCorrentiService,
        public actionService: ActionService,
        dialog: DialogService,
        router: Router
    ) {
        super(prescrittoriService, router, dialog);
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscription = this.aziendeCorrentiService.getAzienda().subscribe(azienda => {
            this.azienda = azienda;
            if (azienda != null) {
                this.refresh(this.datagridState);
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    protected manageRequest(request: DatagridRequest<this>) {
        if (request.component.azienda) {
            super.manageRequest(request);
        }
    }
    
    protected fetchData(state: ClrDatagridStateInterface, fields: Array<string>, operators: Array<string>,
                        values: Array<any>): Observable<QueryResult<Prescrittore>> {
        let i = fields.indexOf('azienda');
        
        if (i == -1) {
            fields.push("aziendaForeignKey");
            operators.push("eq");
            values.push(this.azienda.id);
        } else {
            values[i] = this.azienda.id;
        }
        i = fields.indexOf('strutturaForeignKey');
        if (i != -1) {
            operators[i] = "eq";
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        return super.fetchData(state, fields, operators, values);
    }

    protected getEditUrl(): string {
        return '/prescrittori/';
    }

    onAction(entity: Prescrittore, action: Action) {
        console.log(action);
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }

}
