import {Component} from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {Observable, Subscription} from 'rxjs';
import {QueryResult} from '../../model/query/query-result';
import {DatagridComponent} from '../common/datagrid.component';
import {DatagridRequest} from '../common/datagrid.component';
import {Paziente} from '../../model/query/paziente';
import {StatoPaziente} from '../../model/stato-paziente';
import {PazientiService} from '../../core/pazienti.service';
import {UtilsService} from '../../core/utils.service';
import {Router} from '@angular/router';
import {AuthService} from '../../core/auth.service';
import {ActionService} from '../../core/action.service';
import {AziendeCorrentiService} from '../../core/aziende-correnti.service';
import {PrescrizioneStatusService} from '../../core/prescrizione-status.service';
import {Azienda} from '../../model/query/azienda';
import {Action} from '../../model/action';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-pazienti',
    templateUrl: './pazienti.component.html',
    styles: []
})
export class PazientiComponent extends DatagridComponent<Paziente> {
    azienda: Azienda;
    subscription: Subscription;
    statiPazienteDescription: any;

    constructor(
        public authService: AuthService,
        private aziendeCorrentiService: AziendeCorrentiService,
        private prescrizioneStatusService: PrescrizioneStatusService,
        private pazientiService: PazientiService,
        public utilsService: UtilsService,
        public actionService: ActionService,
        dialog: DialogService,
        router: Router
    ) {
        super(pazientiService, router, dialog);
        this.statiPazienteDescription = this.utilsService.getStatiPazienteDescription();
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscription = this.aziendeCorrentiService.getAzienda().subscribe(azienda => {
            this.azienda = azienda;
            if (azienda != null) {
                this.refresh(this.datagridState);
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    protected manageRequest(request: DatagridRequest<this>) {
        if (request.component.azienda) {
            super.manageRequest(request);
        }
    }
    
    protected fetchData(state: ClrDatagridStateInterface, fields: Array<string>, operators: Array<string>,
                        values: Array<any>): Observable<QueryResult<Paziente>> {
        let i = fields.indexOf('azienda');
        
        if (i == -1) {
            fields.push("comune.distretto.aziendaForeignKey");
            operators.push("eq");
            values.push(this.azienda.id);
        } else {
            values[i] = this.azienda.id;
        }
        i = fields.indexOf('comune.distrettoForeignKey');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        i = fields.indexOf('stato');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        i = fields.indexOf('extraAsl');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = values[i] === '%true%';
        }
        if (!state.sort) {
            state.sort = {'by': 'cognome', 'reverse': false};
        }

        return super.fetchData(state, fields, operators, values);
    }

    protected getEditUrl(): string {
        return  '/pazienti/';
    }

    onAction(entity: Paziente, action: Action) {
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }
    
    onNuovaPrescrizione(paziente: Paziente): void {
        this.router.navigate(['prescrizioni/new'], {queryParams: {'pazienteId': paziente.id}});
    }
    
    onCessa(paziente: Paziente): void {
        this.dialog.confirm({
            title: 'Comunicazione',
            content: 'Confermi la cessazione del paziente?',
            acceptText: 'Sì',
            cancelText: 'Annulla',
            acceptType: 'warning',
            iconShape: null
        }).subscribe((result: boolean) => {
            if (result) {
                this.pazientiService.cessa(paziente.id.toString()).subscribe(() => {
                    paziente.stato = StatoPaziente.CESSATO;
                });
            }
        });
    }
}
