import {Component, Input} from '@angular/core';
import {Subject} from 'rxjs';
import {ClrDatagridFilter, ClrDatagridFilterInterface} from '@clr/angular';
import {IncaricatiService} from "../../core/incaricati.service";
import {Incaricato} from '../../model/query/incaricato';

@Component({
    selector: 'app-incaricato-filter',
    templateUrl: './incaricato.filter.component.html'
})
export class IncaricatoFilterComponent implements ClrDatagridFilterInterface<any> {
    state: {
    };
    @Input('attribute') attribute: string;
    
    incaricati: Array<Incaricato> = new Array<Incaricato>();
    
    constructor(private filterContainer: ClrDatagridFilter, private incaricatiService: IncaricatiService) {
        filterContainer.setFilter(this);
        this.incaricatiService.getAll(0, 1000000, null, null, null, "cognome", "asc").subscribe( incaricati => this.incaricati =  incaricati.objects);
    }
    
    accepts(transaction: any) {
        return false;
    }

    changes = new Subject<any>();
    
    isActive(): boolean {
        return true;
    }

    onValueChange(param: string) {
        if (param) {
            this.state = {
                property: this.attribute,
                value: param
            }
        } else {
            this.state = {};
        }
        this.changes.next(this.state);
    }
}