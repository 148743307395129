// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summaryPazienti {
    margin-top: 1rem;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJlcG9ydC1wYXppZW50aS12ZW50LmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7SUFDSSxnQkFBZ0I7QUFDcEIiLCJmaWxlIjoicmVwb3J0LXBhemllbnRpLXZlbnQuY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5zdW1tYXJ5UGF6aWVudGkge1xuICAgIG1hcmdpbi10b3A6IDFyZW07XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/ui/reports/report-pazienti-vent/report-pazienti-vent.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA,4VAA4V","sourcesContent":[".summaryPazienti {\n    margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
