import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {MaterialiService} from '../../../core/materiali.service';
import {KitMaterialiService} from '../../../core/kit-materiali.service';
import {MaterialeConsumabile} from '../../../model/query/materiale-consumabile';
import {KitMateriale} from '../../../model/query/kit-materiale';
import {MaterialeKit} from '../../../model/query/materiale-kit';
import {MaterialeConsumabileCommand} from '../../../model/command/materiale-consumabile-command';
import {MaterialeConsumabileDto} from '../../../model/command/materiale-consumabile-dto';
import {ActionService} from '../../../core/action.service';
import {UtilsService} from '../../../core/utils.service';
import {Action} from '../../../model/action';

@Component({
    selector: 'app-materiale-detail',
    templateUrl: './materiale-detail.component.html',
    styleUrls: ['./materiale-detail.component.css']
})
export class MaterialeDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    alertMessage: string;
    alertClosed = true;
    action: Action;
    readOnly = false;
    selectedLeftKits: KitMateriale[] = new Array<KitMateriale>();
    selectedRightKits: KitMateriale[] = new Array<KitMateriale>();
    rightKits: KitMateriale[] = new Array<KitMateriale>();
    leftKits: KitMateriale[] = new Array<KitMateriale>();

    constructor(
        private route: ActivatedRoute,
        private materialiService: MaterialiService,
        private kitMaterialiService: KitMaterialiService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        private utilsService: UtilsService,
        public actionService: ActionService,
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        this.kitMaterialiService.getAll(0, 10000000, [], [], [], null, null).subscribe(kits => {
            this.leftKits = kits.objects;
            if (this.id !== 'new') {
                this.materialiService.get(this.id).subscribe((materiale: MaterialeConsumabile) => {
                    this.initializeFormFields(materiale);
                    this.materialiService.getKits(this.id).subscribe((mk: Array<MaterialeKit>) => {
                        for (const obj of mk) {
                            this.rightKits.push(obj.kitMateriale);
                            this.removeKitFromArray(obj.kitMateriale, this.leftKits);
                        }
                    });
                    this.sortKits();
                    if (this.action != null && this.action == this.actionService.ACTION.VIEW) {
                        this.readOnly = true;
                    }
                });
            } else {
                this.sortKits();
                this.initializeFormFields(null);
            }
        });
    }

    removeKitFromArray(obj: KitMateriale, array: Array<KitMateriale>) {
        const index = array.findIndex(x => x.id === obj.id);

        if (index != -1) {
            array.splice(index, 1);
        }
    }

    createForm(): void {
        const formGroup = {
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(materiale: MaterialeConsumabile): void {
        let formValues;

        if (materiale) {
            formValues = {
                nome: materiale.nome
            };
        } else {
            formValues = {
                nome: ''
            };
        }
        this.form.setValue(formValues);
    }


    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const kitIds = this.rightKits.map((obj) => {
            return obj.id;
        });
        const dto: MaterialeConsumabileDto =
            new MaterialeConsumabileDto(new MaterialeConsumabileCommand(formValue.nome), kitIds);
        if (this.id === 'new') {
            this.materialiService.create(dto).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'Il materiale è stato creato';
                    this.alertClosed = false;
                }
            });
        } else {
            this.materialiService.update(this.id, dto).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Il materiale è stato aggiornato';
                    this.alertClosed = false;
                }
            });
        }

    }

    swapKits() {
        const model = this.utilsService.swapAssignment({
            leftArray: this.leftKits,
            leftSelectedArray: this.selectedLeftKits,
            rightArray: this.rightKits,
            rightSelectedArray: this.selectedRightKits
        });
        this.leftKits = model.leftArray;
        this.selectedLeftKits = model.leftSelectedArray;
        this.rightKits = model.rightArray;
        this.selectedRightKits = model.rightSelectedArray;
        this.sortKits();
    }

    sortKits(): void {
        this.leftKits.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
        this.rightKits.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
    }
}
