import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {QueryResult} from '../model/query/query-result';
import {Maschera} from '../model/query/maschera';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class MaschereService extends BaseService<Maschera> {

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('maschere');
    }

    getMaschere(): Observable<QueryResult<Maschera>> {
        return this.httpClient.get<QueryResult<Maschera>>(this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + '- : get maschere', null))
        );
    }
}
