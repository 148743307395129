import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {PartnersService} from '../../../core/partners.service';
import {PartnerCreateCommand} from '../../../model/command/partner-create-command';
import {PartnerUpdateCommand} from '../../../model/command/partner-update-command';
import {Partner} from '../../../model/query/partner';
import {Action} from '../../../model/action';
import {ActionService} from '../../../core/action.service';

@Component({
    selector: 'app-partner-detail',
    templateUrl: './partner-detail.component.html',
    styleUrls: ['./partner-detail.component.css']
})
export class PartnerDetailComponent implements OnInit {

    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;

    constructor(
        private route: ActivatedRoute,
        private partnersService: PartnersService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        public actionService: ActionService,
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        if (this.id !== 'new') {
            this.partnersService.get(this.id).subscribe((entity: Partner) => {
                this.initializeFormFields(entity);
                if (this.action != null && this.action == this.actionService.ACTION.VIEW) {
                    this.readOnly = true;
                }
            });
        } else {
            this.initializeFormFields(null);
        }
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command: PartnerCreateCommand =
            new PartnerCreateCommand(formValue.nome, formValue.cognome, formValue.username, formValue.email, formValue.ragioneSociale);
            
        if (!this.id || this.id === 'new') {
            this.partnersService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'Partner creato!';
                    this.alertClosed = false;
                }
            });
        } else {
            this.partnersService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Partner aggiornato!';
                    this.alertClosed = false;
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            nome: ['', [Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            cognome: ['', [Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            username: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            email: ['', [Validators.required, Validators.maxLength(255), Validators.email]],
            ragioneSociale: ['', [Validators.maxLength(255)]],
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(entity: Partner): void {
        let formValues;

        if (entity) {
            formValues = {
                nome: entity.nome,
                cognome: entity.cognome,
                username: entity.account.username,
                email: entity.account.email,
                ragioneSociale: entity.ragioneSociale ? entity.ragioneSociale : '',
            };
            this.form.get('username').disable();
        } else {
            formValues = {
                nome: '',
                cognome: '',
                username: '',
                email: '',
                ragioneSociale: '',
            };
        }
        this.form.setValue(formValues);
    }

}
