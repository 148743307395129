import {AccountCommand} from './account-command';

export class OperatoreCreateCommand extends AccountCommand {

    operatore: any;

    constructor(nome: string, cognome: string, username: string, email: string) {
        super(username, email);
        this.operatore = {
            'nome': nome,
            'cognome': cognome,
        };
    }
}
