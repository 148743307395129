import {ChiusuraTaskDto} from './chiusura-task-dto';

export class ChiusuraTaskConFirmaDto extends ChiusuraTaskDto {
    firmaTecnico: string;
    firmaAssistito: string;
    firmaDelegato: string;
    
    constructor(
        dataCompletamento: Date,
        completato: boolean,
        note: string,
        firmaTecnico: string,
        firmaAssistito: string,
        firmaDelegato: string
    ) {
        super(dataCompletamento, completato, note);
        this.firmaTecnico = firmaTecnico;
        this.firmaAssistito = firmaAssistito;
        this.firmaDelegato = firmaDelegato;
    }
}
