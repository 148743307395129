import {Component} from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {DatagridComponent} from '../common/datagrid.component';
import {DatagridRequest} from '../common/datagrid.component';
import {Observable, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {QueryResult} from '../../model/query/query-result';
import {Appaltante} from '../../model/query/appaltante';
import {AppaltantiService} from '../../core/appaltanti.service';
import {Action} from '../../model/action';
import {ActionService} from '../../core/action.service';
import {Azienda} from '../../model/query/azienda';
import {AziendeCorrentiService} from '../../core/aziende-correnti.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-appaltanti',
    templateUrl: './appaltanti.component.html'
})
export class AppaltantiComponent extends DatagridComponent<Appaltante> {
    subscription: Subscription;
    azienda: Azienda;
    
    constructor(
        private appaltantiService: AppaltantiService,
        private aziendeCorrentiService: AziendeCorrentiService,
        dialog: DialogService,
        public actionService: ActionService,
        router: Router
    ) {
        super(appaltantiService, router, dialog);
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscription = this.aziendeCorrentiService.getAzienda().subscribe(azienda => {
            this.azienda = azienda;
            if (azienda != null) {
                this.refresh(this.datagridState);
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    protected manageRequest(request: DatagridRequest<this>) {
        if (request.component.azienda) {
            super.manageRequest(request);
        }
    }
    
    protected fetchData(state: ClrDatagridStateInterface, fields: Array<string>, operators: Array<string>,
                        values: Array<any>): Observable<QueryResult<Appaltante>> {
        let i = fields.indexOf('azienda');
        
        if (i == -1) {
            fields.push("aziendaForeignKey");
            operators.push("eq");
            values.push(this.azienda.id);
        } else {
            values[i] = this.azienda.id;
        }
        return super.fetchData(state, fields, operators, values);
    }

    protected getEditUrl(): string {
        return  '/appaltanti/';
    }

    onAction(entity: Appaltante, action: Action) {
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }
}
