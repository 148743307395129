import {Component, Input, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {ClrDatagridFilter, ClrDatagridFilterInterface} from '@clr/angular';
import {StruttureService} from '../core/strutture.service';
import {Struttura} from '../model/query/struttura';

@Component({
    selector: 'app-struttura-filter',
    templateUrl: './struttura.filter.component.html'
})
export class StrutturaFilterComponent implements ClrDatagridFilterInterface<any> {
    state: {
    };
    @Input('attribute') attribute: string;
    @Input('aziendaId') aziendaId: number;
    
    strutture: Array<Struttura> = new Array<Struttura>();
    
    constructor(private filterContainer: ClrDatagridFilter, private struttureService: StruttureService) {
        filterContainer.setFilter(this);
        this.reloadStrutture();
    }
    
    reloadStrutture() : void {
        if (this.aziendaId) {
            this.struttureService.getAll(0, 10000, ['aziendaForeignKey'], ['eq'], [this.aziendaId], 'nome', 'asc').subscribe(strutture => this.strutture = strutture.objects);
        } else {
            this.struttureService.getStrutture().subscribe(strutture => this.strutture = strutture.objects);
        }
    }
    
    accepts(transaction: any) {
        return false;
    }

    changes = new Subject<any>();
    
    isActive(): boolean {
        return true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.aziendaId = changes['aziendaId'].currentValue;
        if (this.aziendaId !== changes['aziendaId'].previousValue) {
            this.reloadStrutture();
        }
    }
    
    onValueChange(param: string) {
        if (param) {
            this.state = {
                property: this.attribute,
                value: param
            }
        } else {
            this.state = {};
        }
        this.changes.next(this.state);
    }
}