import {GetAllRequest} from './get-all-request';

export class GetAllDaProcessareRequest extends GetAllRequest {
    tipo: string;
    
    constructor(tipo: string, fields: Array<string>, operators: Array<string>, values: Array<any>, sort: string, sortType: string, page: number, size: number) {
        super(fields, operators, values, sort, sortType, page, size);
        this.tipo = tipo;
    }
}
