import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {KitMaterialiService} from '../../../core/kit-materiali.service';
import {MaterialiService} from '../../../core/materiali.service';
import {KitMateriale} from '../../../model/query/kit-materiale';
import {MaterialeKit} from '../../../model/query/materiale-kit';
import {KitMaterialeCommand} from '../../../model/command/kit-materiale-command';
import {KitMaterialeDto} from '../../../model/command/kit-materiale-dto';
import {ActionService} from '../../../core/action.service';
import {UtilsService} from '../../../core/utils.service';
import {Action} from '../../../model/action';

@Component({
    selector: 'app-kit-materiale-detail',
    templateUrl: './kit-materiale-detail.component.html',
    styleUrls: ['./kit-materiale-detail.component.css']
})
export class KitMaterialeDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    alertMessage: string;
    alertClosed = true;
    action: Action;
    readOnly = false;
    selectedLeftMateriali: KitMateriale[] = new Array<KitMateriale>();
    selectedRightMateriali: KitMateriale[] = new Array<KitMateriale>();
    rightMateriali: KitMateriale[] = new Array<KitMateriale>();
    leftMateriali: KitMateriale[] = new Array<KitMateriale>();

    constructor(
        private route: ActivatedRoute,
        private kitMaterialiService: KitMaterialiService,
        private materialiService: MaterialiService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        private utilsService: UtilsService,
        public actionService: ActionService,
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        this.materialiService.getAll(0, 10000000, [], [], [], null, null).subscribe(materiali => {
            this.leftMateriali = materiali.objects;
            if (this.id !== 'new') {
                this.kitMaterialiService.get(this.id).subscribe((kit: KitMateriale) => {
                    this.initializeFormFields(kit);
                    this.kitMaterialiService.getMateriali(this.id).subscribe((mk: Array<MaterialeKit>) => {
                        for (const obj of mk) {
                            this.rightMateriali.push(obj.materialeConsumabile);
                            this.removeMaterialeFromArray(obj.materialeConsumabile, this.leftMateriali);
                        }
                        this.sortMateriali();
                    });
                    if (this.action != null && this.action == this.actionService.ACTION.VIEW) {
                        this.readOnly = true;
                    }
                });
            } else {
                this.sortMateriali();
                this.initializeFormFields(null);
            }
        });
    }

    removeMaterialeFromArray(obj: KitMateriale, array: Array<KitMateriale>) {
        const index = array.findIndex(x => x.id === obj.id);

        if (index != -1) {
            array.splice(index, 1);
        }
    }

    createForm(): void {
        const formGroup = {
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(materiale: KitMateriale): void {
        let formValues;

        if (materiale) {
            formValues = {
                nome: materiale.nome
            };
        } else {
            formValues = {
                nome: ''
            };
        }
        this.form.setValue(formValues);
    }


    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const materialiIds = this.rightMateriali.map((obj) => {
            return obj.id;
        });
        const dto: KitMaterialeDto =
            new KitMaterialeDto(new KitMaterialeCommand(formValue.nome), materialiIds);
            
        if (this.id === 'new') {
            this.kitMaterialiService.create(dto).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'Il kit è stato creato';
                    this.alertClosed = false;
                }
            });
        } else {
            this.kitMaterialiService.update(this.id, dto).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Il kit è stato aggiornato';
                    this.alertClosed = false;
                }
            });
        }

    }

    swapMateriali() {
        const model = this.utilsService.swapAssignment({
            leftArray: this.leftMateriali,
            leftSelectedArray: this.selectedLeftMateriali,
            rightArray: this.rightMateriali,
            rightSelectedArray: this.selectedRightMateriali
        });
        this.leftMateriali = model.leftArray;
        this.selectedLeftMateriali = model.leftSelectedArray;
        this.rightMateriali = model.rightArray;
        this.selectedRightMateriali = model.rightSelectedArray;
        this.sortMateriali();
    }
    
    sortMateriali(): void {
        this.leftMateriali.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
        this.rightMateriali.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
    }
}
