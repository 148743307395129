import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';

@Component({
    selector: 'app-search-widget',
    templateUrl: './search-widget.component.html'
})
export class SearchWidgetComponent implements OnInit {
    @Input('placeholder') placeholder: string;
    @Output() newSearchEvent = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit() {
    }

    onSearch(value: string): void {
        this.newSearchEvent.emit(value);
    }    
}
