import {Component, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {AttivitaTitolazioneService} from '../../../core/attivita-titolazione.service';
import {UtilsService} from '../../../core/utils.service';
import {AttivitaTitolazione} from '../../../model/query/attivita-titolazione';
import {Incaricato} from '../../../model/query/incaricato';
import {Prescrittore} from '../../../model/query/prescrittore';
import {AttivitaTitolazioneCommand} from '../../../model/command/attivita-titolazione';
import {IncaricatiService} from '../../../core/incaricati.service';
import {PrescrittoriService} from '../../../core/prescrittori.service';
import {AuthService} from '../../../core/auth.service';
import {ActionService} from '../../../core/action.service';
import {Action} from '../../../model/action';
import * as moment from 'moment';

@Component({
    selector: 'app-attivita-titolazione-detail',
    templateUrl: './attivita-titolazione-detail.component.html'
})
export class AttivitaTitolazioneDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    attivitaTitolazione: AttivitaTitolazione;
    alertClosed = true;
    alertMessage: String;
    incaricati: Array<Incaricato>;
    prescrittori: Array<Prescrittore>;
    file: File;
    action: Action;
    readOnly = false;
    showDateAlertModal = false;
    dateAlertModalText: string;
    isValidDate = true;
    schedulata = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public attivitaTitolazioneService: AttivitaTitolazioneService,
        private utilsService: UtilsService,
        private incaricatiService: IncaricatiService,
        private prescrittoriService: PrescrittoriService,
        public authService: AuthService,
        private formBuilder: FormBuilder,
        public globals: Globals,
        public actionService: ActionService
    ) {
        this.id = this.route.snapshot.paramMap.get('id');
        this.createForm();
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
            this.prescrittoriService.getAll(0, 10000, null, null, null, 'cognome', 'asc').subscribe((res: any) => {
                this.prescrittori = res.objects;
                if (this.id !== 'new') {
                    this.attivitaTitolazioneService.get(this.id).subscribe((attivitaTitolazione) => {
                        this.attivitaTitolazione = attivitaTitolazione;
                        if (!moment(new Date()).isBefore(this.attivitaTitolazione.dataPrevista)) {
                            this.readOnly = true;
                        }
                        if (this.attivitaTitolazione.dataSchedulata) {
                            this.schedulata = true;
                        }
                        this.incaricatiService.getList(this.incaricatiService.TIPO_INCARICATO.TECNICO).subscribe((res: any) => {
                            this.incaricati = res.objects;
                            this.initializeFormFields(attivitaTitolazione);
                            if (this.action != null && this.action == this.actionService.ACTION.VIEW) {
                                this.readOnly = true;
                            }
                        });
                    });
                } else {
                    this.initializeFormFields(null);
                }
            });
        });
    }
    
    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        
        let command: AttivitaTitolazioneCommand = new AttivitaTitolazioneCommand(null,
            this.id === 'new' ? null : Number(formValue.incaricato),
            this.id === 'new' ? new Date() : null,
            this.utilsService.fieldToDate(formValue.dataPrevista),
            this.id === 'new' ? null : this.utilsService.fieldToDate(formValue.dataSchedulata),
            this.id === 'new' ? null : this.utilsService.fieldToDate(formValue.dataCompletamento),
            this.id === 'new' ? false : formValue.nonEseguibile,
            formValue.note,
            formValue.nome,
            formValue.cognome,
            formValue.telefono,
            formValue.indirizzo,
            formValue.comune,
            Number(formValue.prescrittore),
            formValue.titolazione,
            formValue.poligrafia);
            
        if (this.id === 'new') {
            this.attivitaTitolazioneService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'L\'attività è stata creata';
                    this.alertClosed = false;
                }
            });
        } else if (formValue.nonEseguibile) {
            this.attivitaTitolazioneService.annulla(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'L\'attività è stata aggiornata';
                    this.alertClosed = false;
                    this.router.navigate(['/attivita-titolazione']);
                }
            });
        } else {
            this.attivitaTitolazioneService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'L\'attività è stata aggiornata';
                    this.alertClosed = false;
                    this.router.navigate(['/attivita-titolazione']);
                }
            });
        }
    }

    createForm(): void {
        let formGroup: any;

        formGroup = {
            owner: [{value: '', disabled: true}],
            incaricato: [{value: '', disabled: !this.authService.isAmministratoreUser()}],
            dataInserimento: [{value: '', disabled: true}],
            dataPrevista: [{value: '', disabled: !this.authService.isCommercialeUser()}, [Validators.required]],
            dataSchedulata: [{value: '', disabled: !this.authService.isTecnicoVentilazioneUser()}],
            dataCompletamento: [{value: '', disabled: true}],
            nonEseguibile: [{value: false, disabled: !this.authService.isTecnicoVentilazioneUser()}],
            note: '',
            nome: [{value: '', disabled: !this.authService.isCommercialeUser()}],
            cognome: [{value: '', disabled: !this.authService.isCommercialeUser()}],
            indirizzo: [{value: '', disabled: !this.authService.isCommercialeUser()}],
            comune: [{value: '', disabled: !this.authService.isCommercialeUser()}],
            telefono: [{value: '', disabled: !this.authService.isCommercialeUser()}],
            prescrittore: [{value: ''}],
            struttura: [{value: '', disabled: true}],
            titolazione: [{value: false}],
            poligrafia: [{value: false}]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(attivitaTitolazione: AttivitaTitolazione): void {
        let formValues;

        if (attivitaTitolazione) {
            formValues = {
                owner: attivitaTitolazione.owner ? attivitaTitolazione.owner.nome + ' ' + attivitaTitolazione.owner.cognome : '',
                incaricato: attivitaTitolazione.incaricato && attivitaTitolazione.incaricato.id ? attivitaTitolazione.incaricato.id : 0,
                dataInserimento: this.utilsService.dateToField(attivitaTitolazione.dataInserimento),
                dataPrevista: this.utilsService.dateToField(attivitaTitolazione.dataPrevista),
                dataSchedulata: this.utilsService.dateToField(attivitaTitolazione.dataSchedulata) ? this.utilsService.dateToField(attivitaTitolazione.dataSchedulata) : '',
                dataCompletamento: this.utilsService.dateToField(attivitaTitolazione.dataCompletamento) ? this.utilsService.dateToField(attivitaTitolazione.dataCompletamento) : '',
                nonEseguibile: attivitaTitolazione.nonEseguibile ? attivitaTitolazione.nonEseguibile : false,
                note: attivitaTitolazione.note ? attivitaTitolazione.note : '',
                nome: attivitaTitolazione.nome ? attivitaTitolazione.nome : '',
                cognome: attivitaTitolazione.cognome ? attivitaTitolazione.cognome : '',
                indirizzo: attivitaTitolazione.indirizzo ? attivitaTitolazione.indirizzo : '',
                comune: attivitaTitolazione.comune ? attivitaTitolazione.comune : '',
                telefono: attivitaTitolazione.telefono ? attivitaTitolazione.telefono : '',
                prescrittore: attivitaTitolazione.prescrittoreForeignKey ? attivitaTitolazione.prescrittoreForeignKey : 0,
                struttura: attivitaTitolazione.prescrittore.struttura && attivitaTitolazione.prescrittore.struttura.id ? attivitaTitolazione.prescrittore.struttura.nome : '',
                titolazione: attivitaTitolazione.titolazione,
                poligrafia: attivitaTitolazione.poligrafia
            }
            this.form.get('prescrittore').disable();
        } else {
            formValues = {
                owner: '',
                incaricato: 0,
                dataInserimento: this.utilsService.dateToField(new Date()),
                dataPrevista: '',
                dataSchedulata: '',
                dataCompletamento: '',
                nonEseguibile: false,
                note: '',
                nome: '',
                cognome: '',
                indirizzo: '',
                comune: '',
                telefono: '',
                prescrittore: 0,
                struttura: '',
                titolazione: false,
                poligrafia: false
            }
        }
        this.form.setValue(formValues);
        if (attivitaTitolazione && attivitaTitolazione.nonEseguibile) {
            this.form.get('dataSchedulata').disable();
        }
    }

    onSelectPrescrittore(id: string) {
        const formValues = {
            struttura: this.prescrittori.find(p => p.id.toString() == id).struttura.nome
        };

        this.form.patchValue(formValues);
    }

    onFileChange(event: any) {
        this.file = event.target.files[0];
    }

    uploadFile() {
        let fileName = "Titolazione-" + this.attivitaTitolazione.id;
        
        this.attivitaTitolazioneService.aggiungiDocumento(this.attivitaTitolazione.id.toString(), this.file, fileName).subscribe((result) => {
            this.attivitaTitolazione.fileName = fileName;
            
            const formValues = {
                dataCompletamento: this.utilsService.dateToField(new Date())
            };
            
            this.form.patchValue(formValues);
            this.onSubmit();
        });
    }

    onDownloadDocumento() {
        this.attivitaTitolazioneService.getDocumentDownloadUrl(this.attivitaTitolazione.id.toString()).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    onNonEseguibileChange(event): void {
        const checked = event.target.checked;
        
        if (checked) {
            this.form.get('dataSchedulata').disable();
            this.clearDataSchedulata();
        } else {
            this.form.get('dataSchedulata').enable();
        }
    }
    
    clearDataSchedulata(): void {
        const formValues = {
            dataSchedulata: ''
        };

        this.form.patchValue(formValues);
    }

    onDataSchedulataChange(event) {
        if (event) {
            let dataPrevista: Date = this.utilsService.fieldToDate(this.form.getRawValue().dataPrevista);
            
            if (moment(event).isAfter(dataPrevista)) {
                this.dateAlertModalText = 'La data di appuntamento concordata non può essere posteriore al ' + moment(dataPrevista).format('DD/MM/YYYY');
                this.showDateAlertModal = true;
                this.isValidDate = false;
            } else {
                this.isValidDate = true;
            }
        }
    }

    onDateAlertModalClose() {
        this.showDateAlertModal = false;
        this.clearDataSchedulata();
    }
}
 