import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {ReportsService} from '../../../core/reports.service';
import {UtilsService} from '../../../core/utils.service';
import {AuthService} from "../../../core/auth.service";
import {DialogService} from '../../dialog';

@Component({
    selector: 'app-report-utenti',
    templateUrl: './report-utenti.component.html',
    styleUrls: ['./report-utenti.component.css']
})
export class ReportUtentiComponent {
    objectList: any[] = [];
    
    constructor(
        protected reportsService: ReportsService,
        public utilsService: UtilsService,
        public authService: AuthService,
        protected dialog: DialogService
    ) {
    }

    refresh() {
        this.reportsService.fetchReportUtenti().subscribe((result) => {
            this.objectList = result;
        });
    }

    openDownloadAlert() {
        this.onSubmit("xls");
    }

    onSubmit(type: any) {
        this.objectList = [];
        this.refresh();
        if (type) {
            this.reportsService.getReportUtentiDownloadLink().subscribe((url) => {
                if (url) {
                    // window.location.href = url;
                    window.open(url);
                }
            });
        }
    }
}
