import {KitMaterialeCommand} from './kit-materiale-command';

export class KitMaterialeDto {
    kitMateriale: KitMaterialeCommand;
    materialiConsumabiliIds: Array<number>;

    constructor(
        kitMateriale: KitMaterialeCommand,
        materialiConsumabiliIds: Array<number>
    ) {
        this.kitMateriale = kitMateriale;
        this.materialiConsumabiliIds = materialiConsumabiliIds;
    }
}
