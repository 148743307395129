import {NgModule} from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {AuthService} from './auth.service';
import {AuthGuard} from './auth.guard';
import {CheckPathAuthorizationService} from './check-path-authorization.service';
import {AuthInterceptor} from './auth.interceptor';
import {UploadService} from './upload.service';
import {MeService} from './me.service';
import {PrescrizioniService} from './prescrizioni.service';
import {AmministratoriService} from './amministratori.service';
import {PrescrittoriService} from './prescrittori.service';
import {PazientiService} from './pazienti.service';
import {PartnersService} from './partners.service';
import {ApprovatoriService} from './approvatori.service';
import {AppaltantiService} from './appaltanti.service';
import {InfermieriService} from './infermieri.service';
import {FornitoriService} from './fornitori.service';
import {OperatoriCallCenterService} from './operatori-callcenter.service';
import {AziendeService} from './aziende.service';
import {AziendeCorrentiService} from './aziende-correnti.service';
import {UtilsService} from './utils.service';
import {Globals} from './globals.service';
import {ComuniService} from './comuni.service';
import {OperatoriService} from './operatori.service';
import {ContrattiService} from './contratti.service';
import {PrescrizioneStatusService} from './prescrizione-status.service';
import {ContenitoriService} from './contenitori.service';
import {ImportJobsService} from './import-jobs.service';
import {ConcentratoriService} from './concentratori.service';
import {MaterialiService} from './materiali.service';
import {KitMaterialiService} from './kit-materiali.service';
import {ProfiliService} from './profili.service';
import {VentilatoriService} from './ventilatori.service';
import {ModalitaVentilatorieService} from './modalita-ventilatorie.service';
import {ParametriVentilatoreService} from './parametri-ventilatore.service';
import {ParametriAllarmeService} from './parametri-allarme.service';
import {TaskService} from './task.service';
import {IncaricatiService} from './incaricati.service';
import {SaturimetriService} from './saturimetri.service';
import {NazioniService} from './nazioni.service';
import {AppaltiService} from './appalti.service';
import {AttivitaTitolazioneService} from './attivita-titolazione.service';
import {StruttureService} from './strutture.service';
import {RepartiService} from './reparti.service';
import {MaschereService} from './maschere.service';
import {AdminService} from './admin.service';
import {PrescrittoriEsterniService} from './prescrittori-esterni.service';

@NgModule({ imports: [], providers: [
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        AuthGuard,
        CheckPathAuthorizationService,
        UploadService,
        ImportJobsService,
        MeService,
        PrescrizioniService,
        AmministratoriService,
        PrescrittoriService,
        OperatoriService,
        PazientiService,
        ComuniService,
        PartnersService,
        ApprovatoriService,
        AppaltantiService,
        InfermieriService,
        FornitoriService,
        OperatoriCallCenterService,
        AziendeService,
        AziendeCorrentiService,
        ContrattiService,
        PrescrizioneStatusService,
        ContenitoriService,
        ConcentratoriService,
        MaterialiService,
        KitMaterialiService,
        ProfiliService,
        VentilatoriService,
        ModalitaVentilatorieService,
        ParametriVentilatoreService,
        ParametriAllarmeService,
        TaskService,
        IncaricatiService,
        SaturimetriService,
        NazioniService,
        UtilsService,
        AppaltiService,
        AttivitaTitolazioneService,
        StruttureService,
        RepartiService,
        MaschereService,
        AdminService,
        PrescrittoriEsterniService,
        Globals,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class CoreModule {
}
