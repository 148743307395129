import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {AbstractControl, ValidatorFn} from '@angular/forms';
import {MeService} from './me.service';

export function FiscalCodeValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        if (control.value) {
            let cf: string = control.value;

            if (/^[0-9A-Z]{16}$/.test(cf)) {
                let map = [1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 1, 0, 5, 7, 9, 13, 15, 17,
                    19, 21, 2, 4, 18, 20, 11, 3, 6, 8, 12, 14, 16, 10, 22, 25, 24, 23];
                let s = 0;

                for (var i = 0; i < 15; i++) {
                    let c = cf.charCodeAt(i);
                    if (c < 65)
                        c = c - 48;
                    else
                        c = c - 55;
                    if (i % 2 == 0)
                        s += map[c];
                    else
                        s += c < 10 ? c : c - 10;
                }
                return String.fromCharCode(65 + s % 26) === cf.charAt(15) ? null : {'fiscalCodeFormat': {value: control.value}};
            } else {
                return {'fiscalCodeFormat': {value: control.value}};
            }
        } else {
            return null;
        }
    };
}

@Injectable()
export class UtilsService {
    constructor() {
    }

    public isValidEmailAddress(emailAddress: string): boolean {
        var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
        return pattern.test(emailAddress);
    }

    public dateToField(d: Date): string {
        return d ? moment(d).format('DD/MM/YYYY').toString() : '';
    }

    public datetimeToField(d: Date): string {
        return d ? moment(d).format('DD/MM/YYYY HH:mm').toString() : '';
    }

    public dateToFilter(d: Date): string {
        return d ? moment(d).format('YYYY-MM-DD HH:mm:SS').toString() : '';
    }

    public formatDate(d: Date, format: string): string {
        return d ? moment(d).format(format).toString() : '';
    }

    public fieldToDatetimeString(field: string): string {
        return moment.utc(field, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATETIME_LOCAL_MS);
    }

    public fieldToDate(field: string): Date {
        return field ? moment.utc(field, 'DD/MM/YYYY').startOf('day').toDate() : undefined;
    }

    public fieldToDatetime(field: string): Date {
        return field ? moment.utc(field, 'DD/MM/YYYY HH:mm').toDate() : undefined;
    }

    public fieldToDateNoUtc(field: Date): Date {
        return field ? moment(field).startOf('day').toDate() : undefined;
    }

    public dateToEpoch(thedate: Date): Date {
        thedate.setHours(0, 0, 0, 0);

        return thedate;
    }
    
    public datetimeLocalChanged(eventDate: string): Date | null {
        return !!eventDate ? new Date(eventDate) : null;
    }

    getUniqueLinkParameter(): string {
        return new Date().getTime().toString();
    }

    public getServiziOssigenoDescription(meService: MeService) {
        let o = {};
        
        if (meService.isServiceEnabled('o2l')) {
            o['o2l'] = 'Ossigeno liquido';
        }
        if (meService.isServiceEnabled('o2c')) {
            o['o2c'] = 'Concentratore';
        }
        if (meService.isServiceEnabled('sat')) {
            o['sat'] = 'Saturimetro';
        }
        return o;
    }

    public getServiziVentilazioneDescription(meService: MeService) {
        let o = {};
        
        if (meService.isServiceEnabled('vent')) {
            o['vent'] = 'Ventilazione';
        }
        return o;
    }

    public getServiziServiziDescription(meService: MeService) {
        let o = {};
        
        if (meService.isServiceEnabled('ega')) {
            o['ega'] = 'Emogas analisi';
        }
        if (meService.isServiceEnabled('ai')) {
            o['ai'] = 'Assistenza infermieristica';
        }
        return o;
    }

    public getServiziDescription() {
        return {
            'o2l': 'Ossigeno liquido',
            'o2c': 'Concentratore',
            'sat': 'Saturimetro',
            'vent': 'Ventilazione',
            'ega': 'Emogas analisi',
            'ai': 'Assistenza infermieristica'
        };
    }

    public getServiziConScadenzaDescription(meService: MeService) {
        let o = {};
        let all: boolean = true;
        
        if (meService.isServiceEnabled('o2l')) {
            o['o2l'] = 'Ossigeno liquido';
        } else {
            all = false;
        }
        if (meService.isServiceEnabled('o2c')) {
            o['o2c'] = 'Concentratore';
        } else {
            all = false;
        }
        if (meService.isServiceEnabled('sat')) {
            o['sat'] = 'Saturimetro';
        } else {
            all = false;
        }
        if (meService.isServiceEnabled('vent')) {
            o['vent'] = 'Ventilazione';
        } else {
            all = false;
        }
        if (all) {
            o[''] = '';
        }
        return o;
    }

    public getServiziDescriptionMap(): any {
        return new Map()
            .set('o2l', 'Ossigeno liquido')
            .set('o2c', 'Concentratore di ossigeno')
            .set('sat', 'Saturimetro')
            .set('vent', 'Ventilazione')
            .set('ega', 'Emogas analisi')
            .set('ai', 'Assistenza infermieristica');
    }

    public getAllServices(): Array<string> {
        return [
            'o2l',
            'o2c',
            'sat',
            'vent',
            'ega',
            'ai'
        ];
    }

    public getTipiTaskDescription(): Object {
        return {
            'cre': 'Consegna presso reparto',
            'cpa': 'Attivazione presso assistito',
            'sos': 'Sospensione / cessazione',
            'aor': 'Accesso ordinario',
            'ast': 'Accesso straordinario',
            'gen': 'Accesso generico',
            'col': 'Collaudo',
            'cma': 'Consegna consumabili',
            'vel': 'Verifica elettrica',
            'stz': 'Sostituzione',
            'rit': 'Ritiro dispositivo',
            'fup': 'Followup',
            'cpl': 'Compliance',
            'rep': 'Reportistica'
        };
    }

    public getTipiTask1(): Array<string> {
        return [
            'gen',
            'aor',
            'ast',
            'cpa',
            'vel',
            'stz',
            'rit'
        ];
    }

    public getTipiTask2(): Array<string> {
        return [
            'cma',
            'col',
            'cre',
            'sos',
            'fup',
            'cpl',
            'rep'
        ];
    }

    public getTipiTaskDescriptionForReport(): Object {
        return {
            'cre': 'CR',
            'cpa': 'AT',
            'sos': 'SO',
            'aor': 'AO',
            'ast': 'AS',
            'gen': 'AG',
            'col': 'CO',
            'cma': 'CC',
            'vel': 'VE',
            'stz': 'SZ',
            'rit': 'RD',
            'fup': 'FU',
            'cpl': 'CM',
            'rep': 'RE'
        };
    }

    public getStatiTask(): Array<Number> {
        return [0, 1, 2];
    }

    public getStatiTaskDescription() {
        return {
            0: 'Schedulato',
            1: 'Sospeso',
            2: 'Completato'
        };
    }

    public getMotiviRitiroDescription() {
        return [
            'Fine terapia',
            'Sospensione terapia',
            'Decesso assistito',
            'Richiesto da ASL',
            'Richiesto da assistito'
        ];
    }

    public getStatiPazienteDescription() {
        return {
            0: 'Attivo',
            1: 'Non attivo',
            2: 'Deceduto'
        };
    }

    public getMonthsMap() {
        return {
            0: 'Gennaio',
            1: 'Febbraio',
            2: 'Marzo',
            3: 'Aprile',
            4: 'Maggio',
            5: 'Giugno',
            6: 'Luglio',
            7: 'Agosto',
            8: 'Settembre',
            9: 'Ottobre',
            10: 'Novembre',
            11: 'Dicembre',
        };
    }

    public getMonthsArray() {
        return [
            'Gennaio',
            'Febbraio',
            'Marzo',
            'Aprile',
            'Maggio',
            'Giugno',
            'Luglio',
            'Agosto',
            'Settembre',
            'Ottobre',
            'Novembre',
            'Dicembre',
        ];
    }

    removeFromArray(obj: any, array: Array<any>) {
        const index = array.findIndex(x => x.id === obj.id);

        if (index != -1) {
            array.splice(index, 1);
        }
    }

    swapAssignment(model: {
        leftArray: Array<any>;
        leftSelectedArray: Array<any>;
        rightArray: Array<any>;
        rightSelectedArray: Array<any>
    }) {
        for (const obj of model.leftSelectedArray) {
            model.rightArray.push(obj);
            this.removeFromArray(obj, model.leftArray);
        }
        for (const obj of model.rightSelectedArray) {
            model.leftArray.push(obj);
            this.removeFromArray(obj, model.rightArray);
        }
        model.leftSelectedArray = new Array<any>();
        model.rightSelectedArray = new Array<any>();

        return model;
    }
}
