import {MaterialeConsumabileCommand} from './materiale-consumabile-command';

export class MaterialeConsumabileDto {
    materiale: MaterialeConsumabileCommand;
    kitIds: Array<number>;

    constructor(
        materiale: MaterialeConsumabileCommand,
        kitIds: Array<number>
    ) {
        this.materiale = materiale;
        this.kitIds = kitIds;
    }
}
