import {Component, Input} from '@angular/core';
import {Subject} from 'rxjs';
import {ClrDatagridFilter, ClrDatagridFilterInterface} from '@clr/angular';
import {KitMaterialiService} from '../core/kit-materiali.service';
import {KitMateriale} from '../model/query/kit-materiale';

@Component({
    selector: 'app-kit-filter',
    templateUrl: './kit.filter.component.html'
})
export class KitFilterComponent implements ClrDatagridFilterInterface<any> {
    state: {
    };
    @Input('attribute') attribute: string;
    
    kit: Array<KitMateriale> = new Array<KitMateriale>();
    
    constructor(private filterContainer: ClrDatagridFilter, private kitMaterialiService: KitMaterialiService) {
        filterContainer.setFilter(this);
        this.kitMaterialiService.getKitMateriali().subscribe(kit => this.kit = kit.objects);
    }
    
    accepts(transaction: any) {
        return false;
    }

    changes = new Subject<any>();
    
    isActive(): boolean {
        return true;
    }

    onValueChange(param: string) {
        if (param) {
            this.state = {
                property: this.attribute,
                value: param
            }
        } else {
            this.state = {};
        }
        this.changes.next(this.state);
    }
}