import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LOCALE_ID, NgModule} from '@angular/core';
import {Location, LocationStrategy, PathLocationStrategy, registerLocaleData} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {ClarityModule} from '@clr/angular';
import {AppRoutingModule} from './app-routing.module';
import {ErrorComponent} from './error.component';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {UiModule} from './ui/ui.module';
import {SharedModule} from './shared/shared.module';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import '@cds/core/icon/register.js';
import {ClarityIcons, plusIcon, plusCircleIcon, windowCloseIcon, warningStandardIcon, pencilIcon, successStandardIcon, errorStandardIcon, downloadIcon, trashIcon, userIcon, printerIcon, ellipsisVerticalIcon, 
    detailsIcon, tasksIcon, toolsIcon, containerVolumeIcon, viewListIcon, listIcon, importIcon, exportIcon, usersIcon, nodeGroupIcon, clipboardIcon, cogIcon, buildingIcon, 
    formIcon, blocksGroupIcon, dataClusterIcon, barChartIcon, exclamationTriangleIcon, blockIcon, assignUserIcon, checkIcon, truckIcon, searchIcon, displayIcon, devicesIcon,
    filterIcon, eventIcon, calendarIcon, euroIcon, thumbsUpIcon, rewindIcon, fastForwardIcon, archiveIcon, loginIcon} from '@cds/core/icon';

registerLocaleData(localeIt, 'it-IT', localeItExtra);
ClarityIcons.addIcons(plusIcon, plusCircleIcon, windowCloseIcon, warningStandardIcon, pencilIcon, successStandardIcon, errorStandardIcon, downloadIcon, trashIcon, userIcon, printerIcon, ellipsisVerticalIcon, 
    detailsIcon, tasksIcon, toolsIcon, containerVolumeIcon, viewListIcon, listIcon, importIcon, exportIcon, usersIcon, nodeGroupIcon, clipboardIcon, cogIcon, buildingIcon, 
    formIcon, blocksGroupIcon, dataClusterIcon, barChartIcon, exclamationTriangleIcon, blockIcon, assignUserIcon, checkIcon, truckIcon, searchIcon, displayIcon, devicesIcon,
    filterIcon, eventIcon, calendarIcon, euroIcon, thumbsUpIcon, rewindIcon, fastForwardIcon, archiveIcon, loginIcon);

@NgModule({ declarations: [
        ErrorComponent,
        AppComponent,
    ],
    exports: [],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        ClarityModule,
        BrowserAnimationsModule,
        CoreModule,
        SharedModule,
        UiModule], providers: [
        Location,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: LOCALE_ID, useValue: 'it-IT' },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {

}
