import {Component} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ImportJobsService} from '../../../core/import-jobs.service';
import {ImportJobCommand} from '../../../model/command/import-job-command';

@Component({
    selector: 'app-import-job-detail',
    templateUrl: './import-job-detail.component.html',
    styleUrls: ['./import-job-detail.component.css']
})
export class ImportJobDetailComponent {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    file: File;
    alertClosed = true;
    alertMessage: String;

    constructor(
        private router: Router,
        private importJobsService: ImportJobsService,
        private formBuilder: FormBuilder
    ) {
        this.createForm();
    }

    onChange(event: any) {
        this.file = event.target.files[0];
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        
        const formValue = this.form.getRawValue();
        const command: ImportJobCommand = new ImportJobCommand(formValue.name);
        
        this.importJobsService.createJob(this.file, command).subscribe((id) => {
            this.submitButton = ClrLoadingState.DEFAULT;
            this.router.navigate(['import_jobs']);
        });
    }


    createForm(): void {
        const formGroup = {
            name: [null, [Validators.required]]
        };
        this.form = this.formBuilder.group(formGroup);
    }

}
