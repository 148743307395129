import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {FileToUpload} from '../model/file-to-upload';

@Injectable()
export class UploadService {
    constructor(protected httpClient: HttpClient) {
    }

    public upload(files: Array<FileToUpload>): Array<Observable<number>> {
        const status: Array<Observable<number>> = new Array<Observable<number>>();

        files.forEach(file => {
            const req = new HttpRequest('PUT', environment.storageUrl + file.url, file.file, {
                reportProgress: true
            });
            const progress = new Subject<number>();

            this.httpClient.request(req).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {
                    progress.next(Math.round(100 * event.loaded / event.total));
                } else if (event instanceof HttpResponse) {
                    progress.complete();
                }
            });
            status.push(progress.asObservable());
        });
        return status;
    }
}
