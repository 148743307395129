import {Component, OnInit} from '@angular/core';
import {from, interval, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {AuthService} from '../../../core/auth.service';
import {BadgesService} from '../../../core/badges.service';
import {AziendeService} from '../../../core/aziende.service';
import {AziendeCorrentiService} from '../../../core/aziende-correnti.service';
import {AttivitaTitolazioneService} from '../../../core/attivita-titolazione.service';
import {CheckPathAuthorizationService} from '../../../core/check-path-authorization.service';
import {PrescrizioniService} from '../../../core/prescrizioni.service';
import {Azienda} from '../../../model/query/azienda';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styles: []
})
export class SidebarComponent implements OnInit {
    azienda: Azienda;
    numO2l: number = 0;
    numO2c: number = 0; 
    numSat: number = 0; 
    numVent: number = 0; 
    numAi: number = 0; 
    numEga: number = 0; 
    numTitolazioni: number = 0;
    numeroPrescrizioniDaAutorizzare: number = 0;
    
    collapsedVerticalNav: boolean = false; //Variabile che setta lo stato di collapsed iniziale alla vertical navbar laterale di sinistra
    
    constructor(
        public authService: AuthService,
        private aziendeService: AziendeService,
        private aziendeCorrentiService: AziendeCorrentiService,
        private attivitaTitolazioneService: AttivitaTitolazioneService,
        private badgesService: BadgesService,
        public checkPathAuthorizationService: CheckPathAuthorizationService,
        private prescrizioniService: PrescrizioniService,
        private router: Router
    ) {
        this.aziendeCorrentiService.getAzienda().subscribe(azienda => {
            this.azienda = azienda;
            if (this.authService.isAmministratoreUser() || this.authService.isOperatoreUser() || this.authService.isApprovatoreUser() || this.authService.isPartnerUser()) {
                    if (this.authService.isAmministratoreUser()) {
                        this.badgesService.daProcessareAnnounced$.subscribe(() => {
                            this.getNumeroPrescrizioniDaProcessare();
                        });
                        this.badgesService.daAutorizzareAnnounced$.subscribe(() => {
                            this.getNumeroPrescrizioniDaAutorizzare();
                        });
                    } else if (this.authService.isApprovatoreUser()) {
                        this.badgesService.daAutorizzareAnnounced$.subscribe(() => {
                            this.getNumeroPrescrizioniDaAutorizzare();
                        });
                    } else {
                        this.badgesService.daProcessareAnnounced$.subscribe(() => {
                            this.getNumeroPrescrizioniDaProcessare();
                        });
                    }
                    if (this.authService.isAmministratoreUser()) {
                        this.getNumeroPrescrizioniDaAutorizzare();
                        interval(60 * 1000).subscribe(() => {
                            this.getNumeroPrescrizioniDaAutorizzare();
                        });
                        this.getNumeroPrescrizioniDaProcessare();
                        interval(60 * 1000).subscribe(() => {
                            this.getNumeroPrescrizioniDaProcessare();
                        });
                    } else if (this.authService.isApprovatoreUser()) {
                        this.getNumeroPrescrizioniDaAutorizzare();
                        interval(60 * 1000).subscribe(() => {
                            this.getNumeroPrescrizioniDaAutorizzare();
                        });
                    } else {
                        this.getNumeroPrescrizioniDaProcessare();
                        interval(60 * 1000).subscribe(() => {
                            this.getNumeroPrescrizioniDaProcessare();
                        });
                    }
                }
        });
    }

    // TODO: implementare una logica che possa dare alla "<a></a>" la classe "selected" quando l'utente si trova nella relativa pagina,
    //  cosi da dare al link lo stesso stile che ora c'è in hover.

    ngOnInit() {
    }

    getNumeroPrescrizioniDaAutorizzare() {
        if (this.azienda) {
            this.aziendeService.getNumeroPrescrizioniDaAutorizzare(this.azienda.id.toString()).subscribe(counter => {
                this.numeroPrescrizioniDaAutorizzare = counter;
            });
        }
    }

    getNumeroPrescrizioniDaProcessare() {
        if (this.azienda) {
            this.aziendeService.getNumeroPrescrizioniDaProcessare(this.azienda.id.toString(), "o2l").subscribe(counter => {
                this.numO2l = counter;
            });
            this.aziendeService.getNumeroPrescrizioniDaProcessare(this.azienda.id.toString(), "o2c").subscribe(counter => {
                this.numO2c = counter;
            });
            this.aziendeService.getNumeroPrescrizioniDaProcessare(this.azienda.id.toString(), "sat").subscribe(counter => {
                this.numSat = counter;
            });
            this.aziendeService.getNumeroPrescrizioniDaProcessare(this.azienda.id.toString(), "vent").subscribe(counter => {
                this.numVent = counter;
            });
            this.aziendeService.getNumeroPrescrizioniDaProcessare(this.azienda.id.toString(), "ai").subscribe(counter => {
                this.numAi = counter;
            });
            this.aziendeService.getNumeroPrescrizioniDaProcessare(this.azienda.id.toString(), "ega").subscribe(counter => {
                this.numEga = counter;
            });
            this.attivitaTitolazioneService.getNumeroAttivitaDaProcessare().subscribe(counter => {
                this.numTitolazioni = counter;
            });
        }
    }

    showDaProcessare(tipo: string) {
        const queryParams = {
            pfilter: 'da_processare',
            tipo: tipo
        };
        this.router.navigate(['prescrizioni-' + tipo], {queryParams: queryParams});
    }

    show(tipo: string) {
        this.router.navigate(['prescrizioni-' + tipo]);
    }

    showAttivitaTitolazione() {
        this.router.navigate(['attivita-titolazione']);
    }

    showAttivitaTitolazioneDaProcessare() {
        const queryParams = {
            pfilter: 'da_processare'
        };
        this.router.navigate(['attivita-titolazione'], {queryParams: queryParams});
    }

    onExport(tipo: string): void {
        this.prescrizioniService.exportToExcelFile(tipo).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }
}
