import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Infermiere} from '../model/query/infermiere';
import {Azienda} from '../model/query/azienda';
import {Contratto} from '../model/query/contratto';

@Injectable()
export class InfermieriService extends BaseService<Infermiere> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('infermieri');
    }
}
