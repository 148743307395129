import { Component } from "@angular/core";
import {forkJoin} from 'rxjs';
import {Subscription} from 'rxjs';
import {ClrDatagridStateInterface} from '@clr/angular';
import { FormBuilder, Validators } from "@angular/forms";
import { ReportsService } from "../../../core/reports.service";
import { ReportComplianceCommand } from "../../../model/command/report-compliance-command";
import * as moment from "moment";
import { UtilsService } from "../../../core/utils.service";
import { ReportComplianceDownloadCommand } from "../../../model/command/report-compliance-download-command";
import { AuthService } from "../../../core/auth.service";
import { PartnersService } from "../../../core/partners.service";
import { Partner } from "../../../model/query/partner";
import { PazientiService } from "../../../core/pazienti.service";
import { Paziente } from "../../../model/query/paziente";
import {AziendeCorrentiService} from '../../../core/aziende-correnti.service';
import {Azienda} from '../../../model/query/azienda';
import {DialogService} from '../../dialog';

@Component({
    selector: "app-report-compliance",
    templateUrl: "./report-compliance.component.html",
    styleUrls: ["./report-compliance.component.css"],
})
export class ReportComplianceComponent {
    subscription: Subscription;
    total: number = 0;
    loading = false;
    form: any;

    objectList: any[] = [];
    object: any;
    currentState: ClrDatagridStateInterface;

    year: any;
    periodo: string;

    dateFrom: string;
    dateTo: string;

    azienda: Azienda;
    distrettoId: number;
    distretti: { id: number; nome: string; aziendaForeignKey: number }[];
    partners: Partner[];
    partnerId: number;
    pazienti: Paziente[];
    pazienteId: number;

    totMCPrescritti = 0;
    totMC = 0;

    constructor(
        private formBuilder: FormBuilder,
        private reportsService: ReportsService,
        public utilsService: UtilsService,
        private partnersService: PartnersService,
        private pazientiService: PazientiService,
        private aziendeCorrentiService: AziendeCorrentiService,
        public authService: AuthService,
        private dialog: DialogService
    ) {
        this.createForm();
        this.subscription = this.aziendeCorrentiService.getAzienda().subscribe(azienda => {
            if (azienda != null) {
                this.year = moment.utc().get('y');
                this.periodo = '';
                this.partnerId = null;
                this.distrettoId = null;
                this.pazienteId = null;
                
                const getPazienti = this.pazientiService
                                .getAll(0, 1000000, ["comune.distretto.aziendaForeignKey"], ["eq"], [azienda.id.toString()], "cognome", "asc");
                const getPartners = this.partnersService.getAll(0, 1000000, null, null, null, null, null);
                
                forkJoin([getPazienti, getPartners]).subscribe(
                    (results) => {
                        this.pazienti = results[0].objects;
                        this.partners = results[1].objects;
                        this.initForm();
                        this.azienda = azienda;
                    });
                this.aziendeCorrentiService.getDistretti().subscribe(results => this.distretti = results);
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    refresh(state: ClrDatagridStateInterface) {
        if (this.azienda && this.dateFrom && this.dateTo && this.periodo && this.periodo.length > 0) {
            const command = new ReportComplianceCommand(
                this.azienda.id,
                null,
                null,
                this.dateFrom,
                this.dateTo,
                this.distrettoId ? +this.distrettoId : null,
                this.partnerId ? +this.partnerId : null,
                this.pazienteId ? this.pazienteId : null
            );
            this.reportsService
                .fetchReportCompliance(command)
                .subscribe((result) => {
                    if (result) {
                        this.total = result.count;
                        this.objectList = result.objects;
                        for (const obj of result.objects) {
                            this.totMC += obj.mcConsegnati;
                            this.totMCPrescritti += obj.totaleLitriPrevisti;
                        }
                    }
                    this.loading = false;
                });
        }
        this.currentState = state;
    }

    createForm(): void {
        const formGroup = {
            periodo: [{ value: "" }, [Validators.required]],
            anno: [{ value: "" }, [Validators.required]],
            distretto: [{ value: "" }],
            partner: [{ value: "" }],
            paziente: [{ value: "" }],
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initForm() {
        const formValues = {
            periodo: "",
            anno: this.year,
            distretto: "",
            partner: "",
            paziente: ""
        };
        this.form.setValue(formValues);
    }

    openDownloadAlert() {
        this.onSubmit("xls");
    }

    onSubmit(type: any) {
        const formValue = this.form.getRawValue();
        
        this.total = 0;
        this.objectList = [];
        this.totMC = 0;
        this.totMCPrescritti = 0;
        
        this.year = formValue.anno;
        this.periodo = formValue.periodo;
        if (this.periodo && this.periodo.length > 0) {
            let month1, month2: number;

            switch (this.periodo) {
                case "1": {
                    month1 = 0;
                    month2 = 2;
                    break;
                }
                case "2": {
                    month1 = 3;
                    month2 = 5;
                    break;
                }
                case "3": {
                    month1 = 6;
                    month2 = 8;
                    break;
                }
                case "4": {
                    month1 = 9;
                    month2 = 11;
                    break;
                }
                case "5": {
                    month1 = 0;
                    month2 = 5;
                    break;
                }
                case "6": {
                    month1 = 6;
                    month2 = 11;
                    break;
                }
                case "7": {
                    month1 = 0;
                    month2 = 11;
                    break;
                }
            }
            
            let mFrom : moment.Moment = moment.utc({ y: this.year, M: month1 }).startOf('month');
            
            if (!mFrom.isAfter(moment.utc())) {
                this.distrettoId = formValue.distretto;
                this.partnerId = formValue.partner;
                this.pazienteId = formValue.paziente;
                this.dateFrom = mFrom.format("YYYY-MM-DD");
                this.dateTo = moment
                    .utc({ y: this.year, M: month2 })
                    .endOf("month")
                    .format("YYYY-MM-DD");
                this.refresh(this.currentState);
                if (type) {
                    const command = new ReportComplianceDownloadCommand(
                        this.azienda.id,
                        null,
                        null,
                        this.dateFrom,
                        this.dateTo,
                        this.distrettoId,
                        this.partnerId,
                        this.pazienteId,
                        type
                    );
                    this.reportsService
                        .getReportComplianceDownloadLink(command)
                        .subscribe((url) => {
                            if (url) {
                                window.open(url);
                            }
                        });
                }
            }                
        }
    }
}
