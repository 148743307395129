import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PrescrittoriComponent} from './ui/prescrittori/prescrittori.component';
import {PrescrittoreDetailComponent} from './ui/prescrittori/prescrittore-detail/prescrittore-detail.component';
import {PrescrittoriEsterniComponent} from './ui/prescrittori-esterni/prescrittori-esterni.component';
import {PrescrittoreEsternoDetailComponent} from './ui/prescrittori-esterni/prescrittore-esterno-detail/prescrittore-esterno-detail.component';
import {PrescrizioniComponent} from './ui/prescrizioni/prescrizioni.component';
import {PrescrizioniO2lComponent} from './ui/prescrizioni/prescrizioni-o2l.component';
import {PrescrizioniO2cComponent} from './ui/prescrizioni/prescrizioni-o2c.component';
import {PrescrizioniSatComponent} from './ui/prescrizioni/prescrizioni-sat.component';
import {PrescrizioniVentComponent} from './ui/prescrizioni/prescrizioni-vent.component';
import {PrescrizioniEgaComponent} from './ui/prescrizioni/prescrizioni-ega.component';
import {PrescrizioniAiComponent} from './ui/prescrizioni/prescrizioni-ai.component';
import {PazientiComponent} from './ui/pazienti/pazienti.component';
import {PazienteDetailComponent} from './ui/pazienti/paziente-detail/paziente-detail.component';
import {PazientiCallCenterComponent} from './ui/pazienti-call-center/pazienti-call-center.component';
import {PazienteCallCenterDetailComponent} from './ui/pazienti-call-center/paziente-call-center-detail/paziente-call-center-detail.component';
import {ApprovatoreDetailComponent} from './ui/approvatori/approvatore-detail/approvatore-detail.component';
import {ApprovatoriComponent} from './ui/approvatori/approvatori.component';
import {AppaltantiComponent} from './ui/appaltanti/appaltanti.component';
import {AppaltanteDetailComponent} from './ui/appaltanti/appaltante-detail/appaltante-detail.component';
import {AmministratoreDetailComponent} from './ui/amministratori/amministratore-detail/amministratore-detail.component';
import {AmministratoriComponent} from './ui/amministratori/amministratori.component';
import {OperatoreDetailComponent} from './ui/operatori/operatore-detail/operatore-detail.component';
import {OperatoriComponent} from './ui/operatori/operatori.component';
import {OperatoriCallCenterComponent} from './ui/operatori-callcenter/operatori-callcenter.component';
import {OperatoreCallCenterDetailComponent} from './ui/operatori-callcenter/operatore-callcenter-detail/operatore-callcenter-detail.component';
import {PartnerDetailComponent} from './ui/partners/partner-detail/partner-detail.component';
import {PartnersComponent} from './ui/partners/partners.component';
import {ErrorComponent} from './error.component';
import {PrescrizioneDetailComponent} from './ui/prescrizioni/prescrizione-detail/prescrizione-detail.component';
import {PrescrizioneStoricoComponent} from './ui/prescrizioni/prescrizione-storico/prescrizione-storico.component';
import {ContrattoDetailComponent} from './ui/contratti/contratto-detail/contratto-detail.component';
import {ContrattiComponent} from './ui/contratti/contratti.component';
import {AppaltiComponent} from './ui/appalti/appalti.component';
import {AppaltoDetailComponent} from './ui/appalti/appalto-detail/appalto-detail.component';
import {InfermieriComponent} from './ui/infermieri/infermieri.component';
import {InfermiereDetailComponent} from './ui/infermieri/infermiere-detail/infermiere-detail.component';
import {FornitoriComponent} from './ui/fornitori/fornitori.component';
import {FornitoreDetailComponent} from './ui/fornitori/fornitore-detail/fornitore-detail.component';
import {AziendaDetailComponent} from './ui/aziende/azienda-detail/azienda-detail.component';
import {AziendeComponent} from './ui/aziende/aziende.component';
import {ImportJobsComponent} from './ui/import-jobs/import-jobs.component';
import {ImportJobDetailComponent} from './ui/import-jobs/import-job-detail/import-job-detail.component';
import {ReportCostiComponent} from './ui/reports/report-costi/report-costi.component';
import {ReportComplianceComponent} from './ui/reports/report-compliance/report-compliance.component';
import {ReportPazientiComponent} from './ui/reports/report-pazienti/report-pazienti.component';
import {ReportPazientiAttiviComponent} from './ui/reports/report-pazienti-attivi/report-pazienti-attivi.component';
import {ReportConsegneComponent} from './ui/reports/report-consegne/report-consegne.component';
import {ReportTaskComponent} from './ui/reports/report-task/report-task.component';
import {ReportPlanningComponent} from './ui/reports/report-planning/report-planning.component';
import {ReportPazientiAttivatiComponent} from './ui/reports/report-pazienti-attivati/report-pazienti-attivati.component';
import {ReportPazientiAttiviOraComponent} from './ui/reports/report-pazienti-attivi-ora/report-pazienti-attivi-ora.component';
import {ReportPazientiDecedutiComponent} from './ui/reports/report-pazienti-deceduti/report-pazienti-deceduti.component';
import {ReportScadenzeComponent} from './ui/reports/report-scadenze/report-scadenze.component';
import {ReportPazientiVentComponent} from './ui/reports/report-pazienti-vent/report-pazienti-vent.component';
import {ReportUtentiComponent} from './ui/reports/report-utenti/report-utenti.component';
import {ExportMinisteroComponent} from './ui/export-ministero/export-ministero.component';
import {ConcentratoriComponent} from './ui/concentratori/concentratori.component';
import {ConcentratoreDetailComponent} from './ui/concentratori/concentratore-detail/concentratore-detail.component';
import {SaturimetriComponent} from './ui/saturimetri/saturimetri.component';
import {SaturimetroDetailComponent} from './ui/saturimetri/saturimetro-detail/saturimetro-detail.component';
import {MaterialiComponent} from './ui/materiali/materiali.component';
import {MaterialeDetailComponent} from './ui/materiali/materiale-detail/materiale-detail.component';
import {KitMaterialiComponent} from './ui/kit-materiali/kit-materiali.component';
import {KitMaterialeDetailComponent} from './ui/kit-materiali/kit-materiale-detail/kit-materiale-detail.component';
import {ParametriVentilatoreComponent} from './ui/parametri-ventilatore/parametri-ventilatore.component';
import {ParametroVentilatoreDetailComponent} from './ui/parametri-ventilatore/parametro-ventilatore-detail/parametro-ventilatore-detail.component';
import {ParametriAllarmeComponent} from './ui/parametri-allarme/parametri-allarme.component';
import {ParametroAllarmeDetailComponent} from './ui/parametri-allarme/parametro-allarme-detail/parametro-allarme-detail.component';
import {ProfiliComponent} from './ui/profili/profili.component';
import {ProfiloDetailComponent} from './ui/profili/profilo-detail/profilo-detail.component';
import {ModalitaVentilatorieComponent} from './ui/modalita-ventilatorie/modalita-ventilatorie.component';
import {ModalitaVentilatoriaDetailComponent} from './ui/modalita-ventilatorie/modalita-ventilatoria-detail/modalita-ventilatoria-detail.component';
import {VentilatoriComponent} from './ui/ventilatori/ventilatori.component';
import {VentilatoreDetailComponent} from './ui/ventilatori/ventilatori-detail/ventilatore-detail.component';
import {IncaricatiComponent} from './ui/incaricati/incaricati.component';
import {IncaricatoDetailComponent} from './ui/incaricati/incaricato-detail/incaricato-detail.component';
import {TaskComponent} from './ui/task/task.component';
import {TaskDetailComponent} from './ui/task/task-detail/task-detail.component';
import {PasswordComponent} from './ui/password/password.component';
import {AttivitaTitolazioneComponent} from './ui/attivita-titolazione/attivita-titolazione.component';
import {AttivitaTitolazioneDetailComponent} from './ui/attivita-titolazione/attivita-titolazione-detail/attivita-titolazione-detail.component';
import {StruttureComponent} from './ui/strutture/strutture.component';
import {StrutturaDetailComponent} from './ui/strutture/struttura-detail/struttura-detail.component';
import {RepartiComponent} from './ui/reparti/reparti.component';
import {RepartoDetailComponent} from './ui/reparti/reparto-detail/reparto-detail.component';
import {MaschereComponent} from './ui/maschere/maschere.component';
import {MascheraDetailComponent} from './ui/maschere/maschera-detail/maschera-detail.component';
import {RicercaPrescrizioniComponent} from './ui/ricerca-prescrizioni/ricerca-prescrizioni.component';
import {LoginEventsComponent} from './ui/login-events/login-events.component';
import {AuthGuard} from './core/auth.guard';

const routes: Routes = [
    {
        path: 'aziende/:id',
        component: AziendaDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'aziende',
        component: AziendeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'approvatori/:id',
        component: ApprovatoreDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'approvatori',
        component: ApprovatoriComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'appaltanti/:id',
        component: AppaltanteDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'appaltanti',
        component: AppaltantiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'amministratori/:id',
        component: AmministratoreDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'amministratori',
        component: AmministratoriComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'operatori/:id',
        component: OperatoreDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'operatori',
        component: OperatoriComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'operatori-callcenter/:id',
        component: OperatoreCallCenterDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'operatori-callcenter',
        component: OperatoriCallCenterComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'prescrittori/:id',
        component: PrescrittoreDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'prescrittori',
        component: PrescrittoriComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'prescrittori-esterni/:id',
        component: PrescrittoreEsternoDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'prescrittori-esterni',
        component: PrescrittoriEsterniComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'partners/:id',
        component: PartnerDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'partners',
        component: PartnersComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'pazienti/:id',
        component: PazienteDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'pazienti',
        component: PazientiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'pazienti-call-center/:id',
        component: PazienteCallCenterDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'pazienti-call-center',
        component: PazientiCallCenterComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'prescrizioni/:id',
        component: PrescrizioneDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'prescrizione-storico/:id',
        component: PrescrizioneStoricoComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'prescrizioni',
        component: PrescrizioniComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'prescrizioni-o2l',
        component: PrescrizioniO2lComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'prescrizioni-o2c',
        component: PrescrizioniO2cComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'prescrizioni-sat',
        component: PrescrizioniSatComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'prescrizioni-vent',
        component: PrescrizioniVentComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'prescrizioni-ega',
        component: PrescrizioniEgaComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'prescrizioni-ai',
        component: PrescrizioniAiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'contratti/:id',
        component: ContrattoDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'contratti',
        component: ContrattiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'appalti/:id',
        component: AppaltoDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'appalti',
        component: AppaltiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'infermieri/:id',
        component: InfermiereDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'infermieri',
        component: InfermieriComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'fornitori/:id',
        component: FornitoreDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'fornitori',
        component: FornitoriComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'concentratori',
        component: ConcentratoriComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'concentratori/:id',
        component: ConcentratoreDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'saturimetri',
        component: SaturimetriComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'saturimetri/:id',
        component: SaturimetroDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'materiali',
        component: MaterialiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'materiali/:id',
        component: MaterialeDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'kit-materiali',
        component: KitMaterialiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'kit-materiali/:id',
        component: KitMaterialeDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'parametri-ventilatore',
        component: ParametriVentilatoreComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'parametri-ventilatore/:id',
        component: ParametroVentilatoreDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'parametri-allarme',
        component: ParametriAllarmeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'parametri-allarme/:id',
        component: ParametroAllarmeDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'profili',
        component: ProfiliComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'profili/:id',
        component: ProfiloDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'modalita-ventilatorie',
        component: ModalitaVentilatorieComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'modalita-ventilatorie/:id',
        component: ModalitaVentilatoriaDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'ventilatori',
        component: VentilatoriComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'ventilatori/:id',
        component: VentilatoreDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'attivita-titolazione',
        component: AttivitaTitolazioneComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'attivita-titolazione/:id',
        component: AttivitaTitolazioneDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'incaricati',
        component: IncaricatiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'incaricati/:id',
        component: IncaricatoDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'task',
        component: TaskComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'task/:id',
        component: TaskDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'import_jobs',
        component: ImportJobsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'import_jobs/new',
        component: ImportJobDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'strutture',
        component: StruttureComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'strutture/:id',
        component: StrutturaDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reparti',
        component: RepartiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reparti/:id',
        component: RepartoDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'maschere',
        component: MaschereComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'login-events',
        component: LoginEventsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'maschere/:id',
        component: MascheraDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'ricerca-prescrizioni',
        component: RicercaPrescrizioniComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/costi',
        component: ReportCostiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/compliance',
        component: ReportComplianceComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/pazienti',
        component: ReportPazientiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/pazienti-attivi',
        component: ReportPazientiAttiviComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/pazienti-attivi-ora',
        component: ReportPazientiAttiviOraComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/pazienti-attivati',
        component: ReportPazientiAttivatiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/pazienti-disattivati',
        component: ReportPazientiDecedutiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/consegne',
        component: ReportConsegneComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'task-calendar',
        component: ReportTaskComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/planning',
        component: ReportPlanningComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/scadenze',
        component: ReportScadenzeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/pazienti-vent',
        component: ReportPazientiVentComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'reports/elenco-utenti',
        component: ReportUtentiComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'export_ministero',
        component: ExportMinisteroComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'error',
        component: ErrorComponent
    },
    {
        path: 'password',
        component: PasswordComponent
    },
    {path: '**', redirectTo: '/', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
