import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {PrescrittoreEsterno} from '../model/query/prescrittore-esterno';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {QueryResult} from '../model/query/query-result';

@Injectable()
export class PrescrittoriEsterniService extends BaseService<PrescrittoreEsterno> {

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('prescrittori-esterni');
    }

    getList(): Observable<QueryResult<PrescrittoreEsterno>> {
        return this.httpClient.get<QueryResult<PrescrittoreEsterno>>(this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + ' - get', null))
        );
    }
}
