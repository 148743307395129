import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {QueryResult} from '../model/query/query-result';
import {ParametroVentilatore} from '../model/query/parametro-ventilatore';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ParametriVentilatoreService extends BaseService<ParametroVentilatore> {

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('parametri-ventilatore');
    }

    getParametriVentilatore(): Observable<QueryResult<ParametroVentilatore>> {
        return this.httpClient.get<QueryResult<ParametroVentilatore>>(this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + '- : get parametri ventilatore', null))
        );
    }

}
