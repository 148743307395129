import {AbstractCommand} from './abstract-command';

export class AccountCommand extends AbstractCommand {
  username: string;
  email: string;

  constructor(username: string, email: string) {
    super();
    this.username = username;
    this.email = email;
  }

}
