import {Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {AuthService} from './auth.service';
import {catchError, switchMap} from 'rxjs/operators';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const tokenObservable: Observable<string> = this.authService.getToken(5);

        return tokenObservable.pipe(
            switchMap(token => {
                return next.handle(this.addHeaders(req, token)).pipe(
                    catchError((error: any): Observable<HttpEvent<any>> => {
                        // Keycloak adapter doesn't send back CORS headers when sending errors, this is a workaround
                        if (error.status === 0) {
                            const tokenObservable: Observable<string> = this.authService.getToken(-1);

                            return tokenObservable.pipe(
                                switchMap(token => {
                                    return next.handle(this.addHeaders(req, token)).pipe(
                                        catchError((error: any): Observable<HttpEvent<any>> => {
                                            return observableThrowError(error);
                                        }));
                                }),
                                catchError((error: any): Observable<HttpEvent<any>> => {
                                    return observableThrowError(error);
                                })
                            );
                        } else {
                            return observableThrowError(error);
                        }
                    })
                );
            }),
            catchError((error: any): Observable<HttpEvent<any>> => {
                return observableThrowError(error);
            })
        );
    }

    private addHeaders(req: HttpRequest<any>, token: string): HttpRequest<any> {
        if (req.url.indexOf('/api/v1') != -1) {
            let headers: HttpHeaders = req.headers;

            headers = headers.set('Authorization', 'Bearer ' + token);
            return req.clone({headers});
        } else {
            return req;
        }
    }
}
