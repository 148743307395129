import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {QueryResult} from '../model/query/query-result';
import {ParametroAllarme} from '../model/query/parametro-allarme';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ParametriAllarmeService extends BaseService<ParametroAllarme> {

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('parametri-allarme');
    }

    getParametriAllarme(): Observable<QueryResult<ParametroAllarme>> {
        return this.httpClient.get<QueryResult<ParametroAllarme>>(this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + '- : get parametri allarme', null))
        );
    }

}
