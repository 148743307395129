export enum PrescrizioneAction {
    VISUALIZZA,
    MODIFICA,
    CONFERMA_MODIFICA,
    CONFERMA_RINNOVO,
    ANNULLAMENTO,
    ATTIVAZIONE,
    AUTORIZZAZIONE,
    PROROGA,
    CONFERMA_PROROGA,
    RICHIESTA_SOSPENSIONE,
    SOSPENSIONE,
    RINNOVO,
    RICHIESTA_CESSAZIONE,
    CESSAZIONE,
    RICHIESTA_RIATTIVAZIONE,
    RIATTIVAZIONE,
    EFFETTUAZIONE,
    ANNULLAMENTO_RICHIESTA
}
