import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {PrescrittoriService} from '../../../core/prescrittori.service';
import {AziendeService} from '../../../core/aziende.service';
import {StruttureService} from '../../../core/strutture.service';
import {RepartiService} from '../../../core/reparti.service';
import {Globals} from '../../../core/globals.service';
import {ClrLoadingState} from '@clr/angular';
import {Prescrittore} from '../../../model/query/prescrittore';
import {PrescrittoreResponse} from '../../../model/query/prescrittore-response';
import {Azienda} from '../../../model/query/azienda';
import {Contratto} from '../../../model/query/contratto';
import {Struttura} from '../../../model/query/struttura';
import {Reparto} from '../../../model/query/reparto';
import {PrescrittoreUpdateCommand} from '../../../model/command/prescrittore-update-command';
import {PrescrittoreCreateCommand} from '../../../model/command/prescrittore-create-command';
import {FiscalCodeValidator} from '../../../core/utils.service';
import {Action} from '../../../model/action';
import {ActionService} from '../../../core/action.service';
import {UtilsService} from '../../../core/utils.service';

@Component({
    selector: 'app-prescrittore-detail',
    templateUrl: './prescrittore-detail.component.html',
    styleUrls: ['./prescrittore-detail.component.css']
})
export class PrescrittoreDetailComponent implements OnInit {
    tipiPrescrizioniDescription: any;
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    prescrittore: Prescrittore;
    aziende: Array<Azienda> = new Array<Azienda>();
    contratti: Array<Contratto> = new Array<Contratto>();
    strutture: Array<Struttura> = new Array<Struttura>();
    reparti: Array<Reparto> = new Array<Reparto>();
    selectedObjects = [];
    selectedAziendaId: string;
    selectedStrutturaId: number;
    selectedRepartoId: number;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    test = [{a: 'test1'}, {a: 'test2'}];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private prescrittoriService: PrescrittoriService,
        private aziendeService: AziendeService,
        private struttureService: StruttureService,
        private repartiService: RepartiService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        private utilsService: UtilsService,
        public actionService: ActionService,
    ) {
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.tipiPrescrizioniDescription = this.utilsService.getServiziDescription();
        this.createForm();
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        this.aziendeService.getAll(0, 1000000, [], [], [], null, null).subscribe(aziende => {
            this.aziende = aziende.objects;
            if (this.id !== 'new') {
                this.prescrittoriService.getWithEnabledInfo(this.id).subscribe((resp: PrescrittoreResponse) => {
                    this.prescrittore = resp.prescrittore;
                    this.prescrittoriService.getContratti(this.id).subscribe(contratti => {
                        this.contratti = contratti;
                        this.onSelectAzienda(resp.prescrittore.azienda.id.toString());
                        this.onSelectStruttura(resp.prescrittore.struttura && resp.prescrittore.struttura.id ? resp.prescrittore.struttura.id : null);
                        this.onSelectReparto(resp.prescrittore.reparto && resp.prescrittore.reparto.id ? resp.prescrittore.reparto.id : null);
                        this.initializeFormFields(resp);
                        if (this.action != null && this.action == this.actionService.ACTION.VIEW) {
                            this.readOnly = true;
                        }
                    });
                });
            } else {
                this.selectedAziendaId = null;
                this.initializeFormFields(null);
            }
        });
    }

    onSelectAzienda(id: string) {
        if (id) {
            this.selectedAziendaId = id;
            this.struttureService.getAll(0, 1000000, ['aziendaForeignKey'], ['eq'], [Number(id)], null, null).subscribe(strutture => {
                this.strutture = strutture.objects;
            });
        } else {
            this.selectedAziendaId = null;
        }
    }

    onSelectStruttura(id: number) {
        id = Number(id);
        if (id) {
            this.selectedStrutturaId = id;
            this.repartiService.getAll(0, 1000000, ['strutturaForeignKey'], ['eq'], [Number(id)], null, null).subscribe( reparti => {
                this.reparti =  reparti.objects;
            });
        } else {
            this.selectedStrutturaId = 0;
            this.selectedRepartoId = 0;
            this.reparti = new Array<Reparto>();
        }
    }

    onSelectReparto(id: number) {
        this.selectedRepartoId = Number(id);
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command: PrescrittoreCreateCommand = new PrescrittoreCreateCommand(
            formValue.nome,
            formValue.cognome,
            formValue.username,
            formValue.email,
            Number(this.selectedAziendaId),
            this.selectedStrutturaId,
            this.selectedRepartoId,
            formValue.tipo,
            // formValue.codiceStruttura,
            // formValue.tipoStruttura,
            formValue.codiceFiscale,
            formValue.telefono,
            formValue.idErogatore,
            formValue.tipoErogatore,
            formValue.enabled
        );
        
        if (!this.id || this.id === 'new') {
            this.prescrittoriService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'Prescrittore creato!';
                    this.alertClosed = false;
                }
            });
        } else {
            this.prescrittoriService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Prescrittore aggiornato!';
                    this.alertClosed = false;
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            selectedAziendaId: ['', [Validators.required]],
            selectedStrutturaId: '',
            selectedRepartoId: '',
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            cognome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            username: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            email: ['', [Validators.required, Validators.maxLength(255), Validators.email]],
            tipo: ['', [Validators.maxLength(255)]],
            // codiceStruttura: ['', [Validators.required, Validators.maxLength(255)]],
            // tipoStruttura: ['', [Validators.required, Validators.maxLength(255)]],
            codiceFiscale: ['', [FiscalCodeValidator()]],
            telefono: ['', [Validators.pattern(this.globals.INTEGER_PATTERN)]],
            idErogatore: ['', [Validators.maxLength(255)]],
            tipoErogatore: ['', [Validators.maxLength(255)]],
            enabled: [{value: true, disabled: this.id !== 'new'}]
        };
        
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(resp: PrescrittoreResponse): void {
        let formValues;
        let prescrittore: Prescrittore = resp ? resp.prescrittore : null;
        
        if (prescrittore) {
            formValues = {
                selectedAziendaId: this.selectedAziendaId,
                selectedStrutturaId: prescrittore.struttura && prescrittore.struttura.id ? prescrittore.struttura.id : '',
                selectedRepartoId: prescrittore.reparto && prescrittore.reparto.id ? prescrittore.reparto.id : '',
                nome: prescrittore.nome,
                cognome: prescrittore.cognome,
                username: prescrittore.account.username,
                email: prescrittore.account.email,
                tipo: prescrittore.tipo ? prescrittore.tipo : '',
                // codiceStruttura: prescrittore.codiceStruttura,
                // tipoStruttura: prescrittore.tipoStruttura,
                codiceFiscale: prescrittore.codiceFiscale ? prescrittore.codiceFiscale : '',
                telefono: prescrittore.telefono ? prescrittore.telefono : '',
                idErogatore: prescrittore.idErogatore ? prescrittore.idErogatore : '',
                tipoErogatore: prescrittore.tipoErogatore ? prescrittore.tipoErogatore : '',
                enabled: resp.enabled
            };
            this.form.get('username').disable();
            this.form.get('selectedAziendaId').disable();
        } else {
            formValues = {
                selectedAziendaId: this.selectedAziendaId,
                selectedStrutturaId: '',
                selectedRepartoId: '',
                nome: '',
                cognome: '',
                username: '',
                email: '',
                tipo: '',
                // codiceStruttura: '',
                // tipoStruttura: '',
                codiceFiscale: '',
                telefono: '',
                idErogatore: '',
                tipoErogatore: '',
                enabled: true
            };
        }
        this.form.setValue(formValues);
    }

    onCodiceFiscaleChange(event) {
        if(event && event.target.value) {
            this.form.get('codiceFiscale').setValue(event.target.value.toUpperCase());
        }
    }

    onVisualizzaContratto(contratto: any) {
        const url = '/contratti/' + contratto.id;
        
        this.router.navigate([url]);
    }
}
