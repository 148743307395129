import {Component, OnInit} from '@angular/core';
import {forkJoin} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Paziente} from '../../../model/query/paziente';
import {ElencoPrescrizioniResponse} from '../../../model/query/elenco-prescrizioni-response';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from '../../../core/utils.service';
import {PazientiService} from '../../../core/pazienti.service';

@Component({
    selector: 'app-paziente-call-center-detail',
    templateUrl: './paziente-call-center-detail.component.html',
    styleUrls: ['./paziente-call-center-detail.component.css']
})
export class PazienteCallCenterDetailComponent implements OnInit {
    form: FormGroup;
    prescrizioniOssigeno = [];
    prescrizioniConcentratore = [];
    prescrizioniVentilatori = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private utilsService: UtilsService,
        private pazientiService: PazientiService
    ) {
        this.createForm();
    }
    
    createForm(): void {
        const formGroup = {
            nome: [{value: '', disabled: true}],
            cognome: [{value: '', disabled: true}],
            comuneConsegna: [{value: '', disabled: true}],
            indirizzoConsegna: [{value: '', disabled: true}],
            numeroCivicoConsegna: [{value: '', disabled: true}],
            dataNascita: [{value: '', disabled: true}],
            telefono: [{value: '', disabled: true}],
            telefonoFisso: [{value: '', disabled: true}]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(paziente: Paziente): void {
        let formValues = {
            nome: paziente.nome,
            cognome: paziente.cognome,
            comuneConsegna: paziente.comuneConsegna ? paziente.comuneConsegna : '',
            indirizzoConsegna: paziente.indirizzoConsegna ? paziente.indirizzoConsegna : '',
            numeroCivicoConsegna: paziente.numeroCivicoConsegna ? paziente.numeroCivicoConsegna : '',
            dataNascita: this.utilsService.dateToField(paziente.dataNascita),
            telefono: paziente.telefono ? paziente.telefono : '',
            telefonoFisso: paziente.telefonoFisso ? paziente.telefonoFisso : ''
        };
        
        this.form.setValue(formValues);
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            const getObservable = this.pazientiService.get(params.id);
            const getElencoObservable = this.pazientiService.getElencoPrescrizioni(params.id)
            
            forkJoin([getObservable, getElencoObservable]).subscribe((results: [Paziente, ElencoPrescrizioniResponse]) => {
                const epResponse: ElencoPrescrizioniResponse = results[1];
                
                this.prescrizioniOssigeno = epResponse.prescrizioniOssigeno;
                this.prescrizioniConcentratore = epResponse.prescrizioniConcentratore;
                this.prescrizioniVentilatori = epResponse.prescrizioniVentilatori.map(p => {
                    p['critico'] = false;
                    if (((p.profilo.nome.includes('PROFILO 2') || p.profilo.nome.includes('PROFILO 3')) && (p.profilo.nome.includes('PROTOCOLLO A') || p.profilo.nome.includes('PROTOCOLLO B'))) ||
                        p.profilo.nome.includes('BISOGNO')) {
                        p['critico'] = true;
                    }
                    return p;
                });
                this.initializeFormFields(results[0]);
            });
        });
    }

    onSubmit() {
        this.router.navigate(['pazienti-call-center']);
    }
}
