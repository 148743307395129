import {Component} from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {Observable, Subscription} from 'rxjs';
import {QueryResult} from '../../model/query/query-result';
import {DatagridComponent} from '../common/datagrid.component';
import {DatagridRequest} from '../common/datagrid.component';
import {PrescrittoreEsterno} from '../../model/query/prescrittore-esterno';
import {PrescrittoriEsterniService} from '../../core/prescrittori-esterni.service';
import {Router} from '@angular/router';
import {Action} from '../../model/action';
import {ActionService} from '../../core/action.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-prescrittori-esterni',
    templateUrl: './prescrittori-esterni.component.html',
    styles: []
})
export class PrescrittoriEsterniComponent extends DatagridComponent<PrescrittoreEsterno> {
    constructor(
        private prescrittoriEsterniService: PrescrittoriEsterniService,
        dialog: DialogService,
        router: Router
    ) {
        super(prescrittoriEsterniService, router, dialog);
    }

    protected getEditUrl(): string {
        return '/prescrittori-esterni/';
    }
}
