import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {UtilsService} from '../core/utils.service';
import * as moment from 'moment';

@Component({
    selector: 'app-date-range-widget',
    templateUrl: './date-range-widget.component.html'
})
export class DateRangeWidgetComponent implements OnInit {
    @Output() newFilterEvent = new EventEmitter<string>();
    @Output() newMineEvent = new EventEmitter<boolean>();
    @Output() newExpiredEvent = new EventEmitter<boolean>();
    @Input() noInit: boolean = false;
    @Input() showMine: boolean = false;
    @Input() showExpired: boolean = false;
    
    currentYear: number;
    currentMonth: number;
    @Input() mine: boolean = false;
    @Input() expired: boolean = false;

    constructor(public utilsService: UtilsService) {
    }

    ngOnInit() {
        if (!this.noInit) {
            this.currentYear = moment.utc().get('y');
            this.currentMonth = moment.utc().get('M');
        }
    }

    onDateRangeFilter(value: any): void {
        this.newFilterEvent.emit(value);
    }    

    onMineChange(event): void {
        this.newMineEvent.emit(event.target.checked);
    }

    onExpiredChange(event): void {
        this.newExpiredEvent.emit(event.target.checked);
    }
}
