import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {ModalitaVentilatorieService} from '../../../core/modalita-ventilatorie.service';
import {ModalitaVentilatoria} from '../../../model/query/modalita-ventilatoria';
import {ModalitaVentilatoriaCommand} from '../../../model/command/modalita-ventilatoria-command';
import {ActionService} from '../../../core/action.service';
import {Action} from '../../../model/action';

@Component({
    selector: 'app-modalita-ventilatoria-detail',
    templateUrl: './modalita-ventilatoria-detail.component.html',
    styleUrls: ['./modalita-ventilatoria-detail.component.css']
})
export class ModalitaVentilatoriaDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    alertMessage: string;
    alertClosed = true;
    action: Action;
    readOnly = false;


    constructor(
        private route: ActivatedRoute,
        private modalitaVentilatorieService: ModalitaVentilatorieService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        public actionService: ActionService,
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        if (this.id !== 'new') {
            this.modalitaVentilatorieService.get(this.id).subscribe((modalitaVentilatoria: ModalitaVentilatoria) => {
                this.initializeFormFields(modalitaVentilatoria);
                if (this.action != null && this.action == this.actionService.ACTION.VIEW) {
                    this.readOnly = true;
                }
            });
        } else {
            this.initializeFormFields(null);
        }
    }

    createForm(): void {
        const formGroup = {
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(modalitaVentilatoria: ModalitaVentilatoria): void {
        let formValues;

        if (modalitaVentilatoria) {
            formValues = {
                nome: modalitaVentilatoria.nome
            };
        } else {
            formValues = {
                nome: ''
            };
        }
        this.form.setValue(formValues);
    }


    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command: ModalitaVentilatoriaCommand =
            new ModalitaVentilatoriaCommand(formValue.nome);
        if (this.id === 'new') {
            this.modalitaVentilatorieService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'La modalità ventilatoria è stata creata';
                    this.alertClosed = false;
                }
            });
        } else {
            this.modalitaVentilatorieService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'La modalità ventilatoria è stata aggiornata';
                    this.alertClosed = false;
                }
            });
        }

    }

}
