import {Observable, of as observableOf} from 'rxjs';
import {catchError, tap, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {AuthService} from './auth.service';
import {ApprovatoriService} from './approvatori.service';
import {OperatoriService} from './operatori.service';
import {AppaltantiService} from './appaltanti.service';
import {PrescrittoriService} from './prescrittori.service';
import {UtilsService} from './utils.service';
import {Account} from '../model/query/account';
import {User} from '../model/query/user';
import {Contratto} from '../model/query/contratto';

@Injectable()
export class MeService extends BaseService<Account> {
    me: User;
    enabledServices: Array<string>;

    constructor(httpClient: HttpClient, 
            router: Router, 
            private authService: AuthService, 
            private utilsService: UtilsService,
            private approvatoriService: ApprovatoriService, 
            private operatoriService: OperatoriService, 
            private appaltantiService: AppaltantiService, 
            private prescrittoriService: PrescrittoriService) {
        super(httpClient, router);
        this.setApiUrl('me');
        if (!this.authService.isApprovatoreUser() && !this.authService.isOperatoreUser() && !this.authService.isAppaltanteUser() && !this.authService.isPrescrittoreUser() && !this.authService.isFornitoreUser()) {
            this.enabledServices = this.utilsService.getAllServices();
        }
    }

    public changePassword(newPassword: string): Observable<any> {
        return this.httpClient.post<any>(this.apiUrl + '/reset-password', {password: newPassword}).pipe(
            catchError(this.handleError('changePassword', undefined))
        );
    }

    public getMe(): Observable<User> {
        if (this.me) {
            return observableOf(this.me);
        } else {
            return this.httpClient.get<User>(this.apiUrl).pipe(
                tap(user => this.me = user),
                catchError(this.handleError(this.apiUrl + ' - get', null))
            );
        }
    }

    public getEnabledServices(): Observable<Array<string>> {
        if (this.enabledServices) {
            return observableOf(this.enabledServices);
        } else {
            return this.getMe().pipe(
                switchMap(() => {
                    if (this.authService.isApprovatoreUser()) {
                        return this.approvatoriService.getContratti(this.me.id.toString());
                    } else if (this.authService.isOperatoreUser()) {
                        return this.operatoriService.getContratti(this.me.id.toString());
                    } else if (this.authService.isAppaltanteUser()) {
                        return this.appaltantiService.getContratti(this.me.id.toString());
                    } else if (this.authService.isPrescrittoreUser()) {
                        return this.prescrittoriService.getContratti(this.me.id.toString());
                    } else {
                        return observableOf([]);
                    }
                }),
                switchMap((contratti: Array<Contratto>) => {
                    if (this.authService.isApprovatoreUser() || this.authService.isOperatoreUser() || this.authService.isAppaltanteUser() || this.authService.isPrescrittoreUser()) {
                        let services = contratti.map(c => c.servizio);

                        this.enabledServices = services.filter((val, i) => services.indexOf(val) === i);
                    } else if (this.authService.isFornitoreUser()) {
                        this.enabledServices = [
                            'vent'
                        ];
                    } else {
                        this.enabledServices = [
                            'o2l',
                            'o2c',
                            'sat',
                            'vent',
                            'ega',
                            'ai'
                        ];
                    }
                    return observableOf(this.enabledServices);
                }),
                catchError(this.handleError(this.apiUrl + ' - get enabled services', null))
            );
        }
    }

    public setMe(me: User) {
        this.me = me;
    }

    public isServiceEnabled(service: string): boolean {
        return this.enabledServices && this.enabledServices.indexOf(service) >= 0;
    }
}
