import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PrescrizioniBaseComponent} from './prescrizioni-base.component';
import {AuthService} from '../../core/auth.service';
import {MeService} from '../../core/me.service';
import {AziendeCorrentiService} from '../../core/aziende-correnti.service';
import {PrescrizioniService} from '../../core/prescrizioni.service';
import {UtilsService} from '../../core/utils.service';
import {PrescrittoriService} from '../../core/prescrittori.service';
import {PartnersService} from '../../core/partners.service';
import {PrescrizioneActionsService} from '../../core/prescrizione-actions.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-prescrizioni-ai',
    templateUrl: './prescrizioni-ai.component.html',
    styles: []
})
export class PrescrizioniAiComponent extends PrescrizioniBaseComponent {
    constructor(
        protected route: ActivatedRoute,
        public prescrizioniService: PrescrizioniService,
        protected prescrittoriService: PrescrittoriService,
        protected partnersService: PartnersService,
        protected meService: MeService,
        protected aziendeCorrentiService: AziendeCorrentiService,
        protected utilsService: UtilsService,
        public authService: AuthService,
        public prescrizioneActionsService: PrescrizioneActionsService,
        dialog: DialogService,
        router: Router) {
        super(route, prescrizioniService, prescrittoriService, partnersService, meService, aziendeCorrentiService, utilsService, authService, prescrizioneActionsService, dialog, router);
        this.tipo = 'ai';
    }
}
