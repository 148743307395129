import {Component, Input} from '@angular/core';
import {Subject} from 'rxjs';
import {ClrDatagridFilter, ClrDatagridFilterInterface} from '@clr/angular';
import {AziendeCorrentiService} from '../core/aziende-correnti.service';
import {Distretto} from '../model/query/distretto';

@Component({
    selector: 'app-distretto-filter',
    templateUrl: './distretto.filter.component.html'
})
export class DistrettoFilterComponent implements ClrDatagridFilterInterface<any> {
    state: {
    };
    @Input('attribute') attribute: string;
    
    distretti: Array<Distretto> = new Array<Distretto>();
    
    constructor(private filterContainer: ClrDatagridFilter, private aziendeCorrentiService: AziendeCorrentiService) {
        filterContainer.setFilter(this);
        this.aziendeCorrentiService.getDistretti().subscribe(distretti => this.distretti = distretti);
    }
    
    accepts(transaction: any) {
        return false;
    }

    changes = new Subject<any>();
    
    isActive(): boolean {
        return true;
    }

    onValueChange(param: string) {
        if (param) {
            this.state = {
                property: this.attribute,
                value: param
            }
        } else {
            this.state = {};
        }
        this.changes.next(this.state);
    }
}