import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ClarityModule} from '@clr/angular';
import {NgxPaginationModule} from 'ngx-pagination';
import {CoreModule} from '../core/core.module';
import {DialogComponent} from './dialog/dialog.component';
import {HeaderComponent} from './layout/header/header.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {MainComponent} from './layout/main/main.component';
import {PrescrittoriComponent} from './prescrittori/prescrittori.component';
import {PrescrittoreDetailComponent} from './prescrittori/prescrittore-detail/prescrittore-detail.component';
import {PrescrittoriEsterniComponent} from './prescrittori-esterni/prescrittori-esterni.component';
import {PrescrittoreEsternoDetailComponent} from './prescrittori-esterni/prescrittore-esterno-detail/prescrittore-esterno-detail.component';
import {PrescrizioniComponent} from './prescrizioni/prescrizioni.component';
import {PrescrizioniO2lComponent} from './prescrizioni/prescrizioni-o2l.component';
import {PrescrizioniO2cComponent} from './prescrizioni/prescrizioni-o2c.component';
import {PrescrizioniSatComponent} from './prescrizioni/prescrizioni-sat.component';
import {PrescrizioniVentComponent} from './prescrizioni/prescrizioni-vent.component';
import {PrescrizioniEgaComponent} from './prescrizioni/prescrizioni-ega.component';
import {PrescrizioniAiComponent} from './prescrizioni/prescrizioni-ai.component';
import {PazientiComponent} from './pazienti/pazienti.component';
import {PazienteDetailComponent} from './pazienti/paziente-detail/paziente-detail.component';
import {PazientiCallCenterComponent} from './pazienti-call-center/pazienti-call-center.component';
import {PazienteCallCenterDetailComponent} from './pazienti-call-center/paziente-call-center-detail/paziente-call-center-detail.component';
import {ApprovatoriComponent} from './approvatori/approvatori.component';
import {ApprovatoreDetailComponent} from './approvatori/approvatore-detail/approvatore-detail.component';
import {AppaltantiComponent} from './appaltanti/appaltanti.component';
import {AppaltanteDetailComponent} from './appaltanti/appaltante-detail/appaltante-detail.component';
import {InfermieriComponent} from './infermieri/infermieri.component';
import {InfermiereDetailComponent} from './infermieri/infermiere-detail/infermiere-detail.component';
import {FornitoriComponent} from './fornitori/fornitori.component';
import {FornitoreDetailComponent} from './fornitori/fornitore-detail/fornitore-detail.component';
import {AmministratoriComponent} from './amministratori/amministratori.component';
import {AmministratoreDetailComponent} from './amministratori/amministratore-detail/amministratore-detail.component';
import {OperatoriComponent} from './operatori/operatori.component';
import {OperatoreDetailComponent} from './operatori/operatore-detail/operatore-detail.component';
import {OperatoriCallCenterComponent} from './operatori-callcenter/operatori-callcenter.component';
import {OperatoreCallCenterDetailComponent} from './operatori-callcenter/operatore-callcenter-detail/operatore-callcenter-detail.component';
import {PartnersComponent} from './partners/partners.component';
import {PartnerDetailComponent} from './partners/partner-detail/partner-detail.component';
import {PrescrizioneDetailComponent} from './prescrizioni/prescrizione-detail/prescrizione-detail.component';
import {PrescrizioneStoricoComponent} from './prescrizioni/prescrizione-storico/prescrizione-storico.component';
import {ContrattiComponent} from './contratti/contratti.component';
import {ContrattoDetailComponent} from './contratti/contratto-detail/contratto-detail.component';
import {AppaltiComponent} from './appalti/appalti.component';
import {AppaltoDetailComponent} from './appalti/appalto-detail/appalto-detail.component';
import {AziendeComponent} from './aziende/aziende.component';
import {AziendaDetailComponent} from './aziende/azienda-detail/azienda-detail.component';
import {ImportJobsComponent} from './import-jobs/import-jobs.component';
import {SharedModule} from '../shared/shared.module';
import {ImportJobDetailComponent} from './import-jobs/import-job-detail/import-job-detail.component';
import {ReportCostiComponent} from './reports/report-costi/report-costi.component';
import {ReportComplianceComponent} from './reports/report-compliance/report-compliance.component';
import {ReportPazientiComponent} from './reports/report-pazienti/report-pazienti.component';
import {ReportPazientiAttiviComponent} from './reports/report-pazienti-attivi/report-pazienti-attivi.component';
import {ReportConsegneComponent} from './reports/report-consegne/report-consegne.component';
import {ReportPazientiAttivatiComponent} from './reports/report-pazienti-attivati/report-pazienti-attivati.component';
import {ReportPazientiAttiviOraComponent} from './reports/report-pazienti-attivi-ora/report-pazienti-attivi-ora.component';
import {ReportPazientiDecedutiComponent} from './reports/report-pazienti-deceduti/report-pazienti-deceduti.component';
import {ReportScadenzeComponent} from './reports/report-scadenze/report-scadenze.component';
import {ReportTaskComponent} from './reports/report-task/report-task.component';
import {ReportPlanningComponent} from './reports/report-planning/report-planning.component';
import {ReportPazientiVentComponent} from './reports/report-pazienti-vent/report-pazienti-vent.component';
import {ReportUtentiComponent} from './reports/report-utenti/report-utenti.component';
import {ReplaceUnderscorePipe} from './common/replace-underscore.pipe';
import {ExportMinisteroComponent} from './export-ministero/export-ministero.component';
import {ConcentratoriComponent} from './concentratori/concentratori.component';
import {ConcentratoreDetailComponent} from './concentratori/concentratore-detail/concentratore-detail.component';
import {SaturimetriComponent} from './saturimetri/saturimetri.component';
import {SaturimetroDetailComponent} from './saturimetri/saturimetro-detail/saturimetro-detail.component';
import {MaterialiComponent} from './materiali/materiali.component';
import {MaterialeDetailComponent} from './materiali/materiale-detail/materiale-detail.component';
import {KitMaterialiComponent} from './kit-materiali/kit-materiali.component';
import {KitMaterialeDetailComponent} from './kit-materiali/kit-materiale-detail/kit-materiale-detail.component';
import {ParametriVentilatoreComponent} from './parametri-ventilatore/parametri-ventilatore.component';
import {ParametroVentilatoreDetailComponent} from './parametri-ventilatore/parametro-ventilatore-detail/parametro-ventilatore-detail.component';
import {ParametriAllarmeComponent} from './parametri-allarme/parametri-allarme.component';
import {ParametroAllarmeDetailComponent} from './parametri-allarme/parametro-allarme-detail/parametro-allarme-detail.component';
import {ProfiliComponent} from './profili/profili.component';
import {ProfiloDetailComponent} from './profili/profilo-detail/profilo-detail.component';
import {ModalitaVentilatorieComponent} from './modalita-ventilatorie/modalita-ventilatorie.component';
import {ModalitaVentilatoriaDetailComponent} from './modalita-ventilatorie/modalita-ventilatoria-detail/modalita-ventilatoria-detail.component';
import {VentilatoriComponent} from './ventilatori/ventilatori.component';
import {VentilatoreDetailComponent} from './ventilatori/ventilatori-detail/ventilatore-detail.component';
import {IncaricatiComponent} from './incaricati/incaricati.component';
import {IncaricatoDetailComponent} from './incaricati/incaricato-detail/incaricato-detail.component';
import {TaskComponent} from './task/task.component';
import {TaskDetailComponent} from './task/task-detail/task-detail.component';
import {TipoTaskFilterComponent} from './task/tipo-task.filter.component';
import {StatoTaskFilterComponent} from './task/stato-task.filter.component';
import {StatoAttivitaFilterComponent} from './attivita-titolazione/stato-attivita.filter.component';
import {TipoPrescrizioneFilterComponent} from './prescrizioni/tipo-prescrizione.filter.component';
import {TipoPrescrizioneServiziFilterComponent} from './prescrizioni/tipo-prescrizione-servizi.filter.component';
import {StatoPrescrizioneFilterComponent} from './prescrizioni/stato-prescrizione.filter.component';
import {StatoPazienteFilterComponent} from './pazienti/stato-paziente.filter.component';
import {DistrettoFilterComponent} from './distretto.filter.component';
import {AziendaFilterComponent} from './azienda.filter.component';
import {StrutturaFilterComponent} from './struttura.filter.component';
import {AppaltoFilterComponent} from './appalto.filter.component';
import {PrescrittoreFilterComponent} from './prescrittore.filter.component';
import {PrescrittoreEsternoFilterComponent} from './prescrittore-esterno.filter.component';
import {IncaricatoFilterComponent} from './task/incaricato.filter.component';
import {DateFilterComponent} from './date.filter.component';
import {BooleanFilterComponent} from './boolean.filter.component';
import {KitFilterComponent} from './kit.filter.component';
import {SearchWidgetComponent} from './search-widget.component';
import {DateRangeWidgetComponent} from './date-range-widget.component';
import {PasswordComponent} from './password/password.component';
import {TipoConcentratoreFilterComponent} from './prescrizioni/tipo-concentratore.filter.component';
import {ServizioFilterComponent} from './servizio.filter.component';
import {AttivitaTitolazioneComponent} from './attivita-titolazione/attivita-titolazione.component';
import {AttivitaTitolazioneDetailComponent} from './attivita-titolazione/attivita-titolazione-detail/attivita-titolazione-detail.component';
import {FsDialogComponent} from './task/task-detail/fs-dialog/fs-dialog.component';
import {StruttureComponent} from './strutture/strutture.component';
import {StrutturaDetailComponent} from './strutture/struttura-detail/struttura-detail.component';
import {RepartiComponent} from './reparti/reparti.component';
import {RepartoDetailComponent} from './reparti/reparto-detail/reparto-detail.component';
import {MaschereComponent} from './maschere/maschere.component';
import {MascheraDetailComponent} from './maschere/maschera-detail/maschera-detail.component';
import {RicercaPrescrizioniComponent} from './ricerca-prescrizioni/ricerca-prescrizioni.component';
import {LoginEventsComponent} from './login-events/login-events.component';

@NgModule({
    declarations: [
        DialogComponent,
        HeaderComponent,
        SidebarComponent,
        MainComponent,
        PrescrittoriComponent,
        PrescrittoreDetailComponent,
        PrescrittoriEsterniComponent,
        PrescrittoreEsternoDetailComponent,
        PrescrizioniComponent,
        PrescrizioniO2lComponent,
        PrescrizioniO2cComponent,
        PrescrizioniSatComponent,
        PrescrizioniVentComponent,
        PrescrizioniEgaComponent,
        PrescrizioniAiComponent,
        PrescrizioneDetailComponent,
        PrescrizioneStoricoComponent,
        PazientiComponent,
        PazienteDetailComponent,
        PazientiCallCenterComponent,
        PazienteCallCenterDetailComponent,
        ApprovatoriComponent,
        ApprovatoreDetailComponent,
        AppaltantiComponent,
        AppaltanteDetailComponent,
        InfermieriComponent,
        InfermiereDetailComponent,
        FornitoriComponent,
        FornitoreDetailComponent,
        AmministratoriComponent,
        AmministratoreDetailComponent,
        OperatoriComponent,
        OperatoreDetailComponent,
        OperatoriCallCenterComponent,
        OperatoreCallCenterDetailComponent,
        PartnersComponent,
        PartnerDetailComponent,
        ContrattiComponent,
        ContrattoDetailComponent,
        AppaltiComponent,
        AppaltoDetailComponent,
        AziendeComponent,
        AziendaDetailComponent,
        ImportJobsComponent,
        ImportJobDetailComponent,
        ReportCostiComponent,
        ReportComplianceComponent,
        ReportPazientiComponent,
        ReportPazientiAttiviComponent,
        ReportConsegneComponent,
        ReportPazientiAttivatiComponent,
        ReportPazientiAttiviOraComponent,
        ReportPazientiDecedutiComponent,
        ReportScadenzeComponent,
        ReportTaskComponent,
        ReportPlanningComponent,
        ReportPazientiVentComponent,
        ReportUtentiComponent,
        ReplaceUnderscorePipe,
        ExportMinisteroComponent,
        ConcentratoriComponent,
        ConcentratoreDetailComponent,
        SaturimetriComponent,
        SaturimetroDetailComponent,
        StruttureComponent,
        StrutturaDetailComponent,
        RepartiComponent,
        RepartoDetailComponent,
        MaschereComponent,
        MascheraDetailComponent,
        RicercaPrescrizioniComponent,
        LoginEventsComponent,
        MaterialiComponent,
        MaterialeDetailComponent,
        KitMaterialiComponent,
        KitMaterialeDetailComponent,
        ParametriVentilatoreComponent,
        ParametroVentilatoreDetailComponent,
        ParametriAllarmeComponent,
        ParametroAllarmeDetailComponent,
        ProfiliComponent,
        ProfiloDetailComponent,
        ModalitaVentilatorieComponent,
        ModalitaVentilatoriaDetailComponent,
        VentilatoriComponent,
        VentilatoreDetailComponent,
        IncaricatiComponent,
        IncaricatoDetailComponent,
        TaskComponent,
        TaskDetailComponent,
        TipoTaskFilterComponent,
        StatoTaskFilterComponent,
        StatoAttivitaFilterComponent,
        TipoPrescrizioneFilterComponent,
        TipoPrescrizioneServiziFilterComponent,
        StatoPrescrizioneFilterComponent,
        StatoPazienteFilterComponent,
        DistrettoFilterComponent,
        AziendaFilterComponent,
        StrutturaFilterComponent,
        AppaltoFilterComponent,
        PrescrittoreFilterComponent,
        PrescrittoreEsternoFilterComponent,
        IncaricatoFilterComponent,
        DateFilterComponent,
        BooleanFilterComponent,
        KitFilterComponent,
        SearchWidgetComponent,
        DateRangeWidgetComponent,
        PasswordComponent,
        TipoConcentratoreFilterComponent,
        ServizioFilterComponent,
        AttivitaTitolazioneComponent,
        AttivitaTitolazioneDetailComponent,
        FsDialogComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ClarityModule,
        NgxPaginationModule,
        CoreModule,
        SharedModule
    ],
    exports: [
        CoreModule,
        SharedModule,
        DialogComponent,
        HeaderComponent,
        SidebarComponent,
        MainComponent,
        PrescrittoriComponent,
        PrescrittoreDetailComponent,
        PrescrittoriEsterniComponent,
        PrescrittoreEsternoDetailComponent,
        PazientiComponent,
        PazienteDetailComponent,
        PazientiCallCenterComponent,
        PazienteCallCenterDetailComponent,
        PrescrizioniComponent,
        PrescrizioniO2lComponent,
        PrescrizioniO2cComponent,
        PrescrizioniSatComponent,
        PrescrizioniVentComponent,
        PrescrizioniEgaComponent,
        PrescrizioniAiComponent,
        PrescrizioneDetailComponent,
        PrescrizioneStoricoComponent,
        ApprovatoriComponent,
        ApprovatoreDetailComponent,
        AppaltantiComponent,
        AppaltanteDetailComponent,
        InfermieriComponent,
        InfermiereDetailComponent,
        FornitoriComponent,
        FornitoreDetailComponent,
        AmministratoriComponent,
        AmministratoreDetailComponent,
        OperatoriComponent,
        OperatoreDetailComponent,
        OperatoriCallCenterComponent,
        OperatoreCallCenterDetailComponent,
        PartnersComponent,
        PartnerDetailComponent,
        ContrattiComponent,
        ContrattoDetailComponent,
        AppaltiComponent,
        AppaltoDetailComponent,
        AziendeComponent,
        MaterialiComponent,
        MaterialeDetailComponent,
        KitMaterialiComponent,
        KitMaterialeDetailComponent,
        ParametriVentilatoreComponent,
        ParametroVentilatoreDetailComponent,
        ParametriAllarmeComponent,
        ParametroAllarmeDetailComponent,
        ProfiliComponent,
        ProfiloDetailComponent,
        ModalitaVentilatorieComponent,
        ModalitaVentilatoriaDetailComponent,
        VentilatoriComponent,
        VentilatoreDetailComponent,
        IncaricatiComponent,
        IncaricatoDetailComponent,
        TaskComponent,
        TaskDetailComponent,
        TipoTaskFilterComponent,
        StatoTaskFilterComponent,
        StatoAttivitaFilterComponent,
        TipoPrescrizioneFilterComponent,
        TipoPrescrizioneServiziFilterComponent,
        StatoPrescrizioneFilterComponent,
        StatoPazienteFilterComponent,
        DistrettoFilterComponent,
        AziendaFilterComponent,
        StrutturaFilterComponent,
        AppaltoFilterComponent,
        PrescrittoreFilterComponent,
        PrescrittoreEsternoFilterComponent,
        IncaricatoFilterComponent,
        DateFilterComponent,
        BooleanFilterComponent,
        KitFilterComponent,
        SearchWidgetComponent,
        DateRangeWidgetComponent,
        PasswordComponent,
        TipoConcentratoreFilterComponent,
        ServizioFilterComponent,
        AttivitaTitolazioneComponent,
        AttivitaTitolazioneDetailComponent,
        FsDialogComponent
    ]
})
export class UiModule {
}
