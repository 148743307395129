import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {LoginEvent} from '../model/query/login-event';

@Injectable()
export class AdminService {
    private apiUrl = environment.apiUrl;
    
    constructor(private httpClient: HttpClient) {
        this.apiUrl += 'admin';
    }

    public getLoginEvents(page: number, size: number): Observable<Array<LoginEvent>> {
        return this.httpClient.post<Array<LoginEvent>>(this.apiUrl + '/login-events', {page: page, size: size});
    }
}
