import {AbstractCommand} from './abstract-command';

export class AziendaCommand extends AbstractCommand {
    nome: string;
    codice: string;
    // percentualeEvaporazioneContenitori: number;
    // percentualeEvaporazioneStroller: number;

    constructor(
        nome: string,
        codice: string,
        // percentualeEvaporazioneContenitori: number,
        // percentualeEvaporazioneStroller: number
    ) {
        super();
        this.nome = nome;
        this.codice = codice;
        // this.percentualeEvaporazioneContenitori = percentualeEvaporazioneContenitori;
        // this.percentualeEvaporazioneStroller = percentualeEvaporazioneStroller;
    }

}
