import {AbstractCommand} from './abstract-command';

export class RepartoCommand extends AbstractCommand {
    nome: string;
    strutturaId: number;

    constructor(
        nome: string,
        strutturaId: number
    ) {
        super();
        this.nome = nome;
        this.strutturaId = strutturaId;
    }

}
