import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {StatoTask} from '../model/stato-task';
import {StatoTaskPrescrizione} from '../model/stato-task-prescrizione';
import {ResultUrl} from '../model/result-url';
import {Task} from '../model/query/task';
import {TaskConsegnaReparto} from '../model/query/task-consegna-reparto';
import {TaskConsegnaPaziente} from '../model/query/task-consegna-paziente';
import {TaskSospensione} from '../model/query/task-sospensione';
import {TaskAccessoOrdinario} from '../model/query/task-accesso-ordinario';
import {TaskAccessoStraordinario} from '../model/query/task-accesso-straordinario';
import {TaskGenerico} from '../model/query/task-generico';
import {TaskCollaudo} from '../model/query/task-collaudo';
import {TaskVerificaElettrica} from '../model/query/task-verifica-elettrica';
import {TaskRitiroDispositivo} from '../model/query/task-ritiro-dispositivo';
import {TaskSostituzione} from '../model/query/task-sostituzione';
import {TaskCompliance} from '../model/query/task-compliance';
import {TaskReportistica} from '../model/query/task-reportistica';
import {TaskFollowup} from '../model/query/task-followup';
import {TaskConsegnaMateriali} from '../model/query/task-consegna-materiali';
import {TaskDocumento} from '../model/query/task-documento';
import {TaskConsegnaRepartoCommand} from '../model/command/task-consegna-reparto-command';
import {TaskConsegnaPazienteCommand} from '../model/command/task-consegna-paziente-command';
import {TaskSospensioneCommand} from '../model/command/task-sospensione-command';
import {TaskAccessoOrdinarioCommand} from '../model/command/task-accesso-ordinario-command';
import {TaskAccessoStraordinarioCommand} from '../model/command/task-accesso-straordinario-command';
import {TaskGenericoCommand} from '../model/command/task-generico-command';
import {TaskCollaudoCommand} from '../model/command/task-collaudo-command';
import {TaskVerificaElettricaCommand} from '../model/command/task-verifica-elettrica-command';
import {TaskRitiroDispositivoCommand} from '../model/command/task-ritiro-dispositivo-command';
import {TaskSostituzioneCommand} from '../model/command/task-sostituzione-command';
import {TaskComplianceCommand} from '../model/command/task-compliance-command';
import {TaskReportisticaCommand} from '../model/command/task-reportistica-command';
import {TaskFollowupCommand} from '../model/command/task-followup-command';
import {TaskConsegnaMaterialiCommand} from '../model/command/task-consegna-materiali-command';
import {TaskDocumentoCommand} from '../model/command/task-documento-command';
import {ChiusuraTaskDto} from '../model/command/chiusura-task-dto';
import {SignaturesResultUrl} from '../model/signatures-result-url';
import {Globals} from '../core/globals.service';


@Injectable()
export class TaskService extends BaseService<Task> {
    readonly STATO_TASK: typeof StatoTask = StatoTask;
    readonly STATO_TASK_PRESCRIZIONE: typeof StatoTaskPrescrizione = StatoTaskPrescrizione;

    private storageBasePath: string = environment.storageUrl;

    constructor(httpClient: HttpClient, router: Router, private globals: Globals) {
        super(httpClient, router);
        this.setApiUrl('tasks');
    }

    getTask(id: string, tipo: string): Observable<Task> {
        switch (tipo) {
            case this.globals.TIPO_TASK_CONSEGNA_REPARTO:
                return this.getTaskConsegnaReparto(id);
                break;
            case this.globals.TIPO_TASK_CONSEGNA_PAZIENTE:
                return this.getTaskConsegnaPaziente(id);
                break;
            case this.globals.TIPO_TASK_COLLAUDO:
                return this.getTaskCollaudo(id);
                break;
            case this.globals.TIPO_TASK_SOSPENSIONE:
                return this.getTaskSospensione(id);
                break;
            case this.globals.TIPO_TASK_ACCESSO_ORDINARIO:
                return this.getTaskAccessoOrdinario(id);
                break;
            case this.globals.TIPO_TASK_ACCESSO_STRAORDINARIO:
                return this.getTaskAccessoStraordinario(id);
                break;
            case this.globals.TIPO_TASK_GENERICO:
                return this.getTaskGenerico(id);
                break;
            case this.globals.TIPO_TASK_CONSEGNA_MATERIALI:
                return this.getTaskConsegnaMateriali(id);
                break;
            case this.globals.TIPO_TASK_VERIFICA_ELETTRICA:
                return this.getTaskVerificaElettrica(id);
                break;
            case this.globals.TIPO_TASK_FOLLOWUP:
                return this.getTaskFollowup(id);
                break;
            case this.globals.TIPO_TASK_COMPLIANCE:
                return this.getTaskCompliance(id);
                break;
            case this.globals.TIPO_TASK_REPORTISTICA:
                return this.getTaskReportistica(id);
                break;
            case this.globals.TIPO_TASK_SOSTITUZIONE:
                return this.getTaskSostituzione(id);
                break;
            case this.globals.TIPO_TASK_RITIRO_DISPOSITIVO:
                return this.getTaskRitiroDispositivo(id);
                break;
            default:
                return null;
        }
    }

    getTaskConsegnaReparto(id: string): Observable<TaskConsegnaReparto> {
        return this.httpClient.get<TaskConsegnaReparto>(this.apiUrl + '/consegna-reparto/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get task consegna-reparto', null))
        );
    }

    getTaskConsegnaPaziente(id: string): Observable<TaskConsegnaPaziente> {
        return this.httpClient.get<TaskConsegnaPaziente>(this.apiUrl + '/consegna-paziente/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get task consegna-paziente', null))
        );
    }

    getTaskSospensione(id: string): Observable<TaskSospensione> {
        return this.httpClient.get<TaskConsegnaPaziente>(this.apiUrl + '/sospensione/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get task sospensione', null))
        );
    }

    getTaskAccessoOrdinario(id: string): Observable<TaskAccessoOrdinario> {
        return this.httpClient.get<TaskAccessoOrdinario>(this.apiUrl + '/accesso-ordinario/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get task accesso ordinario', null))
        );
    }

    getTaskAccessoStraordinario(id: string): Observable<TaskAccessoStraordinario> {
        return this.httpClient.get<TaskAccessoStraordinario>(this.apiUrl + '/accesso-straordinario/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get task accesso straordinario', null))
        );
    }

    getTaskGenerico(id: string): Observable<TaskGenerico> {
        return this.httpClient.get<TaskGenerico>(this.apiUrl + '/generico/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get task generico', null))
        );
    }

    getTaskCollaudo(id: string): Observable<TaskCollaudo> {
        return this.httpClient.get<TaskCollaudo>(this.apiUrl + '/collaudo/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get task collaudo', null))
        );
    }

    getTaskConsegnaMateriali(id: string): Observable<TaskConsegnaMateriali> {
        return this.httpClient.get<TaskConsegnaMateriali>(this.apiUrl + '/consegna-materiali/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get task consegna-materiali', null))
        );
    }

    getTaskVerificaElettrica(id: string): Observable<TaskVerificaElettrica> {
        return this.httpClient.get<TaskVerificaElettrica>(this.apiUrl + '/verifica-elettrica/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get task verifica-elettrica', null))
        );
    }

    getTaskSostituzione(id: string): Observable<TaskSostituzione> {
        return this.httpClient.get<TaskSostituzione>(this.apiUrl + '/sostituzione/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get task sostituzione', null))
        );
    }

    getTaskCompliance(id: string): Observable<TaskCompliance> {
        return this.httpClient.get<TaskCompliance>(this.apiUrl + '/compliance/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get task compliance', null))
        );
    }

    getTaskReportistica(id: string): Observable<TaskReportistica> {
        return this.httpClient.get<TaskReportistica>(this.apiUrl + '/reportistica/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get task reportistica', null))
        );
    }

    getTaskRitiroDispositivo(id: string): Observable<TaskRitiroDispositivo> {
        return this.httpClient.get<TaskRitiroDispositivo>(this.apiUrl + '/ritiro-dispositivo/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get task ritiro-dispositivo', null))
        );
    }

    getTaskFollowup(id: string): Observable<TaskFollowup> {
        return this.httpClient.get<TaskFollowup>(this.apiUrl + '/followup/' + id).pipe(
            catchError(this.handleError(this.apiUrl + ' - get task followup', null))
        );
    }

    createTaskConsegnaReparto(command: TaskConsegnaRepartoCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/consegna-reparto', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    updateTaskConsegnaReparto(id: string, command: TaskConsegnaRepartoCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/consegna-reparto/' + id, command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    closeTask(id: string, tipo: string, command: ChiusuraTaskDto): Observable<any> {
        switch (tipo) {
            case this.globals.TIPO_TASK_CONSEGNA_REPARTO:
                return this.closeTaskConsegnaReparto(id, command);
                break;
            case this.globals.TIPO_TASK_CONSEGNA_PAZIENTE:
                return this.closeTaskConsegnaPaziente(id, command);
                break;
            case this.globals.TIPO_TASK_SOSPENSIONE:
                return this.closeTaskSospensione(id, command);
                break;
            case this.globals.TIPO_TASK_ACCESSO_ORDINARIO:
                return this.closeTaskAccessoOrdinario(id, command);
                break;
            case this.globals.TIPO_TASK_ACCESSO_STRAORDINARIO:
                return this.closeTaskAccessoStraordinario(id, command);
                break;
            case this.globals.TIPO_TASK_GENERICO:
                return this.closeTaskGenerico(id, command);
                break;
            case this.globals.TIPO_TASK_COLLAUDO:
                return this.closeTaskCollaudo(id, command);
                break;
            case this.globals.TIPO_TASK_CONSEGNA_MATERIALI:
                return this.closeTaskConsegnaMateriali(id, command);
                break;
            case this.globals.TIPO_TASK_VERIFICA_ELETTRICA:
                return this.closeTaskVerificaElettrica(id, command);
                break;
            case this.globals.TIPO_TASK_COMPLIANCE:
                return this.closeTaskCompliance(id, command);
                break;
            case this.globals.TIPO_TASK_REPORTISTICA:
                return this.closeTaskReportistica(id, command);
                break;
            case this.globals.TIPO_TASK_FOLLOWUP:
                return this.closeTaskFollowup(id, command);
                break;
            case this.globals.TIPO_TASK_RITIRO_DISPOSITIVO:
                return this.closeTaskRitiroDispositivo(id, command);
                break;
            case this.globals.TIPO_TASK_SOSTITUZIONE:
                return this.closeTaskSostituzione(id, command);
                break;
            default:
                return null;
        }
    }
    
    closeTaskConsegnaReparto(id: string, command: ChiusuraTaskDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/consegna-reparto/' + id + '/chiudi', command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - chiudi', null))
        );
    }

    deleteTaskConsegnaReparto(id: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/consegna-reparto/' + id, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete', null))
        );
    }

    createTaskConsegnaPaziente(command: TaskConsegnaPazienteCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/consegna-paziente', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    updateTaskConsegnaPaziente(id: string, command: TaskConsegnaPazienteCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/consegna-paziente/' + id, command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    closeTaskConsegnaPaziente(id: string, command: ChiusuraTaskDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/consegna-paziente/' + id + '/chiudi', command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - chiudi', null))
        );
    }

    deleteTaskConsegnaPaziente(id: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/consegna-paziente/' + id, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete', null))
        );
    }

    createTaskSospensione(command: TaskSospensioneCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/sospensione', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    updateTaskSospensione(id: string, command: TaskSospensioneCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/sospensione/' + id, command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    closeTaskSospensione(id: string, command: ChiusuraTaskDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/sospensione/' + id + '/chiudi', command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - chiudi', null))
        );
    }

    deleteTaskSospensione(id: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/sospensione/' + id, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete', null))
        );
    }

    createTaskAccessoOrdinario(command: TaskAccessoOrdinarioCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/accesso-ordinario', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    updateTaskAccessoOrdinario(id: string, command: TaskAccessoOrdinarioCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/accesso-ordinario/' + id, command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    closeTaskAccessoOrdinario(id: string, command: ChiusuraTaskDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/accesso-ordinario/' + id + '/chiudi', command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - chiudi', null))
        );
    }

    deleteTaskAccessoOrdinario(id: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/accesso-ordinario/' + id, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete', null))
        );
    }

    createTaskAccessoStraordinario(command: TaskAccessoStraordinarioCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/accesso-straordinario', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    updateTaskAccessoStraordinario(id: string, command: TaskAccessoStraordinarioCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/accesso-straordinario/' + id, command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    closeTaskAccessoStraordinario(id: string, command: ChiusuraTaskDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/accesso-straordinario/' + id + '/chiudi', command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - chiudi', null))
        );
    }

    deleteTaskAccessoStraordinario(id: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/accesso-straordinario/' + id, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete', null))
        );
    }

    createTaskGenerico(command: TaskGenericoCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/generico', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    updateTaskGenerico(id: string, command: TaskGenericoCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/generico/' + id, command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    closeTaskGenerico(id: string, command: ChiusuraTaskDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/generico/' + id + '/chiudi', command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - chiudi', null))
        );
    }

    deleteTaskGenerico(id: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/generico/' + id, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete', null))
        );
    }

    createTaskCollaudo(command: TaskCollaudoCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/collaudo', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    updateTaskCollaudo(id: string, command: TaskCollaudoCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/collaudo/' + id, command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    closeTaskCollaudo(id: string, command: ChiusuraTaskDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/collaudo/' + id + '/chiudi', command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - chiudi', null))
        );
    }

    deleteTaskCollaudo(id: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/collaudo/' + id, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete', null))
        );
    }

    createTaskConsegnaMateriali(command: TaskConsegnaMaterialiCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/consegna-materiali', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    updateTaskConsegnaMateriali(id: string, command: TaskConsegnaMaterialiCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/consegna-materiali/' + id, command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    closeTaskConsegnaMateriali(id: string, command: ChiusuraTaskDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/consegna-materiali/' + id + '/chiudi', command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - chiudi', null))
        );
    }

    deleteTaskConsegnaMateriali(id: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/consegna-materiali/' + id, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete', null))
        );
    }

    createTaskVerificaElettrica(command: TaskVerificaElettricaCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/verifica-elettrica', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    updateTaskVerificaElettrica(id: string, command: TaskVerificaElettricaCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/verifica-elettrica/' + id, command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    closeTaskVerificaElettrica(id: string, command: ChiusuraTaskDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/verifica-elettrica/' + id + '/chiudi', command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - chiudi', null))
        );
    }

    deleteTaskVerificaElettrica(id: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/verifica-elettrica/' + id, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete', null))
        );
    }

    createTaskRitiroDispositivo(command: TaskRitiroDispositivoCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/ritiro-dispositivo', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    updateTaskRitiroDispositivo(id: string, command: TaskRitiroDispositivoCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/ritiro-dispositivo/' + id, command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    closeTaskRitiroDispositivo(id: string, command: ChiusuraTaskDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/ritiro-dispositivo/' + id + '/chiudi', command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - chiudi', null))
        );
    }

    deleteTaskRitiroDispositivo(id: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/ritiro-dispositivo/' + id, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete', null))
        );
    }

    createTaskSostituzione(command: TaskSostituzioneCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/sostituzione', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    updateTaskSostituzione(id: string, command: TaskSostituzioneCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/sostituzione/' + id, command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    closeTaskSostituzione(id: string, command: ChiusuraTaskDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/sostituzione/' + id + '/chiudi', command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - chiudi', null))
        );
    }

    deleteTaskSostituzione(id: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/sostituzione/' + id, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete', null))
        );
    }

    createTaskFollowup(command: TaskFollowupCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/followup', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    updateTaskFollowup(id: string, command: TaskFollowupCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/followup/' + id, command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    closeTaskFollowup(id: string, command: ChiusuraTaskDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/followup/' + id + '/chiudi', command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - chiudi', null))
        );
    }

    deleteTaskFollowup(id: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/followup/' + id, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete', null))
        );
    }

    createTaskCompliance(command: TaskComplianceCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/compliance', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    updateTaskCompliance(id: string, command: TaskComplianceCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/compliance/' + id, command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    closeTaskCompliance(id: string, command: ChiusuraTaskDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/compliance/' + id + '/chiudi', command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - chiudi', null))
        );
    }

    deleteTaskCompliance(id: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/compliance/' + id, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete', null))
        );
    }

    createTaskReportistica(command: TaskReportisticaCommand): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/reportistica', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                const id = this.extractIdFromLocation(res);
                if (id) {
                    return of(id);
                } else {
                    return of(null);
                }
            }),
            catchError(this.handleError(this.apiUrl + ' - create', null))
        );
    }

    updateTaskReportistica(id: string, command: TaskReportisticaCommand): Observable<any> {
        return this.httpClient.put(this.apiUrl + '/reportistica/' + id, command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - update', null))
        );
    }

    closeTaskReportistica(id: string, command: ChiusuraTaskDto): Observable<any> {
        return this.httpClient.post(this.apiUrl + '/reportistica/' + id + '/chiudi', command, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - chiudi', null))
        );
    }

    deleteTaskReportistica(id: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/reportistica/' + id, {observe: 'response'}).pipe(
            catchError(this.handleError(this.apiUrl + ' - delete', null))
        );
    }

    aggiungiDocumento(tipo: string, taskId: string, file: File, command: TaskDocumentoCommand): Observable<number> {
        let id: number;

        return this.httpClient.post(this.apiUrl + '/' + taskId + '/' + tipo, command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<TaskDocumento> => {
                id = this.extractIdFromLocation(res);
                if (id) {
                    return this.httpClient.get<TaskDocumento>(this.apiUrl + '/' + taskId + '/' + tipo + '/' + id);
                } else {
                    return of(null);
                }
            }),
            switchMap((documento: TaskDocumento) => {
                if (documento != null) {
                    return this.httpClient.put(this.storageBasePath + documento.fileUrl, file);
                } else {
                    return of(null);
                }
            }),
            switchMap(() => {
                return of(id);
            }),
            catchError(this.handleError(' - aggiungiDocumento', null))
        );
    }

    eliminaDocumento(tipo: string, taskId: string, documentoId: string): Observable<any> {
        return this.httpClient.delete(this.apiUrl + '/' + taskId + '/' + tipo + '/' + documentoId).pipe(
            catchError(this.handleError(this.apiUrl + ' - eliminaDocumento', null))
        );
    }

    getDocumenti(tipo: string, id: string): Observable<Array<any>> {
        return this.httpClient.get<Array<any>>(this.apiUrl + '/' + id + '/' + tipo).pipe(
            catchError(this.handleError(this.apiUrl + ' - getDocumenti', null))
        );
    }

    getDocumentDownloadUrl(tipo: string, documentId: string): Observable<string> {
        return this.httpClient.get<ResultUrl>(this.apiUrl + '/' + tipo + '/' + documentId + '/download-url').pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError(this.apiUrl + ' - getDocumentoDownloadUrl', null))
        );
    }
    
    signDdt(taskId: string, documentId: string): Observable<any> {
        let id: number;

        return this.httpClient.post(this.apiUrl + '/' + taskId + '/firma-ddt/' + documentId, {}).pipe(
            catchError(this.handleError(' - firma-ddt', null))
        );
    }
    
    getSignaturesUrl(taskId: string): Observable<SignaturesResultUrl> {
        return this.httpClient.get(this.apiUrl + '/' + taskId + '/signatures-url').pipe(
            catchError(this.handleError(' - signatures-url', null))
        );
    }
    
    getDdtSignatureUrl(taskId: string): Observable<ResultUrl> {
        return this.httpClient.get(this.apiUrl + '/' + taskId + '/ddt-signature-url').pipe(
            catchError(this.handleError(' - ddt-signature-url', null))
        );
    }
}
