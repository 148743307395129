// *************************************************
// * domolife_system Project - © 2020
// *
// * Author: Armando Taglieri (jadbit)
// * Created on: 3/31/20 11:31 PM
// *************************************************

import {ReportPazientiVentCommand} from './report-pazienti-vent-command';
import {StatoPrescrizione} from '../stato-prescrizione';

export class ReportPazientiVentDownloadCommand extends ReportPazientiVentCommand {

    reportType: string;

    constructor(
        aziendaId: number,
        page: number,
        size: number,
        from: string,
        to: string,
        prescrittoreId: number,
        strutturaId: number,
        profiloId: number,
        ventilatoreId: number,
        stato: StatoPrescrizione,
        reportType: string
    ) {
        super(aziendaId, page, size, from, to, prescrittoreId, strutturaId, profiloId, ventilatoreId, stato);
        this.reportType = reportType;
    }
}
