import {Component} from '@angular/core';
import {DatagridComponent} from '../common/datagrid.component';
import {Fornitore} from '../../model/query/fornitore';
import {Router} from '@angular/router';
import {FornitoriService} from '../../core/fornitori.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-fornitori',
    templateUrl: './fornitori.component.html',
    styleUrls: ['./fornitori.component.css']
})
export class FornitoriComponent extends DatagridComponent<Fornitore> {

    constructor(
        private fornitoriService: FornitoriService,
        dialog: DialogService,
        router: Router
    ) {
        super(fornitoriService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/fornitori/';
    }

}
