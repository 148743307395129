import {ChiusuraTaskDto} from './chiusura-task-dto';

export class ChiusuraTaskFollowupDto extends ChiusuraTaskDto {
    followupPositivo: boolean;
    chiarimenti: boolean;
    ulterioreFollowup: boolean;
    
    constructor(
        dataCompletamento: Date,
        completato: boolean,
        note: string,
        followupPositivo: boolean,
        chiarimenti: boolean,
        ulterioreFollowup: boolean
    ) {
        super(dataCompletamento, completato, note);
        this.followupPositivo = followupPositivo;
        this.chiarimenti = chiarimenti;
        this.ulterioreFollowup = ulterioreFollowup;
    }
}
