import {AccountCommand} from './account-command';

export class AmministratoreCreateCommand extends AccountCommand {

    amministratore: any;

    constructor(nome: string, cognome: string, username: string, email: string) {
        super(username, email);
        this.amministratore = {
            'nome': nome,
            'cognome': cognome,
        };
    }
}
