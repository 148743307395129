import {Observable, of as observableOf} from 'rxjs';
import {switchMap, zip} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {MeService} from './me.service';
import {AziendeService} from './aziende.service';
import {ApprovatoriService} from './approvatori.service';
import {OperatoriService} from './operatori.service';
import {AppaltantiService} from './appaltanti.service';
import {PartnersService} from './partners.service';
import {PrescrittoriService} from './prescrittori.service';
import {Azienda} from '../model/query/azienda';
import {QueryResult} from '../model/query/query-result';
import {Distretto} from '../model/query/distretto';
import {Prescrittore} from '../model/query/prescrittore';
import {Approvatore} from '../model/query/approvatore';
import {Operatore} from '../model/query/operatore';
import {Appaltante} from '../model/query/appaltante';
import {DataObserver} from './data-observer';

@Injectable()
export class AziendeCorrentiService {
    private azienda: DataObserver<Azienda> = new DataObserver<Azienda>(null);
    private aziende: DataObserver<Array<Azienda>> = new DataObserver<Array<Azienda>>(new Array<Azienda>());
    private distretti: DataObserver<Array<Distretto>> = new DataObserver<Array<Distretto>>(new Array<Distretto>());
    private prescrittori: DataObserver<Array<Prescrittore>> = new DataObserver<Array<Prescrittore>>(new Array<Prescrittore>());
    private approvatori: DataObserver<Array<Approvatore>> = new DataObserver<Array<Approvatore>>(new Array<Approvatore>());
    private appaltanti: DataObserver<Array<Appaltante>> = new DataObserver<Array<Appaltante>>(new Array<Appaltante>());

    constructor(
        private aziendeService: AziendeService,
        private approvatoriService: ApprovatoriService,
        private operatoriService: OperatoriService,
        private appaltantiService: AppaltantiService,
        private partnersService: PartnersService,
        private prescrittoriService: PrescrittoriService,
        private meService: MeService,
        private authService: AuthService
    ) {
        this.refresh();
    }

    public refresh(): void {
        this.meService.getMe().subscribe(user => {
            let o: Observable<Array<Azienda>>;
            
            if (this.authService.isPrescrittoreUser()) {
                o = this.prescrittoriService.getAziende(user.id.toString());
            } else if (this.authService.isApprovatoreUser()) {
                o = this.approvatoriService.getAziende(user.id.toString());
            } else if (this.authService.isOperatoreUser()) {
                o = this.operatoriService.getAziende(user.id.toString());
            } else if (this.authService.isAppaltanteUser()) {
                o = this.appaltantiService.getAziende(user.id.toString());
            } else if (this.authService.isPartnerUser()) {
                o = this.partnersService.getAziende(user.id.toString());
            } else {
                o = this.aziendeService.getAll(0, 10000, null, null, null, null, null).pipe(
                    switchMap((q: QueryResult<Azienda>) => observableOf(q.objects))
                );
            }
            o.subscribe(aziende => {
                this.aziende.update(aziende);
                if (aziende.length > 0) {
                    this.azienda.update(aziende[0]);            
                    this.loadDistretti();
                    this.loadPrescrittori();
                    this.loadApprovatori();
                    this.loadAppaltanti();
                }
            });
        });
    }
    
    public getAziende(): Observable<Array<Azienda>> {
        return this.aziende.getObservable();
    }
    
    public getAzienda(): Observable<Azienda> {
        return this.azienda.getObservable();
    }

    public getAziendaData(): Azienda {
        return this.azienda.getData();
    }

    public getDistretti(): Observable<Array<Distretto>> {
        return this.distretti.getObservable();
    }

    public getPrescrittori(): Observable<Array<Prescrittore>> {
        return this.prescrittori.getObservable();
    }

    public getApprovatori(): Observable<Array<Approvatore>> {
        return this.approvatori.getObservable();
    }

    public getAppaltanti(): Observable<Array<Appaltante>> {
        return this.appaltanti.getObservable();
    }

    public setAzienda(id: string): void {
        let a: Azienda = this.aziende.getData().find(a => a.id.toString() == id);
        
        if (a) {
            this.azienda.update(a);
            this.loadDistretti();
            this.loadPrescrittori();
            this.loadApprovatori();
            this.loadAppaltanti();
        }
    }
    
    private loadDistretti(): void {
        if (this.azienda.getData()) {
            this.aziendeService.getDistretti(this.azienda.getData().id.toString()).subscribe(distretti => this.distretti.update(distretti));
        } else {
            throw "Nessuna azienda selezionata";
        }
    }

    private loadPrescrittori(): void {
        if (this.azienda.getData()) {
            this.aziendeService.getPrescrittori(this.azienda.getData().id.toString()).subscribe(prescrittori => this.prescrittori.update(prescrittori));
        } else {
            throw "Nessuna azienda selezionata";
        }
    }

    private loadApprovatori(): void {
        if (this.azienda.getData()) {
            this.aziendeService.getApprovatori(this.azienda.getData().id.toString()).subscribe(approvatori => this.approvatori.update(approvatori));
        } else {
            throw "Nessuna azienda selezionata";
        }
    }

    private loadAppaltanti(): void {
        if (this.azienda.getData()) {
            this.aziendeService.getAppaltanti(this.azienda.getData().id.toString()).subscribe(appaltanti => this.appaltanti.update(appaltanti));
        } else {
            throw "Nessuna azienda selezionata";
        }
    }
}
