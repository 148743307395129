import {AbstractCommand} from './abstract-command';

export class VentilatoreCommand extends AbstractCommand {
    nome: string;
    
    constructor(
        nome: string
    ) {
        super();
        this.nome = nome;
    }

}
