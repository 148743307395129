import {AccountCommand} from './account-command';

export class InfermiereCreateCommand extends AccountCommand {

    infermiere: any;

    constructor(nome: string, cognome: string, username: string, email: string) {
        super(username, email);
        this.infermiere = {
            'nome': nome,
            'cognome': cognome,
        };
    }
}
