import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {OperatoreCallCenter} from '../model/query/operatore-callcenter';
import {Azienda} from '../model/query/azienda';
import {Contratto} from '../model/query/contratto';

@Injectable()
export class OperatoriCallCenterService extends BaseService<OperatoreCallCenter> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('operatori-call-center');
    }
}
