import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Nazione} from '../model/query/nazione';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {QueryResult} from '../model/query/query-result';

@Injectable()
export class NazioniService extends BaseService<Nazione> {

    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('nazioni');
    }

    getList(): Observable<QueryResult<Nazione>> {
        return this.httpClient.get<QueryResult<Nazione>>(this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + ' - get', null))
        );
    }
}
