import {Component} from '@angular/core';
import {DatagridComponent} from '../common/datagrid.component';
import {Amministratore} from '../../model/query/amministratore';
import {Router} from '@angular/router';
import {AmministratoriService} from '../../core/amministratori.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-amministratori',
    templateUrl: './amministratori.component.html',
    styleUrls: ['./amministratori.component.css']
})
export class AmministratoriComponent extends DatagridComponent<Amministratore> {

    constructor(
        private amministratoriService: AmministratoriService,
        dialog: DialogService,
        router: Router
    ) {
        super(amministratoriService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/amministratori/';
    }

}
