import {Component} from '@angular/core';
import {IncaricatiService} from '../../core/incaricati.service';
import {Router} from '@angular/router';
import {DatagridComponent} from '../common/datagrid.component';
import {Incaricato} from '../../model/query/incaricato';
import {Action} from '../../model/action';
import {ActionService} from '../../core/action.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-incaricati',
    templateUrl: './incaricati.component.html',
    styleUrls: ['./incaricati.component.css']
})
export class IncaricatiComponent extends DatagridComponent<Incaricato> {
    constructor(
        public incaricatiService: IncaricatiService,
        public actionService: ActionService,
        dialog: DialogService,
        router: Router
    ) {
        super(incaricatiService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/incaricati/';
    }

    onAction(entity: Incaricato, action: Action) {
        const url = this.getEditUrl() + entity.id;
        let queryParams = this.getEditQueryParams(entity);
        if (!queryParams) {
            queryParams = {
                'action': action,
            };
        } else {
            queryParams['action'] = action;
        }
        this.router.navigate([url], {queryParams: queryParams});
    }
}
