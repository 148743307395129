import {AbstractCommand} from './abstract-command';

export class ParametroVentilatoreCommand extends AbstractCommand {
    nome: string;
    unitaDiMisura: string;

    constructor(nome: string, unitaDiMisura: string)
    {
        super();
        this.nome = nome;
        this.unitaDiMisura = unitaDiMisura;
    }

}
