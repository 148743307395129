import {AccountCommand} from './account-command';

export class PrescrittoreCreateCommand extends AccountCommand {

    prescrittore: any;
    enabled: boolean;

    constructor(
        nome: string,
        cognome: string,
        username: string,
        email: string,
        aziendaId: number,
        strutturaId: number,
        repartoId: number,
        tipo: string,
        // codiceStruttura: string,
        // tipoStruttura: string,
        codiceFiscale: string,
        telefono: string,
        idErogatore: string,
        tipoErogatore: string,
        enabled: boolean
    ) {
        super(username, email);
        this.enabled = enabled;
        this.prescrittore = {
            'nome': nome,
            'cognome': cognome,
            'aziendaId': aziendaId,
            'strutturaId': strutturaId,
            'repartoId': repartoId,
            'tipo': tipo,
            // 'codiceStruttura': codiceStruttura,
            // 'tipoStruttura': tipoStruttura,
            'codiceFiscale': codiceFiscale,
            'telefono': telefono,
            'idErogatore': idErogatore,
            'tipoErogatore': tipoErogatore
        };
    }
}
