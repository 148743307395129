// *************************************************
// * domolife_system Project - © 2020
// *
// * Author: Armando Taglieri (jadbit)
// * Created on: 3/31/20 11:31 PM
// *************************************************

import {ReportPazientiCommand} from './report-pazienti-command';
import {StatoPrescrizione} from '../stato-prescrizione';

export class ReportPazientiDownloadCommand extends ReportPazientiCommand {

    reportType: string;

    constructor(
        page: number,
        size: number,
        prescrittoreId: number,
        aziendaId: number,
        distrettoId: number,
        partnerId: number,
        stato: StatoPrescrizione,
        tipo: string,
        reportType: string
    ) {
        super(page, size, prescrittoreId, aziendaId, distrettoId, partnerId, stato, tipo);
        this.reportType = reportType;
    }
}
