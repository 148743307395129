// *************************************************
// * domolife_system Project - © 2020
// *
// * Author: Armando Taglieri (jadbit)
// * Created on: 06/17/20 5:44 PM
// *************************************************

import {ReportCommand} from './report-command';

export class ReportConsegneCommand extends ReportCommand {
    distrettoId: number;
    pazienteId: number;
    partnerId: number;

    constructor(
        aziendaId: number,
        page: number,
        size: number,
        from: string,
        to: string,
        distrettoId: number,
        pazienteId: number,
        partnerId: number
    ) {
        super(aziendaId, page, size, from, to);
        this.distrettoId = distrettoId;
        this.pazienteId = pazienteId;
        this.partnerId = partnerId;
    }
}
