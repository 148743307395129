import {Component, OnInit} from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Action} from '../../../model/action';
import {ActivatedRoute, Router} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {ActionService} from '../../../core/action.service';
import {AppaltiService} from '../../../core/appalti.service';
import {ContrattiService} from '../../../core/contratti.service';
import {AziendeService} from '../../../core/aziende.service';
import {UtilsService} from '../../../core/utils.service';
import {Appalto} from '../../../model/query/appalto';
import {Azienda} from '../../../model/query/azienda';
import {Contratto} from '../../../model/query/contratto';
import {AppaltoCommand} from '../../../model/command/appalto-command';
import {DialogService} from '../../dialog';

@Component({
    selector: 'app-appalto-detail',
    templateUrl: './appalto-detail.component.html',
    styleUrls: ['./appalto-detail.component.css']
})
export class AppaltoDetailComponent implements OnInit {
    tipiPrescrizioniDescription: any;
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    appalto: Appalto;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    aziende: Array<Azienda> = new Array<Azienda>();
    selectedAziendaId: number;
    contratti: Array<Contratto> = new Array<Contratto>();
    selectedContratti: Array<Contratto> = new Array<Contratto>();
    datagridState: ClrDatagridStateInterface;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private appaltiService: AppaltiService,
        private contrattiService: ContrattiService,
        private aziendeService: AziendeService,
        private utilsService: UtilsService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        public actionService: ActionService,
        private dialog: DialogService
    ) {
        this.createForm();
        this.tipiPrescrizioniDescription = this.utilsService.getServiziDescription();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        this.aziendeService.getAll(0, 10000000, [], [], [], null, null).subscribe(aziende => {
            this.aziende = aziende.objects;
            if (this.id !== 'new') {
                this.contrattiService.getAll(0, 1000, ["appaltoForeignKey"], ["eq"], [this.id], null, null).subscribe((contratti) => {
                    this.contratti = contratti.objects;
                });
                this.appaltiService.get(this.id).subscribe((appalto) => {
                    this.appalto = appalto;
                    this.selectedAziendaId = appalto.aziendaForeignKey;
                    this.initializeFormFields(appalto);
                });
            } else {
                this.initializeFormFields(null);
            }
        });
    }

    onSelectAzienda(id: number) {
        this.selectedAziendaId = id;
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command = new AppaltoCommand(
            this.utilsService.fieldToDate(formValue.dataStipula),
            Number(formValue.selectedAziendaId),
            formValue.descrizione
        );
        if (!this.id || this.id === 'new') {
            this.appaltiService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'Appalto salvato!';
                    this.alertClosed = false;
                }
            });
        } else {
            this.appaltiService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Appalto salvato!';
                    this.alertClosed = false;
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            selectedAziendaId: ['', [Validators.required]],
            descrizione: ['', [Validators.required, Validators.maxLength(255)]],
            dataStipula: ['', [Validators.required]]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(appalto: Appalto): void {
        let formValues;

        if (appalto) {
            formValues = {
                selectedAziendaId: appalto.aziendaForeignKey,
                descrizione: appalto.descrizione,
                dataStipula: this.utilsService.dateToField(appalto.dataStipula)
            };
            this.form.get('selectedAziendaId').disable();
        } else {
            formValues = {
                selectedAziendaId: '',
                descrizione: '',
                dataStipula: this.utilsService.dateToField(new Date())
            };
        }
        this.form.setValue(formValues);
    }

    onNuovoContratto() {
        this.router.navigate(['/contratti/new'], {queryParams: {'appaltoId': this.appalto.id}});
    }

    onEliminaContratto(contratto: Contratto) {
        this.dialog.confirm({
            title: 'Comunicazione',
            content: 'Confermi la cancellazione?',
            acceptText: 'Sì',
            cancelText: 'Annulla',
            acceptType: 'warning',
            iconShape: null
        }).subscribe((result: boolean) => {
            if (result) {
                this.contrattiService.delete(contratto.id.toString()).subscribe(x => {
                    if (!x) {
                        this.selectedContratti = new Array<Contratto>();
                        this.dialog.info({
                            title: 'Info',
                            content: 'Eliminato!',
                        }).subscribe(() => {
                            this.contrattiService.getAll(0, 1000, ["appaltoForeignKey"], ["eq"], [this.id], null, null).subscribe((contratti) => {
                                this.contratti = contratti.objects;
                            });
                        });
                    }
                });
            }
        });
    }

    onVisualizzaContratto(contratto: Contratto) {
        this.router.navigate(['/contratti/' + contratto.id], {queryParams: {'action': this.actionService.ACTION.VIEW}});
    }

    onModificaContratto(contratto: Contratto) {
        this.router.navigate(['/contratti/' + contratto.id]);
    }

}
