import {Component, OnInit} from '@angular/core';
import {ClrLoadingState} from '@clr/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {ParametriVentilatoreService} from '../../../core/parametri-ventilatore.service';
import {ParametroVentilatore} from '../../../model/query/parametro-ventilatore';
import {ParametroVentilatoreCommand} from '../../../model/command/parametro-ventilatore-command';
import {ActionService} from '../../../core/action.service';
import {Action} from '../../../model/action';

@Component({
    selector: 'app-parametro-ventilatore-detail',
    templateUrl: './parametro-ventilatore-detail.component.html',
    styleUrls: ['./parametro-ventilatore-detail.component.css']
})
export class ParametroVentilatoreDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    id: string;
    alertMessage: string;
    alertClosed = true;
    action: Action;
    readOnly = false;


    constructor(
        private route: ActivatedRoute,
        private parametriVentilatoreService: ParametriVentilatoreService,
        private formBuilder: FormBuilder,
        private globals: Globals,
        public actionService: ActionService,
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        if (this.id !== 'new') {
            this.parametriVentilatoreService.get(this.id).subscribe((parametro: ParametroVentilatore) => {
                this.initializeFormFields(parametro);
                if (this.action != null && this.action == this.actionService.ACTION.VIEW) {
                    this.readOnly = true;
                }
            });
        } else {
            this.initializeFormFields(null);
        }
    }

    createForm(): void {
        const formGroup = {
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            unitaDiMisura: ['', [Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(parametro: ParametroVentilatore): void {
        let formValues;

        if (parametro) {
            formValues = {
                nome: parametro.nome,
                unitaDiMisura: parametro.unitaDiMisura
            };
        } else {
            formValues = {
                nome: '',
                unitaDiMisura: ''
            };
        }
        this.form.setValue(formValues);
    }


    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command: ParametroVentilatoreCommand =
            new ParametroVentilatoreCommand(formValue.nome, formValue.unitaDiMisura);
        if (this.id === 'new') {
            this.parametriVentilatoreService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (!res) {
                    this.alertMessage = 'Il parametro è stato creato';
                    this.alertClosed = false;
                }
            });
        } else {
            this.parametriVentilatoreService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Il parametro è stato aggiornato';
                    this.alertClosed = false;
                }
            });
        }

    }

}
