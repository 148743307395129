import {Component} from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {Observable, Subscription} from 'rxjs';
import {QueryResult} from '../../model/query/query-result';
import {DatagridComponent} from '../common/datagrid.component';
import {DatagridRequest} from '../common/datagrid.component';
import {Paziente} from '../../model/query/paziente';
import {PazientiService} from '../../core/pazienti.service';
import {Router} from '@angular/router';
import {AziendeCorrentiService} from '../../core/aziende-correnti.service';
import {Azienda} from '../../model/query/azienda';
import {Action} from '../../model/action';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-pazienti-call-center',
    templateUrl: './pazienti-call-center.component.html',
    styles: []
})
export class PazientiCallCenterComponent extends DatagridComponent<Paziente> {
    azienda: Azienda;
    subscription: Subscription;

    constructor(
        private aziendeCorrentiService: AziendeCorrentiService,
        private pazientiService: PazientiService,
        dialog: DialogService,
        router: Router
    ) {
        super(pazientiService, router, dialog);
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscription = this.aziendeCorrentiService.getAzienda().subscribe(azienda => {
            this.azienda = azienda;
            if (azienda != null) {
                this.refresh(this.datagridState);
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    protected manageRequest(request: DatagridRequest<this>) {
        if (request.component.azienda) {
            super.manageRequest(request);
        }
    }
    
    protected fetchData(state: ClrDatagridStateInterface, fields: Array<string>, operators: Array<string>,
                        values: Array<any>): Observable<QueryResult<Paziente>> {
        let i = fields.indexOf('azienda');
        
        if (i == -1) {
            fields.push("comune.distretto.aziendaForeignKey");
            operators.push("eq");
            values.push(this.azienda.id);
        } else {
            values[i] = this.azienda.id;
        }
        if (!state.sort) {
            state.sort = {'by': 'cognome', 'reverse': false};
        }

        return this.pazientiService.getAllCompact(
            state.page.current ? state.page.current - 1 : 0,
            state.page.size ? state.page.size : 10,
            fields,
            operators,
            values,
            state.sort ? state.sort.by.toString() : null,
            state.sort ? (state.sort.reverse ? 'desc' : 'asc') : null
        );
    }

    protected getEditUrl(): string {
        return  '/pazienti-call-center/';
    }

    onVisualizzaTerapie(entity: Paziente) {
        this.router.navigate([this.getEditUrl() + entity.id]);
    }
}
