import {AbstractCommand} from './abstract-command';

export class StrutturaCommand extends AbstractCommand {
    nome: string;
    aziendaId: number;

    constructor(
        nome: string,
        aziendaId: number
    ) {
        super();
        this.nome = nome;
        this.aziendaId = aziendaId;
    }

}
