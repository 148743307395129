import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Contratto} from '../../model/query/contratto';
import {FormBuilder, Validators} from '@angular/forms';
import {PrescrizioniService} from '../../core/prescrizioni.service';
import {ContrattiService} from '../../core/contratti.service';
import {UtilsService} from '../../core/utils.service';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-export-ministero',
    templateUrl: './export-ministero.component.html',
    styleUrls: ['./export-ministero.component.css']
})
export class ExportMinisteroComponent implements OnInit {
    form: any;

    contratti: Contratto[];

    constructor(
        private formBuilder: FormBuilder,
        private prescrizioniService: PrescrizioniService,
        private contrattiService: ContrattiService,
        public utilsService: UtilsService,
    ) {
        this.createForm();
        this.contrattiService.getAll(0, 10000, null, null, null, null, null).subscribe((contratti) => {
            this.contratti = contratti.objects.filter(contratto => contratto.servizio == 'o2c' || contratto.servizio == 'o2l').map(contratto => {
                contratto.servizio = this.utilsService.getServiziDescription()[contratto.servizio];
                return contratto;
            });
            this.initForm();
        });
    }

    ngOnInit() {
    }

    createForm(): void {
        const formGroup = {
            dateFrom: [{value: ''}, [Validators.required]],
            dateTo: [{value: ''}, [Validators.required]],
            contratto: [{value: ''}, [Validators.required]],
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initForm() {
        const formValues = {
            dateFrom: this.utilsService.dateToField(new Date()),
            dateTo: this.utilsService.dateToField(new Date()),
            contratto: '',
        };
        this.form.setValue(formValues);
    }

    onSubmit(tipo: string) {
        const formValue = this.form.getRawValue();
        const dateFrom = moment(this.utilsService.fieldToDate(formValue.dateFrom)).format('YYYY-MM-DD');
        const dateTo = moment(this.utilsService.fieldToDate(formValue.dateTo)).format('YYYY-MM-DD');
        if (tipo === 'dd') {
            this.prescrizioniService.getExportDD(dateFrom, dateTo, +formValue.contratto).subscribe((result) => {
                if (result.url) {
                    window.open(environment.storageUrl + result.url);
                }
            });
        } else if (tipo == 'f1') {
            this.prescrizioniService.getExportF1(dateFrom, dateTo, +formValue.contratto).subscribe((result) => {
                if (result.url) {
                    window.open(environment.storageUrl + result.url);
                }
            });
        } else if (tipo == 'f2') {
            this.prescrizioniService.getExportF2(dateFrom, dateTo, +formValue.contratto).subscribe((result) => {
                if (result.url) {
                    window.open(environment.storageUrl + result.url);
                }
            });
        }
    }

}
