import {ChiusuraTaskConFirmaDto} from './chiusura-task-con-firma-dto';

export class ChiusuraTaskCollaudoDto extends ChiusuraTaskConFirmaDto {
    matricola1: string;
    matricola2: string;
    gruppoContinuita: boolean;
    esitoPositivo: boolean;
    
    constructor(
        dataCompletamento: Date,
        completato: boolean,
        note: string,
        firmaTecnico: string,
        firmaAssistito: string,
        firmaDelegato: string,
        matricola1: string,
        matricola2: string,
        gruppoContinuita: boolean,
        esitoPositivo: boolean
    ) {
        super(dataCompletamento, completato, note, firmaTecnico, firmaAssistito, firmaDelegato);
        this.matricola1 = matricola1;
        this.matricola2 = matricola2;
        this.gruppoContinuita = gruppoContinuita;
        this.esitoPositivo = esitoPositivo;
    }
}
