import {ChiusuraTaskConFirmaDto} from './chiusura-task-con-firma-dto';

export class ChiusuraTaskRitiroDispositivoDto extends ChiusuraTaskConFirmaDto {
    oreFunzionamento: number;
    statoDispositivo: string;
    ritiroConsumabili: boolean;
    
    constructor(
        dataCompletamento: Date,
        completato: boolean,
        note: string,
        firmaTecnico: string,
        firmaAssistito: string,
        firmaDelegato: string,
        oreFunzionamento: number,
        statoDispositivo: string,
        ritiroConsumabili: boolean
    ) {
        super(dataCompletamento, completato, note, firmaTecnico, firmaAssistito, firmaDelegato);
        this.oreFunzionamento = oreFunzionamento;
        this.statoDispositivo = statoDispositivo;
        this.ritiroConsumabili = ritiroConsumabili;
    }
}
