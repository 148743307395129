import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {DatagridComponent} from '../common/datagrid.component';
import {ClrDatagridStateInterface} from '@clr/angular';
import {QueryResult} from '../../model/query/query-result';
import {ImportJob} from '../../model/query/import-job';
import {ImportJobsService} from '../../core/import-jobs.service';
import {Router} from '@angular/router';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-import',
    templateUrl: './import-jobs.component.html',
    styleUrls: ['./import-jobs.component.css']
})
export class ImportJobsComponent extends DatagridComponent<ImportJob> {

    constructor(
        private importJobsService: ImportJobsService,
        dialog: DialogService,
        router: Router
    ) {
        super(importJobsService, router, dialog);
    }

    protected fetchData(
        state: ClrDatagridStateInterface,
        fields: Array<string>,
        operators: Array<string>,
        values: Array<any>
    ): Observable<QueryResult<ImportJob>> {
        if (!state.sort) {
            state.sort = {'by': 'createdOn', 'reverse': true};
        }
        return super.fetchData(state, fields, operators, values);
    }

    protected getEditUrl(): string {
        return '/import_jobs';
    }

}
