import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Reparto} from '../model/query/reparto';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class RepartiService extends BaseService<Reparto> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('reparti');
    }

    getReparti(): Observable<Array<Reparto>> {
        return this.httpClient.get<Array<Reparto>>(this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + '- : get reparti', null))
        );
    }

}
